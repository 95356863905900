<div class="input-icon dropdown-search fxs-search-wrapper" [class]="{ openCustom: showDropDown }">
  <div>
    <i *ngIf="!spinnerVisible" class="input-icon ms-Icon ms-Icon--Search" style="z-index: 999"></i>
    <i *ngIf="spinnerVisible" class="input-icon loader" style="z-index: 999"></i>
  </div>
  <div class="fxs-search-box" [class]="{ openCustom: showDropDown }">
    <input
      #universalSearchInput
      type="text"
      id="universal_search_input"
      class="form-control search-text dropdown-toggle"
      (click)="beforeToggleDropDown()"
      autocomplete="off"
      *ngIf="isUser()"
      (input)="beforeToggleDropDown(); onSearchTextChange(obj.searchTextbox)"
      [(ngModel)]="obj.searchTextbox"
      name="searchUsersTextbox"
      placeholder="{{ 'generic_GlobalSearch' | translate }}"
    />

    <div class="dropdown-menu hold-on-click background-mask" *ngIf="showDropDown">
      <ng-container *ngIf="spinnerVisible || searchedItems?.searchDone; else firstPage">
        <div class="no-results-container" *ngIf="canSeeNoResultsContainer()">
          <p class="no-results" translate *ngIf="!spinnerVisible">generic_NoResults</p>
        </div>

        <div class="search-done-container" *ngIf="obj.searchTextbox && !obj.seeAll && !canSeeNoResultsContainer()">
          <div *ngIf="canSeeCantFindMessage()">
            <div class="cant-find-message" translate>generic_CantFindMessage</div>
          </div>
          <div class="results-container">
            <ng-container *ngFor="let searchedItem of $any(searchedItems) | keyvalue; let $index = index">
              <div
                class="reports-item p-l-5 p-r-5"
                *ngIf="searchedItem.value && $any(searchedItem.value).length > 0 && $any(searchedItem.key).indexOf('Empty') < 0"
              >
                <app-show-element
                  [items]="$any(searchedItem.value)"
                  [limit]="maxNumberOfElements[getSearchTypeEnumByString($any(searchedItem.key))]"
                  [type]="getSearchTypeEnumByString($any(searchedItem.key))"
                  (seeAllResults)="seeAllResults(getSearchTypeEnumByString($any(searchedItem.key)))"
                  (manageItem)="manageItem($event)"
                  (onManageClick)="onManageableElementClick($event)"
                  [allowSeeAll]="true"
                ></app-show-element>
              </div>
            </ng-container>
            <ng-container *ngFor="let searchedItem of $any(searchedItems) | keyvalue; let $index = index">
              <div class="reports-item p-l-5 p-r-5" *ngIf="$any(searchedItem.key).indexOf('Empty') >= 0">
                <app-show-element
                  [items]="$any(searchedItem.value)"
                  [limit]="maxNumberOfElements[getSearchTypeEnumByString($any(searchedItem.key))]"
                  [type]="getSearchTypeEnumByString($any(searchedItem.key))"
                  (seeAllResults)="seeAllResults(getSearchTypeEnumByString($any(searchedItem.key)))"
                  (manageItem)="manageItem($event)"
                  (onManageClick)="onManageableElementClick($event)"
                  [allowSeeAll]="true"
                ></app-show-element>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="all-results-container" *ngIf="obj.seeAll && !canSeeNoResultsContainer()">
          <div *ngIf="maxNumberOfElements['SeeAll'] && (allItemsResult?.filteredItems?.length || 0) > maxNumberOfElements['SeeAll']">
            <div class="cant-find-message"><span translate>generic_SeeAllMessage</span> <span translate>generic_CantFindMessage</span></div>
          </div>
          <div class="elements-container">
            <div class="title-container">
              <img
                class="flex-item"
                src="{{ obj.seeAll ? getIconImg(obj.seeAll) : '' }}"
                alt="{{ obj.seeAll ? (obj.seeAll | translate) : '' }}"
              />
              <span class="flex-item title" translate>{{ obj.seeAll }}</span>
              <span
                class="flex-item m-l-5"
                *ngIf="maxNumberOfElements['SeeAll'] && (allItemsResult?.filteredItems?.length || 0) > maxNumberOfElements['SeeAll']"
                >({{ maxNumberOfElements['SeeAll'] }}+)</span
              >
              <span class="flex-item line-2 m-l-10" style="width: 100%"></span>
              <span class="flex-item m-l-10 back-btn" style="white-space: nowrap" (click)="seeAllResultsBack()" translate>common_Back</span>
            </div>
            <div class="sub-title-container" style="text-align: right" *ngIf="(allItemsResult?.categories?.length || 0) > 0">
              <span *ngFor="let c of allItemsResult?.categories" (click)="selectCategory(c)">
                <span
                  class="m-l-15 categories-btn hidden-1400"
                  style="white-space: nowrap"
                  [class]="{ selected: c === obj.selectedCategory }"
                  >{{ c }}</span
                >
              </span>
            </div>
            <div class="results-container">
              <div
                class="report-items-container"
                *ngFor="let i of allItemsResult?.filteredItems | slice : 0 : maxNumberOfElements['SeeAll']; let $index = index"
              >
                <app-show-more-item
                  [item]="i"
                  [index]="$index"
                  (manageItem)="manageItem($event)"
                  (onManageableElementClick)="onManageableElementClick($event)"
                ></app-show-more-item>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #firstPage>
        <div class="search-done-container" *ngIf="canSeeCantFindResults()">
          <div class="cant-find-message" translate>generic_CantFindMessage</div>
        </div>
        <ng-container *ngIf="!obj.searchTextbox">
          <ng-container *ngIf="!loadingFirstPage">
            <div class="title-container" style="line-height: 35px">
              <mat-icon>schedule</mat-icon>
              <span class="title" style="font-size: 12px; line-height: 35px" translate>generic_RecentSearchs</span>
              <span class="line-2 m-l-10" style="flex: 1"></span>
              <span
                *ngIf="recents.length > 0"
                (click)="clearHistory()"
                class="m-l-10 see-all-results"
                style="white-space: nowrap; line-height: 35px; width: auto"
                translate
                >generic_ClearHistory</span
              >
            </div>
            <div style="display: flex; min-height: 90px">
              <p style="font: 12px; white-space: normal" translate *ngIf="showNoRecentSearches">generic_NoRecentSearches</p>
              <ng-container *ngFor="let search of recents">
                <app-recent-item [search]="search" (manageItem)="manageItem($event)"></app-recent-item>
              </ng-container>
            </div>
            <cv-horizontal-tab-group [activeTab]="activeTab" (tabChanged)="activeTab = $event.index + 1" type="line" class="small-tabs m-t-15">
              <cv-tab [title]="'common_MostUsed' | translate" classIcon="thumb_up material-icons-outlined">
                <div class="results-container results-container-first-page" *ngIf="activeTab == 1">
                  <div *ngIf="mostUsed[SearchTypeEnum.Reports].length" class="reports-item p-l-5 p-r-5">
                    <app-show-element
                      [items]="mostUsed[SearchTypeEnum.Reports]"
                      [type]="SearchTypeEnum.Reports"
                      (manageItem)="manageItem($event)"
                      (onManageClick)="onManageableElementClick($event)"
                    ></app-show-element>
                  </div>
                  <div *ngIf="mostUsed[SearchTypeEnum.Actions].length" class="reports-item p-l-5 p-r-5">
                    <app-show-element
                      [items]="mostUsed[SearchTypeEnum.Actions]"
                      [type]="SearchTypeEnum.Actions"
                      (manageItem)="manageItem($event)"
                      (onManageClick)="onManageableElementClick($event)"
                    ></app-show-element>
                  </div>
                  <div *ngIf="mostUsed[SearchTypeEnum.Playbooks].length" class="reports-item p-l-5 p-r-5">
                    <app-show-element
                      [items]="mostUsed[SearchTypeEnum.Playbooks]"
                      [type]="SearchTypeEnum.Playbooks"
                      (manageItem)="manageItem($event)"
                      (onManageClick)="onManageableElementClick($event)"
                    ></app-show-element>
                  </div>
                </div>
              </cv-tab>
              <cv-tab [title]="'common_MyShortcuts' | translate" classIcon="bolt material-icons-outlined">
                <div class="results-container results-container-first-page"  *ngIf="activeTab == 2">
                  <div *ngIf="shortcuts[SearchTypeShortcutEnum.FavoriteReports].length" class="reports-item p-l-5 p-r-5">
                    <app-show-element
                      [items]="shortcuts[SearchTypeShortcutEnum.FavoriteReports]"
                      [type]="SearchTypeShortcutEnum.FavoriteReports"
                      [limit]="shortcuts[SearchTypeShortcutEnum.FavoriteReports].length"
                      (manageItem)="manageItem($event)"
                      (onManageClick)="onManageableElementClick($event)"
                      [showItemsCount]="true"
                    ></app-show-element>
                  </div>
                  <div *ngIf="shortcuts[SearchTypeShortcutEnum.PinnedActions].length" class="reports-item p-l-5 p-r-5">
                    <app-show-element
                      [items]="shortcuts[SearchTypeShortcutEnum.PinnedActions]"
                      [type]="SearchTypeShortcutEnum.PinnedActions"
                      [limit]="shortcuts[SearchTypeShortcutEnum.PinnedActions].length"
                      (manageItem)="manageItem($event)"
                      (onManageClick)="onManageableElementClick($event)"
                      [showItemsCount]="true"
                    ></app-show-element>
                  </div>
                  <div *ngIf="shortcuts[SearchTypeShortcutEnum.PinnedWorkflows].length" class="reports-item p-l-5 p-r-5">
                    <app-show-element
                      [items]="shortcuts[SearchTypeShortcutEnum.PinnedWorkflows]"
                      [type]="SearchTypeShortcutEnum.PinnedWorkflows"
                      [limit]="shortcuts[SearchTypeShortcutEnum.PinnedWorkflows].length"
                      (manageItem)="manageItem($event)"
                      (onManageClick)="onManageableElementClick($event)"
                      [showItemsCount]="true"
                    ></app-show-element>
                  </div>
                </div>
              </cv-tab>
            </cv-horizontal-tab-group>
          </ng-container>
          <ng-container *ngIf="loadingFirstPage">
            <app-loading-universal-search-skeleton></app-loading-universal-search-skeleton>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
