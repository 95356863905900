<div class="panel-container">
  <div class="left-panel" style="overflow: auto">
    <ng-container *ngFor="let step of steps; let i = index">
      <div
        class="progress-block"
        [class.prev]="isPreviousStep(step)"
        [class.active]="isActiveStep(step) || isActiveChildStep(step)"
        [class.not-available]="step.status === 'NotAvailable'"
        [class.invalid]="!isLoading && isPreviousStep(step) && isInvalidStep(step)"
        attr.cv-data-test="panel-step-{{ step.stepKey }}"
      >
        <span class="icon material-icons"></span>
        <span *ngIf="i !== steps.length - 1" class="bar-bottom"></span>
        <div
          class="progress-title"
          [class.active-step]="isActiveStep(step)"
          [class.active-child-step]="!!step.subSteps?.length && isActiveChildStep(step)"
          [class.m-t-10]="step.status === 'NotAvailable' || (step.isRequired && (step.subSteps?.length || 0) <= 0)"
          (click)="canGoDirectlyToStep(step) ? clickStep(step, false, true) : null"
        >
          <div style="display: flex">
            <div style="flex: 1" translate>{{ step.title }}</div>
            <mat-icon
              (click)="step.expanded = !step.expanded; $event.stopPropagation()"
              *ngIf="!!collapseSteps && !!step.subSteps?.length"
              style="font-size: 20px; width: 20px; height: 20px"
              >{{ step.expanded ? 'expand_less' : 'expand_more' }}</mat-icon
            >
          </div>
          <div *ngIf="step.status === 'NotAvailable'" class="progress-sub-title" translate>common_Unavailable</div>
          <div
            *ngIf="!isLoading && isPreviousStep(step) && isInvalidStep(step) && (step.subSteps?.length || 0) <= 0; else subtitle"
            class="progress-sub-title"
          >
            <div translate>common_FieldsNeedToBeReviewed</div>
          </div>
          <ng-template #subtitle>
            <div *ngIf="!!getSubtitle(step)" class="progress-sub-title info-sub-title">
              {{ getSubtitle(step) | translate }}
            </div>
          </ng-template>
        </div>

        <ng-container *ngIf="!collapseSteps || step.expanded">
          <ng-container *ngFor="let subStep of step.subSteps">
            <div
              (click)="clickStep(subStep, false, true)"
              class="progress-title sub-step"
              [class.active-step]="isActiveStep(subStep) || (!subStep.expanded && isActiveChildStep(subStep))"
              [class.active-child-step]="subStep.expanded && isActiveChildStep(subStep)"
              attr.cv-data-test="panel-step-{{ subStep.parentStepKey }}-{{ subStep.stepKey }}"
            >
              <div style="display: flex">
                <div style="flex: 1" translate>{{ subStep.title }}</div>
                <mat-icon
                  (click)="subStep.expanded = !subStep.expanded; $event.stopPropagation()"
                  *ngIf="!!subStep.subSteps?.length"
                  style="font-size: 20px; width: 20px; height: 20px"
                  >{{ subStep.expanded ? 'expand_less' : 'expand_more' }}</mat-icon
                >
              </div>
              <div *ngIf="subStep.status === 'NotAvailable'" class="progress-sub-title" translate>common_Unavailable</div>
              <div *ngIf="!isLoading && isPreviousStep(step) && isInvalidStep(subStep); else sub_required_message" class="progress-sub-title">
                <div translate>common_FieldsNeedToBeReviewed</div>
              </div>
              <ng-template #sub_required_message>
                <div class="progress-sub-title info-sub-title" *ngIf="subStep.isRequired" translate>common_Required</div>
              </ng-template>
            </div>
            <ng-container *ngIf="subStep.expanded">
              <div
                style="padding-left: 20px; font-size: 10px; min-height: 40px"
                *ngFor="let subSubStep of subStep.subSteps || []"
                (click)="clickStep(subSubStep, false, true)"
                class="progress-title sub-step"
                [class.active-step]="activeStep?.stepKey === subSubStep.stepKey"
                attr.cv-data-test="panel-step-{{ subSubStep.parentStepKey }}-{{ subSubStep.stepKey }}"
              >
                <div translate>{{ subSubStep.title }}</div>
                <div *ngIf="subSubStep.status === 'NotAvailable'" class="progress-sub-title" translate>common_Unavailable</div>
                <div *ngIf="!isLoading && isPreviousStep(step) && isInvalidStep(subSubStep); else sub_required_message" class="progress-sub-title">
                  <div translate>common_FieldsNeedToBeReviewed</div>
                </div>
                <ng-template #sub_required_message>
                  <div class="progress-sub-title info-sub-title" *ngIf="subSubStep.isRequired" translate>common_Required</div>
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="right-panel">
    <div class="content-container">
      <div class="panel-title-container">
        <div cv-data-test="panel-title">
          <span class="uppercase panel-title" translate>{{ panelTitle }}</span>
          <cv-badge-preview
            *ngIf="showPreviewBadge"
            [showText]="true"
            class="p-l-10 {{ previewBadgeIdentifier + '-badge-preview' }}"
            attr.cv-data-test="{{ previewBadgeIdentifier + '-badge-preview' }}"
          ></cv-badge-preview>
        </div>
        <div *ngIf="!!panelSubtitle" cv-data-test="panel-subtitle" class="panel-subtitle">{{ panelSubtitle | translate }}</div>
      </div>

      <div class="tab">
        <ng-content select="[step]"></ng-content>
      </div>
    </div>
    <div class="footer-container">
      <div *ngIf="activeStep?.stepKey === 'complete'" class="m-b-10">
        <ng-content select="[reviewForm]"></ng-content>
      </div>

      <div *ngIf="!viewMode">
        <cv-button
          *ngIf="!activeStep || activeStep.isFirst"
          [text]="'common_Cancel' | translate"
          (click)="cancel()"
          buttonType="secondary"
          leftIcon="close"
          style="float: left"
          class="m-r-10"
          cv-data-test="panel-cancel"
        ></cv-button>
        <cv-button
          *ngIf="!!activeStep && !activeStep.isFirst"
          (click)="clickStep(steps[0])"
          buttonType="secondary"
          leftIcon="first_page"
          style="float: left"
          class="m-r-10"
          cv-data-test="panel-first-page"
        ></cv-button>
        <cv-button
          *ngIf="!!activeStep && !activeStep.isFirst"
          [text]="'common_Back' | translate"
          (click)="back()"
          buttonType="secondary"
          leftIcon="navigate_before"
          style="float: left"
          cv-data-test="panel-back"
        ></cv-button>
        <div style="float: right">
          <cv-button
            *ngIf="!lastStepIsActive()"
            [text]="'common_Next' | translate"
            (click)="next()"
            [disabled]="!canProceed(activeStep)"
            buttonType="secondary"
            rightIcon="navigate_next"
            cv-data-test="panel-next"
          ></cv-button>
          <cv-button
            *ngIf="allowQuickSubmit && !lastStepIsActive()"
            (click)="quickComplete()"
            [text]="getSubmitLabel()"
            [disabled]="isSaving"
            rightIcon="check"
            color="info"
            class="m-l-10"
            cv-data-test="panel-pre-submit"
          ></cv-button>
        </div>
        <cv-button
          *ngIf="lastStepIsActive()"
          (click)="complete()"
          [text]="getSubmitLabel()"
          [disabled]="!canProceed(activeStep) || isSaving"
          rightIcon="check"
          color="success"
          style="float: right"
          class="m-l-10"
          cv-data-test="panel-submit"
        ></cv-button>
      </div>
    </div>
  </div>
</div>
