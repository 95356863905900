<app-panel-steps
  *ngIf="steps.length > 0"
  [steps]="steps"
  [panelTitle]="action.translate || action.title"
  [panelSubtitle]="panelSubtitle"
  [isSaving]="isSaving"
  [allowQuickSubmit]="allowQuickSubmit()"
  [showPreviewBadge]="showPreviewBadge"
  [previewBadgeIdentifier]="previewBadgeIdentifier"
  [isLoading]="isLoading"
  (save)="submit()"
  cv-data-test="mgmt-action-panel"
>
  <div
    step
    *ngIf="steps[0].stepKey === 'selectTargets'"
    [class.hidden]="panelSteps?.activeStep?.stepKey !== 'selectTargets' && panelSteps?.activeStep?.parentStepKey !== 'selectTargets'"
    class="flex-full-height"
  >
    <app-target-selection
      target-selection
      [class.hidden]="panelSteps?.activeStep?.parentStepKey === 'selectTargets'"
      [selectedTargets]="selectedTargets"
      (selectedTargetsChanged)="onSelectedTargetsChanged($event)"
      [targetsToSelectDefinition]="targetSelectionDefinition"
    ></app-target-selection>

    <div
      step
      style="display: flex; flex-direction: column; flex: 1; padding: 0 12px; margin: 0 -12px"
      [class.hidden]="panelSteps?.activeStep?.stepKey === 'selectTargets'"
    >
      <cv-form-builder
        #uploadFormBuilder
        *ngIf="!!panelSteps?.activeStep?.showSchema"
        [jsonFormData]="steps[0]?.jsonFormData || { $schema: '', type: 'object', properties: {} }"
        [form]="uploadForm"
        [singleColumnWidth]="700"
        [visibleProperty]="panelSteps?.activeStep?.stepKey || ''"
        [extraData]="selectedTargets"
        [apiBaseUrl]="basePortalApiUrl"
        (eventFired)="onEventFired($event)"
        cv-data-test="mgmt-form-target-step"
      ></cv-form-builder>
    </div>
  </div>

  <div
    style="display: flex; flex-direction: column; flex: 1; overflow: auto; padding: 0 12px; margin: 0 -12px"
    step
    [class.hidden]="!panelSteps?.activeStep?.showSchema || panelSteps?.activeStep?.parentStepKey === 'selectTargets'"
  >
    <app-loading-form-skeleton *ngIf="!formBuilderReady || !initialValuesLoaded" style="flex: 1"></app-loading-form-skeleton>
    <cv-form-builder
      #formBuilder
      *ngIf="!!jsonFormData && !!initialValuesLoaded"
      [jsonFormData]="jsonFormData"
      [form]="form"
      [singleColumnWidth]="700"
      [visibleProperty]="panelSteps?.activeStep?.stepKey || ''"
      (stepVisibilityChanged)="recalculateSteps()"
      (dataChanged)="onDataChanged($event)"
      (eventFired)="onEventFired($event)"
      (formReady)="onFormBuilderReady()"
      [extraData]="selectedTargets"
      [apiBaseUrl]="basePortalApiUrl"
      [class.hidden]="!formBuilderReady"
      cv-data-test="mgmt-form"
    ></cv-form-builder>
  </div>

  <div
    step
    *ngIf="panelSteps?.activeStep?.stepKey === 'complete'"
    style="overflow: hidden; display: flex; flex-direction: column; flex: 1; padding: 0px 22px; margin: 0 -20px"
  >
    <cv-alert-bar style="display: block" class="m-b-10" *ngIf="isPreview" [hideCloseButton]="true" variant="warning">
      {{ 'management_PreviewActionWarning' | translate }}
    </cv-alert-bar>
    <div *ngIf="!!errorMessage" class="m-b-10" cv-data-test="mgmt-post-error">
      <cv-alert-bar (dismiss)="errorMessage = undefined" [title]="'common_NotAbleToSendRequest' | translate" variant="error">
        <span [innerHtml]="errorMessage | translate">{{ errorMessage }}</span>
      </cv-alert-bar>
    </div>
    <cv-alert-bar
      class="m-b-10"
      *ngIf="action.summaryDisclaimerTitle || action.summaryDisclaimerContent"
      [title]="action.summaryDisclaimerTitle || '' | translate"
      [hideCloseButton]="true"
      [variant]="action.summaryDisclaimerType || 'info'"
    >
      {{ action.summaryDisclaimerContent || '' | translate }}
    </cv-alert-bar>
    <cv-carousel *ngIf="summaryCards.length > 0">
      <app-summary-card
        style="flex: 1"
        cvCarouselContent
        *ngFor="let card of summaryCards"
        [cardInfo]="card"
        attr.cv-data-test="{{ 'mgmt-' + card.title }}"
      ></app-summary-card>
    </cv-carousel>
  </div>

  <div reviewForm cv-data-test="mgmt-review-form">
    <form [formGroup]="reviewForm" style="display: flex">
      <cv-textarea
        *ngIf="!isCustomAction"
        [label]="'management_AddNotes' | translate"
        [errorMessage]="'common_Required' | translate"
        [required]="isNotesRequired"
        formControlName="note"
        style="flex: 1"
        cv-data-test="mgmt-review-form-notes"
      >
      </cv-textarea>
    </form>
  </div>
</app-panel-steps>
