import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { Observable } from 'rxjs'
import { pluck } from 'rxjs/operators'
import { AccountSkuDetail, ManagementAccountSkuDetail } from '@app/core/models/account-sku-details'
import { ServicePlan } from '@app/shared/models/service-plan'

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  constructor(private apiClient: ApiclientService, private httpClient: HttpClient) {}

  getAccountSkus(): Observable<AccountSkuDetail[]> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/accountsku`, {
        params: { pageSize: 100000, metadata: true },
        withCredentials: true,
      })
      .pipe(pluck('accountSkuDetails'))
  }

  getMyLicenseTemplates(): Observable<{ areAllTemplates: boolean; items: { id: string; name: string }[] }> {
    return this.httpClient.get<any>(`${this.apiClient.basePortalApiUrl}/licences/items/templates/my`, {
      params: { metadata: true },
      withCredentials: true,
    })
  }

  getManagementAccountSkuDetails(userPrincipalName?: string | null): Observable<ManagementAccountSkuDetail[]> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/management/accountskudetails`, {
        params: { userPrincipalName: userPrincipalName || '', pageSize: 100000, metadata: true },
        withCredentials: true,
      })
      .pipe(pluck('items'))
  }

  getAccountSkuDetailsServicePlans(): Observable<{ _id: string; name: string }[]> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/accountskudetails/serviceplans`, {
        params: { metadata: true },
        withCredentials: true,
      })
      .pipe(pluck('items'))
  }

  getSubscriptionsBySkuId(skuId: string): Observable<any[]> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/subscriptions/detail`, {
        params: { skuId, metadata: true },
        withCredentials: true,
      })
      .pipe(pluck('msOlSubscriptions'))
  }

  getManagementAccountSkuDetailsTemplate(
    licenseTemplateId: string,
    userPrincipalName?: string | null
  ): Observable<ManagementAccountSkuDetail[]> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/management/accountskudetails/template/`, {
        params: { licenseTemplateId, userPrincipalName: userPrincipalName || '', pageSize: 100000, metadata: true },
        withCredentials: true,
      })
      .pipe(pluck('items'))
  }

  getServicePlans(): Observable<{ servicePlans: ServicePlan[] }> {
    return this.httpClient.get<{ servicePlans: ServicePlan[] }>(`${this.apiClient.basePortalApiUrl}/servicePlans`, {
      withCredentials: true,
    })
  }
}
