import { Component } from '@angular/core'
import { ICellRendererParams } from '@ag-grid-community/core'
import { AgRendererComponent } from '@ag-grid-community/angular'
import { TranslateHelper } from '@coreview/coreview-library'
import { ReportsService } from '@app/core/services/reports.service'

@Component({
  selector: 'app-icon-button-renderer',
  templateUrl: './icon-button-renderer.component.html',
  styleUrls: ['./icon-button-renderer.component.sass'],
})
export class IconButtonRendererComponent implements AgRendererComponent {
  public params: any
  public icon!: string
  public isFabricIcon = false
  public disabled = false
  public tooltip!: string
  public color!: string

  constructor(private location: Location, private window: Window, private translateHelper: TranslateHelper, private reportsService: ReportsService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params
    this.icon = this.params.icon || null
    this.isFabricIcon = this.params.isFabricIcon
    if (this.params.tooltipKey) {
      this.tooltip = this.translateHelper.instant(this.params.tooltipKey)
    } else {
      this.tooltip = this.params.tooltip instanceof Function ? this.params.tooltip(params.node.data) : this.params.tooltip
    }
    if(this.params.disabledFunction) {
      this.disabled = this.params.disabledFunction(params.node.data)
    }
    else if (this.params.disable) {
      this.disabled = this.params.disable(params.node.data)
    }
    this.color = this.params.color
  }

  refresh(params?: any): boolean {
    return true
  }

  click(event: any): void {
    event.preventDefault()
    event.stopImmediatePropagation()
    if (this.params.onClick instanceof Function) {
      const params = {
        event,
        rowData: this.params.node.data,
      }
      this.params.onClick(params)
      this.reportsService.deselectAllGridItems({ gridApi: this.params.api, columnApi: this.params.columnApi })

    } else if (this.params.redirectUrl) {
      let url: string = this.params.redirectUrl
      ;(url.match(/\{([/\w]+)\}/g) || []).forEach((x) => {
        url = url.replace(x, encodeURIComponent(this.params.node.data[x.substring(1, x.length - 1)]))
      })
      this.window.open(`${this.location.origin}/${url}`, '_blank')
      this.reportsService.deselectAllGridItems({ gridApi: this.params.api, columnApi: this.params.columnApi })
    }
  }

  getIcon(): string {
    if (this.params.getIcon instanceof Function) {
      return this.params.getIcon(this.params.node.data)
    } else {
      return this.icon
    }
  }

  hideIcon(): boolean {
    if (this.params.hideIcon instanceof Function) {
      return this.params.hideIcon(this.params.node.data, this.params.node)
    }
    return false
  }
}
