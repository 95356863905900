<cv-smart-panel
  style="margin-left: -15px; flex: 1; overflow: hidden"
  [title]="'reports_FilterAssistant' | translate"
  [open]="(isOpenPanel$ | async) === true"
  (openChange)="openChangePanelCustomReport($event)"
  customSmartPanel
>
  <div style="cursor: default" content class="content">
    <div class="title-container">
      <div class="title" translate>reports_FilterAssistant</div>
    </div>

    <div class="m-l-15 m-b-20 m-t-10 m-r-15 line" translate>reports_GroupingPreferences</div>
    
    <div class="filter-list scrollable-container">
      <cv-radio-button
        *ngFor="let reportType of reportType"
        class="m-t-10 m-l-15"
        [checked]="selectedReportType === reportType.value"
        (change)="onChangeReportTypeSelection(reportType.value)"
        cv-data-test="report-type-1"
      >
        {{ reportType.displayValue }}
      </cv-radio-button>
    </div>      
  </div>
</cv-smart-panel>
