<form [formGroup]="form">
  <cv-select
    *ngIf="!onlyRecurring"
    [suggestions]="recurrenceOptions"
    style="margin: 10px 0; width: 100%; box-sizing: border-box"
    [label]="'reports_Recurrence' | translate"
    formControlName="recurrence"
  ></cv-select>
  <ng-container *ngIf="recurrenceValue === 'recurring'">
    <div style="margin: 10px 0; width: 100%; box-sizing: border-box; display: flex">
      <cv-input
        attr.cv-data-test="interval"
        min="0"
        [min]="'0'"
        inputType="number"
        style="flex: 1; margin-right: 20px"
        label="{{ 'reports_RecurEvery' | translate }}*"
        formControlName="interval"
        [errorMessage]="geIntervalErrorMessage() | translate"
      ></cv-input>
      <cv-select
        attr.cv-data-test="frequency"
        [suggestions]="unitOptions"
        style="flex: 1"
        [label]="'reports_Unit' | translate"
        formControlName="frequency"
        #frequency
      ></cv-select>
    </div>
    <div *ngIf="frequency.value === '3'" style="margin-bottom: 10px; width: 100%; box-sizing: border-box">
      <mat-checkbox
        *ngIf="!hideParticularScheduleWeeks"
        style="display: block; margin: 10px 0px"
        formControlName="particularScheduleWeeks"
        >{{ 'reports_OnParticularSchedule' | translate }}</mat-checkbox
      >
      <cv-select
        attr.cv-data-test="dayOfWeek"
        [suggestions]="daysOptions"
        style="flex: 1; width: 100%"
        [label]="'reports_DayOfWeek' | translate"
        formControlName="weekdaySelected"
        [errorMessage]="'common_Required' | translate"
      ></cv-select>
    </div>
    <div *ngIf="frequency.value === '4'" style="margin-bottom: 15px; width: 100%">
      <mat-radio-group formControlName="monthControl">
        <div style="display: flex; margin-bottom: 15px">
          <mat-radio-button attr.cv-data-test="dayOfMonthOption" style="align-self: center" value="dayMonth"></mat-radio-button>
          <cv-select
            attr.cv-data-test="dayOfMonth"
            style="width: 30%; flex: 1"
            [suggestions]="dayMonthOptions"
            formControlName="dayMonth"
            [label]="'common_OnDay' | translate"
            [errorMessage]="'common_Required' | translate"
          ></cv-select>
        </div>
        <div style="display: flex; margin-bottom: 15px">
          <mat-radio-button attr.cv-data-test="dayOfWeekForMonthOption" style="align-self: center" value="daysOfWeek"></mat-radio-button>
          <cv-select
            attr.cv-data-test="occurence"
            style="width: 30%; margin-right: 30px; flex: 1"
            [suggestions]="ordinalOptions"
            formControlName="ordinal"
            [label]="'common_OnThe' | translate"
            [errorMessage]="'common_Required' | translate"
          ></cv-select>
          <cv-select
            attr.cv-data-test="dayOfWeekForMonth"
            style="width: 30%; flex: 1"
            [suggestions]="daysOptions"
            [label]="'reports_DayOfWeek' | translate"
            formControlName="weekdayMonthSelected"
            [errorMessage]="'common_Required' | translate"
          ></cv-select>
        </div>
        <mat-radio-button attr.cv-data-test="lastDayOfMonthOption" style="margin-right: 10px" value="lastDay">
          {{ 'workflow_LastDayMonth' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </ng-container>
  <div>
    <cv-select
      *ngIf="!onlyRecurring"
      [suggestions]="this.formControls.frequency.value !== '4' ? startingOptions : startingOptionsMonth"
      style="margin: 10px 0; width: 100%; box-sizing: border-box"
      [label]="'reports_Starting' | translate"
      formControlName="starting"
      #starting
    ></cv-select>
    <div *ngIf="this.formControls.starting.value === 'specificTime'">
      <app-datetime-utc-picker style="margin: 10px 0" [isError]="startDatePast" formControlName="startDateTime"></app-datetime-utc-picker>
      <cv-alert-bar [hideCloseButton]="true" variant="error" *ngIf="startDatePast && !isDisabled()">{{
        'common_StartInPast' | translate
      }}</cv-alert-bar>
    </div>
    <div *ngIf="recurrenceValue === 'recurring'">
      <div *ngIf="noEndDatecheck" style="display: flex; flex-direction: column; margin: 10px 0">
        <cv-checkbox [disabled]="form.disabled" (checkedChange)="resetEndDateFormValue()" [(ngModel)]="noEndDate" [ngModelOptions]="{ standalone: true }">
          {{ 'common_NoEndDate' | translate }}
        </cv-checkbox>
        <span *ngIf="noEndDate" style="font-size: 14px; color: #6a6a6a; margin-top: 5px; padding-left: 35px">{{
          'common_NoEndDateDescription' | translate
        }}</span>
      </div>

      <ng-container *ngIf="!noEndDate">
        <app-datetime-utc-picker
          style="margin: 10px 0"
          [dateLabel]="'reports_EndingOnDate'"
          [isError]="endDatePast || incompatibleDates"
          [required]="true"
          formControlName="endDateTime"
        ></app-datetime-utc-picker>
        <cv-alert-bar style="display: block; margin: 10px 0" [hideCloseButton]="true" variant="error" *ngIf="endDatePast && !isDisabled()">
          {{ 'common_EndInPast' | translate }}
        </cv-alert-bar>
        <cv-alert-bar
          style="display: block; margin: 10px 0"
          [hideCloseButton]="true"
          variant="error"
          *ngIf="incompatibleDates && !isDisabled()"
        >
          {{ customIncompatibleErrorMessage ?? 'common_IncompatibleDates' | translate }}
        </cv-alert-bar>
      </ng-container>
    </div>
  </div>
</form>
