<div [style.display]="!(isOpenPanel$ | async) ? null : 'none'" #fakeLeft customSmartPanel></div>
<cv-smart-panel
  type="button"
  icon="tune"
  [style.position]="(isOpenPanel$ | async) ? null : 'absolute'"
  [title]="'reports_FilterAssistant' | translate"
  [open]="(isOpenPanel$ | async) === true"
  (openChange)="openChangePanelCustomReport($event); fakeLeft.classList.add('fake-left-panel')"
  style="z-index: 1; left: 16px"
  customSmartPanel
>
  <div style="cursor: default" content class="content">
    <div class="title-container">
      <div class="title" translate>reports_FilterAssistant</div>
    </div>
    <cv-horizontal-tab-group
      type="line"
      (tabChanged)="tabChanged($event)"
      [activeTab]="selectedPanelTab"
      cv-data-test="filter-assistant-tabs"
    >
      <cv-tab [title]="'reports_QuickFilters' | translate" cv-data-test="lp-quick-filters">
        <div class="m-l-15 m-b-20 m-t-10 m-r-15 line" translate>reports_WhatDoYouWantToCheck</div>
        <div class="filter-list scrollable-container">
          <cv-radio-button
            [checked]="selectedQuickFilter === quickFilterTypes[0].value"
            (change)="onChangeQuickFilterType(quickFilterTypes[0].value)"
            cv-data-test="full-report"
          >
            {{ quickFilterTypes[0].displayValue }}
          </cv-radio-button>
          <cv-select
            [suggestions]="fullReportType"
            class="filter-description select-fullreport"
            [ngModel]="selectedFullReport"
            (selectionChanged)="onChangeFullReportSelection($any($event))"
            [label]="'common_View' | translate"
            cv-data-test="select-full-report-type"
          >
          </cv-select>

          <ng-container *ngIf="!reportOnly">
            <cv-radio-button
              [checked]="selectedQuickFilter === quickFilterTypes[1].value"
              class="m-t-10"
              (change)="onChangeQuickFilterType(quickFilterTypes[1].value)"
              cv-data-test="over-allocated"
            >
              {{ quickFilterTypes[1].displayValue }}
            </cv-radio-button>
            <p class="filter-description" translate>{{ quickFilterTypes[1].otherDisplayValue }}</p>

            <cv-radio-button
              [checked]="selectedQuickFilter === quickFilterTypes[2].value"
              class="m-t-10"
              (change)="onChangeQuickFilterType(quickFilterTypes[2].value)"
              cv-data-test="out-of-capacity"
            >
              {{ quickFilterTypes[2].displayValue }}
            </cv-radio-button>
            <p class="filter-description" translate>{{ quickFilterTypes[2].otherDisplayValue }}</p>
          </ng-container>
        </div>
      </cv-tab>
      <cv-tab [title]="'reports_CustomReports' | translate" cv-data-test="lp-custom-reports" *ngIf="canSeeLeftPanel()">
        <div class="m-l-15 m-b-20 m-t-10" translate>reports_SelectReport</div>
        <div class="saved-report-container scrollable-container">
          <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
        </div>
      </cv-tab>
    </cv-horizontal-tab-group>
  </div>
  <div footer class="buttons-container" *ngIf="selectedPanelTab === 1">
    <cv-button
      class="filter-button"
      buttonType="secondary"
      (click)="handleOpenAdvancedFilters()"
      [fontWeight]="600"
      text="{{ 'licenses_AdvancedFilters' | translate }}"
      leftIcon="filter_alt"
      cv-date-test="open-advanced-filters"
    >
    </cv-button>
  </div>
</cv-smart-panel>
