<div style="display: flex; height: 100%; box-sizing: border-box">
    <div style="display: flex; padding: 20px; flex: 0.8">
        <img style="margin: auto" src="{{ getImage() }}" alt="access is forbidden" />
    </div>
    <div style="flex: 1; display: flex">
      <div style="margin: auto;">
        <div style="font-size: 24px; font-weight: 600" translate>{{ getTitle() }}</div>
          
          <div style="font-size: 16px; margin: 50px 0" [innerHTML]="getInnerHtmlDescription() | translate"></div>
        <cv-button rightIcon="navigate_next"
        buttonType="primary"
        text="{{ 'common_GoToHomepage' | translate }}"
        size="tiny"
        (click)="gotoHome()" ></cv-button>
      </div>
    </div>
  </div>