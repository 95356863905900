import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import { Injectable } from '@angular/core'
import { Constants } from '@app/shared/utilities/constants'
import { Observable } from 'rxjs'
import { DataCenter } from '../models/data-center'
import { LocalstorageService } from './localstorage.service'
import { ServerResponse } from '../models/ServerResponse'
import { Verb } from '../models/PageDataCommonClasses'
import { ApiDataParameters } from '../models/ApiDataParameters'

@Injectable({
  providedIn: 'root',
})
export class ApiclientService {
  public orgEnvName!: string

  //TODO: the OrganizationSetting should return its own DataCenter
  //TODO: the / at the end happen after login, when refresh it does not have the / at the end
  public envNameMap: Record<string, string> = {
    'https://api.4ward365.com/api': 'EU',
    'https://api.coreview.com/api': 'EU',
    'https://api.4ward365.com/api/': 'EU',
    'https://api.coreview.com/api/': 'EU',
    'https://usapi.4ward365.com/api': 'EUS',
    'https://usapi.coreview.com/api': 'EUS',
    'https://usapi.4ward365.com/api/': 'EUS',
    'https://usapi.coreview.com/api/': 'EUS',
    'https://caeapi.4ward365.com/api': 'CAE',
    'https://caeapi.coreview.com/api': 'CAE',
    'https://caeapi.4ward365.com/api/': 'CAE',
    'https://caeapi.coreview.com/api/': 'CAE',
    'https://usgovapi.4ward365.com/api': 'GOV',
    'https://usgovapi.coreview.com/api': 'GOV',
    'https://usgovapi.4ward365.com/api/': 'GOV',
    'https://usgovapi.coreview.com/api/': 'GOV',
    'https://ausapi.coreview.com/api': 'AUS',
    'https://ausapi.coreview.com/api/': 'AUS',
    'https://uknapi.coreview.com/api': 'UKN',
    'https://uknapi.coreview.com/api/': 'UKN',
    'https://devapi.coreview.com/api': 'dev',
    'https://devapi.coreview.com/api/': 'dev',
    'https://devapi-tani.coreview.com/api': 'dev',
    'https://devapi-tani.coreview.com/api/': 'dev',
    'https://devapi-rhino.coreview.com/api': 'dev',
    'https://devapi-rhino.coreview.com/api/': 'dev',
    'https://devapi-ant.coreview.com/api': 'dev',
    'https://devapi-ant.coreview.com/api/': 'dev',
    'https://devapi-ballo.coreview.com/api': 'dev',
    'https://devapi-ballo.coreview.com/api/': 'dev',
    'https://devapi-tech.coreview.com/api': 'dev',
    'https://devapi-tech.coreview.com/api/': 'dev',
    'https://stagingapi.coreview.com/api': 'stg',
    'https://preprodapi.coreview.com/api': 'preprod',
    'https://stagingapi.coreview.com/api/': 'stg',
    'https://preprodapi.coreview.com/api/': 'preprod',
    'https://api.4ward365.local/api': 'LOCAL'
  }

  private basePortalApiUrlValue: string
  private baseAuthApiUrlValue: string

  constructor(private storage: LocalstorageService, private httpClient: HttpClient) {
    this.basePortalApiUrlValue = storage.basePortalApiUrl
    this.baseAuthApiUrlValue = storage.basePortalAuthApiUrl
  }

  set basePortalApiUrl(url: string) {
    this.basePortalApiUrlValue = url
    this.storage.basePortalApiUrl = url
  }

  get basePortalApiUrl() {
    return this.basePortalApiUrlValue || this.getV1BaseApiUrl()
  }

  get baseCentralUrl() {
    return environment.baseCentralUrl
  }

  set baseAuthApiUrl(url: string) {
    this.baseAuthApiUrlValue = url
    this.storage.basePortalAuthApiUrl = url
  }

  get baseAuthApiUrl() {
    return this.baseAuthApiUrlValue || this.getV1BaseAuthApiUrl()
  }

  get serviceNowUrl() {
    return this.basePortalApiUrl.replace('https://', `https://${Constants.providersIntegrations.ServiceNow}`)
  }

  get portalV1Url() {
    return environment.portalV1Url
  }

  getV1BaseApiUrl() {
    const urlSettings = this.storage.getV1String('urlSettings')
    return !!urlSettings ? JSON.parse(urlSettings).baseApiUrl : 'baseurlUndefined'
  }

  getV1BaseAuthApiUrl() {
    const urlSettings = this.storage.getV1String('urlSettings')
    return urlSettings ? JSON.parse(urlSettings).baseAuthApiUrl : 'baseAuthUrlUndefined'
  }

  getDataCenters(): Observable<DataCenter[]> {
    return this.httpClient.get<DataCenter[]>(`${this.baseAuthApiUrl}/dcendpoint/all`, {
      withCredentials: true,
    })
  }

  trackAction(action: string, detail?: string): Observable<void> {
    return this.httpClient.post<void>(`${this.basePortalApiUrl}/activity`, { action, detail }, { withCredentials: true })
  }

  getData(reportUrl: string, verb: Verb, params?: ApiDataParameters, baseUrl?: string): Observable<ServerResponse<any>> {
    const apiUrl = baseUrl ? baseUrl : this.basePortalApiUrl
    const url = `${apiUrl + reportUrl}`

    const withCredentials = typeof params?.withCredentials === 'boolean' ? params?.withCredentials : true
    delete params?.withCredentials

    if (verb === 'post') {
      return this.httpClient.post<any>(url, params, { withCredentials, ...params, params: { metadata: true } })
    }

    return this.httpClient.get<ServerResponse<any>>(url, { withCredentials, ...params, params: { ...params } as any })
  }

  getDataString(
    reportUrl: string,
    params?: string,
    baseUrl?: string,
    options?: { withCredentials: boolean }
  ): Observable<ServerResponse<any>> {
    const apiUrl = baseUrl ? baseUrl : this.basePortalApiUrl
    const url = `${apiUrl + reportUrl}`
    return this.httpClient.get<ServerResponse<any>>(url + params, { withCredentials: true, ...options })
  }

  getWorkflowBaseApi(withCred: boolean, isForPlaybook: boolean = false): { url: string; withCred: boolean } {
    return { url: `${environment.workflowUrlV2[this.envNameMap[this.basePortalApiUrlValue]]}api`, withCred: true }
  }

  getWorkflowCatalogsBaseApi() {
    return { url: `${environment.workflowUrlV2[this.envNameMap[this.basePortalApiUrlValue]]}api`, withCred: true }
  }

  getWorkflowPlaybookBaseApi() {
    return `${environment.workflowUrlV2[this.envNameMap[this.basePortalApiUrlValue]]}`
  }
  
  getPartnerPortalUrl(url: string) {
    const regex = /app/
    const replacement = 'partnerapp'
    return `${url.replace(regex, replacement)}`
  }
}
