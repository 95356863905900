<div class="title-bar" *ngIf="dashboardDefinition">
  <div class="title-bar-content">
    <div class="first-line" translate>
      {{ dashboardDefinition.title }}
      <mat-icon
        *ngIf="!!dashboardDefinition.description"
        (click)="showDescription = !showDescription"
        [ngClass]="{ 'info-active': !!showDescription }"
        class="m-l-5 info material-icons-outlined"
        >info
      </mat-icon>
    </div>
    <div class="m-t-10 f-s-14" *ngIf="!!showDescription" translate>{{ dashboardDefinition.description }}</div>
  </div>
  <div>
    <cv-button
      *ngIf="dashboardDefinition && dashboardDefinition.filterComponent"
      [text]="'common_Filters' | translate"
      buttonType="tertiary"
      leftIcon="filter_alt"
      [leftIconStyle]="(componentFilter | keyvalue).length ? 'filled' : 'outlined'"
      (click)="openFilterPanel()"
    ></cv-button>
    <app-range-filter
      *ngIf="dashboardDefinition && dashboardDefinition.calendarType"
      [calendarType]="dashboardDefinition.calendarType"
      [daysList]="dashboardDefinition.daysList"
      (rangeFilterChanged)="rangeFilterChanged($event)"
    >
    </app-range-filter>
    <app-favorite *ngIf="menu" [menuId]="menu.id" [isFavorite]="menu.isFavorite" entity="dashboards"> </app-favorite>
  </div>
</div>

<cv-alert-bar
[title]="dashboardDefinition!.warningMessage!.title! | translate"
variant="warning"
*ngIf="showNoDataWarning"
style="margin: 10px 0"
>
<span>{{ dashboardDefinition!.warningMessage!.description! | translate }}</span>
</cv-alert-bar>

<div class="main-container" *ngIf="!!dashboardDefinition && !!dashboardDefinition.tabs && dashboardDefinition.tabs.length > 0">
  <cv-horizontal-tab-group type="line" (tabChanged)="tabChanged()">
    <ng-container *ngFor="let tab of dashboardDefinition?.tabs || []">
      <cv-tab [icon]="tab.icon || ''" [title]="tab.title | translate">
        <div class="tab-container">
          <div class="cards-container" *ngIf="!!organization && tab?.cards">
            <ng-container *ngFor="let card of tab?.cards">
              <app-infos-card
                *ngIf="!card.type || card.type === 'infos'"
                [card]="card"
                [data]="data"
                [daysFilter]="daysValue"
                [organization]="organization"
                [loading]="loading"
              ></app-infos-card>

              <app-customizable-chart
                *ngIf="card.type === 'custom-chart'"
                [card]="card"
                [ngStyle]="card.style"
                [loading]="loading"
                [data]="pathParams"
              ></app-customizable-chart>

              <app-charts-card
                *ngIf="card.type === 'chart'"
                [card]="card"
                [attr.style]="card.style"
                [daysFilter]="daysValue"
                [componentFilter]="componentFilter"
                [loading]="loading"
                [organization]="organization"
              ></app-charts-card>

              <app-datagrid
                #grid
                *ngIf="card.type === 'table' && !loadingTable"
                [getItems]="getRefreshData(card)"
                [gridDefinition]="getPageData$(card) | async"
                [hideColumnsSelector]="true"
                style="flex: 1"
              ></app-datagrid>

              <app-client-datagrid
                #clientGrid
                *ngIf="card.type === 'clientTable'"
                [getItems]="card.getItems"
                [manageError]="card.manageError"
                [gridOptions]="gridOptionsSnapshots"
                [hideColumnsSelector]="true"
                [autosizeAllColumns]="true"
                [filterActions]="card.filterActions"
              ></app-client-datagrid>

            </ng-container>
          </div>
        </div>
      </cv-tab>
    </ng-container>
  </cv-horizontal-tab-group>
</div>

<div class="cards-container" *ngIf="!!organization && dashboardDefinition?.cards">
  <ng-container *ngFor="let card of dashboardDefinition?.cards || []">
    <app-infos-card
      *ngIf="!card.type || card.type === 'infos'"
      [card]="card"
      [data]="data"
      [daysFilter]="daysValue"
      [organization]="organization"
      [loading]="loading"
    ></app-infos-card>
    <app-properties-card *ngIf="card.type === 'properties'" [card]="card" [data]="data" [loading]="loading"></app-properties-card>
    <app-charts-card
      *ngIf="card.type === 'chart'"
      [card]="card"
      [attr.style]="card.style"
      [daysFilter]="daysValue"
      [componentFilter]="componentFilter"
      [loading]="loading"
      [organization]="organization"
    ></app-charts-card>
  </ng-container>
</div>
