import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UniversalSearchService } from '@app/core/services/universal-search.service'
import { SearchTypeEnum, SearchTypeShortcutEnum } from '@app/core/enums/search-type'
import { BaseItem } from '@app/core/models/universal-search'
import { SharedHelperService } from '@app/shared/shared.helper.service'

@Component({
  selector: 'app-show-element',
  templateUrl: './show-element.component.html',
  styleUrls: ['../universal-search.component.sass', './show-element.component.sass'],
})
export class ShowElementComponent {
  @Input() items: BaseItem[] = []
  @Input() limit = 10
  @Input() type: SearchTypeEnum | SearchTypeShortcutEnum = SearchTypeEnum.Empty
  @Input() allowSeeAll = false
  @Input() showItemsCount = false

  @Output() seeAllResults: EventEmitter<any> = new EventEmitter()
  @Output() manageItem: EventEmitter<any> = new EventEmitter()
  @Output() onManageClick: EventEmitter<any> = new EventEmitter()

  showOnHover: { [key: number]: boolean } = {}
  constructor(private universalSearchService: UniversalSearchService, private sharedHelperService: SharedHelperService) {}

  getIconImg = (type: SearchTypeEnum | SearchTypeShortcutEnum) => this.universalSearchService.getIconImgFromType(type)

  getActionTitle = (type: SearchTypeEnum | SearchTypeShortcutEnum) => this.universalSearchService.getActionTitleFromType(type)

  clickManageItem = (event: Event, item: BaseItem) => {
    event.stopPropagation()
    this.manageItem.emit(item)
  }

  clickManageableElement = (item: BaseItem) => {
    this.onManageClick.emit(item)
  }

  isElmentManageable = (item: BaseItem) => item.isManageable && this.sharedHelperService.isAdmin()

  clickSeeAllResults = (type: SearchTypeEnum | SearchTypeShortcutEnum) => {
    this.seeAllResults.emit(type)
  }
}
