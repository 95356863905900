import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'

@Component({
  selector: 'app-missing-skus-dialog',
  templateUrl: './missing-skus-dialog.component.html',
  styleUrls: ['./missing-skus-dialog.component.sass'],
})
export class MissingSkusDialogComponent {
  constructor(public dialogRef: MatDialogRef<MissingSkusDialogComponent>, private router: Router) {}

  gotoHome(): void {
    this.router.navigate(['home']).catch((_: any) => _)
    this.dialogRef.close()
  }

  closeDialog(): void {
    this.dialogRef.close()
  }
}
