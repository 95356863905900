<div class="panel-container">
  <div class="panel">
    <div class="title p-l-20" style="display: flex; line-height: 69px">
      <div style="flex: 1; font-size: 14px; font-weight: 600" class="uppercase">
        <span translate>licenses_Filters</span>
      </div>
    </div>
    <div class="p-20" style="height: 100%; overflow-y: scroll">
      <div class="section">
        <div class="subtitle">
          <div translate>
            <span style="line-height: 24px" translate>licenses_ServicePlans</span>
            <mat-icon class="history">history</mat-icon>
          </div>
        </div>

        <mat-radio-group style="display: flex; flex-direction: column" aria-label="Select an option">
          <mat-radio-button
            style="margin: 5px"
            *ngFor="let plan of plansList"
            [value]="plan.key"
            attr.cv-data-test="checkbox-{{ plan.key }}"
            (click)="clickPlan(plan)"
            [checked]="configuration.licenseAggregationFilter == plan.key"
          >
            <div class="plan-container">
              <span>
                {{ capitalize(plan.key) }}
              </span>
              <mat-chip>
                {{ plan.count }}
              </mat-chip>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="section">
        <div class="subtitle">
          <div translate>
            <span style="line-height: 24px" translate>common_SKUS</span>
            <mat-icon class="history">history</mat-icon>
          </div>
        </div>

        <mat-radio-group style="display: flex; flex-direction: column" aria-label="Select an option">
          <mat-radio-button
            style="margin: 5px"
            *ngFor="let sku of skusList"
            [value]="sku.key"
            attr.cv-data-test="checkbox-{{ sku.key }}"
            (click)="clickSku(sku)"
            [checked]="configuration.skuAggregationFilter == sku.key"
          >
            <div class="plan-container">
              <span>
                <div *ngFor="let item of sku.key.split('||')">
                  {{ capitalize(item) }}
                </div>
              </span>
              <mat-chip>
                {{ sku.count }}
              </mat-chip>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="footer-container">
      <cv-button (click)="close()" class="m-r-10" buttonType="secondary" [text]="'common_Cancel' | translate"> </cv-button>
      <cv-button (click)="submit()" primary="secondary" [text]="'common_Apply' | translate"> </cv-button>
    </div>
  </div>
</div>
