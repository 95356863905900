<div class="container-error" *ngIf="isCustomReportNotFound">
  <div class="image">
    <img class="m-t-15" src="../assets/img/404_user_not_found.svg" alt="user not found" />
  </div>
  <div class="messages">
    <div class="title" translate>licenses_TimeForAnUpdate</div>
    <div class="subtitle m-t-30" translate>licenses_GenerateFreshCustomReport</div>
    <cv-button
      class="m-t-30"
      [text]="'licenses_GoToLicensePoolReport' | translate"
      (click)="handleGotoLicenseReport()"
      primary="secondary"
      rightIcon="arrow_forward"
    ></cv-button>
  </div>
</div>

<div class="report-container" [class.open-in-full]="isOpenInFull" [style.display]="'flex'" *ngIf="!isCustomReportNotFound">
  <app-report-title
    [menu]="menu"
    [savedReportId]="savedReportId"
    [reportTitle]="isOpenInFull ? reportTitleFullscreen : reportTitle"
    [reportDescription]="reportDescription"
    [isNonVTenantSensitive]="true"
    [isOpenInFull]="isOpenInFull"
    tabTitle="{{ 'reports_PoolReport' | translate }}"
    [filterTitle]="filterTitleFullscreen"
  >
  </app-report-title>

  <div *ngIf="!licensePoolConfiguration && noLpConfigured">
    <cv-alert-bar variant="info" [title]="'licenses_MissingLicensePoolConfiguration' | translate" [hideCloseButton]="true">
      <span translate>licenses_MissingLicensePoolConfigurationDescription</span>
    </cv-alert-bar>
  </div>

  <cv-horizontal-tab-group
    [type]="horizontalTabsStyle"
    (tabChanged)="tabChanged($event)"
    [activeTab]="tabSelectedIndex + 1"
    cv-data-test="license-pools-reports-tabs"
    *ngIf="!!licensePoolConfiguration"
  >
    <cv-tab
      *ngIf="canSeeTab(menuIds.licensePoolReport)"
      title="{{ 'reports_PoolReport' | translate }}"
      [tooltipString]="'reports_PoolReport_info' | translate"
      cv-data-test="new-license-pools"
    >
      <ng-template cvTabContent>
        <app-license-pool-alert
          *ngIf="isUpdateInProgress || operatorHasNoLPAssigned"
          [updateInProgress]="isUpdateInProgress"
          [noLpAssigned]="operatorHasNoLPAssigned"
          cv-data-test="license-pool-alert"
        ></app-license-pool-alert>

        <div *ngIf="!isUpdateInProgress && !operatorHasNoLPAssigned" class="main-container" [style]="containerStyle">
          <app-license-left-panel
            [style.display]="isOpenInFull === false ? null : 'none'"
            [noLpConfigured]="noLpConfigured"
            [savedReports]="savedReports"
            (openAdvancedFilters)="handleOpenAdvancedFilters()"
            cv-data-test="license-pool-left-panel"
            [reportOnly]="licensePoolConfiguration.reportOnly"
            #leftPanel
          ></app-license-left-panel>

          <app-license-pool-reports-beta
            [class.left-panel-close]="!(leftPanel.isOpenPanel$ | async)"
            [reportTitle]="reportTitle"
            [licensePoolConfiguration]="licensePoolConfiguration"
            [savedReportFilterSortModel]="savedReportFilterSortModel"
            [getReportParams]="getReportParams"
            [lpReportModeV3]="lpReportModeV3"
            [refreshGrid]="refreshV3Grid"
            [(isUpdateInProgress)]="isUpdateInProgress"
            [(operatorHasNoLPAssigned)]="operatorHasNoLPAssigned"
            [(advancedFiltersConfiguration)]="advancedFiltersConfiguration"
            cv-data-test="new-license-pools-report"
          ></app-license-pool-reports-beta>
        </div>
      </ng-template>
    </cv-tab>

    <cv-tab
      *ngIf="canSeeTab(menuIds.licensePoolCostReport)"
      [title]="v2ReportTypes[menuIds.licensePoolCostReport].titleTranslated"
      [tooltipString]="'reports_CostReport_info' | translate"
      cv-data-test="license-pools-cost"
    >
      <ng-template cvTabContent>
        <app-license-pool-alert
          *ngIf="isUpdateInProgress || operatorHasNoLPAssigned"
          [updateInProgress]="isUpdateInProgress"
          [noLpAssigned]="operatorHasNoLPAssigned"
          cv-data-test="license-pool-alert"
        ></app-license-pool-alert>

        <div
          *ngIf="!isUpdateInProgress && !operatorHasNoLPAssigned"
          class="main-container"
          [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'"
        >
          <app-custom-reports-left-panel
            [style.display]="canSeeLeftPanel() ? null : 'none'"
            [noLpConfigured]="noLpConfigured"
            [savedReports]="savedReports"
            cv-data-test="lp-custom-reports"
            #leftPanel
            style="padding-top: 10px"
          ></app-custom-reports-left-panel>

          <div
            [class.left-panel-close]="!leftPanel.isOpenPanelCustomReport"
            class="grid-container"
            *ngIf="!licensePoolConfiguration.reportOnly"
          >
            <app-datagrid
              [getItems]="getLicensePoolsCost"
              [gridDefinition]="licensePoolsCostGridDefinition"
              [hideColumnsSelector]="true"
              [savedReportFilterSortModel]="savedReportFilterSortModel"
              (metadataChanged)="metadata = $event"
              cv-data-test="license-pools-cost-report"
            ></app-datagrid>
          </div>
          <div
            [class.left-panel-close]="!leftPanel.isOpenPanelCustomReport"
            class="grid-container"
            *ngIf="licensePoolConfiguration.reportOnly"
          >
            <app-client-datagrid
              [getItems]="getLicensePoolsClient"
              [gridOptions]="gridOptionsLicensePoolCostClient"
              [columnsParameters]="columnsParametersLicensePoolCostClient"
              detailCellRenderer="customDetail"
              [detailCellRendererParams]="gridOptionsLicensePoolCostClient.detailCellRendererParams"
              [responseItemProp]="reportDefinition?.responseItemProp || ''"
              [isRowMaster]="isRowMasterLp"
              (metadataChanged)="metadata = $event"
              [hideColumnsSelector]="true"
              [autosizeAllColumns]="true"
              [selectionActions]="clientSelectionActions"
              cv-data-test="license-pools-cost-report-only"
            ></app-client-datagrid>
          </div>
        </div>
      </ng-template>
    </cv-tab>

    <cv-tab
      *ngIf="canSeeTab(menuIds.licensePoolReportSnapshots)"
      title="{{ 'reports_Snapshots' | translate }}"
      [tooltipString]="'reports_Snapshots_info' | translate"
      cv-data-test="license-pools-snapshots"
    >
      <ng-template cvTabContent>
        <div class="main-container" style="grid-template-columns: auto 1fr auto">
          <div [style.display]="!leftPanel.open ? null : 'none'" #fakeLeft></div>
          <cv-smart-panel
            #leftPanel
            type="button"
            icon="tune"
            [style.position]="leftPanel.open ? null : 'absolute'"
            [title]="'licenses_ComparisonAssistant' | translate"
            style="z-index: 1; left: 16px; padding-top: 10px"
            [open]="true"
            cv-data-test="snapshot-comparison-panel"
          >
            <div style="cursor: default" content>
              <div class="title-container">
                <div class="title" translate>licenses_ComparisonAssistant</div>
              </div>
              <div class="select-section">
                <div class="subtitle" translate>licenses_SnapshotToView</div>
                <div class="select-container">
                  <cv-select
                    [suggestions]="getSnapshotsToViewDates()"
                    label="{{ 'licenses_SelectSnapshot' | translate }}"
                    [value]="snapshotToViewTimestamp"
                    (selectionChanged)="snapshotToViewChanged($event)"
                    cv-data-test="select-snapshot-view"
                  ></cv-select>
                </div>
              </div>

              <div class="select-section">
                <div class="subtitle" translate>licenses_SnapshotToCompareAgainst</div>
                <div class="select-container">
                  <cv-select
                    style="width: 100%"
                    [suggestions]="getSnapshotsToCompareDates()"
                    label="{{ 'licenses_SelectSnapshot' | translate }}"
                    [value]="snapshotToCompareTimestamp"
                    (selectionChanged)="snapshotToCompareChanged($event)"
                    cv-data-test="select-snapshot-compare"
                  ></cv-select>
                </div>
              </div>
            </div>

            <div footer>
              <cv-button
                (click)="resetSnapshotsFilters()"
                class="m-r-10"
                buttonType="secondary"
                [text]="'common_Reset' | translate"
                cv-data-test="snapshot-reset"
              >
              </cv-button>
              <cv-button
                (click)="applySnaphotsFilters()"
                primary="secondary"
                [text]="'common_Apply' | translate"
                cv-data-test="snapshot-apply"
              >
              </cv-button>
            </div>
          </cv-smart-panel>

          <div [class.left-panel-close]="!leftPanel.open" class="grid-container">
            <app-client-datagrid
              [getItems]="getLicensePoolSnapshots"
              [gridOptions]="gridOptionsSnapshots"
              detailCellRenderer="customDetail"
              [detailCellRendererParams]="detailCellRenderParamsSnapshots"
              [isRowMaster]="isRowMasterSnapshots"
              [hideColumnsSelector]="true"
              [autosizeAllColumns]="true"
              [selectionActions]="clientSelectionActions"
              (metadataChanged)="metadata = $event"
              cv-data-test="license-pools-snapshots-report"
            ></app-client-datagrid>
          </div>
        </div>
      </ng-template>
    </cv-tab>
  </cv-horizontal-tab-group>
</div>
