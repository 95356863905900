import { ColDef, ColGroupDef, GridOptions } from '@ag-grid-community/core'
import { Component, OnInit, ViewChild } from '@angular/core'
import { Helpers } from '@app/shared/utilities/helpers'
import { TextInsideBreadcrumbComponent, TranslateHelper, BreadcrumbService } from '@coreview/coreview-library'
import { Constants } from '@app/shared/utilities/constants'
import { ReportsService } from '@app/core/services/reports.service'
import { MatRadioChange } from '@angular/material/radio'
import { ReportsComponent } from '../../reports.component'
import { sumBy } from 'lodash-es'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { OperationalReportsInfoComponent } from '../../components/operational-reports/operational-reports-info/operational-reports-info.component'
import dayjs from 'dayjs'
import { ReportField, ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'

@Component({
  selector: 'app-microsoft365-service-health',
  templateUrl: './microsoft365-service-health.component.html',
  styleUrls: ['./microsoft365-service-health.component.sass'],
})
export class Microsoft365ServiceHealthComponent implements OnInit {
  @ViewChild(ReportsComponent) reportsComponent!: ReportsComponent

  workloadStatuses!: any[]
  selectedWorkload!: any

  configuration!: {
    gridOptions: GridOptions
    columnDefs?: (ColDef | ColGroupDef)[]
    getParameters: () => any
    isRowMaster: () => boolean
    leftPanel?: string
    rightPanel?: string
  }

  leftPanelOpen = true

  constructor(
    public translateHelper: TranslateHelper,
    private reportsService: ReportsService,
    private breadcrumbService: BreadcrumbService,
    private rightPanelService: RightPanelService,
    private reportHelper: ReportsComponentHelper
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.addComponent({
      type: TextInsideBreadcrumbComponent,
      data: {
        text: this.translateHelper.instant('common_LastUpdate') + ': ' + Helpers.formatDate(dayjs.utc()),
      },
    })

    this.loadData()
  }

  onReloadButtonClicked(event: any) {
    this.workloadStatuses = []
    this.selectedWorkload = null
    this.breadcrumbService.updateComponent(this.translateHelper.instant('common_LastUpdate') + ': ' + Helpers.formatDate(dayjs.utc()))

    this.loadData(true)
  }

  loadData(isReload = false) {
    this.reportsService.getMicrosoft365ServiceHealthCurrentStatus({ forceRefresh: isReload }).subscribe((res: any) => {
      this.workloadStatuses = res.workloadStatuses

      if (this.workloadStatuses?.length > 0) {
        this.selectedWorkload = this.workloadStatuses[0]

        this.configuration = {
          gridOptions: {
            defaultColDef: {
              sortable: true,
              resizable: false,
              filter: true,
              floatingFilter: true,
              filterParams: {
                suppressAndOrCondition: true,
              },
            },
          },
          isRowMaster: () => false,
          columnDefs: this.buildColDefsByFields([
            {
              key: 'detail',
              value: 'Detail',
              type: 'icon',
              cellRenderer: 'iconButtonRenderer',
              cellRendererParams: {
                onClick: (event: any) => {
                  this.rightPanelService.open({
                    type: OperationalReportsInfoComponent,
                    data: {
                      rowData: event.rowData,
                      type: 'microsoft365ServiceHealth',
                      width: Constants.modalDialogSizes.m,
                    },
                  })
                },
                icon: 'info',
                tooltip: this.translateHelper.instant('common_Info'),
              },
            },
            {
              key: 'title',
              value: 'Title',
              type: 'string',
            },
            { key: 'impactDescription', value: 'ImpactDescription', type: 'string' },
            {
              key: 'status',
              value: 'Status',
              type: 'string',
              cellRenderer: 'chipCellRendererComponent',
              cellRendererParams: {
                param: {
                  entity: 'workloadStatus',
                },
              },
            },
          ]),
          getParameters: () => ({
            ids: this.selectedWorkload?.incidentIds.length > 0 ? this.selectedWorkload.incidentIds : ['-1'],
            featureName: this.selectedWorkload?.featureStatus?.length > 0 ? this.selectedWorkload.featureStatus[0].featureName : ['-1'],
          }),
          leftPanel: 'CustomSmartPanel',
        }

        if (isReload) {
          this.reportsComponent?.clientGrid?.refresh()
        }
      }
    })
  }

  countTotalErrors() {
    return sumBy(
      this.workloadStatuses,
      (w: any) => w.featureStatus?.filter((n: any) => n.featureServiceStatus !== 'ServiceOperational').length
    )
  }

  countErrors(featureStatus: any[]) {
    return featureStatus?.filter((n) => n.featureServiceStatus !== 'ServiceOperational').length
  }

  workloadStatusesChange({ value }: MatRadioChange) {
    this.selectedWorkload = value
    this.reportsComponent.clientGrid?.refresh()
  }

  buildColDefsByFields(fields: ReportField[]) {
    const gridColumnsDefs: ColDef[] = []

    fields.forEach((f) => {
      let coldef = { ...this.reportHelper.generateGridColumnsDef(f), unSortIcon: true }
      if (f.type === 'icon') {
        coldef = {
          ...coldef,
          suppressSizeToFit: true,
          sortable: false,
          resizable: false,
          suppressMovable: true,
          suppressMenu: true,
          suppressColumnsToolPanel: true,
          filter: false,
          width: 100,
          minWidth: 100,
          maxWidth: 100,
          cellStyle: () => ({ border: 'none !important' }),
        }
      }
      gridColumnsDefs.push(coldef)
    })

    return gridColumnsDefs
  }

  dateFormatter = (params: any): string => Helpers.formatDate(params.value) || this.translateHelper.instant('common_NeverUsed')
}
