/* eslint-disable max-len */
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core'
import { SearchTypeEnum, SearchTypeShortcutEnum } from '@app/core/enums/search-type'
import { AllResultsResponse, BaseItem, SearchedItems } from '@app/core/models/universal-search'
import { ManagementActionsService } from '@app/core/services/management-actions.service'
import { UniversalSearchService } from '@app/core/services/universal-search.service'
import { PlaybookService } from '@app/modules/playbook/services/playbook.service'
import { RootState } from '@app/store/RootState.type'
import { selectManagementActionsFlat } from '@app/store/management-actions/management-actions.selectors'
import { selectFlatMenusAll } from '@app/store/menu/menu.selectors'
import { Store } from '@ngrx/store'
import { keys, some } from 'lodash-es'
import { Subject, combineLatest } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'

type MostUsedSearchTypes = SearchTypeEnum.Actions | SearchTypeEnum.Reports | SearchTypeEnum.Playbooks;

@Component({
  selector: 'app-universal-search',
  templateUrl: './universal-search.component.html',
  styleUrls: ['./universal-search.component.sass'],
})
export class UniversalSearchComponent implements OnInit {
  @Output() universalSearchToggled = new EventEmitter<boolean>()

  obj: any = {}
  searchTextChanged: Subject<string> = new Subject<string>()
  showOnHover: { [key: number]: boolean } = {}
  maxNumberOfElements: any
  searchedItems: SearchedItems
  allItemsResult?: AllResultsResponse

  disableInput = false
  spinnerVisible = false
  showDropDown = false

  showNoRecentSearches = false

  recents: BaseItem[] = []
  mostUsed: Record<MostUsedSearchTypes, BaseItem[]> = {
    [SearchTypeEnum.Actions]: [],
    [SearchTypeEnum.Reports]: [],
    [SearchTypeEnum.Playbooks]: [],
  }
  shortcuts: Record<SearchTypeShortcutEnum, BaseItem[]> = {
    [SearchTypeShortcutEnum.FavoriteReports]: [],
    [SearchTypeShortcutEnum.PinnedActions]: [],
    [SearchTypeShortcutEnum.PinnedWorkflows]: [],
  }

  // Make the enum accessible to the template
  SearchTypeEnum = SearchTypeEnum;
  SearchTypeShortcutEnum = SearchTypeShortcutEnum;

  activeTab = 1
  loadingFirstPage = true

  @ViewChild('universalSearchInput')
  set setUniversalSearch(el: ElementRef) {
    this.universalSearchInput = el
  }

  private universalSearchInput!: ElementRef

  constructor(
    private universalSearchService: UniversalSearchService,
    private store: Store<RootState>,
    private playbookService:PlaybookService
  ) {
    this.searchTextChanged.pipe(debounceTime(500)).subscribe((model: string) => {
      this.obj.searchTextbox = model
      this.getSuggest(this.obj.searchTextbox)
    })

    this.searchedItems = {
      [SearchTypeEnum.Empty]: [],
      [SearchTypeEnum.Empty2]: [],
    }
  }

  @HostListener('document:click', ['$event'])
  onclick = (event: MouseEvent) => {
    this.closeSearchWhenClickingElsewhere(event, this.closeSearch)
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydown = (event: KeyboardEvent) => {
    event.stopPropagation()
    if (event.key === 'Escape') {
      this.closeSearch()
    }
  }

  ngOnInit(): void {
    this.setManagementActions()
    this.store.select(selectFlatMenusAll).subscribe((x) => {
      this.universalSearchService.setMenuItems(x.flatMenuAll)
      //this.loadFirstPage()
    })
    this.setPlaybooks()
    this.maxNumberOfElements = this.universalSearchService.getMaxNumberOfElements()
  }

  loadFirstPage() {
    this.loadingFirstPage = true
    combineLatest([
      this.universalSearchService.getActionScores(),
      this.universalSearchService.getReportScores(),
      this.universalSearchService.getRecentResults(),
      this.universalSearchService.getRecentPlaybooks(),
      this.universalSearchService.getFavoriteReports(),
      this.universalSearchService.getPinnedActions(),
      this.universalSearchService.getPinnedWorkflows(),
    ]).subscribe(([actions, reports, recents, playbooks, favReports, pinnedActions, pinnedWorkflows]) => {
      actions = actions.concat(ManagementActionsService.topPredefinedManagementActions.slice(0, 10 - actions.length));
      this.universalSearchService.funcsSeeAll
        .Actions()
        ?.subscribe((act) => (this.mostUsed[SearchTypeEnum.Actions] = act.items?.filter((x: any) => actions.includes(x.title))))
      this.mostUsed[SearchTypeEnum.Reports] = reports.slice(0, Math.min(reports.length, 3))
      this.recents = recents.slice(0, Math.min(recents.length, 6))
      this.mostUsed[SearchTypeEnum.Playbooks] = playbooks
      this.showNoRecentSearches = !this.recents?.length
      
      this.shortcuts[SearchTypeShortcutEnum.FavoriteReports] = favReports
      this.shortcuts[SearchTypeShortcutEnum.PinnedActions] = pinnedActions
      this.shortcuts[SearchTypeShortcutEnum.PinnedWorkflows] = pinnedWorkflows

      this.loadingFirstPage = false
    }, () => {
      this.loadingFirstPage = false
    })
  }

  //#region Calls to service

  getSuggest = (query: string) => {
    query = query?.trimLeft()

    this.universalSearchService.setQuery(query)
    this.searchedItems = {}

    if (!query?.trim()) {
      return
    }

    this.spinnerVisible = true

    if (this.obj.seeAll) {
      if (!query) {
        this.obj.seeAll = null
      }

      if ((query && query.length >= 3) || this.obj.seeAll === 'Actions' || this.obj.seeAll === 'Reports') {
        this.seeAllResults(this.obj.seeAll)
        this.searchedItems.searchDone = true
        return
      }

      this.spinnerVisible = false
      return
    }
    this.updateSearchedItems(query)
    this.updateGroups(query)
  }

  seeAllResults = (type?: SearchTypeEnum): void => {
    if (type) {
      this.obj.seeAll = type
      this.universalSearchService.getAllItems(type).subscribe((result) => {
        this.allItemsResult = result
        this.spinnerVisible = false
      })
    }
  }

  seeAllResultsBack = () => {
    this.obj.seeAll = null
    this.getSuggest(this.obj.searchTextbox)
  }

  clearHistory = () => this.universalSearchService.clearHistory().subscribe(() => this.loadFirstPage())

  //#endregion

  removeSkeleton = () => {
    if (this.searchedItems) {
      delete this.searchedItems.Empty
      delete this.searchedItems.Empty2
    }
  }

  onSearchTextChange(query: string) {
    this.searchTextChanged.next(query)
  }

  getIconImg = (type: SearchTypeEnum) => this.universalSearchService.getIconImgFromType(type)

  getActionTitle = (type: SearchTypeEnum) => this.universalSearchService.getActionTitleFromType(type)

  resetVariables = () => {
    this.obj = {}
    this.activeTab = 1
    this.searchedItems = {
      [SearchTypeEnum.Empty]: [],
      [SearchTypeEnum.Empty2]: [],
    }
  }

  manageItem(item?: BaseItem): void {
    if (item) {
      this.universalSearchService.manageItem(item)
      this.resetVariables()
      this.toggleDropDown()
    }
  }

  onManageableElementClick = (item?: BaseItem): void => {
    if (item) {
      this.universalSearchService.processManageableElement(item)
      this.resetVariables()
      this.toggleDropDown()
    }
  }

  beforeToggleDropDown = () => {
    if (
      this.showDropDown
      //&& this.obj.searchTextbox
    ) {
      return
    }
    this.toggleDropDown()
    this.loadFirstPage()
  }

  toggleDropDown = () => {
    this.showDropDown = !this.showDropDown
    this.universalSearchToggled.emit(this.showDropDown)
  }

  selectCategory = (category: any) => {
    if (this.allItemsResult) {
      if (this.obj.selectedCategory === category) {
        this.universalSearchService.resetFilter(this.allItemsResult)
        this.obj.selectedCategory = null
        return
      }
      this.allItemsResult.filteredItems = this.universalSearchService.filterByCategory(this.allItemsResult.items, category, this.obj.seeAll)
    }
    this.obj.selectedCategory = category
  }

  closeSearch = () => {
    if (!this.showDropDown) {
      return
    }

    setTimeout(() => {
      this.resetVariables()
      this.universalSearchInput.nativeElement.blur()
      this.toggleDropDown()
    }, 100)
  }

  getSearchTypeEnumByString(str: string): SearchTypeEnum {
    return (SearchTypeEnum as any)[str]
  }

  canSeeCantFindMessage = () =>
    some(this.searchedItems, (items: any, type: SearchTypeEnum) => items.length > this.maxNumberOfElements[type])

  canSeeCantFindResults = () =>
    this.obj.searchTextbox &&
    !this.searchedItems?.searchDone &&
    (!this.searchedItems || keys(this.searchedItems).length === 0) &&
    (!this.allItemsResult || !this.allItemsResult.filteredItems || this.allItemsResult.filteredItems.length === 0)

  canSeeNoResultsContainer = () =>
    this.obj.searchTextbox &&
    this.searchedItems?.searchDone &&
    (!this.searchedItems || keys(this.searchedItems).filter((x) => x !== 'searchDone').length === 0) &&
    (!this.allItemsResult || !this.allItemsResult.filteredItems || this.allItemsResult.filteredItems.length === 0)

  // TODO use auth to find out if it's a user
  isUser = () => true

  closeSearchWhenClickingElsewhere = (event: MouseEvent, callbackOnClose: () => void) => {
    const clickedElement = event.target as HTMLElement
    if (!clickedElement) {
      return
    }

    const clickedOnSearchInputOrContent =
      clickedElement.closest('.dropdown-menu') ||
      clickedElement.closest('.dropdown-search') ||
      clickedElement.closest('.elements-container')

    if (!clickedOnSearchInputOrContent) {
      callbackOnClose()
    }
  }

  isElmentManageable = (item: BaseItem) => this.universalSearchService.isElmentManageable(item)

  private updateSearchedItems = (query: string) => {
    const searchedReports = this.universalSearchService.getSearchedReports()
    const searchedActions = this.universalSearchService.getSearchedActions()
    const searchedPlaybooks = this.universalSearchService.getSearchedPlaybooks()

    if (searchedReports && searchedReports.length > 0) {
      this.searchedItems.Reports = searchedReports
      this.searchedItems.searchDone = true
    }

    if (searchedActions && searchedActions.length > 0) {
      this.searchedItems.Actions = searchedActions
    }

    if (searchedPlaybooks && searchedPlaybooks.length > 0) {
      this.searchedItems.Playbooks = searchedPlaybooks
    }

    if (!query || query.length < 3) {
      this.spinnerVisible = false
      return
    }

    this.searchedItems.Empty = [{}, {}]

    if ((this.searchedItems.Reports && this.searchedItems.Actions) || (!this.searchedItems.Reports && !this.searchedItems.Actions)) {
      this.searchedItems.Empty2 = [{}, {}]
    }
  }

  private updateGroups = (query: string) => {
    if (query && query.length < 3) return
    const params = { search: query, size: this.maxNumberOfElements.Users + 1 }
    const paramsGroups = { search: query, size: this.maxNumberOfElements.Groups + 1 }

    combineLatest([
      this.universalSearchService.getUsers(params),
      this.universalSearchService.getDistributionGroups(paramsGroups),
      this.universalSearchService.getSecurityGroups(paramsGroups),
      this.universalSearchService.getOffice365Groups(paramsGroups),
      this.universalSearchService.getTeamsGroups(paramsGroups),
    ]).subscribe(([users, distributionGroups, securityGroups, office365Groups, teamsGroups]) => {
      if (this.universalSearchService.getQuery() !== query) {
        return
      }

      const searchedUsers = this.universalSearchService.getSearchedUsers(users.options)
      if (searchedUsers && searchedUsers.length > 0) {
        this.searchedItems.Users = searchedUsers
      }

      const searchedDistributionGroups = this.universalSearchService.getSearchedDistributionGroups(distributionGroups.options)
      if (searchedDistributionGroups && searchedDistributionGroups.length > 0) {
        this.searchedItems.DistributionGroups = searchedDistributionGroups
      }

      const searchedSecurityGroups = this.universalSearchService.getSearchedSecurityGroups(securityGroups.options)
      if (searchedSecurityGroups && searchedSecurityGroups.length > 0) {
        this.searchedItems.SecurityGroups = searchedSecurityGroups
      }

      const searchedOffice365Groups = this.universalSearchService.getSearchedOffice365Groups(office365Groups.options)
      if (searchedOffice365Groups && searchedOffice365Groups.length > 0) {
        this.searchedItems.Office365Groups = searchedOffice365Groups
      }

      const searchedTeamsGroups = this.universalSearchService.getSearchedTeamsGroups(teamsGroups.options)
      if (searchedTeamsGroups && searchedTeamsGroups.length > 0) {
        this.searchedItems.TeamsGroups = searchedTeamsGroups
      }

      this.searchedItems.searchDone = true

      this.removeSkeleton()
      this.disableInput = false
      this.spinnerVisible = false
    })
  }

  private setManagementActions = () => {
    this.store.select(selectManagementActionsFlat)
    .pipe(filter((x) => !!x && x.length > 0))
    .subscribe((x) => {
      this.universalSearchService.setManagementAction(x)
    })
  }

  private setPlaybooks = () => {
    this.playbookService.getPlaybookTitleWithPolicyCount()
      .subscribe((x) => {
        this.universalSearchService.setPlaybookItems(x)
      })
  }
}
