/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core'
import { TranslateHelper } from '@coreview/coreview-library'
import { Router } from '@angular/router'
import { CardDefinition, CardWidget, SecondaryButton } from '@app/modules/cards/Card'
import { Constants } from '@app/shared/utilities/constants'
import { Helpers } from '@app/shared/utilities/helpers'
import { ManagementAction } from '../models/management-action'
import { AuthHelperService } from './auth.helper.service'
import { ManagementHelperService } from '@app/shared/utilities/management.helper.service'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import {
  DistributionGroupCardParams,
  M365GroupCardParams,
  SecurityGroupCardParams,
  TeamsGroupCardParams,
  getCallGroupCardDefinition,
  getDistributionGroupCardDefinition,
  getM365GroupCardDefinition,
  getSecurityGroupCardDefinition,
  getTeamsGroupCardDefinition,
} from './card.service.definitions'
import { ManagementActionsService } from './management-actions.service'
import { UuidService } from '@app/shared/utilities/uuid.service'
import { get } from 'lodash-es'
import { SelectedOrganization } from '@coreview/coreview-library/models/selected-organization'
import { Role } from '../models/authenticated-user'
import { LocalstorageService } from './localstorage.service'
import { ActionsHelperService } from './actions-helper.service'

@Injectable({
  providedIn: 'root',
})
export class CardService {
  id!: string
  availableActions: ManagementAction[] = []
  item: any = {}

  isManagement: boolean
  selectedOrganization: SelectedOrganization
  loggedUser: { userName: string; roles: Role[] }
  defaultValueManagementActionNote = ''

  constructor(
    private router: Router,
    private translateHelper: TranslateHelper,
    private authHelperService: AuthHelperService,
    private managementHelperService: ManagementHelperService,
    private managementActionsService: ManagementActionsService,
    private sharedHelperService: SharedHelperService,
    private uuidService: UuidService,
    private storage: LocalstorageService,
    private actionsHelperService: ActionsHelperService
  ) {
    this.isManagement = this.sharedHelperService.isManagement()
    this.selectedOrganization = this.storage.selectedOrganization
    this.loggedUser = this.storage.getLoggedUser()
    this.defaultValueManagementActionNote = this.translateHelper.instant(Constants.defaultValueManagementActionNote)
  }

  clickOnActionCard = ($event: any, actionId: number, data?: any) => {
    this.openManagementPanel(actionId, [this.item])
  }

  setId(id: string) {
    this.id = id
  }

  setAvailableActions(availableActions: ManagementAction[]) {
    this.availableActions = availableActions
  }

  setItem(item: any) {
    this.item = item
  }

  populateFunc = (card: CardWidget, item: any) => {
    card.options.secondaryButtons?.forEach((but: SecondaryButton) => {
      but.hidden = !this.canSeeAction(but.actionId, [item])
    })

    card.infos = []
    card.defaultFields?.forEach((field: any, index: number) => {
      let value: any = null
      let title = ''

      if (!!field && typeof field === 'string') {
        value = Helpers.parseDate(item[Helpers.downcase(field)])
        title = field
      } else if (!!field && typeof field === 'function') {
        const v = field(item)
        value = v?.value
        title = v?.title
      }

      const info: any = {
        id: index,
        title,
        value,
        type: 'value',
        action: '',
        actionId: 0,
        actionIcon: '',
        actionLabel: '',
        actionFunc: null,
      }

      card.infos?.push(info)

      card.loadingCompleted = true
    })
  }

  getCardDefinition(entity: string): CardDefinition | undefined {
    switch (entity) {
      case 'distributionGroup': {
        const distributionGroupParams: DistributionGroupCardParams = {
          translateHelper: this.translateHelper,
          router: this.router,
          id: this.id,
          isManagement: this.isManagement,
          clickOnActionCard: this.clickOnActionCard,
          getEmailAddresses: this.getEmailAddresses,
          getManagedBy: this.getManagedBy,
          populateFunc: this.populateFunc,
          clickOnActionInline: this.clickOnActionInline,
          canSeeDeleteAction: this.canSeeActionWithoutTargets(Constants.managementActionsIds.removeDistributionGroupMember),
        }
        return getDistributionGroupCardDefinition(distributionGroupParams)
      }
      case 'securityGroup': {
        const securityGroupParams: SecurityGroupCardParams = {
          translateHelper: this.translateHelper,
          router: this.router,
          id: this.id,
          isManagement: this.isManagement,
          clickOnActionCard: this.clickOnActionCard,
          getManagedBy: this.getManagedBy,
          populateFunc: this.populateFunc,
          clickOnActionInline: this.clickOnActionInline,
          canSeeDeleteAction: this.canSeeActionWithoutTargets(Constants.managementActionsIds.removeSecurityGroupMember),
        }
        return getSecurityGroupCardDefinition(securityGroupParams)
      }
      case 'm365Group': {
        const m365GroupParams: M365GroupCardParams = {
          translateHelper: this.translateHelper,
          router: this.router,
          id: this.id,
          isManagement: this.isManagement,
          clickOnActionCard: this.clickOnActionCard,
          populateFunc: this.populateFunc,
          clickOnActionInline: this.clickOnActionInline,
          canSeeDeleteAction: this.canSeeActionWithoutTargets(Constants.managementActionsIds.removeO365GroupMember),
        }
        return getM365GroupCardDefinition(m365GroupParams)
      }

      case 'teamsGroup': {
        const teamsGroupParams: TeamsGroupCardParams = {
          translateHelper: this.translateHelper,
          router: this.router,
          id: this.id,
          isManagement: this.isManagement,
          clickOnActionCard: this.clickOnActionCard,
          populateFunc: this.populateFunc,
          clickOnActionInline: this.clickOnActionInline,
          canSeeDeleteAction: this.canSeeActionWithoutTargets(Constants.managementActionsIds.removeTeamsGroupMember),
          canSeeUpdateChannelAction: this.canSeeActionWithoutTargets(Constants.managementActionsIds.updateTeamsGroupChannel),
        }
        return getTeamsGroupCardDefinition(teamsGroupParams)
      }
      case 'call':
        return getCallGroupCardDefinition(this.translateHelper, this.populateFunc, this.clickOnActionInline)
      default:
        return undefined
    }
  }

  getManagedBy(item: any) {
    const value = item.managedBy.map((m: any) => m.recipientIdentity).join(', ')
    return {
      title: 'ManagedBy',
      value,
    }
  }

  getEmailAddresses(item: any) {
    const value = item.emailAddresses
      .filter((e: string) => e.toLowerCase().startsWith('smtp'))
      .map((e: string) => e.replace(/smtp:/gi, ''))
      .join(', ')
    return {
      title: 'EmailAddresses',
      value,
    }
  }

  clickOnActionInline = ($event: any, actionId: number, name?: any): boolean => {
    const action = this.getAction(actionId)

    if (!action) {
      return false
    }

    if (this.actionsHelperService.checkAction()) {
      this.actionFunctions[name]($event, action)
      return true
    }
    return false
  }

  removeDistributionGroupMember = ($event: any, action: ManagementAction) => {
    const data = $event.rowData
    const model = {
      members: [data.userPrincipalName],
      groupId: data.distributionGroupGuid,
    }
    this.executeInlineAction(action, model)
  }

  removeSecurityGroupMember = ($event: any, action: ManagementAction) => {
    const data = $event.rowData
    const model = {
      members: [data.userPrincipalName],
      securityGroupId: data.securityGroupGuid,
    }
    this.executeInlineAction(action, model)
  }

  removeO365GroupMember = ($event: any, action: ManagementAction) => {
    const data = $event.rowData
    const model = {
      members: [{ userPrincipalName: data.userPrincipalName, isOwner: data.isOwner }],
      groupId: data.office365GroupGuid,
    }
    this.executeInlineAction(action, model)
  }

  removeTeamsGroupMember = ($event: any, action: ManagementAction) => {
    const data = $event.rowData
    const userPrincipalName = decodeURIComponent(data.userPrincipalName)
    const model = {
      members: [{ userPrincipalName, isOwner: data.isOwner }],
      groupId: data.office365GroupGuid,
    }
    this.executeInlineAction(action, model)
  }

  actionFunctions: { [K: string]: (data: any, action: ManagementAction) => void } = {
    removeDistributionGroupMember: this.removeDistributionGroupMember,
    removeSecurityGroupMember: this.removeSecurityGroupMember,
    removeO365GroupMember: this.removeO365GroupMember,
    removeTeamsGroupMember: this.removeTeamsGroupMember,
  }

  executeInlineAction(action: ManagementAction, params: any) {
    this.managementActionsService
      .submitAction(action.submitApiUrl, action.submitVerb, {
        companyId: this.selectedOrganization.id,
        note: this.defaultValueManagementActionNote,
        actionId: action.actionItemId,
        aggregationId: this.uuidService.v4(),
        actionName: action.title,
        category: get(
          action.reportTags?.filter((r) => r.includes('category_')),
          0,
          'category_Undefined'
        ).slice(9),
        ...params,
      })
      .subscribe(() => {})
  }

  getAction(actionId: number) {
    return this.availableActions.find((a: any) => a.actionItemId === actionId)
  }

  private canManage(): boolean {
    return this.authHelperService.hasRole(Constants.roles.Management)
  }

  private canSeeAction(id: number, targets: any[]): boolean {
    return (
      this.canManage() &&
      this.availableActions.some((act) => act?.actionItemId === id && this.managementHelperService.isCompliantWithActionRules(act, targets))
    )
  }

  private canSeeActionWithoutTargets(id: number): boolean {
    return this.canManage() && this.availableActions.some((c: ManagementAction) => c.actionItemId === id)
  }

  private openManagementPanel(actionId: number, targets: any[]) {
    this.managementHelperService.openManagementPanel(
      targets,
      this.availableActions.find((a: any) => a.actionItemId === actionId)
    )
  }
}
