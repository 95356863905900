import { ForbiddenComponent } from './core/components/forbidden/forbidden.component'
import { HomeComponent } from './core/components/home/home.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '@core/guards/auth.guard'
import { accessLevels } from './core/services/roleAccessMapping.service'
import { WorkflowPath } from './modules/workflow/workflow.path'
import { SecureByDefaultComponent } from './core/components/secure-by-default/secure-by-default.component'
import { ConsentAppsComponent } from './core/components/consent-apps/consent-apps.component'
import { UnauthorizedAccessComponent } from './core/components/unauthorized-access/unauthorized-access.component'

/**
 * The main application routes
 */
const routes: Routes = [
  { path: '', pathMatch: 'full', children: [], component: HomeComponent },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
  },
  {
    path: 'securebydefault',
    outlet: 'forbidden',
    component: SecureByDefaultComponent,
  },
  { path: 'unauthorized',
    outlet: 'forbidden',
    component: UnauthorizedAccessComponent,
  },
  {
    path: 'consent',
    component: ConsentAppsComponent,
  },
  {
    path: 'healthcheck',
    redirectTo: '/governance',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: 'playbook',
    loadChildren: () => import('./modules/playbook/playbook.module').then((m) => m.PlaybookModule),
  },
  {
    path: 'administrations',
    loadChildren: () => import('./modules/administrations/administrations.module').then((m) => m.AdministrationsModule),
  },
  {
    path: 'serviceHealth',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule),
    data: { accessLevel: accessLevels.user },
  },
  {
    path: 'usercard/:upn',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/usercard/usercard.module').then((m) => m.UsercardModule),
    data: { accessLevel: accessLevels.noServerCheck },
    pathMatch: 'full',
  },
  {
    path: 'card/:entity/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/cards/cards.module').then((m) => m.CardsModule),
    data: { accessLevel: accessLevels.noServerCheck },
    pathMatch: 'full',
  },
  {
    path: 'management',
    loadChildren: () => import('./modules/management/management.module').then((m) => m.ManagementModule),
  },
  { path: WorkflowPath.root, loadChildren: () => import('./modules/workflow/workflow.module').then((m) => m.WorkflowModule) },
  {
    path: 'governance',
    canActivate: [AuthGuard],
    data: { accessLevel: accessLevels.user },
    loadChildren: () => import('./modules/governance/governance.module').then((m) => m.GovernanceModule),
  },
  {
    path: 'multitenant',
    loadChildren: () => import('./modules/multitenant/multitenant.module').then((m) => m.MultitenantModule),
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
