<ng-container>
  <app-reports
    [class.left-panel-close]="!leftPanelOpen"
    [clientTableConfiguration]="configuration"
    [searchInProgress]="searchInProgress"
    [progressMessage]="progressMessage"
  >
    <div [style.display]="!leftPanelOpen ? null : 'none'" #fakeLeft customSmartPanel></div>
    <cv-smart-panel
      type="button"
      icon="tune"
      [style.position]="leftPanelOpen ? null : 'absolute'"
      (openChange)="fakeLeft.classList.add('fake-left-panel')"
      [title]="'reports_FilterAssistant' | translate"
      style="z-index: 1; left: 16px; overflow: auto"
      [(open)]="leftPanelOpen"
      customSmartPanel
    >
      <div class="filters-container" content>
        <div style="cursor: default">
          <form [formGroup]="form">
            <div class="title-container">
              <div class="title" translate>common_FilterAssistant</div>
            </div>
            <div class="subtitle" translate>reports_FilterAssistantQuarantinedMessagesSubTitle</div>

            <div class="select-section" style="padding-bottom: 0px">
              <div translate>reports_ReceivedDate</div>

              <cv-custom-daterangepicker
                *ngIf="!resetInProgress"
                placeholder="{{ 'reports_CustomRange' | translate }}"
                (rangeChange)="onRangeChange($event)"
                [minDate]="minDate"
                [localeIso]="localeIso"
                [defaultRange]="defaultRange"
                [rangeOptions]="rangeOptions"
                [showCustomRangeAsLabel]="true"
              >
              </cv-custom-daterangepicker>
            </div>

            <div class="select-section">
              <cv-input
                style="width: 47%"
                label="{{ 'common_StartReceivedDate' | translate }}"
                [errorMessage]="'common_Required' | translate"
                [readonly]="true"
                formControlName="startReceivedDate"
              >
              </cv-input>

              <cv-input
                style="width: 47%; float: right"
                label="{{ 'common_StartReceivedTime' | translate }}"
                [errorMessage]="'common_Required' | translate"
                formControlName="startReceivedTime"
                (inputChange)="changedStartReceivedTimeFilter($event)"
              >
              </cv-input>

              <div class="utc-value">UTC: {{ showUTCDate(filters.startReceivedDateObj) }}</div>
            </div>

            <div class="select-section">
              <cv-input
                style="width: 47%"
                label="{{ 'common_EndReceivedDate' | translate }}"
                [errorMessage]="'common_Required' | translate"
                [readonly]="true"
                formControlName="endReceivedDate"
              >
              </cv-input>

              <cv-input
                style="width: 47%; float: right"
                label="{{ 'common_EndReceivedTime' | translate }}"
                [errorMessage]="'common_Required' | translate"
                formControlName="endReceivedTime"
                (inputChange)="changedEndReceivedTimeFilter($event)"
              >
              </cv-input>

              <div class="utc-value">UTC: {{ showUTCDate(filters.endReceivedDateObj) }}</div>
            </div>

            <div class="select-section" style="padding-bottom: 0px">
              <div translate>reports_ExpiresDate</div>

              <cv-custom-daterangepicker
                *ngIf="!resetInProgress"
                placeholder="{{ 'reports_CustomRange' | translate }}"
                (rangeChange)="onRangeChangeExpires($event)"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [localeIso]="localeIso"
                [defaultRange]="defaultRangeExpires"
                [rangeOptions]="rangeOptions"
                [showCustomRangeAsLabel]="true"
              >
              </cv-custom-daterangepicker>
            </div>

            <div class="select-section">
              <cv-input
                style="width: 47%"
                label="{{ 'common_StartExpiresDate' | translate }}"
                [errorMessage]="'common_Required' | translate"
                [readonly]="true"
                formControlName="startExpiresDate"
              >
              </cv-input>

              <cv-input
                style="width: 47%; float: right"
                label="{{ 'common_StartExpiresTime' | translate }}"
                [errorMessage]="'common_Required' | translate"
                formControlName="startExpiresTime"
                (inputChange)="changedStartExpiresTimeFilter($event)"
              >
              </cv-input>

              <div class="utc-value">UTC: {{ showUTCDate(filters.startExpiresDateObj) }}</div>
            </div>

            <div class="select-section">
              <cv-input
                style="width: 47%"
                label="{{ 'common_EndExpiresDate' | translate }}"
                [errorMessage]="'common_Required' | translate"
                [readonly]="true"
                formControlName="endExpiresDate"
              >
              </cv-input>

              <cv-input
                style="width: 47%; float: right"
                label="{{ 'common_EndExpiresTime' | translate }}"
                [errorMessage]="'common_Required' | translate"
                formControlName="endExpiresTime"
                (inputChange)="changedEndExpiresTimeFilter($event)"
              >
              </cv-input>

              <div class="utc-value">UTC: {{ showUTCDate(filters.endExpiresDateObj) }}</div>
            </div>

            <div class="select-section">
              <cv-input label="{{ 'common_SenderAddress' | translate }}" formControlName="sender" autocomplete="off" [required]="false">
              </cv-input>
            </div>

            <div class="select-section">
              <cv-input
                label="{{ 'common_RecipientAddress' | translate }}"
                formControlName="recipient"
                autocomplete="off"
                [required]="false"
              >
              </cv-input>
            </div>

            <div class="select-section">
              <cv-input label="{{ 'common_Subject' | translate }}" formControlName="subject"> </cv-input>
            </div>

            <div class="select-section">
              <cv-select
                style="width: 100%"
                formControlName="type"
                [label]="'common_Type' | translate"
                [suggestions]="typeSuggestions || []"
              >
              </cv-select>
            </div>
          </form>
        </div>
      </div>
      <div footer>
        <div class="error-container" *ngIf="getErrors().length > 0" style="text-align: left">
          <div *ngFor="let e of getErrors()">
            <span translate>{{ e }}</span>
          </div>
        </div>

        <cv-button (click)="reset()" class="m-r-10" buttonType="secondary" [text]="'common_Reset' | translate"> </cv-button>
        <cv-button (click)="search()" primary="secondary" [text]="'common_Search' | translate" [disabled]="isSearchDisabled()"> </cv-button>
      </div>
    </cv-smart-panel>
  </app-reports>
</ng-container>
