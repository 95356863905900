import { SearchTypeEnum, SearchTypeShortcutEnum } from '@app/core/enums/search-type'
import { Observable } from 'rxjs'

export interface Payload {
  iconType: string;
  objectType: string;
  text: string;
}

export interface ElasticSearchResult {
  uniqueId: string;
  text: string;
  payload: Payload;
}

export interface BaseItem {
  id?: string;
  name: string;
  email?: string;
  classIcon: string;
  type: SearchTypeEnum | SearchTypeShortcutEnum;
  category?: string;
  parent?: string;
  isManageable?: boolean;
  detail?: string;
}

export type BaseItemSearch = Pick<BaseItem, 'name' | 'classIcon' | 'type'> & { route: string | null | undefined }

export type RecentSearch = BaseItem

export type UserSearch = BaseItem

export type DistributionGroupSearch = BaseItem

export type SecurityGroupSearch = BaseItem

export type Office365GroupSearch = BaseItem

export type TeamsGroupSearch = BaseItem

export interface PlaybookSearch extends BaseItem {
  route: string;
  routeParam: string;
}

export interface ActionSearch extends BaseItem {
  actionItemId: number;
  // category?: string;
}

export interface ReportSearch extends BaseItem {
  route: string;
  // category?: string;
}

export interface SearchTypeIcons {
  type: SearchTypeEnum | SearchTypeShortcutEnum;
  class: string;
  img: string;
}

export interface CategoryTypeIcons {
  category: string;
  name: string;
  class: string;
}

export interface AllResultsResponse {
  items: any[];
  filteredItems?: any[];
  categories?: any[];
}

/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/ban-types */
export interface SearchedItems {
  Empty?: any;
  Empty2?: any;
  Reports?: any[];
  Actions?: any[];
  Users?: UserSearch[];
  Playbooks?: any[];
  DistributionGroups?: DistributionGroupSearch[];
  SecurityGroups?: SecurityGroupSearch[];
  Office365Groups?: Office365GroupSearch[];
  TeamsGroups?: TeamsGroupSearch[];
  searchDone?: boolean;
}

export interface SeeAllResponse {
  items: any[];
  filteredItems: any[];
}

export class SeeAllFunctionsToCallMapping {
  [SearchTypeEnum.Users]!: Function;
  [SearchTypeEnum.DistributionGroups]!: Function;
  [SearchTypeEnum.SecurityGroups]!: Function;
  [SearchTypeEnum.Office365Groups]!: Function;
  [SearchTypeEnum.TeamsGroups]!: Function;
  [SearchTypeEnum.Actions]!: () => Observable<SeeAllResponse>;
  [SearchTypeEnum.Reports]!: () => Observable<SeeAllResponse>;
  [SearchTypeEnum.Playbooks]!: Function;
  [SearchTypeEnum.Empty]!: Function;
  [SearchTypeEnum.Empty2]!: Function
}

/* eslint-enable @typescript-eslint/naming-convention */
