import { Injectable } from '@angular/core'
import { AuthenticatedUser } from '../models/authenticated-user'
import { GlobalFilter } from '../models/GlobalFilter'
import { SelectedOrganization } from '@coreview/coreview-library/models/selected-organization'
import dayjs from 'dayjs'
import { ServicePlan } from '@app/shared/models/service-plan'

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  private baseApiUrl: string
  private baseAuthApiUrl: string
  private loggeduser: AuthenticatedUser
  private internalAuthToken: string | null
  private internalSelectedOrganization!: SelectedOrganization

  constructor() {
    this.baseApiUrl = this.getString('basePortalApiUrl') || '' //environment.baseCentralUrl
    this.baseAuthApiUrl = this.getString('baseAuthApiUrl') || ''
    this.loggeduser = JSON.parse(this.getString('loggedUser') || '{ "userName": "anonymous" }')
    this.internalAuthToken = this.get4wardToken() || this.getString('Token')
    this.get4wardSelectedOrganization()
    //TODO: on startup the selected organization should be the same as myorganization
    const selOrg = this.getString('SelectedOrganization')
    if (!this.selectedOrganization && selOrg && selOrg !== 'undefined') {
      this.internalSelectedOrganization = JSON.parse(selOrg)
    }
  }

  public get basePortalApiUrl(): string {
    return this.baseApiUrl
  }

  public set basePortalApiUrl(v: string) {
    this.baseApiUrl = v
    this.setString('basePortalApiUrl', v)
  }

  public get basePortalAuthApiUrl(): string {
    return this.baseAuthApiUrl
  }

  public set basePortalAuthApiUrl(v: string) {
    this.baseAuthApiUrl = v
    this.setString('baseAuthApiUrl', v)
  }

  public getLoggedUser(): AuthenticatedUser {
    return this.loggeduser
  }

  public setLoggedUser(v: AuthenticatedUser) {
    this.loggeduser = v
    this.setString('loggedUser', JSON.stringify(v))
  }

  set preferredLanguage(lang: string) {
    this.setString('preferred-language', lang)
  }

  get preferredLanguage(): string {
    return this.getString('preferred-language') || 'en'
  }

  public get chartDisplayPreference(): boolean {
    if (this.getString('chartPreference') === null) {
      return true
    }
    return this.getString('chartPreference') === 'true'
  }

  public set chartDisplayPreference(chartPreference: boolean) {
    this.setString('chartPreference', String(chartPreference))
  }

  public get mapDisplayPreference(): boolean {
    if (this.getString('mapPreference') === null) {
      return true
    }
    return this.getString('mapPreference') === 'true'
  }

  public set mapDisplayPreference(mapPreference: boolean) {
    this.setString('mapPreference', String(mapPreference))
  }

  public get isOpenPanelCustomReport(): boolean {
    return JSON.parse(this.getString('isOpenPanelCustomReport') || 'true') === true
  }

  public set isOpenPanelCustomReport(open: boolean) {
    this.setString('isOpenPanelCustomReport', String(open))
  }

  public get selectedTabSmartPanel(): number {
    return Number(this.getString('activeIndexMASmartPanel')) || 2
  }

  public set selectedTabSmartPanel(v: number) {
    this.setString('activeIndexMASmartPanel', String(v))
  }

  public setSelectedGroupFilter(selectedGroupFilter: string | null) {
    this.setString('selectedGroupFilter', selectedGroupFilter || '')
  }

  public getSelectedGroupFilter(): string | null {
    const filter = this.getString('selectedGroupFilter')
    return !filter || filter === '' ? null : filter
  }

  public setSelectedGlobalFilter(selectedGlobalFilter: GlobalFilter | null) {
    if (selectedGlobalFilter) {
      this.setString('SelectedGlobalFilter', JSON.stringify(selectedGlobalFilter))
    } else {
      localStorage.removeItem('cv-SelectedGlobalFilter')
    }
  }

  public getSelectedGlobalFilter(): GlobalFilter | null {
    const globalFilterString = this.getString('SelectedGlobalFilter')
    if (!globalFilterString || globalFilterString === '') {
      return null
    } else {
      const result = JSON.parse(globalFilterString) as GlobalFilter
      return result
    }
  }

  public get authToken(): string {
    return this.internalAuthToken || ''
  }

  public set authToken(v: string) {
    this.setString('Token', v)
    this.internalAuthToken = v
  }

  public get selectedOrganization(): SelectedOrganization {
    return this.internalSelectedOrganization
  }

  public set selectedOrganization(v: SelectedOrganization) {
    this.setString('SelectedOrganization', JSON.stringify(v))
    this.internalSelectedOrganization = v
  }

  public removeAuthToken() {
    localStorage.removeItem('cv-Token')
  }

  public removeAll() {
    localStorage.clear()
  }

  public getServicePlans(): ServicePlan[] {
    return JSON.parse(this.getString('servicePlans') || '[]')
  }

  public setServicePlans(sp: ServicePlan[]) {
    this.setString('servicePlans', JSON.stringify(sp))
  }

  public setMessageTraceSearchCorrelationId(id: string | null) {
    this.setString('messageTraceSearchCorrelationId', id || '')
  }

  public getMessageTraceSearchCorrelationId(): string | null {
    return this.getString('messageTraceSearchCorrelationId')
  }

  public setMessageTraceSearchFilters(filters: any | null) {
    this.setString('messageTraceSearchFilters', JSON.stringify(filters))
  }

  public getMessageTraceSearchFilters(): any | null {
    return JSON.parse(this.getString('messageTraceSearchFilters') || '""')
  }

  public setCalendarPermissionSearchCorrelationId(id: string | null) {
    this.setString('calendarPermissionSearchCorrelationId', id || '')
  }

  public getCalendarPermissionSearchCorrelationId(): string | null {
    return this.getString('calendarPermissionSearchCorrelationId')
  }

  public setCalendarPermissionSearchFilters(filters: any | null) {
    this.setString('calendarPermissionSearchFilters', JSON.stringify(filters))
  }

  public getCalendarPermissionSearchFilters(): any | null {
    return JSON.parse(this.getString('calendarPermissionSearchFilters') || '""')
  }

  public setQuarantinedMessagesSearchFilters(filters: any | null) {
    this.setString('quarantinedMessagesSearchFilters', JSON.stringify(filters))
  }

  public getQuarantinedMessagesSearchFilters(): any | null {
    return JSON.parse(this.getString('quarantinedMessagesSearchFilters') || '""')
  }

  public setNewCustomReportData(data: { name: string; selectedColumns?: string[] }) {
    this.setString('newCustomReportData', JSON.stringify(data))
  }

  public getNewCustomReportData(): { name: string; selectedColumns?: string[] } {
    return JSON.parse(this.getString('newCustomReportData') || '""')
  }

  public setIsBannerClosed() {
    this.setString('isBannerClosed', JSON.stringify(true))
  }

  public getIsBannerClosed(): boolean {
    return JSON.parse(this.getString('isBannerClosed') || 'false')
  }

  public setWhenClosedImportErrorBanner() {
    this.setString('whenClosedImportErrorBanner', JSON.stringify(dayjs()))
  }

  public getWhenClosedImportErrorBanner(): dayjs.Dayjs | null {
    return JSON.parse(this.getString('whenClosedImportErrorBanner') ?? 'null')
  }

  /*V1 integration*/

  get4wardToken() {
    const t = this.getV1String('X-4ward365-Token')
    if (t) {
      const trimmed = t.replace(/"/g, '')
      this.authToken = trimmed
      return trimmed
    }
    return undefined
  }

  get4wardSelectedOrganization() {
    const t = this.getV1String('selectedOrganization')
    if (t) {
      this.selectedOrganization = JSON.parse(t)
    }
  }

  public setV1String(key: string, value: string) {
    localStorage.setItem(`ls.${key}`, value)
  }

  public getV1String(key: string): any {
    return localStorage.getItem(`ls.${key}`)
  }

  public setV1UserSettings(value: any) {
    localStorage.setItem(`ls.userSettings`, JSON.stringify(value))
  }

  /* end V1 integration*/

  private setString(key: string, value: string) {
    localStorage.setItem(`cv-${key}`, value)
  }

  private getString(key: string) {
    return localStorage.getItem(`cv-${key}`)
  }
}
