import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { GroupCategoryTypeEnum } from '@app/core/enums/group-category-type'
import { SearchTypeEnum, SearchTypeShortcutEnum } from '@app/core/enums/search-type'
import {
  ActionSearch,
  AllResultsResponse,
  BaseItem,
  CategoryTypeIcons,
  DistributionGroupSearch,
  ElasticSearchResult,
  Office365GroupSearch,
  PlaybookSearch,
  RecentSearch,
  ReportSearch,
  SearchTypeIcons,
  SecurityGroupSearch,
  SeeAllFunctionsToCallMapping,
  SeeAllResponse,
  TeamsGroupSearch,
  UserSearch,
} from '@app/core/models/universal-search'
import { ActionPanelComponent } from '@app/shared/components/action-panel/action-panel.component'
import { Constants } from '@app/shared/utilities/constants'
import { CustomActionsHelper } from '@app/shared/utilities/custom-actions-helper'
import { ManagementHelperService } from '@app/shared/utilities/management.helper.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { cloneDeep, flattenDeep, groupBy } from 'lodash-es'
import { Observable, ReplaySubject, combineLatest, of } from 'rxjs'
import { map, pluck, switchMap, take } from 'rxjs/operators'
import { PlaybookTitleWithPolicyCount } from '../models/playbook'
import { NavItem } from '@coreview/coreview-library/models/nav-item'
import { ApiclientService } from './apiclient.service'
import { LocalstorageService } from './localstorage.service'
import { ManagementActionsService } from './management-actions.service'
import { RightPanelService } from './right-panel.service'
import { ToolbarService } from './toolbar.service'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { selectFavorites, selectMenusAll } from '@app/store/menu/menu.selectors'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { selectManagementActionsFlat } from '@app/store/management-actions/management-actions.selectors'
import { accessLevels } from './roleAccessMapping.service'
import { WorkflowHttpService } from '@app/modules/workflow/http/workflow.http'

const classIcons: SearchTypeIcons[] = [
  { type: SearchTypeEnum.Users, class: 'ms-Icon ms-Icon--Contact', img: 'assets/img/universal-search/user_icon_universal_search.svg' },
  {
    type: SearchTypeEnum.DistributionGroups,
    class: 'ms-Icon ms-Icon--Group',
    img: 'assets/img/universal-search/dg_icon_universal_search.svg',
  },
  { type: SearchTypeEnum.SecurityGroups, class: 'ms-Icon ms-Icon--Group', img: 'assets/img/universal-search/sg_icon_universal_search.svg' },
  {
    type: SearchTypeEnum.Office365Groups,
    class: 'ms-Icon ms-Icon--Group',
    img: 'assets/img/universal-search/og_icon_universal_search.svg',
  },
  { type: SearchTypeEnum.TeamsGroups, class: 'ms-Icon ms-Icon--Group', img: 'assets/img/universal-search/tg_icon_universal_search.svg' },
  { type: SearchTypeEnum.Actions, class: 'ms-Icon ms-Icon--FileCode', img: 'assets/img/universal-search/action_icon_universal_search.svg' },
  {
    type: SearchTypeEnum.Reports,
    class: 'ms-Icon ms-Icon--FabricUserFolder',
    img: 'assets/img/universal-search/report_icon_universal_search.svg',
  },
  {
    type: SearchTypeEnum.Playbooks,
    class: 'ms-Icon ms-Icon--BoxPlaySolid',
    img: 'assets/img/universal-search/Playbook-folder.svg',
  },
  { type: SearchTypeEnum.Empty, class: '', img: 'assets/img/universal-search/empty-folder.svg' },
  { type: SearchTypeEnum.Empty2, class: '', img: 'assets/img/universal-search/empty-folder.svg' },
  {
    type: SearchTypeShortcutEnum.FavoriteReports,
    class: 'ms-Icon ms-Icon--FavoriteStarFill',
    img: 'assets/img/universal-search/favorites_icon_universal_search.svg',
  },
  {
    type: SearchTypeShortcutEnum.PinnedActions,
    class: 'ms-Icon ms-Icon--PinSolid',
    img: 'assets/img/universal-search/pinned_actions_universal_search.svg',
  },
  {
    type: SearchTypeShortcutEnum.PinnedWorkflows,
    class: 'ms-Icon ms-Icon--FavoriteStarFill',
    img: 'assets/img/universal-search/pinned_actions_universal_search.svg',
  },
]

const groupsCategoriesList: CategoryTypeIcons[] = [
  { category: GroupCategoryTypeEnum.DistributionGroup, name: 'DistributionGroup', class: 'ms-Icon ms-Icon--Group' },
  { category: GroupCategoryTypeEnum.SecurityGroup, name: 'SecurityGroup', class: 'ms-Icon ms-Icon--SecurityGroup' },
  { category: GroupCategoryTypeEnum.Office365Group, name: 'Office365Group', class: 'ms-Icon ms-Icon--Teamwork' },
  { category: GroupCategoryTypeEnum.TeamsGroup, name: 'TeamsGroup', class: 'ms-Icon ms-Icon--Teams' },
]

const maxNumberSeeAll = 20
const maxNumberOfUsers = 5
const maxNumberOfGroups = 2
const maxNumberOfActions = 3
const maxNumberOfReports = 3
const maxNumberOfPlaybooks = 3

@Injectable({
  providedIn: 'root',
})
export class UniversalSearchService {
  users: ReplaySubject<UserSearch> = new ReplaySubject<UserSearch>()
  distributionGroups: ReplaySubject<DistributionGroupSearch> = new ReplaySubject<DistributionGroupSearch>()
  securityGroups: ReplaySubject<SecurityGroupSearch> = new ReplaySubject<SecurityGroupSearch>()
  o365Groups: ReplaySubject<Office365GroupSearch> = new ReplaySubject<Office365GroupSearch>()
  teamsGroups: ReplaySubject<TeamsGroupSearch> = new ReplaySubject<TeamsGroupSearch>()

  funcsSeeAll = new SeeAllFunctionsToCallMapping()
  menuItems: (Pick<BaseItem, 'name' | 'classIcon' | 'type' | 'parent'> & { route: string | null | undefined })[] = []
  managementActions: any[] = []
  playbookItems: any[] = []
  query = ''

  constructor(
    private apiClient: ApiclientService,
    private httpClient: HttpClient,
    private router: Router,
    private translateHelper: TranslateHelper,
    private rightPanelService: RightPanelService,
    private managementHelperService: ManagementHelperService,
    private toolbarService: ToolbarService,
    private localStorageService: LocalstorageService,
    private managementActionsService: ManagementActionsService,
    private sharedHelperService: SharedHelperService,
    private store: Store<RootState>,
    private manageActionsService: ManagementActionsService,
    private workflowHttp: WorkflowHttpService,
  ) {
    this.funcsSeeAll.Actions = this.getAllActions
    this.funcsSeeAll.Reports = this.getAllReports
    this.funcsSeeAll.Users = this.getAllUsers
    this.funcsSeeAll.DistributionGroups = this.getAllDistributionGroups
    this.funcsSeeAll.SecurityGroups = this.getAllSecurityGroups
    this.funcsSeeAll.Office365Groups = this.getAllOffice365Groups
    this.funcsSeeAll.TeamsGroups = this.getAllTeamsGroups
    this.funcsSeeAll.Playbooks = this.getAllPlaybooks
  }

  getActionScores(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.apiClient.basePortalApiUrl}/search/actionscores`, { withCredentials: true })
  }

  getReportScores(): Observable<any> {
    return this.httpClient
      .get<string[]>(`${this.apiClient.basePortalApiUrl}/search/reportscores`, { withCredentials: true })
      .pipe(map((x) => this.menuItems.filter((m) => m.route && x.includes('/' + m.route))))
  }

  getRecentResults(): Observable<BaseItem[]> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/search/recentresults`, { withCredentials: true })
      .pipe(pluck('recentResults'))
      .pipe(
        map((results) =>
          results
            .map((res: any) => {
              if (res.category === SearchTypeEnum.Users) {
                return {
                  name: res.title,
                  email: res.name,
                  type: SearchTypeEnum.Users,
                  classIcon: this.getIconClassFromType(SearchTypeEnum.Users),
                  detail: res.detail,
                }
              } else if (res.category === SearchTypeEnum.Reports) {
                return this.menuItems.find((m) => m.route === res.route)
              } else if (res.category === SearchTypeEnum.Actions) {
                return this.managementActions.find((a) => a.name === res.title)
              } else if (res.category === SearchTypeEnum.Playbooks) {
                return this.playbookItems.find((a) => a.name === res.title)
              }
              return null
            })
            .filter((x: any) => !!x)
        )
      )
  }

  getRecentPlaybooks = (): Observable<any> => of(this.playbookItems.filter((p) => p.category === ''))

  getFavoriteReports(): Observable<BaseItem[]> {
    return combineLatest([this.store.select(selectMenusAll), this.store.select(selectFavorites)]).pipe(
      take(1),
      switchMap(([{ menu }, favorites]) => {
        const flat = flattenDeep(menu.menuFiltered.map((x) => [x, x.children?.map((y) => [y, y.children ?? []]) ?? []]))
        const favMenu = flat?.find((x) => x.id === Constants.menuIds.favorites)
        if (favMenu) {
          const favMenus: BaseItem[] = cloneDeep(
            favorites.favoriteMenus.map((favId: number) => flat?.find((y) => y.id === favId)).filter((x: any): x is NavItem => !!x)
          ).map((x: NavItem) => {
            return {
              id: x.id.toString(),
              sortId: x.sortId,
              name: x.title,
              route: x.route,
              classIcon: 'material-icons star',
              type: SearchTypeShortcutEnum.FavoriteReports,
            }
          })

          const favSavedReports: BaseItem[] = cloneDeep(favorites.favoriteSavedReports)
            .map((x: any, index: number) => ({
              id: x.id.toString(),
              sortId: Math.pow(10, 5) + index,
              name: x.title,
              route: x.route,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              queryParams: { SavedReportId: x.savedReportId },
              classIcon: 'material-icons save',
              type: SearchTypeShortcutEnum.FavoriteReports,
            }))
            .filter((x: any) => !!x)
          return of(
            [...favMenus, ...favSavedReports].sort((a: BaseItem, b: BaseItem) =>
              this.translateHelper.instant(a.name).localeCompare(this.translateHelper.instant(b.name))
            )
          )
        }
        return of([])
      })
    )
  }

  getPinnedActions(): Observable<BaseItem[]> {
    return combineLatest([this.manageActionsService.getFavoriteActions(), this.store.select(selectManagementActionsFlat)]).pipe(
      take(1),
      switchMap(([favorites, actions]) => {
        return of(
          actions
            .filter((a) => favorites.includes(a.actionItemId))
            .filter((a) => this.filterActions(a))
            .map((i: any) => ({ ...i, name: i.translate, classIcon: i.icon, type: SearchTypeShortcutEnum.PinnedActions, category: i.parentCategory }))
        )
      })
    )
  }

  getPinnedWorkflows(): Observable<BaseItem[]> {
    if (this.sharedHelperService.checkRoles(accessLevels.workflow)) {
      return this.workflowHttp.fetchFavourites().pipe(switchMap((workflows) => {
        return of(workflows.map((w) => ({
          id: w.id,
          name: w.name,
          classIcon: 'ms-Icon ms-Icon--AutomateFlow',
          type: SearchTypeShortcutEnum.PinnedWorkflows,
        })))
      }))
    }
    return of([])
  }

  isElmentManageable = (item: BaseItem) => item.isManageable && this.sharedHelperService.isAdmin()

  public clearHistory(): Observable<void> {
    return this.httpClient.post<void>(`${this.apiClient.basePortalApiUrl}/search/clearhistory`, null, { withCredentials: true })
  }

  public getUsers(params: any): Observable<any> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/completionSuggest/user/`, { withCredentials: true, params })
      .pipe(pluck('elasticSearchResult'))
  }

  public getDistributionGroups(params: any): Observable<any> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/completionSuggest/distributionGroup/`, { withCredentials: true, params })
      .pipe(pluck('elasticSearchResult'))
  }

  public getSecurityGroups(params: any): Observable<any> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/completionSuggest/securitygroup/`, { withCredentials: true, params })
      .pipe(pluck('elasticSearchResult'))
  }

  public getOffice365Groups(params: any): Observable<any> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/completionSuggest/office365group/`, { withCredentials: true, params })
      .pipe(pluck('elasticSearchResult'))
  }

  public getTeamsGroups(params: any): Observable<any> {
    return this.httpClient
      .get<any>(`${this.apiClient.basePortalApiUrl}/completionSuggest/teamsgroup/`, { withCredentials: true, params })
      .pipe(pluck('elasticSearchResult'))
  }

  public setManagementAction(actions: any): void {
    const items = actions
      .filter((a: any) => this.filterActions(a))
      .map((i: any) => ({ ...i, name: i.translate, classIcon: i.icon, type: SearchTypeEnum.Actions, category: i.parentCategory || i.category }))

    this.managementActions = items
  }

  public setPlaybookItems(playbookTitleWithPolicyCounts: PlaybookTitleWithPolicyCount[]): void {
    this.playbookItems = this.createPlaybookSearchItems(playbookTitleWithPolicyCounts)
  }

  public setMenuItems(menus: NavItem[]): void {
    this.menuItems = menus
      .filter((x) => x.parent?.parent?.id === Constants.menuIds.reports || x.parent?.parent?.id === Constants.menuIds.audit)
      .map((i: NavItem) => ({
        id: i.id,
        name: this.translateHelper.instant(i.title),
        title: i.title,
        classIcon: i.parent?.icon || '',
        type: SearchTypeEnum.Reports,
        route: i.route,
        parent: i.parent?.title,
        parentId: i.parent?.id,
      }))
  }

  public setSeeAllActions(funcs: any): void {
    this.funcsSeeAll = funcs
  }

  public setQuery(query: string): void {
    this.query = query
  }

  public getQuery(): string {
    return this.query
  }

  public getMaxNumberOfElements(): any {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      SeeAll: maxNumberSeeAll,
      Users: maxNumberOfUsers,
      Groups: maxNumberOfGroups,
      DistributionGroups: maxNumberOfGroups,
      SecurityGroups: maxNumberOfGroups,
      Office365Groups: maxNumberOfGroups,
      TeamsGroups: maxNumberOfGroups,
      Actions: maxNumberOfActions,
      Reports: maxNumberOfReports,
      Playbooks: maxNumberOfPlaybooks,
    }
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  public getSearchedUsers(docs: any): UserSearch[] {
    return this.buildSearchedUsers(docs)
  }

  public buildSearchedUsers(docs: ElasticSearchResult[]): UserSearch[] {
    const items: UserSearch[] = []

    docs.forEach((r: any) => {
      items.push({
        id: r.uniqueId,
        name: r.payload.text,
        email: r.uniqueId,
        category: r.payload.iconType,
        type: SearchTypeEnum.Users,
        classIcon: this.getIconClassFromType(SearchTypeEnum.Users),
      })
    })

    return items
  }

  public getSearchedDistributionGroups(docs: any): DistributionGroupSearch[] {
    return this.buildSearchedDistributionGroups(docs)
  }

  public buildSearchedDistributionGroups(docs: any): DistributionGroupSearch[] {
    const items: DistributionGroupSearch[] = []

    docs.forEach((r: any) => {
      items.push({
        id: r.uniqueId,
        name: r.payload.text,
        email: r.text,
        type: SearchTypeEnum.DistributionGroups,
        classIcon: this.getGroupsIconClassFromType(r.payload.objectType),
      })
    })

    return items
  }

  public getSearchedSecurityGroups(docs: any): SecurityGroupSearch[] {
    return this.buildSearchedSecurityGroups(docs)
  }

  public buildSearchedSecurityGroups(docs: any): SecurityGroupSearch[] {
    const items: SecurityGroupSearch[] = []

    docs.forEach((r: any) => {
      items.push({
        id: r.uniqueId,
        name: r.payload.text,
        email: r.text,
        type: SearchTypeEnum.SecurityGroups,
        classIcon: this.getGroupsIconClassFromType(r.payload.objectType),
      })
    })

    return items
  }

  public getSearchedOffice365Groups(docs: any): Office365GroupSearch[] {
    return this.buildSearchedOffice365Groups(docs)
  }

  public buildSearchedOffice365Groups(docs: any): Office365GroupSearch[] {
    const items: Office365GroupSearch[] = []

    docs.forEach((r: any) => {
      items.push({
        id: r.uniqueId,
        name: r.payload.text,
        email: r.text,
        type: SearchTypeEnum.Office365Groups,
        classIcon: this.getGroupsIconClassFromType(r.payload.objectType),
      })
    })

    return items
  }

  public getSearchedTeamsGroups(docs: any): TeamsGroupSearch[] {
    return this.buildSearchedTeamsGroups(docs)
  }

  public buildSearchedTeamsGroups(docs: any): TeamsGroupSearch[] {
    const items: TeamsGroupSearch[] = []

    docs.forEach((r: any) => {
      items.push({
        id: r.uniqueId,
        name: r.payload.text,
        email: r.text,
        type: SearchTypeEnum.TeamsGroups,
        classIcon: this.getGroupsIconClassFromType(r.payload.objectType),
      })
    })

    return items
  }

  public getSearchedReports(): any[] {
    return this.buildSearchedReports()
  }

  public buildSearchedReports(): RecentSearch[] {
    return this.menuItems.filter((i: any) => i.name.toLowerCase().indexOf(this.query.toLowerCase()) !== -1)
  }

  public getSearchedActions(): ActionSearch[] {
    return this.buildSearchedActions()
  }

  public getSearchedPlaybooks = (): any[] =>
    this.playbookItems.filter((i: any) => i.name.toLowerCase().indexOf(this.query.toLowerCase()) !== -1)

  public buildSearchedActions(): ActionSearch[] {
    if (!this.query) {
      return this.managementActions
    }
    return this.managementActions.filter((i: any) => i.translate.toLowerCase().indexOf(this.query.toLowerCase()) !== -1)
  }

  public resetFilter(items: AllResultsResponse): void {
    items.filteredItems = items.items
  }

  public filterByCategory(items: BaseItem[], category: string, type: SearchTypeEnum): BaseItem[] {
    switch (type) {
      case SearchTypeEnum.Users:
        const u = items
        return u.filter((i: any) => i.category === category)
      case SearchTypeEnum.Actions:
        const a = items as ActionSearch[]
        return a.filter((i: any) => i.category === category)
      case SearchTypeEnum.Reports:
        return items as ReportSearch[]
      case SearchTypeEnum.DistributionGroups:
        return items
      case SearchTypeEnum.SecurityGroups:
        return items
      case SearchTypeEnum.Office365Groups:
        return items
      case SearchTypeEnum.TeamsGroups:
        return items
      default:
        alert('Error filterByCategory')
        return []
    }
  }

  public getIconImgFromType(type: SearchTypeEnum | SearchTypeShortcutEnum): string {
    const res = classIcons.find((i: any) => i.type === type)
    if (res) {
      return res.img
    }
    return ''
  }

  public getActionTitleFromType(type: SearchTypeEnum | SearchTypeShortcutEnum): string {
    switch (type) {
      case SearchTypeEnum.Users:
        return 'Manage'
      case SearchTypeEnum.Actions:
        return 'Execute'
      case SearchTypeEnum.Reports:
        return 'View'
      case SearchTypeEnum.DistributionGroups:
        return 'Manage'
      case SearchTypeEnum.SecurityGroups:
        return 'Manage'
      case SearchTypeEnum.Office365Groups:
        return 'Manage'
      case SearchTypeEnum.TeamsGroups:
        return 'Manage'
      case SearchTypeEnum.Empty:
      case SearchTypeEnum.Empty2:
        break
      default:
        break
    }
    return ''
  }

  public getIconClassFromType(type: SearchTypeEnum): string {
    const res = classIcons.find((i: any) => i.type === type)
    if (res) {
      return res.class
    }
    return ''
  }

  public getGroupsIconClassFromType(type: GroupCategoryTypeEnum): string {
    const res = groupsCategoriesList.find((i: any) => i.category === type)
    if (res) {
      return res.class
    }
    return ''
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  public manageItem(item: BaseItem): void {
    const params = {
      Category: item.type,
      Title: item.name,
      Name: '',
      Detail: item.detail ?? '',
      Route: '',
    }
    /* eslint-enable @typescript-eslint/naming-convention */

    switch (item.type) {
      case SearchTypeEnum.Users:
        const user = item

        params.Name = user.email || ''
        params.Detail = params.Detail || params.Category || ''
        this.searchSave(params).subscribe(() => {})

        this.selectUser(user)
        break

      case SearchTypeEnum.Actions:
        const action = item as ActionSearch
        params.Category = SearchTypeEnum.Actions
        params.Name = action.category || ''
        this.searchSave(params).subscribe(() => {})

        this.selectAction(action)
        break
      case SearchTypeShortcutEnum.PinnedActions:
        this.selectAction(item as ActionSearch)
        break
      case SearchTypeShortcutEnum.PinnedWorkflows:
        this.router.navigate(['workflow/list', item.id]).catch((_: any) => _)
        break
      case SearchTypeEnum.Reports:
        const report = item as ReportSearch

        params.Name = report.category || ''
        params.Route = report.route
        this.searchSave(params).subscribe(() => {})

        this.selectReport(report)
        break
      case SearchTypeShortcutEnum.FavoriteReports:
        this.selectSavedReport(item as ReportSearch & { queryParams: any })
        break
      case SearchTypeEnum.DistributionGroups:
        params.Name = item.email || ''
        this.searchSave(params).subscribe(() => {})

        this.selectDistributionGroup(item)
        break
      case SearchTypeEnum.SecurityGroups:
        this.searchSave(params).subscribe(() => {})
        this.selectSecurityGroup(item)
        break
      case SearchTypeEnum.Office365Groups:
        params.Name = item.email || ''
        this.searchSave(params).subscribe(() => {})
        this.selectOffice365Group(item)
        break
      case SearchTypeEnum.TeamsGroups:
        params.Name = item.email || ''
        this.searchSave(params).subscribe(() => {})
        this.selectTeamsGroup(item)
        break
      case SearchTypeEnum.Playbooks:
        this.searchSave(params).subscribe(() => {})
        this.selectPlaybook(item as PlaybookSearch)
        break
      case SearchTypeEnum.Empty:
      case SearchTypeEnum.Empty2:
        break
      default:
        alert('Error manageItem')
        break
    }
  }

  processManageableElement = (item: BaseItem) => {
    if (item.type === SearchTypeEnum.Playbooks) {
      this.managePlaybook(item as PlaybookSearch)
    } else {
      alert('Error processManageableElement')
    }
  }

  //#region Get all

  public getAllActions = (): Observable<SeeAllResponse> =>
    of({
      items: this.managementActions,
      filteredItems: this.getSearchedActions().slice(0, maxNumberSeeAll + 1),
    })

  public getAllReports = (): Observable<SeeAllResponse> =>
    of({
      items: this.menuItems,
      filteredItems: this.getSearchedReports().splice(0, maxNumberSeeAll + 1),
    })

  public getAllPlaybooks = (): Observable<any> =>
    of({
      items: this.playbookItems,
      filteredItems: this.getSearchedPlaybooks().splice(0, maxNumberSeeAll + 1),
    })

  public getAllUsers = (): any =>
    this.getUsers({ search: this.query, size: maxNumberSeeAll + 1 }).pipe(
      map((result) => ({
        items: this.getSearchedUsers(result.options),
        filteredItems: this.getSearchedUsers(result.options),
        categories: Object.keys(groupBy(result.options, 'payload.iconType')).filter((k: any) => k !== 'null' && k !== ''),
      }))
    )

  public getAllDistributionGroups = (): any =>
    this.getDistributionGroups({ search: this.query, size: maxNumberSeeAll + 1 }).pipe(
      map((result) => ({
        filteredItems: this.getSearchedDistributionGroups(result.options),
      }))
    )

  public getAllSecurityGroups = (): any =>
    this.getSecurityGroups({ search: this.query, size: maxNumberSeeAll + 1 }).pipe(
      map((result) => ({
        filteredItems: this.getSearchedSecurityGroups(result.options),
      }))
    )

  public getAllOffice365Groups = (): any =>
    this.getOffice365Groups({ search: this.query, size: maxNumberSeeAll + 1 }).pipe(
      map((result) => ({
        filteredItems: this.getSearchedOffice365Groups(result.options),
      }))
    )

  public getAllTeamsGroups = (): any =>
    this.getTeamsGroups({ search: this.query, size: maxNumberSeeAll + 1 }).pipe(
      map((result) => ({
        filteredItems: this.getSearchedTeamsGroups(result.options),
      }))
    )

  public getAllItems(type: SearchTypeEnum): Observable<any> {
    return this.funcsSeeAll[type]()
  }

  public setOver(item: any, value: boolean) {
    item.isOver = value
  }

  //#endregion

  //#region select single element

  private createPlaybookSearchItems = (playbookTitleWithPolicyCounts: PlaybookTitleWithPolicyCount[]): any[] => {
    const playbookDashboards = playbookTitleWithPolicyCounts
      ?.slice()
      .sort((a, b) => {
        if (a.playbookGroupType === b.playbookGroupType) {
          return a.title?.toLocaleLowerCase().localeCompare(b.title?.toLocaleLowerCase())
        } else {
          return a.playbookGroupType === 'CustomPlaybook' ? 1 : -1
        }
      })
      .map((x, index) => {
        let category: string
        if (x.playbookGroupType === 'CustomPlaybook') {
          category = 'custom'
        } else {
          category = x.playbookGroupType === 'LegacyPlaybook' ? 'legacy' : ''
        }
        return {
          id: x.id,
          email: `${x.policyCount} active policies`,
          name: x.playbookGroupType === 'OutOfTheBoxPlaybook' ? this.translateHelper.instant(x.title) : x.title,
          route: x.playbookGroupType === 'LegacyPlaybook' ? `/playbook/settings/` : `/governance/${x.id}`,
          classIcon: 'icon material-icons-outlined play_lesson',
          routeParam: x.playbookGroupType === 'LegacyPlaybook',
          rightIcon: x.playbookGroupType === 'LegacyPlaybook' ? 'sync_alt' : '',
          category,
          type: 'Playbooks',
          isManageable: false // US 24433, criteria 6 
        }
      })
      .filter((x: any) => !!x)

    return playbookDashboards
  }

  //TODO: Write integration test to check that eah url exist
  private selectReport(item: ReportSearch): void {
    this.router.navigate([item.route]).catch((_: any) => _)
  }

  private selectSavedReport(item: ReportSearch & { queryParams: any }): void {
    this.router.navigate([item.route], { queryParams: { ...item.queryParams } } ).catch((_: any) => _)
  }

  private selectAction(item: any): void {
    if (!!item && !!this.managementHelperService.canRunAction(item)) {
      if (item.category === 'CustomAction') {
        this.managementActionsService
          .getCustomAction(this.localStorageService.selectedOrganization.id, item.customActionId)
          .subscribe((customAction) => {
            this.rightPanelService.open({
              type: ActionPanelComponent,
              data: { action: CustomActionsHelper.getManagementAction(customAction), isCustomAction: true, width: '100%' },
            })
          })
      } else {
        this.rightPanelService.open({
          type: ActionPanelComponent,
          data: {
            action: item,
            width: '100%',
          },
        })
      }
    } else {
      this.toolbarService.open('management-session')
    }
  }

  private selectUser(item: UserSearch): void {
    this.router.navigate(['/usercard/' + item.email]).catch((_: any) => _)
  }

  private selectDistributionGroup(item: DistributionGroupSearch): void {
    this.router.navigate(['/card/distributionGroup/' + item.id]).catch((_: any) => _)
  }

  private selectSecurityGroup(item: SecurityGroupSearch): void {
    this.router.navigate(['/card/securityGroup/' + item.id]).catch((_: any) => _)
  }

  private selectOffice365Group(item: Office365GroupSearch): void {
    this.router.navigate(['/card/m365Group/' + item.id]).catch((_: any) => _)
  }

  private selectTeamsGroup(item: TeamsGroupSearch): void {
    this.router.navigate(['/card/teamsGroup/' + item.id]).catch((_: any) => _)
  }

  private selectPlaybook(item: PlaybookSearch): void {
    this.router.navigate(['/governance'], { queryParams: { playbookId: item.id } }).catch((_: any) => _)
  }

  private managePlaybook(item: PlaybookSearch): void {
    this.router.navigate(['/playbook/settings/'], { queryParams: { playbookId: item.id } }).catch((_: any) => _)
  }

  //#endregion

  private searchSave(params: any): Observable<void> {
    return this.httpClient.post<any>(`${this.apiClient.basePortalApiUrl}/search/savesearchterm`, params, { withCredentials: true })
  }

  private filterActions(a: any): boolean {
    return (
      a.title !== 'Workflow' &&
      !!a.reportTags &&
      !!a.reportTags.length &&
      !a.reportTags.some((tag: string) => tag.toLowerCase() === 'needv2api' || tag.toLowerCase() === 'hidefromlist')
    )
  }
}
