import { MenuState } from './menu.types'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { NavItem } from '@coreview/coreview-library/models/nav-item'
import { cloneDeep, flatten } from 'lodash-es'

export const selectMenuState = createFeatureSelector<MenuState>('menu')

export const selectMenusAll = createSelector(selectMenuState, (state: MenuState) => ({ menu: state.menu }))

export const selectMenuHelps = createSelector(selectMenuState, (state: MenuState) => ({ menu: state.menuHelps }))

export const selectFlatMenusAll = createSelector(selectMenuState, (state: MenuState) => 
  ({ flatMenuAll: decorateMenuFunction(cloneDeep(state.menu.menuFiltered)) }))

export const selectNonFilteredMenus = createSelector(selectMenuState, (state: MenuState) => 
  ({ flatMenuAll: decorateMenuFunction(cloneDeep(state.menu.menuAll)) }))

const decorateMenuFunction = (menus: NavItem[], parent: NavItem | null = null, depth: number = 0): NavItem[] => {
  const tempMenu: NavItem[][] = []
  menus
    .filter((x) => !!x)
    .forEach((x) => {
      x.parent = parent
      x.depth = depth
      if (x.children?.length) {
        tempMenu.push(decorateMenuFunction(x.children, x, depth + 1))
      }
      tempMenu.push([x])
    })
  return flatten(tempMenu)
}

export const selectMenuLoaded = createSelector(
  selectMenuState,
  (state: MenuState) => state.loaded
)

export const selectFavorites = createSelector(selectMenuState, (state: MenuState) => ({
  favoriteMenus: state.favoriteMenus,
  favoriteSavedReports: state.favoriteSavedReports,
}))

export const selectMenuHelpsLoaded = createSelector(
  selectMenuState,
  (state: MenuState) => state.loaded
)

export const selectMenuByRoute = (route: string) => createSelector(selectFlatMenusAll, (state: { flatMenuAll: NavItem[] }) =>
  state.flatMenuAll.find((x) => x.route === route)
)
