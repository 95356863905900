import { ReportDefinition } from './../../../../core/models/ReportDefinition'
import { PlaybookService } from './../../services/playbook.service'
import { SummaryCard } from '@app/shared/models/summary-card'
import { ExceptionDetails, ExecutionType } from './../../../../core/models/playbook'
import { Policy } from '@app/core/models/playbook'
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { PanelStepsComponent } from '@app/shared/components/panel-steps/panel-steps.component'
import { PanelStep } from '@app/shared/models/panel-step'
import { SelectionAction } from '@app/core/models/ReportDefinition'
import { TranslateHelper } from '@coreview/coreview-library'
import { PolicyItemListComponent } from '../policy-item-list/policy-item-list.component'
import { ReportsService } from '@app/core/services/reports.service'
import { ClientDatagridComponent } from '@app/shared/components/client-datagrid/client-datagrid.component'
import { ColDef } from '@ag-grid-community/core'
import { iif, Observable, of } from 'rxjs'
import { intersection, remove, isEqual } from 'lodash-es'
import { RightPanelRef } from '@app/core/services/right-panel.service'
import { Helpers } from '@app/shared/utilities/helpers'
import dayjs from 'dayjs'
import { DialogComponent, ToastService } from '@coreview/coreview-components'
import { MatDialog } from '@angular/material/dialog'
import { tap } from 'rxjs/operators'

@Component({
  selector: 'app-add-to-exception',
  templateUrl: './add-to-exception.component.html',
  styleUrls: ['./add-to-exception.component.sass'],
})
export class AddToExceptionComponent implements OnInit, OnDestroy {
  @ViewChild(ClientDatagridComponent) selectedTargetsGrid!: ClientDatagridComponent
  @ViewChild(PanelStepsComponent) panelSteps!: PanelStepsComponent | undefined
  @ViewChild(PolicyItemListComponent) matchedItems!: PolicyItemListComponent

  private firstTimeEnteringManagement: boolean = true

  reportDefinition!: ReportDefinition

  selectionActionsAddToExceptions: SelectionAction[] = []
  selectedTargetsGridSelectionActions: SelectionAction[] = []
  steps!: PanelStep[]

  policy!: Policy
  policyId!: string
  isPolicyProvided = true

  selectedTargetsGridColumnsDefs: ColDef[] = []
  selectedTargets: any[] = []

  form!: UntypedFormGroup
  reportType: 'matchedItems' | 'matchedExceptions' = 'matchedItems'

  initialValuesLoaded = false
  isSaving = false
  isSelectedTargetsViewOpen = false
  isPanelExpandedByDefault = false

  metadata!: any

  constructor(
    private rightPanelRef: RightPanelRef,
    private translateHelper: TranslateHelper,
    private fb: UntypedFormBuilder,
    private reportsService: ReportsService,
    private service: PlaybookService,
    private dialog: MatDialog,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.steps = this.getPanelSteps()
    this.form = this.fb.group({
      isNeverExpire: new UntypedFormControl(false, Validators.required),
      expirationDate: new UntypedFormControl(null, [Validators.required, this.pastValidator()]),
      notes: new UntypedFormControl('', [Validators.required, Validators.min(1)]),
    })
    if (this.isPolicyProvided) {
      this.initPolicy(this.policy)
    } else {
      this.service.getPolicy(this.policyId, false).subscribe((resp) => {
        this.initPolicy(resp)
      })
    }
  }

  ngOnDestroy(): void {
    if (this.policy?.globalAndReportTreeFilters || this.policy?.groupMembershipFilter) {
      this.reportsService.deleteSessionFiltersReports().subscribe(() => (this.reportsService.reportFilter = undefined))
    }
  }

  initPolicy = (policy: Policy) => {
    this.policy = { ...policy }
    iif(
      () => !!this.policy?.globalAndReportTreeFilters || !!this.policy?.groupMembershipFilter,
      this.reportsService
        .saveSessionFiltersReports({
          targetEntity: this.policy.policyType === ExecutionType.EventBased ? 'Audit' : undefined,
          reportTreeFilters: this.policy.globalAndReportTreeFilters,
          membershipReportFilters: this.policy.groupMembershipFilter,
        })
        .pipe(
          tap(
            () =>
              (this.reportsService.reportFilter = {
                targetEntity: this.policy.policyType === ExecutionType.EventBased ? 'Audit' : undefined,
                reportTreeFilters: this.policy.globalAndReportTreeFilters,
                membershipReportFilters: this.policy.groupMembershipFilter,
              })
          )
        ),
      of(void 0)
    ).subscribe(
      () =>
        (this.reportDefinition = {
          ...this.policy.reportDefinition,
          treeFilters: this.policy.reportTreeFilters,
          fields: [...this.policy.reportDefinition.fields, ...(this.policy.policyExceptionFields || [])],
          lockedColumns: [...this.policy.reportDefinition.fields, ...(this.policy.policyExceptionFields || [])],
          selectionActions: this.selectionActionsAddToExceptions,
          sortOrder: this.policy.reportDefinition?.sortOrder ?? null,
          entity: undefined,
        })
    )
  }

  getSelectedTargets = (): any => of(this.selectedTargets)

  addOrRemoveRowFromSelectedTargets(input?: { rowData: any; isSelected: boolean }) {
    if (!this.isSelectedTargetsViewOpen || !input) {
      return
    }

    const { rowData, isSelected } = input

    if (isSelected) {
      if (!this.selectedTargets.find((x) => this.areEquals(x, rowData))) {
        this.selectedTargets.push(rowData)
      }
    } else {
      remove(this.selectedTargets, (x) => this.areEquals(x, rowData))
    }
  }

  deleteSelectedException(index: number): void {
    this.selectedTargets.splice(index, 1)
    this.updateSelectedTargetsGridData()
    this.checkSelectionOnServerDataGrid()
  }

  onTargetsGridRowSelected = ({ rowData, isSelected }: { rowData: any; isSelected: boolean }) => {
    const target = this.policy.reportDefinition.entityIdField ? Helpers.downcase(this.policy.reportDefinition.entityIdField) : ''
    if (isSelected) {
      if (this.policy.policyGroupType !== 'LegacyPolicy') {
        if (!this.selectedTargets.find((x) => x[target || ''] === rowData[target || ''])) {
          this.selectedTargets.push(rowData)
        }
      } else {
        if (!this.selectedTargets.find((x) => this.areEquals(x, rowData))) {
          this.selectedTargets.push(rowData)
        }
      }
    } else {
      if (this.policy.policyGroupType !== 'LegacyPolicy') {
        remove(this.selectedTargets, (x) => x[target || ''] === rowData[target || ''])
      } else {
        remove(this.selectedTargets, (x) => this.areEquals(x, rowData))
      }
    }
    this.updateSelectedTargetsGridData()
  }

  onTargetColumnDefsDefined() {
    this.setSelectedTargetsGridDefinition()
  }

  onModelUpdated() {
    this.checkSelectionOnServerDataGrid()
  }

  onAfterExpandSelectedTargetsView(): void {
    this.isSelectedTargetsViewOpen = true
  }

  onAfterCollapseSelectedTargetsView(): void {
    this.selectedTargets = this.selectedTargetsGrid.gridApi.getSelectedRows()
    this.checkSelectionOnServerDataGrid()
    this.updateSelectedTargetsGridData()
    this.isSelectedTargetsViewOpen = false
  }

  onSelectedTargetsChanged(selectedTargets: any[]) {
    this.selectedTargets = selectedTargets
    this.initialValuesLoaded = false
  }

  onGridReady() {
    if (!!this.selectedTargets && this.selectedTargets.length > 0) {
      this.selectedTargetsGrid?.gridApi.selectAll()
    }
  }

  metadataChanged(event: any) {
    this.metadata = event
  }

  changeFormDefinition(): void {
    if (!this.form.get('isNeverExpire')?.value) {
      this.form.addControl('expirationDate', new UntypedFormControl(null, [Validators.required, this.pastValidator()]))
    } else {
      this.form.removeControl('expirationDate')
    }
    this.form.updateValueAndValidity()
  }

  populateSummaryCardException(): SummaryCard {
    return {
      title: this.translateHelper.instant('playbook_exception'),
      properties: [
        {
          ['Targets']: [
            ...this.selectedTargets.map((x) =>
              (this.policy.exceptionsDisplayFields?.length ? this.policy.exceptionsDisplayFields : ['Id'])
                .map((f) => x[Helpers.downcase(f)])
                .join(' ')
            ),
          ],
        },
      ],
      editFunction: () => {
        this.panelSteps?.clickStep(this.steps[0])
      },
    }
  }

  populateSummaryCardConfiguration(): SummaryCard {
    const exceptionDetails = this.form.getRawValue()
    if (exceptionDetails.expirationDate) {
      exceptionDetails.expirationDate = dayjs(exceptionDetails.expirationDate).utc(false).format('LLL')
    }
    return {
      title: this.translateHelper.instant('playbook_exceptionConfiguration'),
      properties: Object.entries(exceptionDetails).map(([key, value]) => ({ [key]: value })),
      editFunction: () => {
        this.panelSteps?.clickStep(this.steps[1])
      },
    }
  }

  submit(): void {
    let obs: Observable<void>
    const exceptionDetails = this.form.getRawValue() as ExceptionDetails

    if (this.policy.policyGroupType !== 'LegacyPolicy') {
      obs = this.service.submitPolicyExceptions(
        this.policy.id || '',
        this.selectedTargets.map((x) => ({
          entityId: x[Helpers.downcase(this.policy.reportDefinition.entityIdField || '')],
          expirationDateUtc: exceptionDetails.expirationDate?.toISOString(),
        })),
        exceptionDetails.notes
      )
    } else {
      obs = this.service.submitLegacyPolicyExceptions(
        this.policy.id || '',
        exceptionDetails.expirationDate?.toISOString(),
        exceptionDetails.notes,
        this.selectedTargets,
        this.metadata.responseType,
        ['Id', ...this.metadata.fields]
      )
    }

    obs.subscribe(() => {
      this.rightPanelRef.close()

      if (this.policy.reportUrl && this.policy.reportUrl.includes('api/onlineusers')) {
        this.dialog.open(DialogComponent, {
          width: '50%',
          data: {
            title: this.translateHelper.instant('common_SaveChange'),
            text: this.translateHelper.instant('playbook_OnlineUsersUpdated'),
            primaryText: this.translateHelper.instant('common_Continue'),
            type: 'success',
            centered: true,
          },
        })
      } else {
        this.toastService.open({
          id: 'success',
          variant: 'success',
          title: this.translateHelper.instant('playbook_ExceptionManagedTitle'),
          message: this.translateHelper.instant('playbook_ExceptionManagedMessage'),
        })
      }
    })
  }

  getField(item: any, field: string) {
    return item[Helpers.downcase(field)] || item.id
  }

  onManageStepEnter() {
    if (this.firstTimeEnteringManagement && this.selectedTargets.length === 1 && this.reportType === 'matchedExceptions') {
      this.patchFormValues(this.selectedTargets[0])
    }

    this.firstTimeEnteringManagement = false
  }

  patchFormValues(selectedTarget: any) {
    this.form.patchValue({
      isNeverExpire: selectedTarget.isNeverExpire,
      expirationDate: selectedTarget.exceptionExpirationDate,
      notes: selectedTarget.exceptionNotes,
    })
    this.changeFormDefinition()
  }

  getStepSubtitle(): string {
    if (this.panelSteps?.activeStep?.stepKey === 'selectTargets') {
      return this.reportType === 'matchedExceptions'
        ? this.translateHelper.instant('playbook_SelectExceptionToEdit')
        : this.translateHelper.instant('playbook_manageExceptionsSubtitle')
    }
    return `${this.selectedTargets.length} ${this.translateHelper.instant('common_SelectedItems')}`
  }

  getPanelSteps(): PanelStep[] {
    return [
      {
        sort: 0,
        stepKey: 'selectTargets',
        title:
          this.reportType === 'matchedItems'
            ? this.translateHelper.instant('playbook_selectTargetsToExclude')
            : this.translateHelper.instant('playbook_SelectExceptionToEdit'),
        isRequired: true,
        status: 'Active',
        canProceed: () => this.selectedTargets.length > 0,
      },
      {
        sort: 1,
        stepKey: 'manageException',
        title: this.translateHelper.instant('playbook_manageException'),
        isRequired: true,
        status: 'Active',
        isDefaultStep: this.selectedTargets.length > 0,
        canProceed: () => this.selectedTargets.length > 0 && this.form.valid,
        onEnterStep: () => this.onManageStepEnter(),
      },
      {
        sort: 2,
        stepKey: 'complete',
        title: this.translateHelper.instant('playbook_reviewAndComplete'),
        isRequired: false,
        status: 'Active',
        canProceed: () => this.selectedTargets.length > 0 && this.form.valid,
      },
    ]
  }
  private checkSelectionOnServerDataGrid() {
    const target = this.policy.reportDefinition.entityIdField ? Helpers.downcase(this.policy.reportDefinition.entityIdField) : ''
    this.matchedItems.grid.gridApi?.getRenderedNodes().forEach((node) => {
      if (
        !!node.data &&
        !!this.selectedTargets.find((x) =>
          this.policy.policyGroupType === 'LegacyPolicy' ? this.areEquals(x, node.data) : x[target || ''] === node.data[target || '']
        )
      ) {
        node.setSelected(true)
      } else {
        node.setSelected(false)
      }
    })
  }

  private setSelectedTargetsGridDefinition() {
    this.selectedTargetsGridColumnsDefs = [
      ...this.matchedItems.grid.columnDefs
        .filter((x) => !!x.originalName)
        .map((x) => ({ ...x, floatingFilterComponentParams: { ...(x.floatingFilterComponentParams || {}), suppressFilterButton: false } })),
    ]
    this.selectedTargetsGrid?.gridApi.setColumnDefs(this.selectedTargetsGridColumnsDefs)
  }

  private updateSelectedTargetsGridData() {
    this.selectedTargetsGrid?.gridApi.deselectAll()
    const model = this.selectedTargetsGrid?.gridApi?.getFilterModel()
    this.selectedTargetsGrid?.gridApi?.setRowData(this.selectedTargets)
    this.selectedTargetsGrid?.gridApi?.setFilterModel(model)
    if (this.selectedTargets?.length) {
      this.selectedTargetsGrid?.gridApi.selectAll()
    } else {
      this.selectedTargetsGrid?.gridApi.deselectAll()
    }
  }

  private pastValidator =
    () =>
    (control: AbstractControl): ValidationErrors | null =>
      control.value < dayjs() ? { past: { value: true } } : null

  private areEquals(a: any, b: any) {
    return intersection(Object.keys(a), Object.keys(b)).every((k) => isEqual(a[k], b[k]))
  }
}
