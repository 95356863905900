import { ReportExportService } from '@app/core/services/report-export.service'
import { SavedReport } from '@app/core/models/saved-report'
import { SaveViewComponent } from './components/save-view/save-view.component'

/* eslint-disable max-len */
import { EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren, Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { ExternalFilter, FilterReportColumn, ReportDefinition, SelectionAction } from '@app/core/models/ReportDefinition'
import { ReportDefinition as LibReportDefinition } from '@coreview/coreview-library/models/ReportDefinition'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { BreadcrumbService, TranslateHelper } from '@coreview/coreview-library'
import { ReportsService } from '@app/core/services/reports.service'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { selectReportDefinitionById, selectReportsDefinition } from '@app/store/reports/reports-definition.selectors'

import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { ButtonComponent, SmartPanelComponent, ToastService } from '@coreview/coreview-components'
import { uniqWith, cloneDeep, keys, values, uniq, first, isEqual } from 'lodash-es'
import { Observable, of, Subject, combineLatest, timer, iif } from 'rxjs'
import { Metadata, ServerResponse } from '@app/core/models/ServerResponse'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'

import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { selectFavorites, selectFlatMenusAll } from '@app/store/menu/menu.selectors'

import { tileLayer, latLng, marker, MarkerClusterGroup, latLngBounds, Marker, Control, DomUtil, DomEvent } from 'leaflet'
import { IReportChartDefinition } from './report-chart-definitions'
import {
  debounceTime,
  filter,
  mergeMap,
  startWith,
  takeUntil,
  tap,
  map,
  catchError,
  distinctUntilChanged,
  concatMap,
  buffer,
  take,
} from 'rxjs/operators'
import { IReportMapDefinition, mapIcon, ReportsMapService } from './report-map-definitions'
import { MapConfiguration } from './map-configuration.interface'
import { NavItem } from '@coreview/coreview-library/models/nav-item'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { basemapLayer } from 'esri-leaflet'
import * as Highcharts from 'highcharts'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { NewSchedulationComponent } from './pages/new-schedulation/new-schedulation.component'
import { DataCenter } from '@app/core/models/data-center'
import { Constants } from '@app/shared/utilities/constants'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { Role } from '@app/core/models/authenticated-user'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { AuthHelperService } from '@app/core/services/auth.helper.service'
import { BuildColAgGrid } from '@app/shared/utilities/build-col-ag-grid'
import { ClientDatagridComponent } from '@app/shared/components/client-datagrid/client-datagrid.component'
import { ColDef, ColGroupDef, GridOptions } from '@ag-grid-community/core'
import { ExecutionType, Policy } from '@app/core/models/playbook'
import { NewCustomPolicyComponent } from '../playbook/components/new-custom-policy/new-custom-policy.component'
import { ReportsFiltersComponent } from './components/reports-filters/reports-filters.component'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'
import { ReportComponentHelper as LibReportsComponentHelper } from '@coreview/coreview-library'
import {
  selectManagementActionsLoading,
  selectRequiredFieldsByReportTags,
} from '@app/store/management-actions/management-actions.selectors'
import { QueryFilter2, childFilter } from '@app/core/models/QueryFilter'
import { WorkflowItemsSelectionColumnsDefinition } from '@app/shared/models/workflow-target-selection-definition'
import {
  TargetEntityAuditType,
  TargetEntityBaseType,
  TargetEntityCustomActionType,
  TargetEntityPolicyType,
  TargetEntityTeamsType,
  TargetEntityType,
  TargetEntityWorkflowType,
  convertFromMembersTypeUser,
} from '@app/core/enums/group-type'
import { selectLastMessageOfType } from '@app/store/messages/messages.selectors'
import { selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'
import { ReportsFilters } from '@app/core/models/reports-filters'
import { PolicyService } from '../playbook/services/policy.service'
import { BoxIconValueModel } from '@coreview/coreview-components/models/box-icon-value'

export declare interface PrepareApiParams {
  /**
   * A callback method that is invoked immediately before the call to GetRows
   */
  onPrepareApiParams(prepareParams: (params: { fields: string[]; showSensitiveData?: boolean }) => void): void
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass'],
})
export class ReportsComponent implements OnInit, OnDestroy, PrepareApiParams {
  @ViewChild(DatagridComponent) grid!: DatagridComponent
  @ViewChild(ClientDatagridComponent) clientGrid!: ClientDatagridComponent
  @ViewChildren(SmartPanelComponent) panels!: QueryList<SmartPanelComponent>

  @Input() clientTableConfiguration: {
    gridOptions: GridOptions
    columnDefs?: (ColDef | ColGroupDef)[]
    getParameters?: () => any
    isRowMaster: () => boolean
    leftPanel?: string
    rightPanel?: string
  } = {
    gridOptions: {
      defaultColDef: {
        sortable: true,
        resizable: false,
        filter: true,
        floatingFilter: true,
        filterParams: {
          suppressAndOrCondition: true,
        },
      },
    },
    isRowMaster: () => false,
  }

  @Input() searchInProgress?: boolean
  @Input() progressMessage?: string

  @Output() reloadButtonClicked: EventEmitter<any> = new EventEmitter()
  @Output() metadataChanged = new EventEmitter<Metadata>()
  @Output() getItemsResChange = new EventEmitter<any>()
  @Output() openInFullChange = new EventEmitter<boolean>()

  public reportDefinition$!: Observable<ReportDefinition | undefined>
  public reportResponse$!: Observable<ServerResponse<any>> | null

  public entityName = ''
  public refreshData!:
    | ((
        params: ApiDataParameters,
        dataCenterUrl?: string
      ) => {
        items: Observable<ServerResponse<any>>
        cols: Observable<CoreViewColumn[]> | null
      })
    | undefined

  isPivotModeEnabled = true
  isNonVTenantSensitive = false
  public isPivotModeActive = false
  loadingPivotData = false

  reportDefinition: ReportDefinition | undefined
  fieldsForManagement: string[] = []
  chartsData: Highcharts.Options[] = []
  highcharts = Highcharts

  chartsContainer: any = {}
  chartOptions: Highcharts.Options = {}
  dataChart: any
  data: any
  params!: ApiDataParameters
  pivotResponseItemProp = ''
  customParameters: any = {}
  rangeFilters!: { since?: string; to?: string; days?: number } | undefined
  customFilters: FilterReportColumn = {}
  routeParamsFilters!: Record<string, string>
  savedReportFilterSortModel?: { filterModel: any; treeFilters?: QueryFilter2; sortModel: { sortField: string; sortOrder: 'asc' | 'desc' } }
  selectedRows: any[] = []

  menu?: NavItem
  savedReports: SavedReport[] = []
  metadata!: Metadata

  loadedFirstTime = false
  hasCharts = false
  allCols!: CoreViewColumn[] | undefined

  //savedReportIdFilter?: string // do not use this property, it gets cleaned up after first api call
  savedReportId?: string
  savedReport!: SavedReport | null
  defaultRangeFilter?: { days?: 0 | 1 | 60 | 3 | 7 | 14 | 30 | 90 | 180; since?: string; to?: string }
  reportTitle?: string
  reportDescription?: string
  reportTitleFullscreen?: string
  tabTitleFullscreen?: string
  filterTitleFullscreen?: string

  hideColumnsSelector = false
  isOpenPanelCustomReport = true
  isOpenInFull = false
  hasActions = false

  loggedUser!: { userName: string; roles: Role[] }
  externalFilterHandlers: Record<string, (filter: ExternalFilter, key: string | null) => void>

  policy!: Policy | undefined
  targetEntity:
    | TargetEntityBaseType
    | TargetEntityType
    | TargetEntityPolicyType
    | TargetEntityCustomActionType
    | TargetEntityAuditType
    | TargetEntityWorkflowType
    | TargetEntityTeamsType
    | undefined

  selectionActions: SelectionAction[] = []
  clientSelectionActions: SelectionAction[] = []
  pivotSelectionActions: SelectionAction[] = []
  reportInsightsFilter!: FilterReportColumn & { title: string; }
  reportInsightswidgets: BoxIconValueModel[] = []
  
  onBoxClick(box: any) {

    const removeFilterForClosingBox = () => {
      
      const openBoxes = this.reportInsightswidgets.filter(b => b.isSelected && b.title !== box.title)
      let combinedOpensFilters: FilterReportColumn & { title: string}  = {} as any
      openBoxes.forEach(openBox => {
        const insight = (this.reportDefinition as LibReportDefinition)?.reportInsights?.find(ri => ri.reportInsightsUI.some(ui => ui.title === openBox.title)
        )
        const boxFilter = insight?.reportInsightsUI.find(ui => ui.title === openBox.title)?.filters
        if (boxFilter) {
          combinedOpensFilters = { ...combinedOpensFilters, ...boxFilter }
        }
      })
      Object.keys(combinedOpensFilters).forEach(key => {
        if (clickedBoxFilter!.hasOwnProperty(key)) {
          delete (clickedBoxFilter as any)[key]
        }
      })
      this.grid.removeFilter(clickedBoxFilter as FilterReportColumn)
      this.grid.forceFilter(combinedOpensFilters as FilterReportColumn)
    }

    const clickedBoxFilter = (this.reportDefinition as LibReportDefinition)?.reportInsights?.reduce((acc, insight) => {
      const ui = insight.reportInsightsUI.find((ui) => ui.title === box?.title)
      return { ...acc, ...ui?.filters, title: ui?.title }
    }, {} as (FilterReportColumn & { title: string} ))

    if (clickedBoxFilter) {
      if (!box.isSelected) {
        removeFilterForClosingBox()
      } else {
        this.grid.forceFilter(clickedBoxFilter as FilterReportColumn)
      }
      this.reportInsightsFilter = clickedBoxFilter
    }

  }

  saveCustomReportAction: SelectionAction = {
    ...this.reportsComponentHelper.saveCustomReportAction,
    onClick: (_: any[]) => {
      this.saveView()
    },
  }

  saveCustomReportPivotAction: SelectionAction = {
    ...this.reportsComponentHelper.saveCustomReportPivotAction,
    onClick: (_: any[]) => {
      this.savePivotView()
    },
  }

  exportAction: SelectionAction = {
    isMenuButton: true,
    text: 'common_Export',
    buttonType: 'tertiary',
    icon: 'download',
    visibility: 'noRow',
    isVisible: () => true,
    options: Constants.exportOptions,
    onClick: (_: any[], key: string | null) => {
      this.exportReport(key || '')
    },
  }

  exportClientAction: SelectionAction = {
    isMenuButton: true,
    text: 'common_Export',
    buttonType: 'tertiary',
    icon: 'download',
    visibility: 'noRow',
    isVisible: () => true,
    options: Constants.exportOptionsNoPdf,
    onClick: (_: any[], key: string | null) => {
      this.clientGrid.exportClientReport(this.translateHelper.instant(this.reportDefinition?.title || ''), key || '')
    },
  }

  exportPivotAction: SelectionAction = {
    ...this.reportsComponentHelper.exportPivotAction,
    onClick: (data: any[], key: string | null) => {
      this.reportsComponentHelper.exportPivotReport(key || '', this.clientGrid)
    },
  }

  scheduleAction: SelectionAction = {
    ...this.reportsComponentHelper.scheduleAction,
    onClick: (data: any[]) => {
      this.schedule()
    },
  }

  managementColumnDefinition: WorkflowItemsSelectionColumnsDefinition = { fields: [], configs: [] }

  chartPreference: boolean

  mapDefinition!: IReportMapDefinition
  mapData!: MapConfiguration
  mapPreference: boolean
  mapLoaded = false

  policyItemsSelectorOptions = [
    { key: 'matchedItems', text: this.translateHelper.instant('playbook_MatchedItems') },
    { key: 'exceptions', text: this.translateHelper.instant('playbook_Exceptions') },
  ]

  policyItemsSelectorValue: 'matchedItems' | 'exceptions' = 'matchedItems'

  rowSelected$ = new EventEmitter<{ rowData: any; isSelected: boolean }>()

  route!: string

  selectedDataCenter?: DataCenter

  pivotFilterModel: any

  canCreatePolicy = false
  canSeeCustomReports = true //#bug 40906

  private pageDataChanged$ = new Subject<{
    currentPage: number
    currentPageSize: number
    data: any[]
    dataChart: any
    tabbableChart?: IReportChartDefinition
  }>()
  private destroyed$: Subject<boolean> = new Subject()

  private loadedPivotFirstTime = false

  private extraState: any = null

  private onlineUserFields: string[] = []

  private refreshDataReportsUrls: Record<string, { url: string; correlationId: string }> = {
    quarantinedMobileDevices: { url: 'mobiledevices/quarantined/reload', correlationId: 'RefreshQuarantinedMobileDevices' },
    office365DeletedGroups: { url: 'office365groups/deleted/refresh', correlationId: 'Office365DeletedGroupsRefresh' },
    sharePointDeletedSites: { url: 'sharepoint/recycled/refresh', correlationId: 'DeletedSharePointSitesRefresh' },
  }

  private customSettings!: { title?: string; fromGovernance?: boolean; fields?: string[] } | undefined

  constructor(
    private service: ReportsService,
    private breadcrumbService: BreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private store: Store<RootState>,
    private reportsMapService: ReportsMapService,
    private router: Router,
    private storage: LocalstorageService,
    private translateHelper: TranslateHelper,
    private rightPanelService: RightPanelService,
    private sharedHelperService: SharedHelperService,
    private reportExportService: ReportExportService,
    private authHelperService: AuthHelperService,
    private buildColumnsHelper: BuildColAgGrid,
    private toastService: ToastService,
    private policyService: PolicyService,
    public reportsComponentHelper: ReportsComponentHelper,
    public libReportsComponentHelper: LibReportsComponentHelper
  ) {
    this.chartPreference = this.storage.chartDisplayPreference
    this.mapPreference = this.storage.mapDisplayPreference
    this.extraState = this.router.getCurrentNavigation()?.extras?.state
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.activatedRoute = activatedRoute
    this.savedReportId = this.activatedRoute.snapshot.queryParams.SavedReportId
    this.loggedUser = this.storage.getLoggedUser()
    this.libReportsComponentHelper.baseUrlApi = service.basePortalApiUrl

    const event = this.router.events.pipe(
      filter((eventRes: any) => eventRes instanceof NavigationEnd),
      startWith(this.router)
    )
    this.externalFilterHandlers = { columnFilter: this.columnFilterClick }

    combineLatest([event, this.activatedRoute.data])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([eventRes, data]: [NavigationEnd, any]) => {
        this.route = eventRes.url.split('?')[0]
        this.entityName = data.entity
        this.savedReport = data.savedReport
        this.policy = data.policy
        this.hideColumnsSelector = !this.isAdmin() && !!this.savedReport?.columnsLocked

        if (this.policy) {
          this.iAmAPolicy(this.policy)
        } else {
          this.reportDefinition$ = this.store.select(selectReportDefinitionById(this.entityName))
        }
      })
  }

  iAmAPolicy = (policy: Policy) => {
    this.rangeFilters = policy.timeRangeFilter
    if (policy.globalAndReportTreeFilters || policy.groupMembershipFilter) {
      this.service.reportFilter = {
        targetEntity: policy.policyType === ExecutionType.EventBased ? 'Audit' : undefined,
        reportTreeFilters: policy.globalAndReportTreeFilters,
        membershipReportFilters: policy.groupMembershipFilter,
      }
    }
    if (policy.policyDescription) {
      policy.description = new Function('data', 'translateHelper', policy.policyDescription.policyJavascriptFunction || '')(
        this.policy,
        this.translateHelper
      )
    }

    this.reportDefinition$ = this.store.select(selectReportsDefinition).pipe(
      concatMap((defs: any) =>
        iif(
          () => !!this.service.reportFilter,
          this.service.saveSessionFiltersReports(this.service.reportFilter).pipe(map(() => defs)),
          of(defs)
        )
      ),
      map((defs) =>
        this.policy
          ? {
              ...this.policy?.reportDefinition,
              title: this.policy?.title,
              actionsTags: this.policy.reportDefinition?.reportDefinitionId
                ? defs[this.policy.reportDefinition?.reportDefinitionId]?.actionsTags
                : uniq(
                    values(defs)
                      .filter(
                        (x) =>
                          x?.url?.toLocaleLowerCase() === this.policy?.reportDefinition.url?.toLocaleLowerCase() ||
                          x?.url?.toLocaleLowerCase() === '/' + this.policy?.reportDefinition.url?.toLocaleLowerCase()
                      )
                      .map((x) => x?.actionsTags || [])
                      .flat()
                      .filter((x) => x !== 'None')
                  ),
              treeFilters: this.policy?.reportTreeFilters,
              urlParameters: this.policy?.urlParameters,
              defaultDaysFilter: undefined,
              targetEntity:
                this.policy?.policyType === ExecutionType.EventBased
                  ? TargetEntityAuditType.Audit
                  : this.policy.reportDefinition?.reportDefinitionId
                  ? defs[this.policy.reportDefinition?.reportDefinitionId]?.targetEntity
                  : first(
                      uniq(
                        values(defs)
                          .filter(
                            (x) =>
                              (x?.url?.toLocaleLowerCase() === this.policy?.reportDefinition.url?.toLocaleLowerCase() ||
                                x?.url?.toLocaleLowerCase() === '/' + this.policy?.reportDefinition.url?.toLocaleLowerCase()) &&
                              !!x?.targetEntity
                          )
                          .map((x) => x?.targetEntity)
                      )
                    ),
              entity: undefined,
              sortOrder: this.policy?.reportDefinition.sortOrder ?? null,
            }
          : undefined
      )
    )
  }

  onPrepareApiParams(prepareParams: (params: { fields: string[]; showSensitiveData?: boolean }) => void): void {
    this.prepareParams = prepareParams
  }

  getCurrentPage = (): number => this.grid.gridApi.paginationGetCurrentPage() + 1

  getPageSize = (): number => this.grid.gridApi.paginationGetPageSize()

  loadMap(params: ApiDataParameters): void {
    if (!!this.mapData && !!this.mapDefinition) {
      const par = { ...params }
      delete par.savedReportId
      this.mapDefinition.getData({ ...par, pageSize: this.mapDefinition?.pageSize ?? 30000 }).subscribe((items) => {
        this.mapData = { ...this.mapData, markers: this.buildMapMarkers(items) }
        this.mapLoaded = true
      })
    }
  }

  getItems$ = (reportUrl: string, verb: Verb, params: ApiDataParameters, dataCenterUrl?: string) => {
    this.loadMap(params)

    if (this.loadedFirstTime === true) {
      delete params.savedReportId
    }
    this.params = params

    this.libReportsComponentHelper.refreshReportInsightsData(params as any, 
      this.reportDefinition as LibReportDefinition, 
      this.reportInsightsFilter, 
      this.reportInsightswidgets).subscribe((x) => {
        this.reportInsightswidgets = x
      })

    return (this.service.getData(reportUrl, verb, { ...params }, dataCenterUrl) || of({} as ServerResponse<any>)).pipe(
      tap((x: any) => {
        this.loadedFirstTime = true
        this.managementColumnDefinition.configs = x.configs
      }),
      tap((x: any) => {
        this.service.reportFilter = this.reassignReportTreeFilters(this.service.reportFilter, x)
      }),
      tap((resp: any) => this.getItemsResChange.emit(resp)),
      catchError((_err: any) => {
        this.toastService.open({
          id: 'error',
          variant: 'error',
          title: this.translateHelper.instant('common_Error'),
          message: this.translateHelper.instant('common_ErrorMessage'),
        })
        return of({} as ServerResponse<any>)
      })
    )
  }

  private reassignReportTreeFilters = (reportFilter: any, x: any) => {
    const rf: ReportsFilters = { ...(reportFilter || {}) }
    if (x.metadata.queryRequest?.reportTreeFilters) {
      rf.reportTreeFilters = x.metadata.queryRequest.reportTreeFilters
    }
    if (x.metadata.queryRequest?.savedReportParams?.groupMembershipFilter) {
      rf.membershipReportFilters = x.metadata.queryRequest?.savedReportParams?.groupMembershipFilter
    }
    return rf
  }

  getExportedItems = (params: ApiDataParameters & { asyncExport: boolean; reportNameContainer: string }, format: string) => {
    this.params = params
    return (
      this.service.getExportedData(
        this.reportDefinition?.url ?? '',
        this.reportDefinition?.verb ?? 'post',
        params,
        format,
        this.selectedDataCenter?.apiUrl
      ) || of({} as any)
    )
  }

  onlineUsersColumns = () => this.store.select(selectOnlineuserColumns)

  ngOnInit(): void {
    this.customSettings = this.activatedRoute.snapshot.queryParams?.customSettings
      ? JSON.parse(this.activatedRoute.snapshot.queryParams?.customSettings)
      : null
    this.sharedHelperService.manageScroll('.grid-container', 'app-datagrid')

    this.onlineUsersColumns().subscribe((columns) => {
      this.onlineUserFields = columns.map((v) => v.originalName)
    })

    this.breadcrumbService.addComponent({
      type: ButtonComponent,
      data: {
        buttonType: 'tertiary',
        text: this.translateHelper.instant('common_Reload'),
        leftIcon: 'autorenew',
        size: 'tiny',
      },
      events: {
        clicked: (e: any) => {
          this.reloadButtonClicked.emit(e)
          if (this.grid) {
            this.grid.gridApi?.deselectAll()
            this.grid.refresh()
          } else {
            this.clientGrid?.refresh()
          }
        },
      },
    })

    this.rowSelected$.pipe(buffer(this.rowSelected$.pipe(debounceTime(10)))).subscribe((_: any) => {
      this.rowSelected()
    })

    this.managementColumnDefinition.getVisibleFields = this.getVisibleFields

    this.store
      .select(selectedOrganizationSkus)
      .pipe(
        takeUntil(this.destroyed$),
        filter((x: any) => !!x?.length),
        take(1)
      )
      .subscribe((skus: any) => {
        this.canCreatePolicy = !!skus?.includes('FT:SHOWPOLICYCREATION')
        this.loadData()
      })
  }

  hasChartProperties() {
    return this.reportDefinition && this.reportDefinition.chart && this.reportDefinition.chart.length > 1 && this.reportDefinition.chart[0].areTabbable
  }

  onClickChartEvent = (params: { filters?: any; paramsApi?: any }) => {
    if (!this.reportDefinition || (!params.filters && !params.paramsApi)) {
      return
    }

    if (!this.reportDefinition.filters) {
      this.reportDefinition = { ...this.reportDefinition }
    }

    if (!this.customParameters) {
      this.customParameters = {}
    }

    if (params.paramsApi) {
      Object.keys(params.paramsApi).forEach((f: any) => {
        this.customParameters[f] = params.paramsApi[f]
      })
    }

    if (params.filters) {
      this.customFilters = { ...params.filters }
    }

    this.savedReportId = undefined
    this.grid?.refresh()
  }

  ngOnDestroy(): void {
    this.breadcrumbService.clear()
    if (this.service.reportFilter) {
      this.service.reportFilter = undefined
      this.service.deleteSessionFiltersReports().subscribe()
    }
    this.sharedHelperService.cleanupScrollListeners('.grid-container')
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  isNewCustomReport() {
    return this.route.indexOf('/reports/newCustomReport') >= 0
  }

  pageDataChanged(event: { currentPage: number; currentPageSize: number; data: any[]; dataChart: any }) {
    this.pageDataChanged$.next(event)
  }

  onRangeFilterChange(event: { since?: string; to?: string; days?: number }) {
    this.rangeFilters = event
    timer(10).subscribe(() => {
      this.savedReportId = undefined
      this.clientGrid?.refresh()
      this.grid?.refresh()
    })
  }

  selectDataCenter = (dc: DataCenter) => {
    this.selectedDataCenter = dc
    this.savedReportId = undefined
    this.grid.refresh(dc.apiUrl)
  }

  showReportsFiltersPanel() {
    this.savedReportId = undefined
    const mappedTargetEntity =
      convertFromMembersTypeUser(this.reportDefinition?.targetEntity ?? '') ?? this.reportDefinition?.targetEntity ?? ''

    this.service.showReportsFiltersPanel(
      mappedTargetEntity,
      this.grid,
      mappedTargetEntity === 'Audit',
      first(this.grid?.gridApi?.getFilterModel()?.workload?.filter?.children?.map((x: childFilter) => x.queryFilter?.value)),
      this.grid?.gridApi?.getFilterModel()?.operation?.filter?.children?.map((x: childFilter) => x.queryFilter?.value),
      this.reportDefinition?.actionsTags && this.reportDefinition.actionsTags?.indexOf('reportFilters_skipSelect') >= 0
    )
  }

  schedule() {
    this.metadata.queryRequest.fields = this.getVisibleFields()
    const panelRef = this.rightPanelService.open({
      type: NewSchedulationComponent,
      data: {
        request: this.metadata?.queryRequest,
        route: this.route,
        initialName: this.reportTitle,
        savedReport: this.savedReport,
        fromReport: true,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.loadSavedReports()
        this.panels.first.open = true
      })
  }

  saveView() {
    if (!this.isPivotModeActive) {
      this.metadata.queryRequest.fields = this.getVisibleFields()
    }
    const panelRef = this.rightPanelService.open({
      type: SaveViewComponent,
      data: {
        lastRequest: this.metadata?.queryRequest,
        reportDefinition: this.reportDefinition,
        route: this.route,
        savedReport: this.savedReport,
        pivotSettings: this.isPivotModeActive ? JSON.stringify(this.reportsComponentHelper.pivotSettings) : null,
        isPivot: this.isPivotModeActive,
      },
    })
    panelRef
      .afterClosed()
      .pipe(filter((x) => !!x))
      .subscribe(() => {
        this.loadSavedReports()
        this.panels.first.open = true
      })
  }

  getChart(value: any) {
    return value.chart
  }

  getChartData(value: any) {
    return value.data
  }

  getChartDataChart(value: any) {
    return value.dataChart
  }

  changeChart($this: any) {
    if (!this.reportDefinition?.chart) {
      return
    }

    this.chartsContainer = {}
    this.pageDataChanged$.next({
      currentPage: this.getCurrentPage(),
      currentPageSize: this.getPageSize(),
      data: [],
      dataChart: undefined,
      tabbableChart: this.reportDefinition.chart[$this.index],
    })
  }

  allChartsLoaded() {
    return this.reportDefinition?.chart && this.reportDefinition?.chart[0].areTabbable
      ? Object.keys(this.chartsContainer).length === 1
      : Object.keys(this.chartsContainer).length === this.reportDefinition?.chart?.length
  }

  calculateChartsContainerWidth() {
    return 'calc(100% * (1/' + Object.keys(this.chartsContainer).length + '))'
  }

  getDefaultTabChartIndex(): number {
    if (this.reportDefinition?.chart) {
      return this.reportDefinition.chart.findIndex((c: IReportChartDefinition) => c.isDefault)
    }
    return 0
  }

  exportReport(key: string) {
    this.metadata.queryRequest.fields = this.getVisibleFields()

    this.reportExportService
      .exportGeneric(
        this.getExportedItems,
        this.metadata,
        this.reportDefinition!.verb,
        key,
        this.reportTitle || '',
        this.reportDefinition?.url!
      )
      .subscribe()
  }

  rowSelected() {
    this.selectedRows = this.grid?.getSelectedRows() || []
    this.isOpenPanelCustomReport = false
  }

  onMetadataChanged = (md: Metadata) => {
    this.metadata = md
    this.metadataChanged.emit(md)
  }

  rowSelectedClient() {
    this.selectedRows = this.clientGrid?.gridApi?.getSelectedRows()
    this.isOpenPanelCustomReport = false
  }

  onPivotModeChanged = (val: boolean) => {
    if (this.isPivotModeActive && !val) {
      const filterModel = this.buildColumnsHelper.dictionaryFilterToFilterDefinition(this.metadata.queryRequest.filters, [])
      if (this.reportDefinition) {
        this.reportDefinition.initialFilters = filterModel
      }
      this.savedReportFilterSortModel = {
        filterModel: this.metadata.queryRequest.filters,
        sortModel: {
          sortField: this.metadata.queryRequest.sort,
          sortOrder: this.metadata.queryRequest.sortOrder,
        },
      }
      this.reportsComponentHelper.pivotSettings = this.clientGrid.columnApi.getColumnState()
    }
    this.chartPreference = val ? false : this.storage.chartDisplayPreference
    this.mapPreference = val ? false : this.storage.mapDisplayPreference

    this.isPivotModeActive = val
    this.loadedPivotFirstTime = true
    if (val) {
      this.sharedHelperService.cleanupScrollListeners('.grid-container')
    } else {
      this.sharedHelperService.manageScroll('.grid-container', 'app-datagrid')
    }
  }

  onAppDataGridColumnDefined = (colConfig: { columnDefs: (ColDef & CoreViewColumn)[]; serverCols: CoreViewColumn[] }) => {
    this.reportsComponentHelper.pivotParamsForBuildColDef = {
      selectedCols: this.params.fields,
      allcols: colConfig.serverCols.filter((x: any) => this.metadata?.fields?.includes(x.originalName)),
      isPivot: true,
    }
    if (this.savedReport?.isPivot && !this.loadedPivotFirstTime) {
      setTimeout(() => {
        this.reportsComponentHelper.pivotFilterModel = this.grid.gridApi.getFilterModel()
        this.onPivotModeChanged(true)
      }, 200)
    }

    this.managementColumnDefinition.builtColumnDefs = cloneDeep(colConfig)
      .columnDefs.filter((x) => !x.hide)
      .map((x) => {
        if (this.buildColumnsHelper.isLinkRenderer(x.cellRenderer as string)) {
          x.cellRenderer = undefined
        }
        return x
      })
  }

  onFilterChanged() {
    this.savedReportId = undefined
  }

  getPivotData = () => {
    this.loadingPivotData = true

    let pivotParams: ApiDataParameters = { ...this.metadata?.queryRequest }
    pivotParams.fields = this.filterPivotFields(this.metadata?.fields)
    pivotParams.itemsPerPage = this.params.itemsPerPage
    pivotParams.pageNumber = this.params.pageNumber
    pivotParams.excludedItems = this.params.excludedItems

    if (this.rangeFilters) {
      pivotParams = { ...pivotParams, ...this.rangeFilters, days: this.rangeFilters?.days ? this.rangeFilters.days + '' : '0' }
    }

    return this.reportsComponentHelper.getPivotData(this.reportDefinition! as LibReportDefinition, pivotParams).pipe(
      tap((x) => {
        this.loadedFirstTime = true
        this.loadingPivotData = false
      })
    )
  }

  getClientGridData = () => {
    if (this.reportDefinition?.entity) {
      const coldefs = this.reportsComponentHelper.getColDefByReportName(this.reportDefinition.entity)
      if (coldefs.length > 0) {
        this.clientTableConfiguration.columnDefs = coldefs
      }
    }

    const params: any = {
      pageSize: 100000,
      pageNumber: 1,
      sortField: this.reportDefinition?.sortField,
      sortOrder: this.reportDefinition?.sortOrder,
      fields: this.reportDefinition?.fields,
      ...this.rangeFilters,
    }

    return this.getItems$(this.reportDefinition?.url || '', this.reportDefinition?.verb || 'get', {
      ...this.getReportParams(params),
      ...(this.clientTableConfiguration.getParameters ? this.clientTableConfiguration.getParameters() : {}),
    })
  }

  onPivotGridReady = () => {
    setTimeout(() => {
      this.reportsComponentHelper.onPivotGridReady(this.clientGrid)
    }, 100)
  }

  canSeeManagementPanel = (actionsTags?: string[]) =>
    this.hasManagementRole() &&
    ((!!actionsTags?.length && actionsTags?.indexOf('None') === -1) ||
      (!!this.policy?.isWorkflowEnabled && this.policy.remediationType === 'Workflow'))

  canSeeLeftPanel(panel: string): boolean {
    if (panel === 'CustomReports') {
      return (
        this.canSeeCustomReports &&
        !this.isPivotModeActive &&
        (!this.clientTableConfiguration?.leftPanel || this.clientTableConfiguration.leftPanel === panel) &&
        !this.isOpenInFull
      )
    } else if (panel === 'CustomSmartPanel') {
      return this.clientTableConfiguration?.leftPanel === panel
    }
    return false
  }

  setCreateCustomPolicyAction(createPolicyEnabled: boolean) {
    if ((this.isAdmin() || this.sharedHelperService.isPlaybookAdmin()) && createPolicyEnabled) {
      this.selectionActions = [
        ...this.selectionActions,
        {
          text: 'reports_CreateCustomPolicy',
          buttonType: 'tertiary',
          icon: 'add',
          visibility: 'noRow',
          isVisible: () => true,
          onClick: (data: any[]) => {
            const filters = this.grid.getFilters()
            const reportDefinition = {
              ...this.reportDefinition,
              fields: this.grid?.columnApi
                ?.getAllDisplayedColumns()
                ?.map((x) => (x.getColDef().field ? Helpers.capitalize(x.getColDef().field || '') : ''))
                ?.filter((x) => !!x && x !== 'Selection'),
              title: this.reportDefinition?.title ? this.translateHelper.instant(this.reportDefinition?.title) : '',
              initialUrlFilters: this.reportDefinition?.verb === 'get' ? JSON.parse(filters || '{}') : filters,
              defaultDaysFilter: this.rangeFilters?.days,
              since: this.rangeFilters?.since,
              to: this.rangeFilters?.to,
              treeFilters: this.grid.getTreeFilters(),
            }
            this.rightPanelService
              .open({
                type: NewCustomPolicyComponent,
                data: {
                  width: '100%',
                  customReportId:
                    this.savedReport && !this.reportDefinition?.url?.includes('auditactivities') ? this.savedReport?.guid : undefined,
                  reportDefinition: createPolicyEnabled ? reportDefinition : undefined,
                  defaultTarget: this.reportDefinition?.url?.includes('auditactivities')
                    ? 'Audit'
                    : this.savedReport
                    ? 'CustomReports'
                    : 'Reports',
                },
              })
              .afterClosed()
              .pipe(filter((x) => !!x))
              .subscribe(() =>
                this.toastService.open({
                  id: 'success',
                  variant: 'success',
                  title: this.translateHelper.instant('playbook_PolicyCreatedTitle'),
                  message: this.translateHelper.instant('playbook_PolicyCreatedMessage'),
                })
              )
          },
        },
      ]
    }
  }

  setReportsFiltersActions() {
    this.reportDefinition!.filterActions = [
      {
        text: 'reports_ClearFilters',
        buttonType: 'tertiary',
        icon: 'filter_alt_off',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        cvDataTest: 'clear-reports-filters',
        color: 'alert',
        isVisible: () =>
          this.hasReportFilterApplied() || !!this.grid?.hasRemovableColumnFilters(),
        onClick: () => {
          const obs = this.hasReportFilterApplied() ? this.service.deleteSessionFiltersReports() : of(null)
          obs.subscribe(() => {
            this.service.reportFilter = undefined
            this.reportInsightswidgets.forEach((w) => {w.isSelected = false})
            this.grid?.gridApi?.setFilterModel(null)
            this.grid.forceFilter({})
            this.grid.refresh()
          })
        },
        visibility: 'custom',
      },
      {
        text: 'reports_ReportsFilters',
        buttonType: 'tertiary',
        icon: 'filter_alt',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        cvDataTest: 'reports-filters',
        isVisible: () =>
          this.reportDefinition?.verb === 'post' &&
          ReportsFiltersComponent.supportedTargetTypes.includes(
            convertFromMembersTypeUser(this.reportDefinition?.targetEntity ?? '') ?? this.reportDefinition?.targetEntity ?? ''
          ),
        onClick: () => {
          this.showReportsFiltersPanel()
        },
        visibility: 'custom',
      },
      {
        text: 'common_OpenInFull',
        buttonType: 'tertiary',
        icon: 'open_in_full',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        isVisible: () => this.reportDefinition?.hasOpenInFull === true && !this.isOpenInFull,
        onClick: () => {
          this.isOpenInFull = !this.isOpenInFull
          this.openInFullChange.emit(this.isOpenInFull)
          this.configureOpenInFullscreen()
        },
        visibility: 'custom',
        cvDataTest: 'open-full-mode',
      },
      {
        text: 'common_CloseFullscreen',
        buttonType: 'tertiary',
        icon: 'close_fullscreen',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        isVisible: () => this.reportDefinition?.hasOpenInFull === true && this.isOpenInFull,
        onClick: () => {
          this.isOpenInFull = !this.isOpenInFull
          this.openInFullChange.emit(this.isOpenInFull)
          this.configureOpenInFullscreen()
        },
        visibility: 'custom',
        cvDataTest: 'close-full-mode',
      },
    ]
    this.targetEntity = this.reportDefinition!.targetEntity
  }

  configureOpenInFullscreen() {
    if (this.isOpenInFull) {
      this.breadcrumbService.hide()
    } else {
      this.breadcrumbService.show()
    }
  }

  setGroupedActions() {
    if (!this.reportDefinition) {
      return
    }

    let createPolicyEnabled = !this.policy

    if (this.reportDefinition.actionsTags?.includes('playbook_DisablePolicyCreation') || !this.canCreatePolicy) {
      createPolicyEnabled = false
    }

    this.setCreateCustomPolicyAction(createPolicyEnabled)

    this.setRefreshDataAction()

    if (!this.reportDefinition.notAllowedActions || this.reportDefinition.notAllowedActions.indexOf('Export') < 0) {
      if (!this.reportDefinition.isClientDataGrid) {
        this.selectionActions.push(this.exportAction)
      } else {
        this.clientSelectionActions.push(this.exportClientAction)
      }
    }

    if (
      !this.policy &&
      (!this.reportDefinition.notAllowedActions || this.reportDefinition.notAllowedActions.indexOf('SaveCustomReport') < 0)
    ) {
      if (!this.reportDefinition.isClientDataGrid) {
        this.selectionActions.push(this.saveCustomReportAction)
      } else {
        this.clientSelectionActions.push(this.saveCustomReportAction)
      }
    }

    if (!this.reportDefinition.notAllowedActions || this.reportDefinition.notAllowedActions.indexOf('Schedule') < 0) {
      if (!this.reportDefinition.isClientDataGrid) {
        this.selectionActions.push(this.scheduleAction)
      }
    }

    if (!this.reportDefinition.notAllowedActions || this.reportDefinition.notAllowedActions.indexOf('ExportPivot') < 0) {
      this.pivotSelectionActions.push(this.exportPivotAction)
    }

    if (!this.reportDefinition.notAllowedActions || this.reportDefinition.notAllowedActions.indexOf('SaveCustomReportPivot') < 0) {
      this.pivotSelectionActions.push(this.saveCustomReportPivotAction)
    }

    this.reportDefinition.groupedActions = this.selectionActions
  }

  setReportTitle(reportTitle: string) {
    if (this.reportDefinition) {
      this.reportDefinition = { ...this.reportDefinition, title: reportTitle }
    }
    this.reportTitle = this.customSettings?.title ?? reportTitle
  }

  setReportDescription() {
    let reportDescription = ''
    if (this.customSettings) return
    if (this.savedReport) {
      reportDescription = this.savedReport.description
    } else if (this.policy) {
      reportDescription = this.policy.description ? this.translateHelper.instant(this.policy.description) : ''
    } else {
      reportDescription = this.translateHelper.instant(`descriptions_${this.reportDefinition!.entity}`)
    }
    this.reportDescription = reportDescription
  }

  updateChartVisibilityPreference(chartPreference: boolean) {
    this.chartPreference = chartPreference
    if (!this.isPivotModeActive) {
      this.storage.chartDisplayPreference = chartPreference
    }
  }

  updateMapVisibilityPreference(mapPreference: boolean) {
    this.mapPreference = mapPreference
    if (!this.isPivotModeActive) {
      this.storage.mapDisplayPreference = mapPreference
    }
  }

  checkMap() {
    this.mapDefinition = this.reportsMapService.definitionsMap[this.reportDefinition?.url || '']
    if (this.mapDefinition) {
      const southWest = latLng(-89.98155760646617, -180)
      const northEast = latLng(89.99346179538875, 180)
      this.mapData = {
        markers: [],
        options: {
          layers: [
            basemapLayer('Gray', {
              detectRetina: true,
            }),
            basemapLayer('GrayLabels'),
            tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
            ...(this.mapDefinition.mapData?.customLayers || []),
          ],
          zoom: 2,
          maxZoom: 15,
          center: latLng(20, 0),
          maxBounds: latLngBounds(southWest, northEast),
          dragging: !this.mapDefinition.mapData?.disableAllControls,
          touchZoom: !this.mapDefinition.mapData?.disableAllControls,
          doubleClickZoom: !this.mapDefinition.mapData?.disableAllControls,
          boxZoom: !this.mapDefinition.mapData?.disableAllControls,
          keyboard: !this.mapDefinition.mapData?.disableAllControls,
        },
        clusterOptions: {
          spiderfyOnMaxZoom: false,
          animate: true,
          showCoverageOnHover: false,
          zoomToBoundsOnClick: false,
        },
        clusterClick: this.mapDefinition.clusterClick,
      }
    }
  }

  onMapReady(leafletMap: L.Map, mapConfiguration: MapConfiguration) {
    const resetView = new Control({ position: 'topright' })
    resetView.onAdd = (m) => {
      const azoom = DomUtil.create('a', 'leaftlet-reset-button')
      DomEvent.disableClickPropagation(azoom).addListener(
        azoom,
        'click',
        () => {
          m.setView(latLng(20, 0), 2)
          this.customParameters = {}
          this.grid.refresh()
        },
        azoom
      )
      return azoom
    }
    leafletMap.addControl(resetView)
    const moveend = new EventEmitter<any>()

    moveend.pipe(debounceTime(500)).subscribe((ev) => {
      if (mapConfiguration.distincyMarkersCount !== 1) {
        const bounds = leafletMap.getBounds()
        const nw = bounds.getNorthWest()
        const se = bounds.getSouthEast()

        const nwLimitLat = -90
        const nwLimitLng = -180
        const seLimitLat = 90
        const seLimitLng = 180

        this.executeMarkerSearchBound(
          Math.max(nw.lng, nwLimitLng),
          Math.max(nw.lat, nwLimitLat),
          Math.min(se.lng, seLimitLng),
          Math.min(se.lat, seLimitLat)
        )
      }
    })

    leafletMap.on('moveend', (ev: any) => moveend.emit(ev))

    //TODO CHECK
    leafletMap.on('drag', () => leafletMap.panInsideBounds(leafletMap.getBounds(), { animate: false }))
  }

  markerClusterReady(markerCluster: MarkerClusterGroup, mapConfiguration: MapConfiguration) {
    // Do stuff with group
    markerCluster.addEventListener('clusterclick', (ev: any) => {
      if (mapConfiguration.clusterClick) {
        mapConfiguration.distincyMarkersCount = uniqWith(
          ev.layer.getAllChildMarkers(),
          (a: any, b: any) => a._latlng.lat === b._latlng.lat
        ).length

        if (mapConfiguration.distincyMarkersCount === 1) {
          this.executeMarkerSearch(ev.latlng.lng, ev.latlng.lat)
        }
      }

      ev.layer.zoomToBounds()
    })

    //?? TO CHECK
    markerCluster.addEventListener('click', (ev: any) => {
      if (mapConfiguration.clusterClick) {
        this.executeMarkerSearch(ev.latlng.lng, ev.latlng.lat)
      }
    })
  }

  policyItemsSelectorChanged(value: string) {
    if (!this.policy) {
      return
    }
    this.routeParamsFilters =
      value === 'matchedItems'
        ? {
            [this.policy.matchedReportFilters?.key || '']: this.policy.matchedReportFilters?.value || '',
            ...this.policy.reportFilters,
          }
        : {
            [this.policy.exceptionReportFilters?.key || '']: this.policy.exceptionReportFilters?.value || '',
            ...this.policy.reportFilters,
          }
    this.grid.refresh()
  }

  showPolicyItemsSelector() {
    return this.policy && this.policy.policyType !== ExecutionType.EventBased && !this.policy.hideExceptions
  }

  openChangePanelCustomReport(open: boolean) {
    this.storage.isOpenPanelCustomReport = open
    this.isOpenPanelCustomReport = open
  }

  loadSavedReports() {
    this.service
      .getViews({ pageSize: 50, pageNumber: 1, route: this.route, sortOrder: 'asc', sortField: 'reportName' })
      .subscribe((res) => {
        this.savedReports = res
        this.isOpenPanelCustomReport = res.length > 0 && this.storage.isOpenPanelCustomReport
      })
  }

  setIsFavorite(favorites: any) {
    if (this.menu) {
      if (this.savedReportId || this.savedReport) {
        this.menu.isFavorite = !!favorites.favoriteSavedReports.find(
          (x: any) => this.savedReportId === x.savedReportId || this.savedReport?.guid === x.savedReportId
        )
      } else {
        this.menu.isFavorite = !!favorites.favoriteMenus.find((x: any) => this.menu?.id === x)
      }
    }
  }

  getVisibleFields = (): string[] => this.reportsComponentHelper.getVisibleFields((this.grid || this.clientGrid).columnApi)

  savePivotView = () => {
    this.reportsComponentHelper.pivotSettings = this.clientGrid.columnApi.getColumnState()
    this.saveView()
  }

  filterPivotFields(fields: Array<string | undefined> | undefined): Array<string | undefined> | undefined {
    if (this.reportDefinition?.isOnlineUsersType) {
      return fields?.filter((x) => !!x && this.onlineUserFields.includes(x))
    } else {
      return fields?.filter((x) => x !== 'selection')
    }
  }

  private buildMapMarkers(items: any[]): Marker<any>[] {
    return items.map((x: any) => marker(latLng(x.latitude, x.longitude), mapIcon))
  }

  private hasManagementRole(): boolean {
    return this.sharedHelperService.isManagement()
  }

  private buildChart(chart: IReportChartDefinition) {
    this.hasCharts = true

    this.pageDataChanged$.pipe(takeUntil(this.destroyed$)).subscribe(({ data, dataChart, tabbableChart }) => {
      if (tabbableChart) {
        chart = tabbableChart
      }
      if (chart.hasSameApi) {
        this.dataChart = dataChart
        this.data = data

        this.chartsContainer[chart.name || 'chart'] = { dataChart, data, chart }
      } else if (chart.apiUrl && this.params && (!this.data || chart.reloadOnChange)) {
        const resetPagination = { itemsPerPage: 500, pageNumber: 1, pageSize: 500 }
        this.service
          .getChartData(chart.apiUrl, chart.apiVerb, { ...this.params, ...resetPagination, ...chart.apiParams })
          .subscribe((d) => {
            this.data = d

            this.chartsContainer[chart.name || 'chart'] = { data: d, chart }
          })
      }
    })
  }

  private checkCharts() {
    if (
      !this.reportDefinition?.chart ||
      this.reportDefinition.chart.length === 0 ||
      (this.reportDefinition.chartAccessLevel &&
        !this.authHelperService.authorize(this.loggedUser as any, this.reportDefinition.chartAccessLevel))
    ) {
      return
    }

    const charts =
      this.reportDefinition?.chart && this.reportDefinition.chart.length > 1 && this.reportDefinition.chart[0].areTabbable
        ? [this.reportDefinition.chart[this.getDefaultTabChartIndex()]]
        : this.reportDefinition.chart

    if (!charts || charts.length === 0) {
      return
    }

    charts.forEach((chart: IReportChartDefinition) => {
      this.buildChart(chart)
    })
  }

  private executeMarkerSearch(longitude: number, latitude: number) {
    this.customParameters = {}
    this.customParameters.singleGeoPoint = JSON.stringify({
      lon: longitude,
      lat: latitude,
    })

    this.savedReportId = undefined
    this.grid.refresh()
  }

  private executeMarkerSearchBound(nwLng: number, nwLat: number, seLng: number, seLat: number) {
    this.customParameters.singleGeoPoint = null
    this.customParameters.boundingBox = JSON.stringify({
      topLeftLon: nwLng,
      topLeftLat: nwLat,
      bottomRightLon: seLng,
      bottomRightLat: seLat,
    })

    this.savedReportId = undefined
    this.grid.refresh()
  }

  private loadData() {
    this.reportDefinition$
      .pipe(
        mergeMap((definition) =>
          iif(
            () => this.canSeeManagementPanel(definition?.actionsTags),
            this.store.select(selectManagementActionsLoading).pipe(
              filter((x) => !x),
              mergeMap(() =>
                this.store.select(selectRequiredFieldsByReportTags(definition?.actionsTags || [])).pipe(
                  filter((x: string[]) => !!x),
                  distinctUntilChanged(isEqual),
                  takeUntil(this.destroyed$),
                  map((requiredFields: string[]) => ({
                    requiredFields,
                    definition,
                  }))
                )
              )
            ),
            of({ requiredFields: [], definition })
          )
        ),
        tap(({ requiredFields, definition }) => {
          this.loadedFirstTime = false
          this.allCols = undefined

          this.fieldsForManagement = requiredFields

          if (definition) {
            this.reportDefinition = cloneDeep(definition)

            this.reloadGridAfterDataRefresh()

            if (this.isNewCustomReport() && !this.savedReport) {
              this.setNewCustomReportDefinition()
            } else {
              this.setReportTitle(this.savedReport ? this.savedReport.reportName : this.translateHelper.instant(definition.title || ''))
              this.reportTitleFullscreen = this.translateHelper.instant(definition.title || '')
              this.filterTitleFullscreen = this.savedReport ? this.savedReport.reportName : undefined
              this.setReportDescription()
            }

            this.managementColumnDefinition.isOnlineUsersType = definition.isOnlineUsersType

            if (this.activatedRoute.snapshot.data?.savedReport?.request?.fields?.length) {
              this.reportDefinition.fields = this.activatedRoute.snapshot.data.savedReport.request.fields
            }
            if (this.customSettings?.fields?.length) {
              this.reportDefinition.entity = ''
              this.reportDefinition.fields = this.customSettings?.fields
            }
            this.setReportsFiltersActions()
            this.reportDefinition.groupedActions = this.selectionActions
            this.setGroupedActions()
            this.setSavedReportsParams()
            this.checkMap()
            this.checkCharts()

            if (!definition.isClientDataGrid) {
              this.refreshData = (params: ApiDataParameters, dataCenterUrl?: string) => {
                this.prepareParams(params as any)
                return {
                  items: this.getItems$(
                    this.reportDefinition?.url || '',
                    this.reportDefinition?.verb || 'post',
                    this.getReportParams(params),
                    dataCenterUrl
                  ),
                  cols: definition?.isOnlineUsersType ? this.onlineUsersColumns() : null,
                }
              }
            } else {
              this.clientTableConfiguration.leftPanel = this.clientTableConfiguration?.leftPanel || 'none'
            }

            this.defaultRangeFilter = this.savedReport
              ? {
                  days: this.savedReport.request?.days,
                  since: this.savedReport.request?.since,
                  to: this.savedReport.request?.to,
                }
              : this.policy
              ? (this.policy.timeRangeFilter as any)
              : { days: definition.defaultDaysFilter }

            const urlDays =
              this.activatedRoute.snapshot.queryParams?.timeRangeFilterDays ?? this.activatedRoute.snapshot.params?.timeRangeFilterDays
            if (urlDays) {
              this.defaultRangeFilter = {
                days: Number(urlDays) as any,
              }
            }
          }

          this.isPivotModeEnabled =
            !!this.reportDefinition &&
            this.reportDefinition.verb === 'post' &&
            !this.reportDefinition.actionsTags?.includes('feature_HidePivotTable')
          this.isNonVTenantSensitive =
            !!this.reportDefinition && !!this.reportDefinition.actionsTags?.includes('feature_NonVTenantSensitive')
          this.reportsComponentHelper.pivotSettings =
            !!this.savedReport && !!this.savedReport?.pivotSettings ? JSON.parse(this.savedReport?.pivotSettings as unknown as string) : []
          this.pivotResponseItemProp = this.reportDefinition?.responseItemProp ? this.reportDefinition?.responseItemProp : ''
          if (!this.policy) {
            this.routeParamsFilters = {}
            keys(this.activatedRoute.snapshot.params || {}).forEach(
              (x) => (this.routeParamsFilters[Helpers.capitalize(x)] = this.activatedRoute.snapshot.params[x])
            )
            keys(this.activatedRoute.snapshot.queryParams || {})
              .filter(
                (x) =>
                  !['savedReportId', 'showExceptions', 'timeRangeFilterDays', 'customSettings']
                    .map((s) => s.toLocaleLowerCase())
                    .includes(x.toLowerCase())
              )
              .forEach((x) => (this.routeParamsFilters[Helpers.capitalize(x)] = this.activatedRoute.snapshot.queryParams[x]))
          } else {
            if (this.activatedRoute.snapshot.queryParams.showExceptions === 'true' && this.showPolicyItemsSelector()) {
              this.policyItemsSelectorValue = 'exceptions'
            }
            this.routeParamsFilters =
              this.policyItemsSelectorValue === 'matchedItems'
                ? {
                    [this.policy.matchedReportFilters?.key || '']: this.policy.matchedReportFilters?.value || '',
                    ...this.policy.reportFilters,
                  }
                : {
                    [this.policy.exceptionReportFilters?.key || '']: this.policy.exceptionReportFilters?.value || '',
                    ...this.policy.reportFilters,
                  }
          }

          this.grid?.refresh()
        }),
        mergeMap(() => combineLatest([this.store.select(selectFlatMenusAll), this.store.select(selectFavorites)])),
        takeUntil(this.destroyed$)
      )
      .subscribe(([{ flatMenuAll }, favorites]) => this.arrangeMenuandBreadcrumb(flatMenuAll, favorites))
  }

  private arrangeMenuandBreadcrumb = (flatMenuAll: NavItem[], favorites: {}) => {
    {
      const urlToFind = this.savedReportId ? this.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '') : this.route
      this.menu = flatMenuAll.find((x: NavItem) => '/' + x.route === urlToFind)
      if (!this.menu) {
        let segment = urlToFind
        while (!this.menu && segment.length) {
          this.menu = flatMenuAll
            .filter((x) => x.id !== Constants.menuIds.favorites && x.parent?.id !== Constants.menuIds.favorites)
            .find((x) => x.route && segment.replace(/\//g, '') === x.route?.replace(/\//g, ''))
          if (segment.indexOf('?') > 0) {
            segment = segment.substring(0, segment.indexOf('?'))
          } else {
            segment = segment.substring(0, segment.lastIndexOf('/'))
          }
        }
      }
      if (this.menu) {
        this.setIsFavorite(favorites)
        this.loadSavedReports()

        const partialPath = this.customSettings?.fromGovernance
          ? [
              this.translateHelper.instant('governance_Governance'),
              { text: this.translateHelper.instant('governance_GovernanceCenter'), route: '/governance' },
              {
                text: this.translateHelper.instant('healthcheck_Overview'),
                route: '/governance',
                queryParams: {
                  playbookId: 'overview',
                },
              },
            ]
          : this.policyService.getPath(this.policy, this.menu, this.extraState, this.activatedRoute.snapshot.params.playbook)

        this.breadcrumbService.updatePath([...partialPath, this.reportTitle || '-'])
      } else {
        this.breadcrumbService.updatePath([this.translateHelper.instant('common_Reports'), this.reportTitle || '-'])
      }
      this.pageDataChanged({
        data: [],
        currentPage: 0,
        currentPageSize: 0,
        dataChart: undefined,
      })
    }
  }

  private reloadGridAfterDataRefresh() {
    if (Object.keys(this.refreshDataReportsUrls).includes(this.reportDefinition?.entity || '')) {
      this.store
        .select(selectLastMessageOfType('NotifyTaskStatus'))
        .pipe(filter((x) => !!x))
        .subscribe((x) => {
          if (
            x?.body.title === this.refreshDataReportsUrls[this.reportDefinition?.entity || ''].correlationId &&
            x?.body?.state === 'Finished'
          ) {
            this.grid?.refresh()
          }
        })
    }
  }

  private setRefreshDataAction() {
    if (Object.keys(this.refreshDataReportsUrls).includes(this.reportDefinition?.entity || '')) {
      this.selectionActions = [
        {
          text: 'reports_RefreshData',
          buttonType: 'tertiary',
          icon: 'refresh',
          visibility: 'noRow',
          isVisible: () => true,
          onClick: () => {
            const data = this.refreshDataReportsUrls[this.reportDefinition?.entity || '']
            this.service
              .refreshDataReport(data?.url || '', data?.correlationId || '')
              .subscribe((res: { hasMessageToShow: boolean; messageToShow: string }) => {
                if (res.hasMessageToShow) {
                  this.toastService.open({
                    id: 'warning',
                    variant: 'warning',
                    title: this.translateHelper.instant('common_Warning'),
                    message: res.messageToShow,
                  })
                }
              })
          },
        },
        ...this.selectionActions,
      ]
    }
  }

  private prepareParams = (params: { fields: string[]; showSensitiveData: boolean | undefined }) => {}

  private getReportParams(params: ApiDataParameters): any {
    const reportParams = { ...params }
    if (this.reportDefinition?.urlParameters) {
      Object.assign(reportParams, this.reportDefinition.urlParameters)
    }

    if (!!this.reportDefinition?.urlRouteParameters && Object.keys(this.reportDefinition.urlRouteParameters).length > 0) {
      const urlRoute: any = {}

      Object.keys(this.reportDefinition?.urlRouteParameters).forEach((p: string) => {
        if (this.reportDefinition?.urlRouteParameters != null) {
          urlRoute[p] = this.activatedRoute.snapshot.params[this.reportDefinition.urlRouteParameters[p]]
        }
      })

      Object.assign(reportParams, urlRoute)
    }

    if (this.savedReportId) {
      Object.assign(reportParams, { savedReportId: this.savedReportId })
    }

    return reportParams
  }

  private setSavedReportsParams() {
    if (this.savedReport) {
      this.savedReportFilterSortModel = {
        filterModel: this.savedReport.request.filters,
        treeFilters: this.savedReport.request.treeFilters,
        sortModel: { sortField: this.savedReport.request.sort, sortOrder: this.savedReport.request.sortOrder },
      }
    }
  }

  private isAdmin = (): boolean => this.sharedHelperService.isAdmin()

  private columnFilterClick = (extFilter: ExternalFilter, key: string | null) => {
    let filterName = ''
    const externalFilter = this.reportDefinition?.externalFilters?.filter((x) => x.title === extFilter.title)[0]
    if (externalFilter) {
      keys(externalFilter.filter).forEach((f) => {
        if (key) {
          externalFilter.filter[f] = key
          filterName = f
        }
      })
      extFilter.enum.forEach((en) => {
        en.icon = undefined
      })
      if (key === 'SeeAll') {
        this.grid.gridApi.destroyFilter(filterName)
      } else {
        const filterModel = {
          ...this.grid.gridApi?.getFilterModel(),
          ...this.buildColumnsHelper.dictionaryFilterToFilterDefinition(externalFilter.filter, this.grid.allColsLocal as CoreViewColumn[]),
        }
        const checkedEnum = extFilter.enum.find((x) => x.key === key)
        if (checkedEnum) {
          checkedEnum.icon = 'check'
          checkedEnum.iconPosition = 'right'
        }
        this.grid.gridApi.setFilterModel(filterModel)
      }
    }
  }

  private setNewCustomReportDefinition() {
    const customReportConfiguration = this.storage.getNewCustomReportData()
    if (!!customReportConfiguration && this.reportDefinition) {
      this.setReportTitle(customReportConfiguration.name)
      this.reportDefinition.fields = customReportConfiguration.selectedColumns || []
    }
  }

  private hasReportFilterApplied(): boolean {
    return !!this.service.reportFilter && Object.keys(this.service.reportFilter).length > 0 && this.reportDefinition?.targetEntity !== 'Audit';
  }

  //#endregion
}
