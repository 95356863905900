<div class="recent-item" (click)="handleManageItem(search)">
  <span class="circle-icon-container">
    <span class="circle-icon">
      <em [class]="search.classIcon"></em>
    </span>
  </span>
  <div [tooltip]="search.name" [display]="checkEllipsis.isEllipsed" tooltipClass="cv-tooltip" class="tooltip-item" #tooltip="tooltip">
    <div appCheckEllipsis class="search" #checkEllipsis="appCheckEllipsis">
      {{ search.name }}
    </div>
  </div>
</div>
