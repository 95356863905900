import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { DialogComponent } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'
import { Observable, of } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class DialogHelperService {
  constructor(private dialog: MatDialog, private translateHelper: TranslateHelper) {}

  private maxTargetThreshold = 100

  openDeleteDialog(multiple: boolean, subject: string, item?: string): Observable<boolean> {
    return this.dialog
      .open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.combineTranslations('generic_DeleteModalTitle', subject),
          text: multiple
            ? this.translateHelper.combineTranslations('generic_DeleteModalMessage_Plural', subject)
            : this.translateHelper.combineTranslations(`generic_DeleteModalMessage`, subject, item),
          primaryText: this.translateHelper.instant('common_Delete'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'alert',
          centered: false,
        },
      })
      .afterClosed()
  }

  openConfirmationDialog(message: string = 'common_ConfirmAction'): Observable<boolean> {
    return this.dialog
      .open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.instant('common_Confirmation'),
          text: this.translateHelper.instant(message),
          primaryText: this.translateHelper.instant('common_DiscardChanges'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'alert',
          centered: false,
        },
      })
      .afterClosed()
  }

  openConfirmationSubmitDialog(): Observable<boolean> {
    return this.dialog
      .open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.instant('common_Confirmation'),
          text: this.translateHelper.instant('administration_ProceedConfirmation'),
          primaryText: this.translateHelper.instant('common_Continue'),
          secondaryText: this.translateHelper.instant('common_Cancel'),
          type: 'info',
          centered: false,
        },
      })
      .afterClosed()
  }

  private openSubmitDialogIfTargetsExceed(
    titleKey: string,
    descriptionKey: string,
    descriptionParams: any,
    count: number
  ): Observable<boolean> {
    if (count > this.maxTargetThreshold) {
      return this.dialog
        .open(DialogComponent, {
          width: '50%',
          data: {
            title: this.translateHelper.instant(titleKey),
            text: this.translateHelper.instant(descriptionKey, descriptionParams),
            primaryText: this.translateHelper.instant('common_Proceed'),
            secondaryText: this.translateHelper.instant('common_Cancel'),
            type: 'info',
            centered: false,
          },
        })
        .afterClosed()
    } else {
      return of(true)
    }
  }

  public openActionSubmitDialogIfTargetsExceed(action: string, count: number): Observable<boolean> {
    return this.openSubmitDialogIfTargetsExceed('common_RunActionTitle', 'common_RunActionDescription', { action, count }, count)
  }

  public openWorkflowSubmitDialogIfTargetsExceed(workflow: string, count: number): Observable<boolean> {
    return this.openSubmitDialogIfTargetsExceed(
      'common_RunWorkflowTitle',
      'common_RunActionDescription',
      { action: workflow, count },
      count
    )
  }

  public notifyUser(type: 'success' | 'error' | 'alert' | 'info', title: string, text: string) {
		this.dialog.open(DialogComponent, {
			width: '50%',
			data: {
				title: this.translateHelper.instant(title),
				text: this.translateHelper.instant(text),
				primaryText: this.translateHelper.instant('common_Continue'),
				type,
				centered: true,
			}
		});
	}
}
