
<div class="report-container" style="display: flex;">
    <app-report-title
        [menu]="menu"
        [reportTitle]="reportTitle | translate"
        [reportDescription]="reportDescription"
        [isNonVTenantSensitive]="false"
        [reportDefinition]="reportDefinition"
    >
    </app-report-title>

    <div class="main-container" style="grid-template-columns: auto 1fr auto">
        <app-msp-multitenant-dashboard-left-panel (reportTypeChange)="onReportTypeChange($event)"></app-msp-multitenant-dashboard-left-panel>

        <div class="grid-container">
            <div class="flex-full-height">
                <app-datagrid
                    *ngIf="gridDefinition"
                    [getItems]="getDataGrouped"
                    [gridDefinition]="gridDefinition"
                    (metadataChanged)="metadata = $event"
                    [treeData]="true"
                    [isServerSideGroup]="hasGroupedInfo"
                    [getServerSideGroupKey]="getGroupKey"
                    [autoGroupColumnDef]="autoGroupColumnDef"
                    [defaultColumnsDefinition]="gridDefinition?.gridOptions?.defaultColDef"
                    class="flex-full-height"
                    cv-data-test="grouped-report"
                    (gridReady)="onGridReady()"
                    [hideColumnsSelector]="false"
                    >

                    <app-expand-collapse-button expandCollapse [isExpanded]="areAllRowsExpanded" (expandedChange)="toggleExpandCollapseAll($event)" cv-data-test="grouped-expand-collapse">
                    </app-expand-collapse-button>
                </app-datagrid>
            </div>
        </div>
    </div>
</div>
