/* eslint-disable max-len */
import { TranslateHelper } from '@coreview/coreview-library'
import { ToastService } from '@coreview/coreview-components'
import { Component, Input, OnInit, EventEmitter, OnDestroy, SimpleChanges, OnChanges, Output } from '@angular/core'
import { ColDef, ColumnApi } from '@ag-grid-community/core'
import { Observable, of, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { selectUserSettingsColumnsByEntity } from '@app/store/userSettings/userSettings.selectors'
import { UserSettingsService } from '@app/core/services/user-settings.service'
import { FilterReportColumn } from '@app/core/models/ReportDefinition'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { Helpers } from '@app/shared/utilities/helpers'
import { cloneDeep } from 'lodash-es'

@Component({
  selector: 'app-columns-selector',
  templateUrl: './columns-selector.component.html',
  styleUrls: ['./columns-selector.component.sass'],
})
export class ColumnsSelectorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() columnDefs!: (ColDef & CoreViewColumn)[]
  @Input() defaultCols!: (ColDef & CoreViewColumn)[]
  @Input() lockedColumns?: string[]
  @Input() defaultHiddenFields?: string[]
  @Input() columnApi!: ColumnApi
  @Input() maxSelectableColumns!: number
  @Input() entityName?: string
  @Input() selectedColumnsChanged$: Subject<{ newColumns: (ColDef & CoreViewColumn)[]; visibilityUpdated: boolean }> = new Subject<{
    newColumns: (ColDef & CoreViewColumn)[]
    visibilityUpdated: boolean
  }>()

  @Input() set filters(value: FilterReportColumn | null | undefined) {
    if (value) {
      this.defaultFilters = value
      this.defaultFiltersKeys = Object.keys(value)
    }
  }

  @Output() visibleColumnChanged: EventEmitter<(ColDef<any, any> & CoreViewColumn)[]> = new EventEmitter()

  public filterChanged$ = new EventEmitter<string>()
  public filter = ''
  public selectedCols: string[] | null = null
  public newColumnsSelected: string[] = []

  public defaultFilters?: FilterReportColumn
  public defaultFiltersKeys: string[] = []

  public currentSelection: string[] = []
  public selectAllStatus: 'unchecked' | 'checked' | 'indeterminate' = 'indeterminate'
  public visibleColumns: (ColDef & CoreViewColumn)[] = []
  public pinnedColumns: (ColDef & CoreViewColumn)[] = []

  private destroyed$: Subject<boolean> = new Subject()

  private excludedColumns = ['selection', 'common_Actions']

  private initialColumns: (ColDef & CoreViewColumn)[] = []

  constructor(
    private toastService: ToastService,
    private translateHelper: TranslateHelper,
    private store: Store<RootState>,
    private userSettingsService: UserSettingsService
  ) {}

  @Input() refresh: () => any = () => of(null)

  ngOnInit(): void {
    this.filterChanged$.pipe(debounceTime(200)).subscribe((x) => {
      this.filter = x
      this.calculateComponentStatus()
    })

    this.selectedColumnsChanged$.pipe(debounceTime(500), distinctUntilChanged()).subscribe((columns) => {
      this.saveUserSettings(columns.newColumns).subscribe(() => {
        if (columns.visibilityUpdated) {
          this.setColumnsVisibility(columns.newColumns)
        }
      })
    })

    if (this.entityName) {
      this.store
        .select(selectUserSettingsColumnsByEntity(this.entityName))
        .pipe(takeUntil(this.destroyed$), distinctUntilChanged())
        .subscribe((columns) => {
          if (columns) {
            this.selectedCols = columns?.map((x) => x.originalName).filter((x) => !!x)
            this.pinnedColumns = columns?.filter((x) => x.pinned)
          }
        })
    }
    this.maxSelectableColumns = this.maxSelectableColumns || 150
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.columnDefs && this.columnDefs) {
      this.initialColumns = cloneDeep(this.columnDefs)
      this.columnDefs
        .filter((col) => !col.hide)
        .forEach((col, index) => {
          if (col.position === undefined) {
            col.position = index + 1
          }
          return { ...col }
        })
      this.calculateComponentStatus()
      this.selectedCols = null
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  refreshPanelColumns() {
    this.filter = ''
    if (this.selectedCols) {
      this.columnDefs?.forEach((x: any) => {
        x.hide = !(this.selectedCols || []).includes(x.originalName || '')
      })
    } else {
      this.selectedCols = this.columnDefs?.filter((x) => !x.hide).map((x) => x.originalName || '')
    }
    this.calculateComponentStatus()
  }

  check(column: ColDef & CoreViewColumn, event: MatCheckboxChange) {
    if (event.checked && this.currentSelection.length >= this.maxSelectableColumns) {
      this.showMaxColumnsWarning()
      event.source.checked = false
    } else {
      column.hide = !event.checked

      const shownColumns = this.columnDefs?.filter((x) => !x.hide)
      let maxPosition = 0
      if (shownColumns.length > 0) {
        maxPosition = Math.max(...shownColumns.map((x) => x.position || 0))
      }
      column.position = !column.hide ? maxPosition + 1 : undefined
      shownColumns.sort((a, b) => (a.position || 0) - (b.position || 0))
      shownColumns.forEach((x, index) => (x.position = index + 1))
      this.calculateComponentStatus(false)
    }
  }

  toggleCheckbox(col: ColDef & CoreViewColumn, event: MouseEvent) {
    event.stopPropagation()
    event.preventDefault()
    col.hide = !col.hide
    const checkboxEvent: MatCheckboxChange = {
      source: {
        checked: !col.hide,
      } as any,
      checked: !col.hide,
    }
    this.check(col, checkboxEvent)
  }

  onCheckboxClick(event: MouseEvent) {
    event.stopPropagation()
  }

  checkAll(event: Event) {
    event.stopPropagation()
    const nextChecked = this.selectAllStatus !== 'checked'
    const selectableColumns = this.getFilteredColumns() || []
    const selectableColumnsNames = selectableColumns.map((c) => c.field)
    if (nextChecked) {
      const unchecked = selectableColumns.filter((x) => !x.notSelectable && !x.notAvailable && !!x.originalName && x.hide).length
      if (unchecked + this.currentSelection.length > this.maxSelectableColumns) {
        event.preventDefault()
        this.showMaxColumnsWarning()
        return
      }
    }
    this.columnDefs
      ?.filter(
        (x) =>
          selectableColumnsNames.includes(x.field) && !x.notSelectable && !x.notAvailable && !!x.originalName && !this.isLockedColumn(x)
      )
      .forEach((x) => (x.hide = !nextChecked))
    this.calculateComponentStatus(false)
  }

  resetColumns(): void {
    this.filter = ''

    this.setColumnsVisibility(
      this.defaultCols?.length > 0
        ? this.defaultCols
        : this.columnDefs.filter((x) => !x.notSelectable && !x.notAvailable && !!x.originalName)
    )

    this.columnDefs.forEach((col) => {
      const defaultColIndex = this.defaultCols.findIndex((defaultCol) => defaultCol.field === col.field)
      if (defaultColIndex !== -1) {
        col.position = defaultColIndex + 1
      }
    })

    this.calculateComponentStatus()
  }

  changedFilter(event: any): void {
    this.filterChanged$.emit(event)
  }

  getFilteredColumns(): (ColDef & CoreViewColumn)[] | undefined {
    this.columnDefs?.sort((a: any, b: any) =>
      !a.hide && !b.hide ? a.position - b.position : a.hide && b.hide ? a.headerName?.localeCompare(b.headerName) : a.hide - b.hide
    )
    const columns = (this.columnDefs as (ColDef & CoreViewColumn)[] | undefined)?.filter(
      (x) =>
        !x.notAvailable &&
        !x.notSelectable &&
        x.originalName &&
        !this.isDefaultHiddenField(x) &&
        (!this.filter || (x.headerName || '').toUpperCase().indexOf(this.filter.toUpperCase()) >= 0)
    )
    return columns
  }

  clear(): void {
    this.filter = ''
    this.columnDefs = cloneDeep(this.initialColumns)

    const currentVisibleColumns = this.columnDefs.filter((col) => !col.hide)
    this.setColumnsVisibility(currentVisibleColumns)

    this.columnDefs.forEach((col) => {
      const currentCol = currentVisibleColumns.find((current) => current.field === col.field)
      if (currentCol) {
        col.position = currentCol.position
      }
    })

    this.calculateComponentStatus()
  }

  changeColumnsVisibility() {
    let visibleColumns: (ColDef<any, any> & CoreViewColumn)[] = [];
    let notVisibleColumns: (ColDef<any, any> & CoreViewColumn)[] = [];
    
    if (this.columnDefs) {
      ({ visibleColumns, notVisibleColumns } = this.columnDefs.reduce((acc, column) => {
        if (!column.hide || column.name === 'selection' || column.originalName === 'selection') {
          acc.visibleColumns.push(column);
        } else {
          acc.notVisibleColumns.push(column);
        }
        return acc;
      }, 
      { visibleColumns: [], notVisibleColumns: [] } as 
      { visibleColumns: (ColDef<any, any> & CoreViewColumn)[]; notVisibleColumns: (ColDef<any, any> & CoreViewColumn)[] }));
    }
  
    visibleColumns.sort((a: any, b: any) => a.position - b.position)

    if (visibleColumns.length === 0) {
      visibleColumns =
        this.defaultCols?.length > 0
          ? this.defaultCols
          : this.columnDefs.filter((x) => !x.notSelectable && !x.notAvailable && !!x.originalName)
      this.setColumnsVisibility(visibleColumns)
    }

    this.columnApi.setColumnsVisible(
      visibleColumns.map((x) => x.field || ''),
      true
    )

    // This avoid the columns to move around the table (during sorting) before disapearing
    this.columnApi.setColumnsVisible(
      notVisibleColumns.map((x) => x.field || ''),
      false
    )

    const columnState = this.columnApi
      .getColumnState()
      .sort((a, b) => visibleColumns.map((x) => x.field).indexOf(a.colId || '') - visibleColumns.map((x) => x.field).indexOf(b.colId || ''))

    const combinedColumnState = columnState.map((column) => {
      const pinnedColumn = this.pinnedColumns.find((pinnedCol) => pinnedCol.originalName.toLowerCase() === column.colId.toLowerCase())
      return {
        ...column,
        pinned: pinnedColumn ? pinnedColumn.pinned : undefined,
      }
    })

    this.columnApi.applyColumnState({
      state: combinedColumnState,
      applyOrder: true,
    })

    this.initialColumns = cloneDeep(this.columnDefs)
    this.visibleColumnChanged.emit(visibleColumns)
    this.saveUserSettings(visibleColumns).subscribe(() => {
      this.filter = ''
      this.selectedCols = null
      this.calculateComponentStatus()
      this.refresh()
    })
  }

  isLockedColumn(column: ColDef & CoreViewColumn) {
    return this.lockedColumns?.includes(column.originalName || column.name)
  }

  isDefaultHiddenField(column: ColDef & CoreViewColumn) {
    return this.defaultHiddenFields?.includes(column.originalName || column.name)
  }

  calculateComponentStatus(changeVisible: boolean = true) {
    if (changeVisible) {
      this.visibleColumns = this.getFilteredColumns() || []      
    }
    this.calculateSelectedColumns()
    this.calculateCheckboxStatus()
  }

  calculateSelectedColumns() {
    this.currentSelection = (this.columnDefs || []).reduce((acc, col) => {
      if ((!col.hide || this.isLockedColumn(col)) && !col.notSelectable) {
        acc.push(col.name || col.originalName)
      }
      return acc
    }, [] as string[])
  }

  calculateCheckboxStatus() {
    const checkedColumns = this.visibleColumns.filter((col) => !col.hide || this.isLockedColumn(col))
    if (checkedColumns.length === 0) {
      this.selectAllStatus = 'unchecked'
    } else if (checkedColumns.length < this.visibleColumns.length) {
      this.selectAllStatus = 'indeterminate'
    } else {
      this.selectAllStatus = 'checked'
    }
  }

  drop(event: CdkDragDrop<(ColDef & CoreViewColumn)[]>) {
    moveItemInArray(this.visibleColumns, event.previousIndex, event.currentIndex)

    this.visibleColumns.forEach((col, index) => {
      col.position = index + 1
    })

    this.calculateComponentStatus()
  }

  showHandCursor(event: MouseEvent) {
    const target = event.target as HTMLElement
    target.style.cursor = 'pointer'
  }

  hideHandCursor(event: MouseEvent) {
    const target = event.target as HTMLElement
    target.style.cursor = 'default'
  }

  private showMaxColumnsWarning() {
    this.toastService.open({
      id: 'maxColumns',
      title: this.translateHelper.instant('common_Warning'),
      message: this.translateHelper.instant('reports_MaxColumnsWarning'),
      variant: 'warning',
    })
  }

  private saveUserSettings(columns: (ColDef & CoreViewColumn)[]): Observable<any> {
    if (this.entityName) {
      const savedColumns = Helpers.getSavedColumns(columns, this.columnDefs, this.excludedColumns)

      return this.userSettingsService.updateUserSettings(savedColumns, this.entityName)
    }
    return of(undefined)
  }

  private setColumnsVisibility(newVisibleColumns: (ColDef & CoreViewColumn)[]) {
    this.columnDefs?.forEach((x) => (x.hide = true))
    ;[...newVisibleColumns, ...this.columnDefs.filter((x) => x.notSelectable)]
      .map((x) => x.originalName)
      .filter((x) => !!x)
      .forEach((x: string | undefined) => {
        this.columnDefs?.filter((y) => y.originalName === x).forEach((y) => (y.hide = false))
      })
  }
}
