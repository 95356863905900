import { Component, Input, OnInit } from '@angular/core';
import { AdministrationsService } from '@app/core/services/administrations.service';
import { LocalstorageService } from '@app/core/services/localstorage.service';
import { RightPanelRef } from '@app/core/services/right-panel.service';
import { Constants } from '@app/shared/utilities/constants';
import { TranslateHelper } from '@coreview/coreview-library';
import { TertiaryDropdownComponent } from '@coreview/coreview-components';
import { sumBy } from 'lodash-es'
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-license-optimization-advanced-filters',
  templateUrl: './license-optimization-advanced-filters.component.html',
  styleUrls: ['./license-optimization-advanced-filters.component.sass']
})
export class LicenseOptimizationAdvancedFiltersComponent implements OnInit {
  @Input() configuration!: { daysBefore: number; filters?: any; selectedSkus?: any[]; selectedPlans?: any[]; plansFilters: any }

  skuList: any[] = []
  serviceUsageItems: { key: string, status: string }[] = []
  plansList: any[] = []

  selectedSkus: any[] = []
  selectedPlans: any[] = []
  plansFilters: any = {}

  options: { key: string; text: string }[] = [{
    key: 'AND',
    text: this.translateHelper.instant('common_Active')
  }, {
    key: 'NOT',
    text: this.translateHelper.instant('common_Inactive')
  }, {
    key: '-',
    text: this.translateHelper.instant('common_Ignore')
  }]

  msProductsLogoClassIconMap: any = {
    exchange: 'Exchange',
    onedrive: 'OneDrive',
    officeproplus: 'OfficeProPlus',
    sharepoint: 'SharePoint',
    yammer: 'Yammer',
    skype: 'Skype',
    teams: 'Teams',
    powerbipro: 'PowerBIPro',
    crm: 'Crm',
    copilot: 'Copilot',
    project: 'Project',
    visio: 'Visio'
  }

  constructor(
    private rightPanelRef: RightPanelRef,
    private administrationsService: AdministrationsService,
    public storage: LocalstorageService,
    private translateHelper: TranslateHelper
  ) { }

  ngOnInit(): void {
    if (this.configuration.plansFilters) {
      this.plansFilters = this.configuration.plansFilters
    }
    this.serviceUsageItems = this.getServiceUsageItems()

    this.loadData()
  }

  loadData() {
    this.administrationsService.getLicenseOptimizationAvailableSkus(this.storage.selectedOrganization?.id).subscribe((result: any) => {
      const calls: Observable<void>[] = []

      var params = { Days: this.configuration.daysBefore, plansVisibility: this.plansFilters, filters: this.configuration.filters };
      result.skus.forEach((s: any) => {
        calls.push(this.administrationsService.getLicenseOptimizationActiveServices(this.storage.selectedOrganization?.id, s.sku, params))
      });

      combineLatest(calls)
        .subscribe(
          (responses: any) => {
            this.plansList = []
            this.skuList = responses
              .filter((item: any) => item.aggregations[0].items.length > 0)
              .map((item: any) => {
                var skuItem: any = {
                  key: item.sku.toLowerCase(),
                  plans: item.aggregations[0].items.map((item: any) => { return { key: item.key, count: item.count } }),
                  checked: this.configuration.selectedSkus && this.configuration.selectedSkus.indexOf(item.sku.toLowerCase()) >= 0
                    || this.selectedSkus && this.selectedSkus.some((s: any) => s.key === item.sku.toLowerCase()),
                  isVisible: true
                }
                skuItem.count = skuItem.plans.reduce((accumulator: any, obj: any) => {
                  return accumulator + obj.count;
                }, 0)

                return skuItem;
              });

            responses.forEach((response: any) => {
              response.aggregations[0].items.forEach((item: any) => {
                var existingPlan = this.plansList.length ? this.plansList.find((p: any) => p.key === item.key) : null;

                if (existingPlan) {
                  existingPlan.skus.push({
                    key: response.sku.toLowerCase(),
                    count: item.count
                  });

                  if (existingPlan.key !== 'inactive') {
                    existingPlan.count += item.count;
                  }
                }
                else {
                  var plan: any = {
                    skus: [{
                      key: response.sku.toLowerCase(),
                      count: item.count
                    }],
                    key: item.key,
                    checked: this.configuration.selectedPlans && this.configuration.selectedPlans.indexOf(item.key.toLowerCase()) >= 0 ||
                      this.selectedPlans && this.selectedPlans.some((s: any) => s.key === item.key.toLowerCase()),
                    isVisible: true
                  }

                  if (plan.key !== 'inactive') {
                    plan.count = item.count;
                  }

                  this.plansList.push(plan);
                }
              });
            });

            this.skuList = this.skuList.sort((a: any, b: any) => (a.key as string).localeCompare(b.key as string))

            this.plansList = this.plansList.sort((planA: any, planB: any) => {
              if (planB.key === 'inactive') {
                return -1
              } else if (planA.key === 'inactive') {
                return 1
              }

              const valueA = (planA.key.split(':').length * 100000) + (planA.key.length + (planA.count || 0))
              const valueB = (planB.key.split(':').length * 100000) + (planB.key.length + (planB.count || 0))

              if (valueA > valueB) {
                return -1
              } else if (valueA < valueB) {
                return 1
              }

              return 0
            });

            if (this.configuration.selectedSkus) {
              this.selectedSkus = this.skuList.filter((p: any) => this.configuration.selectedSkus && this.configuration.selectedSkus.indexOf(p.key) >= 0)
            }

            if (this.configuration.selectedPlans) {
              this.selectedPlans = this.plansList.filter((p: any) => this.configuration.selectedPlans && this.configuration.selectedPlans.indexOf(p.key) >= 0)
            }

            this.reloadSkusPlans()
            this.loadInactiveCount()
          },
          (err) => {
            console.log('err', err)

          }
        )
    })
  }

  getParametersSkus = () => (this.selectedSkus.length ? this.selectedSkus : this.skuList.filter((s: any) => s.isVisible)).map((item: any) => item.key)

  loadInactiveCount() {
    var inactivePlan = this.plansList.find((p: any) => p.key === 'inactive');
    if (!!inactivePlan) {
      const params: any = { days: this.configuration.daysBefore };

      params.skus = this.getParametersSkus()

      if (!!this.configuration.filters)
        params.filters = JSON.stringify(this.configuration.filters)

      params.getAllInactive = this.selectedSkus.length === 0

      this.administrationsService.getLicenseOptimizationInactiveOnlineUsersCountBySkus(this.storage.selectedOrganization?.id, params).subscribe((result: any) => {
        inactivePlan.count = result.count
      })
    }
  }

  clickSku(sku: any) {
    sku.checked = !sku.checked
    this.reloadSkusPlans();
    this.loadInactiveCount()
  }

  clickPlan(plan: any) {
    plan.checked = !plan.checked;
    this.reloadSkusPlans();
  };

  reloadSkusPlans = () => {
    this.selectedSkus = this.skuList.filter((s: any) => s.checked)
    this.selectedPlans = this.plansList.filter((s: any) => s.checked)

    if (this.selectedSkus.length) {
      var visiblePlans: any = [];

      this.selectedSkus.forEach((s: any) => {
        visiblePlans = visiblePlans.concat(s.plans)
      })

      this.plansList.forEach((plan: any) => {

        plan.isVisible = visiblePlans.some((v: any) => v.key === plan.key)
        plan.checked = plan.checked && plan.isVisible

        var index = this.selectedPlans.findIndex((p: any) => p.key === plan.key)

        if (!plan.checked && index !== -1) {
          this.selectedPlans.splice(index, 1);
        }
      });
    }
    else {
      this.plansList.forEach((p: any) => {
        p.isVisible = true
      });
    }

    this.recalculateCount()
  }

  recalculateCount() {
    this.plansList.filter(p => p.key !== 'inactive' && p.isVisible)
      .forEach((plan: any) => plan.count = this.getPlanCount(plan))

    this.skuList
      .forEach((sku: any) => sku.count = this.getSkuCount(sku))
  }

  isServiceActive(service: string) {
    return this.plansFilters[service] ||
      this.plansList.filter((p: any) => p.isVisible).some((vp: any) => vp.key.indexOf(service.toLowerCase()) !== -1);
  }

  getOptions() {
    return this.options
  }

  serviceUsageStatusChanged(service: { key: string, status: string }, status: any, component?: TertiaryDropdownComponent) {
    if (!!component)
      component.value = status

    service.status = status

    if (service.status === '-')
      delete this.plansFilters[service.key]
    else
      this.plansFilters[service.key] = service.status

    this.loadData()
  }

  getSkuCount(sku: any) {
    return sumBy(sku.plans, (plan: any) => !this.selectedPlans.length || !!this.selectedPlans.find(s => s.key === plan.key) ? plan.count : 0);
  };

  getPlanCount(plan: any) {
    return sumBy(plan.skus, (sku: any) => !this.selectedSkus.length || !!this.selectedSkus.find(s => s.key === sku.key) ? sku.count : 0);
  }

  getServiceUsageItems(): { key: string, status: string }[] {
    return Object.keys(Constants.msProductsLicenseOptimizationPage).map((s: string) => { return { key: s, status: this.plansFilters[s] || '-' } })
  }

  getKeysByPlanKey(key: string) {
    return key.split(':')
  }

  getIconTitleByService(key: string) {
    return Constants.msProductsLicenseOptimizationPage[key]
  }

  getIconColorByService(key: string) {
    return Constants.msProductsLogoColor[key]
  }

  getIconTitle(key: string) {
    if (key === 'inactive')
      return 'fa fa-ban'
    return this.getIconTitleByService(this.msProductsLogoClassIconMap[key])
  }

  getIconColor(key: string) {
    return this.getIconColorByService(this.msProductsLogoClassIconMap[key])
  }

  getVisiblePlansList() {
    return this.plansList.filter(p => p.isVisible)
  }

  close() {
    this.rightPanelRef.close()
  }

  submit() {
    this.rightPanelRef.close({
      ...this.configuration,
      selectedSkus: this.selectedSkus?.length > 0 ? this.selectedSkus.map((item: any) => item.key) : undefined,
      selectedPlans: this.selectedPlans?.length > 0 ? this.selectedPlans.map((s: any) => s.key) : undefined,
      plansFilters: this.plansFilters
    })
  }
}
