import { OverlayModule } from '@angular/cdk/overlay'
import { CoreViewComponentsModule } from '@coreview/coreview-components'
import { LibraryModule } from '@coreview/coreview-library'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { MaterialModule } from './material/material.module'
import { AuthInterceptor } from './interceptors/auth.interceptor'
import { ApiclientService } from './services/apiclient.service'
import { NavigationComponent } from './components/navigation/navigation.component'
import { ToolbarComponent } from './components/toolbar/toolbar.component'
import { UniversalSearchComponent } from './components/universal-search/universal-search.component'
import { ShowElementComponent } from './components/universal-search/show-element/show-element.component'
import { NotAllowedDialogComponent } from './components/dialogs/not-allowed-dialogs/not-allowed-dialog.component'
import { SharedModule } from '@app/shared/shared.module'
import { TranslateModule } from '@ngx-translate/core'
import { MissingPermissionsDialogComponent } from './components/dialogs/missing-permissions-dialog/missing-permissions-dialog.component'
import { MissingSkusDialogComponent } from './components/dialogs/missing-skus-dialog/missing-skus-dialog.component'
import { HomeComponent } from './components/home/home.component'
import { RightPanelComponent } from './components/right-panel/right-panel.component'
import { ExportingDialogComponent } from './components/exporting-dialog/exporting-dialog.component'
import { HttpErrorsInterceptor } from './interceptors/http-errors.interceptor'
import { ForbiddenComponent } from './components/forbidden/forbidden.component'
import { TermsOfServiceDialogComponent } from './components/dialogs/terms-of-service/terms-of-service-dialog.component'
import { SecureByDefaultComponent } from './components/secure-by-default/secure-by-default.component'
import { FiltersComponent } from './components/filters/filters.component'
import { ConsentAppsComponent } from './components/consent-apps/consent-apps.component'
import { LoadingLoginSkeletonComponent } from './components/loading-login-skeleton/loading-login-skeleton.component'
import { RecentItemComponent } from './components/universal-search/recent-item/recent-item.component'
import { EnableYarpInterceptor } from './interceptors/enable-yarp.interceptor'
import { TooltipModule, TooltipOptions } from '@cloudfactorydk/ng2-tooltip-directive'
import { DemoExperienceFilterComponent } from './components/toolbar/demo-experience-filter/demo-experience-filter.component';
import { UnauthorizedAccessComponent } from './components/unauthorized-access/unauthorized-access.component';
import { ManagementPanelComponent } from './components/management-panel/management-panel.component';
import { ShowMoreItemComponent } from './components/universal-search/show-more-item/show-more-item.component'
import { LoadingUniversalSearchSkeletonComponent } from './components/universal-search/loading-universal-search-skeleton/loading-universal-search-skeleton.component'

/**
 * The CoreModule contains singleton services, universal components and other single instanced features.
 * It exports the necessary features to run the application.
 * The CoreModule should be imported only in the AppModule.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const CvDefaultTooltipOptions: TooltipOptions = {
  hideDelay: 0,
  showDelay: 0,
  animationDuration: 0,
}

@NgModule({
  declarations: [
    UniversalSearchComponent,
    ShowElementComponent,
    NavigationComponent,
    ToolbarComponent,
    NotAllowedDialogComponent,
    HomeComponent,
    MissingPermissionsDialogComponent,
    MissingSkusDialogComponent,
    RightPanelComponent,
    ExportingDialogComponent,
    ForbiddenComponent,
    TermsOfServiceDialogComponent,
    SecureByDefaultComponent,
    FiltersComponent,
    ConsentAppsComponent,
    LoadingLoginSkeletonComponent,
    RecentItemComponent,
    DemoExperienceFilterComponent,
    UnauthorizedAccessComponent,
    ManagementPanelComponent,
    ShowMoreItemComponent,
    LoadingUniversalSearchSkeletonComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    HttpClientModule,
    RouterModule,
    CoreViewComponentsModule,
    LibraryModule,
    SharedModule,
    TranslateModule,
    TooltipModule.forRoot(CvDefaultTooltipOptions as TooltipOptions),
    OverlayModule,
  ],
  exports: [
    FormsModule,
    MaterialModule,
    UniversalSearchComponent,
    ShowElementComponent,
    NavigationComponent,
    ToolbarComponent,
    CoreViewComponentsModule,
    LibraryModule,
    RightPanelComponent,
    TooltipModule,
    FiltersComponent,
    ConsentAppsComponent,
    LoadingLoginSkeletonComponent,
    SharedModule,
    UnauthorizedAccessComponent,
    ShowMoreItemComponent,
    LoadingUniversalSearchSkeletonComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EnableYarpInterceptor,
      multi: true,
    },
    { provide: Window, useValue: window },
    { provide: Location, useValue: location },
    ApiclientService,
  ],
})
export class CoreModule {}
