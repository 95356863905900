<div class="report-container">
  <div class="title-bar" *ngIf="dashboardDefinition">
    <div class="title-bar-content">
      <app-v-tenant-indicator
        *ngIf="imSelected(menuIds.licenseSavingsOpportunities) || imSelected(menuIds.licenseServiceUsage)"
      ></app-v-tenant-indicator>
      <div class="first-line">
        {{ reportTitle }}
        <mat-icon
          *ngIf="!!reportDescription"
          (click)="showDescription = !showDescription"
          [ngClass]="{ 'info-active': !!showDescription }"
          class="m-l-5 info material-icons-outlined"
          >info
        </mat-icon>
      </div>
      <div class="m-t-10 f-s-14" *ngIf="!!showDescription">{{ reportDescription }}</div>
    </div>
    <div>
      <cv-button
        *ngIf="dashboardDefinition && dashboardDefinition.filterComponent"
        [text]="'common_Filters' | translate"
        buttonType="tertiary"
        leftIcon="filter_alt"
        [leftIconStyle]="(componentFilter | keyvalue).length ? 'filled' : 'outlined'"
        (click)="openFilterPanel()"
      ></cv-button>
      <app-range-filter
        *ngIf="daysBefore"
        [calendarType]="calendarType"
        [daysList]="daysList"
        [defaultDaysFilter]="daysBefore"
        (rangeFilterChanged)="rangeFilterChanged($event)"
      >
      </app-range-filter>
      <app-favorite *ngIf="menu" [menuId]="menu.id" [isFavorite]="menu.isFavorite" entity="dashboards"> </app-favorite>
    </div>
  </div>

  <cv-horizontal-tab-group type="line" (tabChanged)="tabChanged($event)" [activeTab]="tabSelectedIndex + 1" *ngIf="!!dashboardDefinition">
    <cv-tab *ngIf="canSeeTab(menuIds.licenseSavingsOpportunities)" title="{{ 'licenses_Savings' | translate }}">
      <div class="tab-container">
        <div class="main-container">
          <div class="grid-container">
            <div
              class="cards-container"
              *ngIf="
                imSelected(menuIds.licenseSavingsOpportunities) &&
                dashboardDefinition &&
                dashboardDefinition.tabs &&
                dashboardDefinition.tabs[0].cards
              "
            >
              <ng-container *ngFor="let card of dashboardDefinition.tabs[0].cards">
                <app-infos-card
                  *ngIf="!card.type || card.type === 'infos'"
                  [card]="card"
                  [data]="data"
                  [daysFilter]="daysValue"
                  [organization]="organization"
                  [loading]="loading"
                ></app-infos-card>

                <ng-container *ngIf="!noLpConfigured">
                  <app-customizable-chart
                    *ngIf="card.type === 'custom-chart'"
                    [card]="card"
                    [ngStyle]="card.style"
                    [loading]="loading"
                    [data]="pathParams"
                  ></app-customizable-chart>

                  <app-charts-card
                    *ngIf="card.type === 'chart'"
                    [card]="card"
                    [attr.style]="card.style"
                    [daysFilter]="daysValue"
                    [componentFilter]="componentFilter"
                    [loading]="loading"
                    [organization]="organization"
                  ></app-charts-card>

                  <app-datagrid
                    *ngIf="card.type === 'table' && !loadingTable"
                    [getItems]="getRefreshData(card)"
                    [gridDefinition]="getPageData$(card) | async"
                    [hideColumnsSelector]="true"
                    style="flex: 1"
                  ></app-datagrid>

                  <app-client-datagrid
                    *ngIf="card.type === 'clientTable'"
                    [getItems]="card.getItems"
                    [manageError]="card.manageError"
                    [gridOptions]="gridOptionsSnapshots"
                    [hideColumnsSelector]="true"
                    [autosizeAllColumns]="true"
                    [filterActions]="card.filterActions"
                  ></app-client-datagrid>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </cv-tab>

    <cv-tab *ngIf="canSeeTab(menuIds.licenseOptimization)" title="{{ 'licenses_Licenses' | translate }}">
      <div class="tab-container">
        <div
          class="main-container"
          [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'"
          *ngIf="imSelected(menuIds.licenseOptimization)"
        >
          <div [style.display]="!leftPanel.open && canSeeLeftPanel() ? null : 'none'" #fakeLeft></div>
          <cv-smart-panel #leftPanel type="button" icon="description" [style.position]="leftPanel.open ? null : 'absolute'" [style.display]="canSeeLeftPanel() ? null : 'none'" (openChange)="fakeLeft.classList.add('fake-left-panel')" [title]="'reports_CustomReports' | translate" style="padding-top: 10px">
            <div style="cursor: default" content>
              <div class="title-container">
                <div class="title" translate>reports_CustomReports</div>
                <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
              </div>
              <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
              <div class="saved-report-container">
                <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
              </div>
            </div>
          </cv-smart-panel>

          <div [class.left-panel-close]="!leftPanel.open" [class.right-panel-close]="hasActions && !rightPanel.open" class="grid-container" *ngIf="!loadingLicenses">
            <div *ngIf="showNoPrimaryLicensesLicensesDisclaimer">
              <cv-alert-bar variant="info" [title]="'licenses_NoPrimaryLicensesDisclaimerTitle' | translate" [hideCloseButton]="true">
                <div [innerHTML]="'licenses_NoPrimaryLicensesDisclaimerDescription' | translate"></div>
              </cv-alert-bar>
            </div>
            <app-datagrid
              [getItems]="getLicenseOptmizationItems"
              [gridDefinition]="reportDefinition"
              [savedReportFilterSortModel]="savedReportFilterSortModel"
              (metadataChanged)="metadata = $event"
              (rowSelected)="rowSelected()"
              style="flex: 1"
            ></app-datagrid>
          </div>

          <app-management-panel
            class="m-t-10"
            [targetEntity]="reportDefinition?.targetEntity"
            [collectionName]="reportDefinition?.dbCollectionName"
            [reportDefinition]="reportDefinition"
            [reportTitle]="reportDefinition?.title"
            [entityIdField]="reportDefinition?.entityIdField"
            [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
            [selectedRows]="selectedRows"
            [tagsByDefault]="reportDefinition?.actionsTags || []"
            (hasActionsChange)="hasActions = $event"
            #rightPanel
          ></app-management-panel>
        </div>
      </div>
    </cv-tab>

    <cv-tab *ngIf="canSeeTab(menuIds.licenseByUser)" title="{{ 'licenses_LicenseByUser' | translate }}">
      <div class="tab-container">
        <div
          class="main-container"
          [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'"
          *ngIf="imSelected(menuIds.licenseByUser)"
        >
          <div [style.display]="!leftPanel.open && canSeeLeftPanel() ? null : 'none'" #fakeLeft></div>
          <cv-smart-panel #leftPanel type="button" icon="description" [style.position]="leftPanel.open ? null : 'absolute'" [style.display]="canSeeLeftPanel() ? null : 'none'" (openChange)="fakeLeft.classList.add('fake-left-panel')" [title]="'reports_CustomReports' | translate" style="padding-top: 10px">
            <div style="cursor: default" content>
              <div class="title-container">
                <div class="title" translate>reports_CustomReports</div>
                <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
              </div>
              <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
              <div class="saved-report-container">
                <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
              </div>
            </div>
          </cv-smart-panel>

          <div [class.left-panel-close]="!leftPanel.open" [class.right-panel-close]="!rightPanel.open" class="grid-container" *ngIf="!!organization && reportDefinition">
            <app-datagrid
              [getItems]="getLicenseByUserItems"
              [gridDefinition]="reportDefinition"
              [savedReportFilterSortModel]="savedReportFilterSortModel"
              (metadataChanged)="metadata = $event"
              (rowSelected)="rowSelectedLicenseByUser()"
              (columnDefsDefined)="onAppDataGridColumnDefined($event)"
              style="flex: 1"
            ></app-datagrid>
          </div>

          <app-management-panel
            class="m-t-10"
            [targetEntity]="reportDefinition?.targetEntity"
            [collectionName]="reportDefinition?.dbCollectionName"
            [reportDefinition]="reportDefinition"
            [reportTitle]="reportDefinition?.title"
            [entityIdField]="reportDefinition?.entityIdField"
            [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
            [selectedRows]="selectedRowsLicenseByUser"
            [tagsByDefault]="reportDefinition?.actionsTags || []"
            (hasActionsChange)="hasActions = $event"
            #rightPanel
          ></app-management-panel>
        </div>
      </div>
    </cv-tab>

    <cv-tab *ngIf="canSeeTab(menuIds.licenseServiceUsage)" title="{{ 'licenses_ServiceUsage' | translate }}">
      <div class="tab-container">
        <div
          class="main-container"
          [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'"
          *ngIf="imSelected(menuIds.licenseServiceUsage)"
        >
          <div [style.display]="!leftPanel.open && canSeeLeftPanel() ? null : 'none'" #fakeLeft></div>
          <cv-smart-panel #leftPanel type="button" icon="description" [style.position]="leftPanel.open ? null : 'absolute'" [style.display]="canSeeLeftPanel() ? null : 'none'" (openChange)="fakeLeft.classList.add('fake-left-panel')" [title]="'reports_CustomReports' | translate" style="padding-top: 10px; z-index: 1">
            <div style="cursor: default" content>
              <div class="title-container">
                <div class="title" translate>reports_CustomReports</div>
                <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
              </div>
              <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
              <div class="saved-report-container">
                <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
              </div>
            </div>
          </cv-smart-panel>

          <div [class.left-panel-close]="!leftPanel.open" [class.right-panel-close]="!rightPanel.open" class="grid-container">
            <ng-container
              *ngIf="
                dashboardDefinition &&
                dashboardDefinition.tabs &&
                dashboardDefinition.tabs[3] &&
                dashboardDefinition.tabs[3].cards &&
                dashboardDefinition.tabs[3].cards[0]
              "
            >
              <app-datagrid
                *ngIf="dashboardDefinition.tabs[3].cards[0].type === 'table'"
                [getItems]="getServiceUsageItems"
                [gridDefinition]="reportDefinition"
                [savedReportFilterSortModel]="savedReportFilterSortModel"
                [hideColumnsSelector]="true"
                (metadataChanged)="metadata = $event"
                (rowSelected)="rowSelectedServiceUsage()"
                style="flex: 1"
              ></app-datagrid>

              <app-client-datagrid
                *ngIf="dashboardDefinition.tabs[3].cards[0].type === 'clientTable'"
                [getItems]="dashboardDefinition.tabs[3].cards[0].getItems"
                [responseItemProp]="'items'"
                [gridOptions]="gridOptionsServiceUsage"
                [hideColumnsSelector]="true"
                [autosizeAllColumns]="true"
                [filterActions]="dashboardDefinition.tabs[3].cards[0].filterActions"
                [selectionActions]="clientSelectionActions"
              ></app-client-datagrid>
            </ng-container>
          </div>

          <app-management-panel
            class="m-t-10"
            [style.display]="serviceUsageGroupBy === 'User' ? null : 'none'"
            [targetEntity]="reportDefinition?.targetEntity"
            [collectionName]="reportDefinition?.dbCollectionName"
            [reportDefinition]="reportDefinition"
            [reportTitle]="reportDefinition?.title"
            [entityIdField]="reportDefinition?.entityIdField"
            [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
            [selectedRows]="selectedRowsLicenseByUser"
            [tagsByDefault]="reportDefinition?.actionsTags || []"
            (hasActionsChange)="hasActions = $event"
            #rightPanel
          ></app-management-panel>
        </div>
      </div>
    </cv-tab>

    <cv-tab *ngIf="canSeeTab(menuIds.licenseDuplicatedPlans)" title="{{ 'licenses_DuplicatedPlans' | translate }}">
      <div class="tab-container">
        <div
          class="main-container"
          [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'"
          *ngIf="imSelected(menuIds.licenseDuplicatedPlans)"
        >
          <div [style.display]="!leftPanel.open && canSeeLeftPanel() ? null : 'none'" #fakeLeft></div>
          <cv-smart-panel type="button" icon="description" [style.position]="leftPanel.open ? null : 'absolute'" [style.display]="canSeeLeftPanel() ? null : 'none'" [title]="'reports_CustomReports' | translate" (openChange)="fakeLeft.classList.add('fake-left-panel')" style="padding-top: 10px" #leftPanel>
            <div style="cursor: default" content>
              <div class="title-container">
                <div class="title" translate>reports_CustomReports</div>
                <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
              </div>
              <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
              <div class="saved-report-container">
                <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
              </div>
            </div>
          </cv-smart-panel>
          <div
            [class.left-panel-close]="!leftPanel.open"
            [class.right-panel-close]="!rightPanel.open"
            class="grid-container"
            *ngIf="!loadingDuplicatedPlans && !!organization && reportDefinition"
          >
            <div *ngIf="showNoPrimaryLicensesDuplicatedDisclaimer">
              <cv-alert-bar variant="info" [title]="'licenses_NoPrimaryDuplicatedPlansTitle' | translate" [hideCloseButton]="true">
                <div [innerHTML]="'licenses_NoPrimaryDuplicatedPlansDescription' | translate"></div>
              </cv-alert-bar>
            </div>
            <app-datagrid
              [getItems]="getDuplicatedPlansItems"
              [gridDefinition]="reportDefinition"
              [savedReportFilterSortModel]="savedReportFilterSortModel"
              (metadataChanged)="metadata = $event"
              (rowSelected)="rowSelectedDuplicatedPlans()"
              style="flex: 1"
            ></app-datagrid>
          </div>
          <app-management-panel
            class="m-t-10"
            [targetEntity]="reportDefinition?.targetEntity"
            [collectionName]="reportDefinition?.dbCollectionName"
            [reportDefinition]="reportDefinition"
            [reportTitle]="reportDefinition?.title"
            [entityIdField]="reportDefinition?.entityIdField"
            [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
            [selectedRows]="selectedRowsDuplicatedPlans"
            [tagsByDefault]="reportDefinition?.actionsTags || []"
            (hasActionsChange)="hasActions = $event"
            #rightPanel
          ></app-management-panel>
        </div>
      </div>
    </cv-tab>
  </cv-horizontal-tab-group>
</div>
