import { ColDef, ColGroupDef, GridOptions } from '@ag-grid-community/core'
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { UserSettings } from '@coreview/coreview-library/models/user-settings'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { ReportsService } from '@app/core/services/reports.service'
import { Constants } from '@app/shared/utilities/constants'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'
import { TranslateHelper } from '@coreview/coreview-library'
import { selectLastMessage } from '@app/store/messages/messages.selectors'
import { RootState } from '@app/store/RootState.type'
import { selectUserSettings } from '@app/store/userSettings/userSettings.selectors'
import { Store } from '@ngrx/store'
import { Suggestion, ToastService } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import { Observable, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { ReportsComponent } from '../../reports.component'
import { Helpers } from '@app/shared/utilities/helpers'

@Component({
  selector: 'app-quarantined-messages',
  templateUrl: './quarantined-messages.component.html',
  styleUrls: ['./quarantined-messages.component.sass'],
})
export class QuarantinedMessagesComponent implements OnInit, OnDestroy {
  @ViewChild(ReportsComponent) reportsComponent!: ReportsComponent

  userSettings$!: Observable<UserSettings | undefined>
  userSettings?: UserSettings
  localeIso!: string
  defaultRange: 0 | 1 | 60 | 3 | 7 | 14 | 30 | 90 | 180 = 30
  defaultRangeExpires: 0 | 1 | 60 | 3 | 7 | 14 | 30 | 90 | 180 = 30
  rangeOptions: (1 | 3 | 7 | 14 | 30 | 60 | 90 | 180 | 0)[] = [1, 3, 7, 14, 30]
  minDate: any = dayjs.utc().add(-1, 'years')
  maxDate: any = dayjs.utc().add(90, 'days')

  configuration!: {
    gridOptions: GridOptions
    columnDefs?: (ColDef | ColGroupDef)[]
    getParameters: () => any
    isRowMaster: () => boolean
    leftPanel?: string
    rightPanel?: string
  }

  filters!: {
    startReceivedDateObj: dayjs.Dayjs
    startReceivedDate: string
    endReceivedDateObj: dayjs.Dayjs
    endReceivedDate: string
    startExpiresDateObj: dayjs.Dayjs
    startExpiresDate: string
    endExpiresDateObj: dayjs.Dayjs
    endExpiresDate: string
    type?: string
    subject?: string
    senderAddress?: string
    recipientAddress?: string
  }

  types = ['Spam', 'TransportRule', 'Bulk', 'Phish']

  error!: string | undefined
  resetInProgress!: boolean

  form = new UntypedFormGroup({
    sender: new UntypedFormControl('', [Validators.email]),
    recipient: new UntypedFormControl('', [Validators.email]),
    type: new UntypedFormControl(),
    subject: new UntypedFormControl(),
    startReceivedDate: new UntypedFormControl('', [Validators.required]),
    startReceivedTime: new UntypedFormControl('', [Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)]),
    endReceivedDate: new UntypedFormControl('', [Validators.required]),
    endReceivedTime: new UntypedFormControl('', [Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)]),
    startExpiresDate: new UntypedFormControl('', [Validators.required]),
    startExpiresTime: new UntypedFormControl('', [Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)]),
    endExpiresDate: new UntypedFormControl('', [Validators.required]),
    endExpiresTime: new UntypedFormControl('', [Validators.required, Validators.pattern(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)]),
  })

  typeSuggestions: Suggestion[] = []

  searchInProgress: boolean = false

  leftPanelOpen = true

  public readonly progressMessage = 'reports_QuarantinedMessageSearchInProgress'

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private store: Store<RootState>,
    private localstorageService: LocalstorageService,
    private reportsService: ReportsService,
    private appInsights: ApplicationInsightService,
    private toastService: ToastService,
    public translateHelper: TranslateHelper,
    private reportsComponentHelper: ReportsComponentHelper,
  ) {}

  ngOnInit(): void {
    this.initializeFilters()
    this.setFormData()

    this.types.forEach((s: string) => {
      this.typeSuggestions.push({ displayValue: s, value: s })
    })

    this.userSettings$ = this.store.select(selectUserSettings)

    this.userSettings$.pipe(takeUntil(this.destroyed$)).subscribe((userSettings) => {
      if (userSettings) {
        const regionalSetting = Constants.regionalSettings.find((us) => us.fullLabel === userSettings.regionalSettings)
        this.localeIso = this.localeIso = Helpers.isoRegionalSetting(regionalSetting)
      }
    })

    this.store
      .select(selectLastMessage)
      .pipe(
        filter((x) => !!x && x.type === 'NotifyTaskStatus' && x.body.title === 'GetQuarantineMessages' && x.body.state === 'Finished'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.searchInProgress = false
        this.reportsComponent?.clientGrid?.refresh()
      })

    this.loadData()
  }

  initializeFilters(isReset?: boolean) {
    if (!!this.localstorageService.getQuarantinedMessagesSearchFilters() && !isReset) {
      this.filters = this.localstorageService.getQuarantinedMessagesSearchFilters()
      this.filters.startReceivedDateObj = dayjs(this.filters.startReceivedDateObj)
      this.filters.endReceivedDateObj = dayjs(this.filters.endReceivedDateObj)
      this.filters.startExpiresDateObj = dayjs(this.filters.startExpiresDateObj)
      this.filters.endExpiresDateObj = dayjs(this.filters.endExpiresDateObj)
      this.defaultRange = 0
      this.defaultRangeExpires = 0
    } else {
      this.defaultRange = 7
      this.defaultRangeExpires = 0
      this.filters = {
        startReceivedDateObj: dayjs().add(-this.defaultRange, 'day'),
        startReceivedDate: dayjs().add(-this.defaultRange, 'day').format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
        endReceivedDateObj: dayjs(),
        endReceivedDate: dayjs().format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
        startExpiresDateObj: dayjs().add(-this.defaultRangeExpires, 'day'),
        startExpiresDate: dayjs().add(-this.defaultRangeExpires, 'day').format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
        endExpiresDateObj: dayjs().add(31, 'day'),
        endExpiresDate: dayjs().add(31, 'day').format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
      }
    }
  }

  setFormData() {
    this.form.get('startReceivedDate')?.setValue(this.filters.startReceivedDateObj.format('L'))
    this.form.get('startReceivedTime')?.setValue(this.filters.startReceivedDateObj.format('HH:mm'))
    this.form.get('endReceivedDate')?.setValue(this.filters.endReceivedDateObj.format('L'))
    this.form.get('endReceivedTime')?.setValue(this.filters.endReceivedDateObj.format('HH:mm'))
    this.form.get('startExpiresDate')?.setValue(this.filters.startExpiresDateObj.format('L'))
    this.form.get('startExpiresTime')?.setValue(this.filters.startExpiresDateObj.format('HH:mm'))
    this.form.get('endExpiresDate')?.setValue(this.filters.endExpiresDateObj.format('L'))
    this.form.get('endExpiresTime')?.setValue(this.filters.endExpiresDateObj.format('HH:mm'))
    this.form.get('sender')?.setValue(this.filters.senderAddress)
    this.form.get('recipient')?.setValue(this.filters.recipientAddress)
    this.form.get('type')?.setValue(this.filters.type)
    this.form.get('subject')?.setValue(this.filters.subject)
  }

  changedStartReceivedTimeFilter($event: any) {
    if (this.form.get('startReceivedTime')?.valid) {
      const startReceivedTime = this.calculateTime(this.form.get('startReceivedTime')?.value)
      this.filters.startReceivedDateObj = this.filters.startReceivedDateObj
        .set('hour', startReceivedTime.hours)
        .set('minute', startReceivedTime.minutes)
        .set('second', 0)
        .set('millisecond', 0)
    }
  }

  changedEndReceivedTimeFilter($event: any) {
    if (this.form.get('endReceivedTime')?.valid) {
      const endReceivedTime = this.calculateTime(this.form.get('endReceivedTime')?.value)
      this.filters.endReceivedDateObj = this.filters.endReceivedDateObj
        .set('hour', endReceivedTime.hours)
        .set('minute', endReceivedTime.minutes)
        .set('second', 0)
        .set('millisecond', 0)
    }
  }

  changedStartExpiresTimeFilter($event: any) {
    if (this.form.get('startExpiresTime')?.valid) {
      const startExpiresTime = this.calculateTime(this.form.get('startExpiresTime')?.value)
      this.filters.startExpiresDateObj = this.filters.startExpiresDateObj
        .set('hour', startExpiresTime.hours)
        .set('minute', startExpiresTime.minutes)
        .set('second', 0)
        .set('millisecond', 0)
    }
  }

  changedEndExpiresTimeFilter($event: any) {
    if (this.form.get('endExpiresTime')?.valid) {
      const endExpiresTime = this.calculateTime(this.form.get('endExpiresTime')?.value)
      this.filters.endExpiresDateObj = this.filters.endExpiresDateObj
        .set('hour', endExpiresTime.hours)
        .set('minute', endExpiresTime.minutes)
        .set('second', 0)
        .set('millisecond', 0)
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  showUTCDate(date: dayjs.Dayjs) {
    return dayjs.utc(date.format()).format('L LT')
  }

  loadData() {
    this.configuration = {
      gridOptions: {
        defaultColDef: {
          sortable: true,
          resizable: false,
          filter: true,
          floatingFilter: true,
          filterParams: {
            suppressAndOrCondition: true,
          },
        },
        masterDetail: false,
      },
      isRowMaster: () => false,
      columnDefs: this.reportsComponentHelper.getColDefByReportName('QuarantinedMessages'),
      getParameters: () => {},
      leftPanel: 'CustomSmartPanel',
    }
  }

  onRangeChange(newRange: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs; days?: string }) {
    this.form.get('startReceivedDate')?.setValue(newRange.startDate.format('L'))
    this.form.get('endReceivedDate')?.setValue(newRange.endDate.format('L'))

    const startReceivedTime = this.calculateTime(this.form.get('startReceivedTime')?.value)
    this.filters.startReceivedDateObj = newRange.startDate
      .set('hour', startReceivedTime.hours)
      .set('minute', startReceivedTime.minutes)
      .set('second', 0)
      .set('millisecond', 0)

    if (newRange.startDate.isUTC())
      this.filters.startReceivedDateObj = this.filters.startReceivedDateObj
        .add(-dayjs().utcOffset(), 'minute')
        .set('second', 0)
        .set('millisecond', 0)
        .local()

    const endReceivedTime = this.calculateTime(this.form.get('endReceivedTime')?.value)
    this.filters.endReceivedDateObj = newRange.endDate
      .set('hour', endReceivedTime.hours)
      .set('minute', endReceivedTime.minutes)
      .set('second', 0)
      .set('millisecond', 0)

    if (newRange.endDate.isUTC())
      this.filters.endReceivedDateObj = this.filters.endReceivedDateObj
        .add(-dayjs().utcOffset(), 'minute')
        .set('second', 0)
        .set('millisecond', 0)
        .local()
  }

  onRangeChangeExpires(newRange: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs; days?: string }) {
    this.form.get('startExpiresDate')?.setValue(newRange.startDate.format('L'))
    this.form.get('endExpiresDate')?.setValue(newRange.endDate.format('L'))

    const startExpiresTime = this.calculateTime(this.form.get('startExpiresTime')?.value)
    this.filters.startExpiresDateObj = newRange.startDate
      .set('hour', startExpiresTime.hours)
      .set('minute', startExpiresTime.minutes)
      .set('second', 0)
      .set('millisecond', 0)

    if (newRange.startDate.isUTC())
      this.filters.startExpiresDateObj = this.filters.startExpiresDateObj
        .add(-dayjs().utcOffset(), 'minute')
        .set('second', 0)
        .set('millisecond', 0)
        .local()

    const endExpiresTime = this.calculateTime(this.form.get('endExpiresTime')?.value)
    this.filters.endExpiresDateObj = newRange.endDate
      .set('hour', endExpiresTime.hours)
      .set('minute', endExpiresTime.minutes)
      .set('second', 0)
      .set('millisecond', 0)

    if (newRange.endDate.isUTC())
      this.filters.endExpiresDateObj = this.filters.endExpiresDateObj
        .add(-dayjs().utcOffset(), 'minute')
        .set('second', 0)
        .set('millisecond', 0)
        .local()
  }

  getErrors() {
    const errors = []

    if (this.isSearchDisabled() && this.form.dirty) {
      if (
        this.form.get('startReceivedTime')?.errors ||
        this.form.get('endReceivedTime')?.errors ||
        this.form.get('startExpiresTime')?.errors ||
        this.form.get('endExpiresTime')?.errors
      ) {
        if (
          this.form.get('startReceivedTime')?.errors?.required ||
          this.form.get('endReceivedTime')?.errors?.required ||
          this.form.get('startExpiresTime')?.errors?.required ||
          this.form.get('endExpiresTime')?.errors?.required
        )
          errors.push('reports_TimeFieldsAreRequired')
        else errors.push('reports_InvalidTimeFormat')
      }

      if (this.form.get('sender')?.errors || this.form.get('recipient')?.errors) {
        if (this.form.get('sender')?.errors?.email || this.form.get('recipient')?.errors?.email) {
          errors.push('reports_SenderAndRecipientMustBeValidEmail')
        }
      }
    }

    return errors
  }

  isSearchDisabled() {
    return this.form.invalid
  }

  calculateTime(time: string) {
    return {
      hours: +time.substring(0, time.indexOf(':')),
      minutes: +time.substring(time.indexOf(':') + 1, time.indexOf(':') + 3),
    }
  }

  search() {
    this.error = undefined

    setTimeout(() => {
      if (this.form.get('sender')?.value) this.filters.senderAddress = this.form.get('sender')?.value
      else this.filters.senderAddress = undefined

      if (this.form.get('recipient')?.value) this.filters.recipientAddress = this.form.get('recipient')?.value
      else this.filters.recipientAddress = undefined

      if (this.form.get('type')?.value) this.filters.type = this.form.get('type')?.value
      else this.filters.type = undefined

      if (this.form.get('subject')?.value) this.filters.subject = this.form.get('subject')?.value
      else this.filters.subject = undefined

      this.filters.startReceivedDate = dayjs(this.filters.startReceivedDateObj).toISOString()
      this.filters.endReceivedDate = dayjs(this.filters.endReceivedDateObj).toISOString()

      this.filters.startExpiresDate = dayjs(this.filters.startExpiresDateObj).toISOString()
      this.filters.endExpiresDate = dayjs(this.filters.endExpiresDateObj).toISOString()

      this.reportsService.getDataSimplified('/management/quarantineMessages', 'post', this.filters).subscribe({
        next: (res) => {
          if (res.responseStatus?.errorCode === 'Success') {
            this.localstorageService.setQuarantinedMessagesSearchFilters(this.filters)
            this.searchInProgress = true
            this.reportsComponent.clientGrid?.gridApi.showLoadingOverlay()

            this.toastService.open({
              id: 'success',
              variant: 'success',
              title: this.translateHelper.instant('common_Success'),
              message: this.translateHelper.instant('reports_QuarantinedMessagesRequestSuccessfullySent'),
            })


          }
        },
        error: (error) => {
          this.appInsights.trackError('Search quarantined messages failed with error: : ' + error)
          this.toastService.open({
            id: 'err',
            variant: 'error',
            title: this.translateHelper.instant('reports_QuarantinedMessagesSearch'),
            dismiss: (toast: { id: any }) => this.toastService.close(toast.id),
          })

          console.log(error.error.responseStatus.message)
        },
      })
    }, 1);
  }

  reset() {
    this.resetInProgress = true
    this.error = undefined

    setTimeout(() => {
      this.initializeFilters(true)

      this.form.reset({
        startReceivedDate: this.filters.startReceivedDateObj.format('L'),
        startReceivedTime: this.filters.startReceivedDateObj.format('HH:mm'),
        endReceivedDate: this.filters.endReceivedDateObj.format('L'),
        endReceivedTime: this.filters.endReceivedDateObj.format('HH:mm'),
        startExpiresDate: this.filters.startExpiresDateObj.format('L'),
        startExpiresTime: this.filters.startExpiresDateObj.format('HH:mm'),
        endExpiresDate: this.filters.endExpiresDateObj.format('L'),
        endExpiresTime: this.filters.endExpiresDateObj.format('HH:mm'),
      })

      this.form.markAsPristine()

      this.resetInProgress = false
    }, 1)
  }
}
