import { AgFilterComponent, IFilterAngularComp } from '@ag-grid-community/angular'
import { IDoesFilterPassParams, AgPromise, IFilterParams, IAfterGuiAttachedParams } from '@ag-grid-community/core'
import { Component, EventEmitter, Input, OnDestroy } from '@angular/core'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { QueryFilter2 } from '@app/core/models/QueryFilter'
import { comparableVerbsWithNoValues } from '@app/core/models/ReportDefinition'
import { Helpers } from '@app/shared/utilities/helpers'
import { TranslateHelper } from '@coreview/coreview-library'
import { cloneDeep, groupBy, toPairs } from 'lodash-es'
import { Observable } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

type TypeOptions = '' | 'contains' | 'doesNotContain' | 'isEmpty' | 'isNotEmpty' | 'equals'
type TypeOutputOptions = TypeOptions | 'multiselect'

type OptionType = { value: string; locked: boolean; checked: boolean; group?: string }
type FilterType = {
  filterType: string
  type: TypeOutputOptions
  filter?: any
  groupFilter?: QueryFilter2
  modelAsString?: string
}

export const MULTISELECT_OPTIONS: string[] = ['equals', 'contains', 'doesNotContain']

export interface IFilterParamsMultiSelect extends IFilterParams {
  options: Observable<string[]>
  filterOptions: { key: TypeOptions; value: string }[]
  defaultFilterType: TypeOptions
}

@Component({
  selector: 'app-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.sass'],
})
export class MultiSelectFilterComponent implements OnDestroy, AgFilterComponent, IFilterAngularComp {
  @Input() preCheckedValues!: string[]

  notLockedValues!: string[]
  colId!: string
  params!: IFilterParamsMultiSelect
  type: TypeOutputOptions = ''

  filterOptions: { key: string; value: string }[] = []

  value!: string | null
  values: string[] = []
  options!: OptionType[]
  filteredOptions!: OptionType[]
  optionsWithNoValue = Object.keys(comparableVerbsWithNoValues)
  lastAppliedOptions?: OptionType[]
  groupedOptions!: [string, OptionType[]][]
  searchFilter = ''
  selectAll = false
  isLoaded = false

  searchChanged$ = new EventEmitter<void>()

  private hideFilter!: (() => void) | undefined
  private model!: FilterType
  private modelFilter: (string | undefined)[] | undefined
  private destroyed$ = new EventEmitter<void>()

  constructor(private translateHelper: TranslateHelper) {}

  ngOnDestroy(): void {
    this.destroyed$.emit()
    this.destroyed$.complete()
  }

  isOptionWithNoValue(): boolean {
    return this.optionsWithNoValue.includes(this.type)
  }

  isShow() {
    return (this.type && !this.isOptionWithNoValue()) || (!this.type && this.filterOptions.length === 0)
  }

  isFilterActive(): boolean {
    if (!this.type) {
      return false
    }
    if (this.isOptionWithNoValue()) {
      return true
    }
    return this.values?.length > 0
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const value = this.params.valueGetter(params.data)
    switch (this.type) {
      case 'contains':
        {
          if (Array.isArray(value)) { 
            return value.some((v) => this.values?.includes(v))
          }
          return this.values?.includes(value)
        }
      case 'doesNotContain':
        {
          if (Array.isArray(value)) { 
            return !value.some(v => this.values?.includes(v))
          }
          return !this.values?.includes(value)
        }
      case 'isEmpty':
        return value.length === 0
      case 'isNotEmpty':
        return value.length > 0
      case 'equals':
        {
          if (Array.isArray(value)) { 
            return value.every((v) => this.values?.includes(v))
          }
          return this.values?.length === 1 && this.values[0] === value
        }
      default:
        return this.values?.includes(value)
    }
  }

  getModel(): FilterType | null {
    if (!this.isFilterActive()) {
      return null
    }
    if (this.isOptionWithNoValue()) {
      const result = {
        filterType: 'string',
        type: this.type,
        filter: null,
      }
      return result
    } else {
      const operator = this.type === 'doesNotContain' ? '<>' : '='
      const result = {
        filterType: 'multiselect',
        type: 'multiselect' as TypeOutputOptions,
        filter: {
          operation: 'OR',
          children: this.values?.map((v) => ({ queryFilter: { name: Helpers.capitalize(this.colId), value: v, operation: this.type } })),
        },
        modelAsString:
          this.values?.length === 1
            ? operator + this.values?.toString()
            : operator +
              this.translateHelper.genericCombineTranslations('common_CountSelected', undefined, {
                count: this.values?.length.toString(),
              }),
      }
      return result
    }
  }

  modelChanged() {
    if (!this.type || this.isFilterActive()) {
      this.params.filterChangedCallback({ source: 'columnFilter' })
      if (this.hideFilter) {
        this.hideFilter()
      }
    }
  }

  setModel(model: FilterType): void | AgPromise<void> {
    if (model) {
      this.type = model.type || ''
      this.value = model.filter

      this.model = { ...this.model, ...model }
      this.modelFilter = this.model.filter?.children
        ?.filter((f: any) => MULTISELECT_OPTIONS.includes(Helpers.downcase(f.queryFilter?.operation)))
        .map((m: any) => m.queryFilter?.value)
      this.notLockedValues =
        this.model.filter?.children
          ?.filter((f: any) => MULTISELECT_OPTIONS.includes(Helpers.downcase(f.queryFilter?.operation)) && f.unchecked)
          .map((m: any) => m.queryFilter?.value) || []

      const type = this.model.filter?.children?.[0]?.queryFilter?.operation
      if (type) {
        this.type = Helpers.downcase(type) as TypeOutputOptions
      }

      this.setFixedValues()
    } else {
      this.type = ''
    }
  }

  setFixedValues = () => {
    if (!!this.model && !!this.options) {
      this.preCheckedValues = this.modelFilter as string[]
      const selectedGroupOptions = this.selectedGroupOptions()

      this.modelFilter
        ?.filter((f) => !!f)
        .forEach((f) => {
          if (f) {
            const matchingValues: OptionType[] = selectedGroupOptions
              .filter((x): x is OptionType => x !== undefined)
              .filter((x) =>
                this.type === 'contains'
                  ? x.value.toLocaleLowerCase().includes(f.toLocaleLowerCase())
                  : x.value.toLocaleLowerCase() === f.toLocaleLowerCase()
              )

            matchingValues.forEach((val) => {
              if (val) {
                val.checked = true
                val.locked = !this.notLockedValues.includes(f)
              }
            })
          }
        })
    }

    this.updateModel()
  }

  agInit(params: IFilterParamsMultiSelect): void {
    this.searchChanged$.pipe(debounceTime(200)).subscribe(() => this.searchChanged())

    this.params = params
    this.colId = params.column.getColId()
    ;(this.params as any).options.subscribe((res: string[]) => {
      this.isLoaded = true
      this.options = res.map((v: string | { id: string; value: string; groupBy: string }) =>
        typeof v === 'string'
          ? { value: v, locked: this.preCheckedValues?.includes(v), checked: this.preCheckedValues?.includes(v) }
          : {
              value: v.value,
              locked: this.preCheckedValues?.includes(v.value),
              checked: this.preCheckedValues?.includes(v.value),
              group: v.groupBy,
            }
      )
      this.filteredOptions = this.options
      if (this.params.filterOptions) {
        this.filterOptions = this.params.filterOptions
        this.type = this.type ?? this.params.defaultFilterType
      } else {
        this.type = 'equals'
      }
      this.setFixedValues()

      if (this.values?.length) {
        this.params.filterChangedCallback({ colId: this.colId, source: 'columnFilter' })
      }
    })
  }

  updateGroupedOptions() {
    this.groupedOptions = toPairs(groupBy(this.filteredOptions, 'group'))
    const groupFilter = this.model?.groupFilter?.children.map((m) => m.queryFilter?.value)
    this.groupedOptions = this.groupedOptions?.filter((x) => !groupFilter || (!!groupFilter && groupFilter?.includes(x[0])))
  }

  check(column: any, event: MatCheckboxChange) {
    column.checked = event.checked
    this.checkSelectAll()
  }

  checkAll(event: MatCheckboxChange) {
    this.filteredOptions?.filter((x) => !x.locked).forEach((x) => (x.checked = event.checked))
  }

  resetColumns() {
    this.searchFilter = ''
    this.options?.filter((x) => !x.locked).forEach((x) => (x.checked = false))
    this.checkSelectAll()
    this.searchChanged()
  }

  clearSearch() {
    this.searchFilter = ''
    this.searchChanged()
  }

  cancelFilter() {
    if (this.hideFilter) {
      this.hideFilter()
    }
  }

  applyFilter() {
    this.updateModel()
    this.lastAppliedOptions = cloneDeep(this.options)
    this.params.filterChangedCallback({ colId: this.colId, source: 'columnFilter' })
    if (this.hideFilter) {
      this.hideFilter()
    }
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
    this.hideFilter = params?.hidePopup
  }

  afterGuiDetached(): void {
    if (this.lastAppliedOptions?.length) {
      this.options = cloneDeep(this.lastAppliedOptions)
    } else {
      this.options?.filter((x) => !x.locked).forEach((x) => (x.checked = false))
    }
    this.searchChanged()
    this.checkSelectAll()
  }

  onFloatingFilterChanged(type: string, value: string) {
    const defaultType = this.filterOptions.length > 1 ? '' : 'Equals'
    this.type = (type || defaultType) as any
    this.value = value

    this.resetColumns()
    this.lastAppliedOptions = cloneDeep(this.options)
    this.params.filterChangedCallback({ colId: this.colId, source: 'columnFilter' })
  }

  getSortedItems(items: OptionType[]): OptionType[] {
    return items.sort((a, b) => {
      if (a.locked && !b.locked) return -1
      if (!a.locked && b.locked) return 1

      if (a.checked && !b.checked) return -1
      if (!a.checked && b.checked) return 1

      return a.value.localeCompare(b.value)
    })
  }

  trackByGroupedFn(index: number, item: any) {
    return item.value
  }

  private searchChanged() {
    this.filteredOptions = this.searchFilter
      ? this.options?.filter((x) => x.value.toLocaleLowerCase().indexOf(this.searchFilter.toLocaleLowerCase()) > -1)
      : this.options
    this.updateModel()
  }

  private checkSelectAll() {
    this.selectAll = !this.options?.find((x) => !x.checked)
  }

  private selectedGroupOptions() {
    const parentGroup = this.model?.groupFilter?.children.map((g) => g.queryFilter?.value || 'undefined')
    if (parentGroup) {
      this.updateGroupedOptions()
      return parentGroup.flatMap((p: string) => (this.groupedOptions?.find((g) => g[0] === p) || [])[1])
    }
    return this.options
  }

  private updateModel() {
    this.updateGroupedOptions()
    this.values = this.options?.filter((x) => x.checked).map((x) => x.value)
  }
}
