import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { ReportsService } from '@app/core/services/reports.service'
import { Policy } from '@app/core/models/playbook'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { Store } from '@ngrx/store'
import { RootState } from '@app/store/RootState.type'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { map } from 'rxjs/operators'
import { cloneDeep } from 'lodash-es'
import { of } from 'rxjs'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'

@Component({
  selector: 'app-policy-item-list',
  templateUrl: './policy-item-list.component.html',
  styleUrls: ['./policy-item-list.component.sass'],
})
export class PolicyItemListComponent implements OnInit {
  @Input()
  policy!: Policy
  @Input()
  reportDefinition!: ReportDefinition
  @Input()
  reportType!: 'matchedItems' | 'matchedExceptions' | 'expiredExceptions'

  @Output()
  rowSelected = new EventEmitter<any>()
  @Output()
  columnDefsDefined = new EventEmitter<any>()
  @Output()
  modelUpdated = new EventEmitter<any>()
  @Output() gridReady = new EventEmitter<any>()
  @Output() metadataChanged = new EventEmitter<any>()

  @ViewChild(DatagridComponent)
  set datagrid(datagrid: DatagridComponent) {
    if (!!datagrid) {
      this.grid = datagrid
    }
  }

  grid!: DatagridComponent

  params!: ApiDataParameters

  constructor(private readonly reportsService: ReportsService, private readonly store: Store<RootState>) {}

  ngOnInit(): void {
    if (this.reportType !== 'matchedItems') {
      this.reportDefinition.fields = [
        ...(this.reportDefinition.fields || []),
        'ExceptionNotes',
        'ExceptionCreationDate',
        'ExceptionExpirationDate',
      ]
      this.reportDefinition.lockedColumns = [
        ...(this.reportDefinition.lockedColumns || []),
        'ExceptionNotes',
        'ExceptionCreationDate',
        'ExceptionExpirationDate',
      ]
    }
    if (this.policy.policyType === 'EventBased') {
      this.reportDefinition.operationsColumns = []
    }
  }

  matchedItemsFilters = () => ({
    [this.policy.matchedReportFilters?.key || '']: this.policy.matchedReportFilters?.value || '',
    ...this.policy.reportFilters,
  })
  exceptionItemsFilters = () => ({
    [this.policy.exceptionReportFilters?.key || '']: this.policy.exceptionReportFilters?.value || '',
    ...this.policy.reportFilters,
  })
  expiredExceptionItemsFilters = () => ({
    [this.policy.expiredExceptionReportFilters!.key]: this.policy.expiredExceptionReportFilters!.value,
    ...this.policy.reportFilters,
  })

  getItems = (params: ApiDataParameters) => {
    if (!!this.policy?.urlParameters) {
      Object.assign(params, this.policy.urlParameters)
    }

    this.params =
      this.reportType === 'matchedItems'
        ? params
        : {
            url: 'api/' + this.reportDefinition.url,
            verb: this.reportDefinition.verb,
            policyId: this.policy.id,
            responseItem: this.reportDefinition.responseItemProp,
            entityIdField: this.reportDefinition.entityIdField,
            request: params,
          }
    return {
      items:
        this.reportType === 'matchedItems'
          ? this.reportsService.getData(this.reportDefinition.url!, this.reportDefinition.verb, params)
          : this.reportsService.getData('/playbook/policies/queryExceptions', 'post', this.params),
      cols: this.reportDefinition.isOnlineUsersType
        ? this.store.select(selectOnlineuserColumns).pipe(map((cols) => cloneDeep(cols)))
        : of([]),
    }
  }
}
