import { ColDef, GridOptions, IServerSideGetRowsParams } from '@ag-grid-community/core';
import { Component, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component';
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper';
import { RootState } from '@app/store/RootState.type';
import * as Actions from '@app/store/msp-multitenant-dashboard/msp-multitenant-dashboard.actions'
import { BreadcrumbService, TranslateHelper } from '@coreview/coreview-library';
import { ReportsService } from '@app/core/services/reports.service'
import { ApiDataGroupParameters, ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { SelectionAction } from '@coreview/coreview-library/models/ReportDefinition';
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { Store } from '@ngrx/store';
import { combineLatest, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Verb } from '@coreview/coreview-library/models/PageDataCommonClasses';
import { NavItem } from '@coreview/coreview-library/models/nav-item'
import { selectFavorites, selectFlatMenusAll } from '@app/store/menu/menu.selectors';
import { MspMultiTenantDashboardType } from '../msp-multitenant-dashboard-left-panel/msp-multitenant-dashboard-left-panel.component';
import { Helpers } from '@app/shared/utilities/helpers';
import { AdministrationsService } from '@app/core/services/administrations.service';
import { Constants } from '@app/shared/utilities/constants'

@Component({
  selector: 'app-msp-multitenant-dashboard',
  templateUrl: './msp-multitenant-dashboard.component.html',
  styleUrls: ['./msp-multitenant-dashboard.component.sass']
})
export class MspMultitenantDashboardComponent implements OnInit, OnDestroy {
  @ViewChild(DatagridComponent)
  set setGrid(grid: DatagridComponent) {
    this.grid = grid
  }
  grid!: DatagridComponent

  reportTitle = ''
  reportDescription = ''

  reportMode:
    | MspMultiTenantDashboardType.GroupedByTenant
    | MspMultiTenantDashboardType.GroupedByPlaybook = MspMultiTenantDashboardType.GroupedByTenant

  reportDefinition!: ReportDefinition
  autoGroupColumnDef!: ColDef
  gridDefinition!: ReportDefinition
  groupedActions!: SelectionAction[]
  areAllRowsExpanded = false
  shouldExpandCollapseNextModelUpdate = false

  menu?: NavItem
  metadata!: any

  defaultColumnDef: ColDef = {
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: 150,
  }

  commonGroupedReportsGridOptions!: GridOptions

  private readonly hiddenCols = [
    {
      name: 'playbookTitle',
      translate: 'PlaybookTitle',
      type: 'string'
    },
    {
      name: 'id',
      translate: 'Id',
      type: 'string',
    },
    {
      name: 'title',
      translate: 'Title',
      type: 'string',
    },
    {
      name: 'companyName',
      translate: 'CompanyName',
      type: 'string',
    }
  ]

  private readonly route!: string
  private readonly destroyed$: Subject<boolean> = new Subject()

  constructor(
    private readonly administrationsService: AdministrationsService,
    private readonly reportsService: ReportsService,
    public readonly reportsComponentHelper: ReportsComponentHelper,
    private readonly translateHelper: TranslateHelper,
    private readonly store: Store<RootState>,
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    this.reportTitle = this.translateHelper.instant('reports_MspMultiTenantDashboard')
    this.geData(null as any)
    this.setCommonGroupedReportsGridOptions()
  }

  ngOnInit(): void {
    this.loadMenuInfo()

    this.groupedActions = []

    this.setGridDefinitions()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reportMode && changes.reportMode.currentValue !== changes.reportMode.previousValue) {
      this.areAllRowsExpanded = true
    }
  }

  ngOnDestroy(): void {
    this.breadcrumbService.clear()
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  setGridDefinitions() {
    this.gridDefinition = {
      entity: 'MspMultitenantDashboard_' + this.reportMode,
      title: 'notused',
      fields: [ 'Status', 'Exceptions', 'Failures'],
      sortedFields: [],
      defaultHiddenFields: ['Title', 'PlaybookTitle', 'CompanyName', 'Id', 'Group', 'Greens', 'Yellows', 'Reds'],
      lockedColumns: [],
      entityIdField: 'uniqueName',
      sortField: 'Name',
      sortOrder: 'asc',
      verb: 'post' as Verb,
      responseItemProp: 'policies',
      filterActions: [],
      groupedActions: this.groupedActions,
      rowSelection: 'none',
      operationsOnCols: [],
      gridOptions: {
        ...this.commonGroupedReportsGridOptions,
      },
      operationsColumns: [
        ...this.getHiddenCols(),
        {
          type: 'undefined',
          name: 'open_in_new',
          translate: 'open_in_new',
          originalName: 'open_in_new',
          position: -5,
          notSelectable: true,
          agColDef: {
              ...Constants.defaultOperationColumnsDefinition,
            colId: 'openCard',
            pinned: 'left',
            lockPinned: true,
            hide: false,
            cellRenderer: 'iconButtonRenderer',
            cellRendererParams: {
              hideIcon: (rowData: any, node: any) => this.hideIcon(rowData, node),
              icon: 'open_in_new',
              tooltipKey: 'common_OpenTenantInNewTab',
              onClick: (event: any): void => {
                const url = this.reportMode === MspMultiTenantDashboardType.GroupedByPlaybook ? '/governance?playbookId=' + event.rowData.uniqueName.split('_')[0] + '&' : '?'
                window.open(url + 'selectedTenant=' + event.rowData.companyId, '_blank')
              },
            },
          },
        },
        {
          originalName: 'status',
          name: 'status',
          translate: 'PolicyViolations',
          type: 'string',
          filter: { type: 'string', name: 'status' },
          agColDef: {
            filter: false,
            cellRendererSelector: (params) => {
              return {
                component: 'iconRenderer',
              }
            },
            cellRendererParams: {
              showValue: true,
              isMultipleIcons: true,
              getIcons: (node: any) => this.getIcons(node),
            },
          },
        },
        {
          originalName: 'exceptions',
          name: 'exceptions',
          translate: 'Exceptions',
          type: 'number',
          agColDef: {
            filter: false,
            valueGetter: (params) => {
              return params?.node?.level === 2 ? params.data.exceptions : null;
            },
            cellRendererSelector: (params) => {
              return {
                component: 'iconRenderer',
              }
            },
            cellRendererParams: {
              showValue: true,
              getIcon: (event: any) => {
                if (event.exceptions) {
                  return 'not_interested'
                }
                return ''
              },
              getIconClass: (event: any) => 'material-icons-outlined exceptions',
            },
          },
        },
        {
          originalName: 'failures',
          name: 'failures',
          translate: 'Failures',
          type: 'number',
          agColDef: {
            filter: false,
            cellRendererSelector: (params) => {
              return {
                component: 'iconRenderer',
              }
            },
            cellRendererParams: {
              showValue: true,
              getIcon: (event: any) => {
                if (event.failures && event.failures > 0) {
                  return 'error';
                }
                return ''
              },
              getIconClass: (event: any) => 'material-icons-outlined failures',
             },
          },
        },
        {
          originalName: 'severity',
          name: 'severity',
          translate: 'Severity',
          type: 'string'
        },
        {
          originalName: 'isRemediationEnabled',
          name: 'isRemediationEnabled',
          translate: 'PolicyEnabled',
          type: 'string',
          agColDef: {
            valueGetter: (params) => {
              return params?.node?.level === 2 ? params.data.isRemediationEnabled : null;
            },
            cellRenderer: (params: any) => {
              return params?.node?.level === 2 ? params.value : '';
            }
          }
        },
        {
          originalName: 'remediationType',
          name: 'remediationType',
          translate: 'RemediationType',
          type: 'string'
        },
        {
          originalName: 'isSchedulingEnabled',
          name: 'isSchedulingEnabled',
          translate: 'WorkflowScheduled',
          type: 'string',
          agColDef: {
            valueGetter: (params) => {
              return params?.node?.level === 2 ? params.data.isRemediationEnabled : null;
            },
            cellRenderer: (params: any) => {
              if (params.node.expanded) {
                return '';
              }
              return params?.node?.level === 2 ? params.value : '';
            }
          }
        },
      ],
    }

    this.autoGroupColumnDef = {
      headerName: this.translateHelper.instant('common_' + this.reportMode),
      field: 'name',
      sortable: false,
      suppressMenu: true,
      lockPosition: true,
      floatingFilter: false
    }
  }

  private readonly getIcon = (status: string, value?: string) => {
    if(!status || (value && parseInt(value) === 0)) {
      return ''
    }
    else if (status === 'greens' || status === 'green') {
      return 'check_circle'
    }
    else {
      return 'warning'
    }
  }

  private readonly getIconClass = (status: string) => {
    if(!status) {
      return ''
    }
    else if (status === 'greens' || status === 'green') {
      return 'status-success'
    }
    else if (status === 'yellows' || status === 'yellow') {
      return 'status-warning'
    }
    else {
      return 'status-error'
    }
  }

  private readonly getColor = (status: string) => {
    if(!status) {
      return ''
    }
    else if (status === 'greens' || status === 'green') {
      return 'green'
    }
    else if (status === 'yellows' || status === 'yellow') {
      return 'yellow'
    }
    else {
      return 'red'
    }
  }

  private readonly getValue = (value: string) => {
    return parseInt(value) ? parseInt(value) : ''
  }

  private readonly hideIcon = (rowData: any, node: any) => {
    return this.reportMode === MspMultiTenantDashboardType.GroupedByTenant && node.level !== 0 ||
      this.reportMode === MspMultiTenantDashboardType.GroupedByPlaybook && node.level !== 1
  }

  getDataGrouped = (
    params: ApiDataGroupParameters,
    dataCenterUrl?: string,
    serverSideParams?: IServerSideGetRowsParams
  ): any => {
    if (params.groupParameters?.length) {
      const data = serverSideParams?.parentNode.data
      return {
        items: of({
          policies: params.groupParameters.length === 1 ? this.processDataChildren(data) : this.processDataChildrenSecondLevel(data, params),
          metadata: { ...this.metadata, totalCount: data.group.length },
          configs: this.grid.serverCols,
        }),
        cols: null,
      }
    } else {
      return {
        items: this.geData(params).pipe(
          map((res: any) => {
            if (res?.policies?.length) {
              this.processDataItems(res.policies)
            }
            return res
          })
        ),
        cols: null,
      }
    }
  }

  hasGroupedInfo(dataItem: any): boolean {
    return !!dataItem.group?.length
  }

  getGroupKey = (dataItem: any) => {
    return dataItem.name
  }

  toggleExpandCollapseAll = (expand?: boolean) => {
    if(expand !== undefined) {
      this.areAllRowsExpanded = expand
    }

    if (this.areAllRowsExpanded) {
      this.grid.gridApi.expandAll()
    } else {
      this.grid.gridApi.collapseAll()
    }

    if (this.reportMode === MspMultiTenantDashboardType.GroupedByTenant) {
      this.store.dispatch(Actions.setGroupedByTenantsAreAllRowsExpanded({ areExpanded: this.areAllRowsExpanded }))
    } else {
      this.store.dispatch(Actions.setGroupedByPlaybooksAreAllRowsExpanded({ areExpanded: this.areAllRowsExpanded }))
    }
  }

  onGridReady() {
    this.grid?.gridApi.sizeColumnsToFit()
  }

  onReportTypeChange(reportType: MspMultiTenantDashboardType) {
    this.reportMode = reportType
    this.shouldExpandCollapseNextModelUpdate = true

    this.gridDefinition = undefined as any
    setTimeout(() => {
      this.setCommonGroupedReportsGridOptions()
      this.setGridDefinitions()
    }, 1)
  }

  private getHiddenCols() {
    return this.hiddenCols.map((x: any) => ({
      ...x,
      originalName: x.name,
      notSelectable: true,
      agColDef: {
        hide: true,
      }
    }))
  }

  private setCommonGroupedReportsGridOptions() {
    this.commonGroupedReportsGridOptions = this.reportsService.getCommonGroupedReportsGridOptions(
      this.defaultColumnDef,
      this.toggleExpandCollapseAll,
      this.shouldExpandCollapseNextModelUpdate,
      this.grid
    )

    this.commonGroupedReportsGridOptions.defaultColDef = {
      ...this.commonGroupedReportsGridOptions.defaultColDef,
      floatingFilter: true
    }
  }

  private processDataItems(data: any[]) {
    return data.forEach((x: any) => {
      x.name = this.reportMode === MspMultiTenantDashboardType.GroupedByTenant ? x.companyName : this.translateHelper.instant(x.playbookTitle)
      x.uniqueName = this.reportMode === MspMultiTenantDashboardType.GroupedByTenant ? x.companyId : x.playbookId
    })
  }

  private processDataChildren(data: any) {
    return data.group.map((x: any) => ({
      ...x,
      name:  this.reportMode === MspMultiTenantDashboardType.GroupedByTenant ? this.translateHelper.instant(x.playbookTitle) : x.companyName,
      uniqueName: `${this.reportMode === MspMultiTenantDashboardType.GroupedByTenant ? (data.companyId + '_' + x.playbookId) : (data.playbookId + '_' + x.companyId)}`,
    }))
  }

  private processDataChildrenSecondLevel(data: any, params: ApiDataGroupParameters) {
    return data.group.map((x: any) => ({
      ...x,
      name: x.title,
      uniqueName: `${params.groupParameters![0]}_${params.groupParameters![1]}_${x.id}`,
    }))
  }

  private geData(paramsToPass: ApiDataParameters): any {
    this.reportDefinition = {
      title: this.reportTitle + '_Report',
      fields: [],
      responseItemProp: '',
      sortField: '',
      actionsTags: ['preview'],
      sortOrder: null,
      verb: 'post',
      url: '/partners/playbookpolicies/' + (this.reportMode === MspMultiTenantDashboardType.GroupedByTenant ? "bytenant" : "byplaybook"),
    }

    return this.administrationsService.getMspMultitenantDashboardData(paramsToPass, this.reportMode)
  }

  private subscribeToMenuAndFavorites() {
    return combineLatest([this.store.select(selectFlatMenusAll), this.store.select(selectFavorites)]).pipe(takeUntil(this.destroyed$))
  }

  private loadMenuInfo() {
    this.subscribeToMenuAndFavorites().subscribe(([{ flatMenuAll }, favorites]) => {
        this.menu = flatMenuAll.find((x: NavItem) => {
          const urlToFind = this.route
          return '/' + x.route === urlToFind
        })
        if (this.menu) {
          this.setIsFavorite(favorites)
          this.breadcrumbService.updatePath([...this.getPath(), this.reportTitle || '-'])
        } else {
          this.breadcrumbService.updatePath([this.translateHelper.instant('common_MultiTenantHub'), this.reportTitle || '-'])
        }
      })
  }

  private setIsFavorite(favorites: any) {
    if (this.menu) {
        this.menu.isFavorite = !!favorites.favoriteMenus.find((x: any) => this.menu?.id === x)
    }
  }

  private getPath(): string[] {
    return Helpers.getPath(this.menu?.parent, this.translateHelper)
  }

  private getIcons(node: any) {
    const data = node.data
    if(node.expanded || !data.status) {
      return []
    }

    if(!data.group) {
      return [{
          getIcon: () => this.getIcon(data.status.trim().toLowerCase()),
          getIconClass: () => this.getIconClass(data.status.trim().toLowerCase()),
          getColor: () => this.getColor(data.status.trim().toLowerCase()),
          getValue: () => '',
        }
      ]
    }

    const icons: any[] = []

    data.status.split(',').reverse().forEach((keyValueStatus: string) => {
      const status = keyValueStatus.split(':')[0].trim().toLowerCase()
      const value = keyValueStatus.split(':')[1].trim()

      icons.push({
        getIcon: () => this.getIcon(status, value),
        getIconClass: () => this.getIconClass(status),
        getColor: () => this.getColor(status),
        getValue: () =>  this.getValue(value),
      })
    })

    return icons
  }
}
