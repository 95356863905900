import { Component, Input, OnInit, Optional, Self, OnDestroy } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, NgControl, ValidatorFn, Validators } from '@angular/forms'
import { SuggesterService } from '@app/core/services/suggester.service'
import { BaseControlComponent, Suggestion } from '@coreview/coreview-components'
import { Subject } from 'rxjs'
import { debounceTime, delay, distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { isEqual } from 'lodash-es'
import { TranslateHelper } from '@coreview/coreview-library'
import { FormHelpers } from '@app/shared/utilities/form-helpers'

@Component({
  selector: 'app-auto-attendant-redirection',
  templateUrl: './auto-attendant-redirection.component.html',
  styleUrls: ['./auto-attendant-redirection.component.sass'],
})
export class AutoAttendantRedirectionComponent extends BaseControlComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  public static readonly displayValueMapping: Record<AutoAttendantRedirectionTypes, string> = {
    Disconnect: 'Disconnect',
    UserOperator: 'Operator',
    User: 'PersonInOrganization',
    ApplicationEndpoint: 'VoiceApp',
    ExternalPstn: 'ExternalPhoneNumber',
    SharedVoicemail: 'Voicemail',
    AnnouncementAudio: 'management_AnnouncementAudio',
    AnnouncementText: 'management_AnnouncementText',
  }
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() redirectionTypes!: string[]
  @Input() customDisplayValueMapping?: Record<AutoAttendantRedirectionTypes, string>
  @Input()
  get value(): { type: string; destination: any; enableTranscription?: boolean; enableSharedVoicemailSystemPromptSuppression?: boolean } {
    return this.form.getRawValue()
  }

  set value(model: any) {
    if (!!model.type) {
      this.form.patchValue(model)
      this.form.markAllAsTouched()
      this.form.updateValueAndValidity()
    }
  }
  @Input() label = 'RedirectTo'

  form = new UntypedFormGroup({
    type: new UntypedFormControl('', [Validators.required]),
    destination: new UntypedFormControl('', [
      FormHelpers.conditionalValidator(
        (): boolean => this.form.get('type')?.value !== 'Disconnect',
        Validators.required
      ),
    ]),
    enableTranscription: new UntypedFormControl(),
    enableSharedVoicemailSystemPromptSuppression: new UntypedFormControl(),
  })

  typesSuggestions: Suggestion[] = []
  destinationSuggestions: Suggestion[] = []
  destinationUpdate = new Subject<string>()

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private suggesterService: SuggesterService,
    private translateHelper: TranslateHelper,
    @Optional() @Self() public ngControl: NgControl
  ) {
    super(ngControl)

    this.destinationUpdate.pipe(debounceTime(400), distinctUntilChanged()).subscribe((value) => {
      this.getDestinationSuggest(value)
    })
  }

  ngOnInit(): void {
    this.typesSuggestions = this.redirectionTypes.map((x) => ({
      value: x,
      displayValue: this.translateHelper.instant(
        !!this.customDisplayValueMapping && !!this.customDisplayValueMapping[x as AutoAttendantRedirectionTypes]
          ? this.customDisplayValueMapping[x as AutoAttendantRedirectionTypes]
          : AutoAttendantRedirectionComponent.displayValueMapping[x as AutoAttendantRedirectionTypes]
      ),
    }))

    this.form.valueChanges.pipe(delay(1), takeUntil(this.destroyed$)).subscribe(() => {
      this.onChange(this.value)
    })

    this.form.statusChanges.pipe(delay(1), distinctUntilChanged(isEqual), takeUntil(this.destroyed$)).subscribe(() => {
      if (this.ngControl?.control) {
        if (!this.form.valid) {
          this.ngControl.control.addValidators(this.setError)
        } else {
          this.ngControl.control.removeValidators(this.setError)
        }
        this.ngControl.control.updateValueAndValidity()
      }
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next()
    this.destroyed$.complete()
  }

  setError = () => ({
    error: true,
  })

  writeValue(value: any): void {
    if (value) {
      this.value = value
    }
  }

  typeChanged() {
    this.form.get('destination')?.setValue('')
    this.form.get('destination')?.updateValueAndValidity()
  }

  getDestinationSuggest = (value: string | null) => {
    this.destinationSuggestions = []
    if (value) {
      const destinationType = this.form.get('type')?.value
      const apiToCall =
        destinationType === 'SharedVoicemail'
          ? this.suggesterService.getTeamsVoicemailRedirectionSuggest({
              searchedString: value,
              pageSize: 5,
            })
          : this.suggesterService.getTeamsPersonVoiceAppRedirectionSuggest({
              pageSize: 5,
              redirectType: destinationType === 'User' ? 'person' : 'voice',
              search: value,
            })
      apiToCall.subscribe((suggestions: any) => {
        this.destinationSuggestions =
          destinationType === 'SharedVoicemail'
            ? suggestions.map((s: any) => ({
                value: s.identity,
                displayValue: s.name,
                otherDisplayValue: parseInt(s.groupType) === 0 ? 'DistributionGroup' : parseInt(s.groupType) === 1 ? 'Office365Group' : 'SecurityGroup',
              }))
            : suggestions.map((s: any) => ({
                value: s.objectId,
                displayValue: s.userPrincipalName,
              }))
      })
    }
  }
}

export type AutoAttendantRedirectionTypes =
  | 'Disconnect'
  | 'UserOperator'
  | 'User'
  | 'ApplicationEndpoint'
  | 'ExternalPstn'
  | 'SharedVoicemail'
  | 'AnnouncementAudio'
  | 'AnnouncementText'
