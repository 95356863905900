import { KeysPipe } from './pipes/keys.pipe'
import { EditCellRendererComponent } from './components/datagrid/edit-renderer/edit-cell-renderer.component'
import { IconMenuButtonRendererComponent } from './components/icon-menu-button-renderer/icon-menu-button-renderer.component'
import { TooltipRendererComponent } from './components/datagrid/tooltip-renderer/tooltip-renderer.component'
import { CustomLinkRendererComponent } from './components/client-datagrid/custom-link-renderer/custom-link-renderer.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { MaterialModule } from '@core/material/material.module'
import { CoreViewComponentsModule } from '@coreview/coreview-components'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { ClientDatagridComponent } from './components/client-datagrid/client-datagrid.component'
import { DictionaryCellRendererComponent } from './components/datagrid/dictionary-cell-renderer/dictionary-cell-renderer.component'
import { LinkCellRendererComponent } from './components/datagrid/link-cell-renderer/link-cell-renderer.component'
import { ColumnsSelectorComponent } from './components/columns-selector/columns-selector.component'
import { TreeViewModule } from '@shared/components/tree-view/tree-view.module'
import { IconButtonRendererComponent } from './components/client-datagrid/icon-button-renderer/icon-button-renderer.component'
import { ActionCellRendererComponent } from './components/datagrid/action-cell-renderer/action-cell-renderer.component'
import { IconDialogButtonRendererComponent } from './components/icon-dialog-button-renderer/icon-dialog-button-renderer.component'
import { BaseDialogComponent } from './components/base-dialog/base-dialog.component'
import { AgGridModule } from '@ag-grid-community/angular'
import { DatagridActionsComponent } from './components/datagrid-actions/datagrid-actions.component'
import { PaginationComponent } from './components/pagination/pagination.component'
import { HighchartsChartModule } from 'highcharts-angular'
import { SelectAllHeaderComponent } from './components/datagrid/headers/select-all-header/select-all-header.component'
import { TooltipModule, TooltipOptions } from '@cloudfactorydk/ng2-tooltip-directive'
import { GenericFloatingFilterComponent } from './components/datagrid/filters/generic-floating-filter/generic-floating-filter.component'
import { DateFilterComponent } from './components/datagrid/filters/date-filter/date-filter.component'
import { SortByPipe } from '@shared/pipes/sort-by/sort-by.pipe'
import { IconRendererComponent } from './components/datagrid/icon-renderer/icon-renderer.component'
import { CustomDetailComponent } from './components/datagrid/custom-detail/custom-detail.component'
import { TooltipHeaderComponent } from './components/datagrid/headers/tooltip-header/tooltip-header.component'
import { SingleSelectFilterComponent } from './components/datagrid/filters/single-select-filter/single-select-filter.component'
import { MultiSelectFilterComponent } from './components/datagrid/filters/multi-select-filter/multi-select-filter.component'
import { ArrayCellRendererComponent } from './components/datagrid/array-cell-renderer/array-cell-renderer.component'
import { FilterPipe } from './pipes/filter/filter.pipe'
import { RangeFilterComponent } from './components/range-filter/range-filter.component'
import { GenericDropdownFilterComponent } from './components/generic-dropdown-filter/generic-dropdown-filter.component'
import { FavoriteComponent } from './components/favorite/favorite.component'
import { SummaryCardComponent } from './components/summary-card/summary-card.component'
import { ChartSeriesSelectorComponent } from './components/chart-series-selector/chart-series-selector.component'
import { PanelStepsComponent } from './components/panel-steps/panel-steps.component'
import { BadgeRendererComponent } from './components/datagrid/badge-renderer/badge-renderer.component'
import { TaskTitleRendererComponent } from './components/datagrid/task-title-renderer/task-title-renderer.component'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { DayjsDatePipe } from './pipes/dayjs-date.pipe'
import { LoadingCellRendererComponent } from './components/datagrid/loading-cell-renderer/loading-cell-renderer.component'
import { DatetimeUtcPickerComponent } from './components/datetime-utc-picker/datetime-utc-picker.component'
import { CardBadgeComponent } from './components/cards/card-badge/card-badge.component'
import { CustomWidgetComponent } from './components/cards/custom-widget/custom-widget.component'
import { DatetimeUtcRangePickerComponent } from './components/datetime-utc-range-picker/datetime-utc-range-picker.component'
import { CustomizableChartComponent } from './components/cards/customizable-chart/customizable-chart.component'
import { DataListComponent } from './components/cards/data-list/data-list.component'
import { DataPointComponent } from './components/cards/data-point/data-point.component'
import { CustomizeParamsDialogComponent } from './components/customize-params-dialog/customize-params-dialog.component'
import { ClientDataGridDialogComponent } from './components/client-data-grid-dialog/client-data-grid-dialog.component'
import { TargetSelectionComponent } from './components/target-selection/target-selection.component'
import { ChipCellRendererComponent } from './components/datagrid/chip-cell-renderer/chip-cell-renderer.component'
import { ScoreComponent } from './components/cards/score/score.component'
import { ObjectCellRenderComponent } from './components/datagrid/object-cell-render/object-cell-render.component'
import { OrganizationalUnitPickerComponent } from './components/organizational-unit-picker/organizational-unit-picker.component'
import { RecurrenceComponent } from './components/recurrence/recurrence.component'
import { BooleanRendererComponent } from './components/datagrid/boolean-renderer/boolean-renderer.component'
import { LicenseTemplateDetailComponent } from './components/license-template-detail/license-template-detail.component'
import { DatagridDialogComponent } from './components/datagrid-dialog/datagrid-dialog.component'
import { LightReadonlyTreeviewComponent } from './components/light-readonly-treeview/light-readonly-treeview.component'
import { CheckRowSpanDirective } from './directives/check-rowSpan.directive'
import { TruncatePipe } from './pipes/truncate.pipe'
import { ActionPanelComponent } from './components/action-panel/action-panel.component'
import { SubmitErrorMessageDetailComponent } from './components/action-panel/submit-error-message-detail/submit-error-message-detail.component'
import { CoreviewDynamoformsModule } from '@coreview/coreview-dynamoforms'
import { LoadingReportSkeletonComponent } from './components/skeletons/loading-report-skeleton/loading-report-skeleton.component'
import { LoadingPanelSkeletonComponent } from './components/skeletons/loading-panel-skeleton/loading-panel-skeleton.component'
import { LoadingFormSkeletonComponent } from './components/skeletons/loading-form-skeleton/loading-form-skeleton.component'
import { LicensesPickerComponent } from './components/licenses-picker/licenses-picker.component'
import { LoadingTreeViewSkeletonComponent } from './components/skeletons/loading-tree-view-skeleton/loading-tree-view-skeleton.component'
import { DefaultFilterIndicatorComponent } from './components/default-filter-indicator/default-filter-indicator.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { LoadingPivotSkeletonComponent } from './components/skeletons/loading-pivot-skeleton/loading-pivot-skeleton.component'
import { EditableCellRendererComponent } from './components/datagrid/editable-cell-renderer/editable-cell-renderer.component'
import { PersistableCellEditorComponent } from './components/datagrid/persistable-cell-editor/persistable-cell-editor.component'
import { ReportIndicatorComponent } from './components/report-indicator/report-indicator.component'
import { VTenantIndicatorComponent } from './components/v-tenant-indicator/v-tenant-indicator.component'
import { FlatDictionaryCellRendererComponent } from './components/datagrid/flat-dictionary-cell-renderer/flat-dictionary-cell-renderer.component'
import { LoadingDashboardSkeletonComponent } from './components/skeletons/loading-dashboard-skeleton/loading-dashboard-skeleton.component'
import { MarkdownViewerComponent } from './components/markdown-viewer/markdown-viewer.component'
import { CustomReportsListComponent } from './components/custom-reports-list/custom-reports-list.component'
import { SwitchRendererComponent } from './components/datagrid/switch-renderer/switch-renderer.component'
import { NumberEditableCellRendererComponent } from './components/datagrid/number-editable-renderer/number-editable-renderer.component'
import { LibraryModule } from '@coreview/coreview-library'
import { DropdownSelectorComponent } from './components/dropdown-selector/dropdown-selector.component'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { ArrayLinkCellRendererComponent } from './components/datagrid/array-link-cell-renderer/array-link-cell-renderer.component'
import { NewCustomPolicyComponent } from '@app/modules/playbook/components/new-custom-policy/new-custom-policy.component'
import { ClampDirective } from './directives/clamp.directive'
import { WizardComponent } from './components/wizard/wizard.component'
import { WizardSummary } from './components/wizard-summary/wizard-summary.component';
import { DialogWithCheckboxComponent } from './components/dialog-with-checkbox/dialog-with-checkbox.component'

/**
 * Exports reusable shared components, pipes, filters and services.
 * The SharedModule should be imported in any other one when those items will be re-used.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const CvDefaultTooltipOptions: TooltipOptions = {
  hideDelay: 0,
  showDelay: 0,
  animationDuration: 0,
}

@NgModule({
  declarations: [
    DatagridComponent,
    ClientDatagridComponent,
    LinkCellRendererComponent,
    ColumnsSelectorComponent,
    IconButtonRendererComponent,
    ActionCellRendererComponent,
    BaseDialogComponent,
    IconDialogButtonRendererComponent,
    IconMenuButtonRendererComponent,
    DatagridActionsComponent,
    PaginationComponent,
    DictionaryCellRendererComponent,
    FlatDictionaryCellRendererComponent,
    SelectAllHeaderComponent,
    GenericFloatingFilterComponent,
    DateFilterComponent,
    SortByPipe,
    IconRendererComponent,
    CustomDetailComponent,
    CustomLinkRendererComponent,
    TooltipHeaderComponent,
    SingleSelectFilterComponent,
    MultiSelectFilterComponent,
    ArrayCellRendererComponent,
    ArrayLinkCellRendererComponent,
    FilterPipe,
    RangeFilterComponent,
    GenericDropdownFilterComponent,
    FavoriteComponent,
    CheckRowSpanDirective,
    BadgeRendererComponent,
    TaskTitleRendererComponent,
    EditCellRendererComponent,
    TooltipRendererComponent,
    SummaryCardComponent,
    ChartSeriesSelectorComponent,
    PanelStepsComponent,
    DayjsDatePipe,
    KeysPipe,
    LoadingCellRendererComponent,
    DatetimeUtcPickerComponent,
    CardBadgeComponent,
    CustomWidgetComponent,
    DatetimeUtcRangePickerComponent,
    DataPointComponent,
    DataListComponent,
    CustomizableChartComponent,
    CustomizeParamsDialogComponent,
    ClientDataGridDialogComponent,
    TargetSelectionComponent,
    ChipCellRendererComponent,
    ScoreComponent,
    ObjectCellRenderComponent,
    OrganizationalUnitPickerComponent,
    RecurrenceComponent,
    BooleanRendererComponent,
    LicenseTemplateDetailComponent,
    DatagridDialogComponent,
    LightReadonlyTreeviewComponent,
    TruncatePipe,
    ActionPanelComponent,
    SubmitErrorMessageDetailComponent,
    LoadingReportSkeletonComponent,
    LoadingPanelSkeletonComponent,
    LoadingFormSkeletonComponent,
    LicensesPickerComponent,
    LoadingTreeViewSkeletonComponent,
    LoadingDashboardSkeletonComponent,
    DefaultFilterIndicatorComponent,
    LoadingPivotSkeletonComponent,
    EditableCellRendererComponent,
    PersistableCellEditorComponent,
    ReportIndicatorComponent,
    VTenantIndicatorComponent,
    MarkdownViewerComponent,
    CustomReportsListComponent,
    SwitchRendererComponent,
    NumberEditableCellRendererComponent,
    DropdownSelectorComponent,
    NewCustomPolicyComponent,
    ClampDirective,
    WizardComponent,
    WizardSummary,
    DialogWithCheckboxComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    CoreViewComponentsModule,
    AgGridModule,
    RouterModule,
    //AgGridModule.withComponents([DatagridComponent, ClientDatagridComponent, DictionaryCellRendererComponent]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TreeViewModule,
    HighchartsChartModule,
    TooltipModule.forRoot(CvDefaultTooltipOptions as TooltipOptions),
    CoreviewDynamoformsModule,
    OverlayModule,
    LibraryModule,
    DragDropModule,
  ],
  exports: [
    DatagridComponent,
    ClientDatagridComponent,
    TreeViewModule,
    BaseDialogComponent,
    SortByPipe,
    FilterPipe,
    DayjsDatePipe,
    KeysPipe,
    RangeFilterComponent,
    GenericDropdownFilterComponent,
    FavoriteComponent,
    CheckRowSpanDirective,
    SummaryCardComponent,
    PanelStepsComponent,
    DatetimeUtcPickerComponent,
    CardBadgeComponent,
    CustomWidgetComponent,
    DatetimeUtcRangePickerComponent,
    DataPointComponent,
    DataListComponent,
    CustomizableChartComponent,
    CustomizeParamsDialogComponent,
    ClientDataGridDialogComponent,
    TargetSelectionComponent,
    IconMenuButtonRendererComponent,
    ScoreComponent,
    OrganizationalUnitPickerComponent,
    RecurrenceComponent,
    LicenseTemplateDetailComponent,
    LightReadonlyTreeviewComponent,
    LoadingReportSkeletonComponent,
    LoadingPanelSkeletonComponent,
    LoadingFormSkeletonComponent,
    LicensesPickerComponent,
    LoadingTreeViewSkeletonComponent,
    LoadingDashboardSkeletonComponent,
    DefaultFilterIndicatorComponent,
    LoadingPivotSkeletonComponent,
    ReportIndicatorComponent,
    VTenantIndicatorComponent,
    MarkdownViewerComponent,
    CustomReportsListComponent,
    NumberEditableCellRendererComponent,
    CoreviewDynamoformsModule,
    DropdownSelectorComponent,
    NewCustomPolicyComponent,
    ClampDirective,
    WizardComponent,
    DialogWithCheckboxComponent
  ],
})
export class SharedModule {
  constructor() {
    LicenseManager.setLicenseKey(
      'Using_this_{AG_Grid}_Enterprise_key_{AG-056126}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Coreview_Usa,_Inc.}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{5_May_2025}____[v3]_[01]_MTc0NjM5OTYwMDAwMA==59af030dd47e6b301b744a58ba486152'
    )
  }
}
