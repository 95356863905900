/* eslint-disable @typescript-eslint/naming-convention, no-shadow */
export enum SearchTypeEnum {
  Empty = 'Empty',
  Empty2 = 'Empty2',
  Users = 'Users',
  DistributionGroups = 'DistributionGroups',
  SecurityGroups = 'SecurityGroups',
  Office365Groups = 'Office365Groups',
  TeamsGroups = 'TeamsGroups',
  Actions = 'Actions',
  Reports = 'Reports',
  Playbooks = 'Playbooks'
}

export enum SearchTypeShortcutEnum {
  FavoriteReports = 'FavouritesReports', // not a typo, this is for translation
  PinnedActions = 'PinnedActions',
  PinnedWorkflows = 'PinnedWorkflows'
}
