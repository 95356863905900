<header>
  <h3 class="management-panel-title" translate>common_ManagementSessionConfiguration</h3>
  <cv-close-button (click)="handleClose()" cv-data-test="close-management-panel"></cv-close-button>
</header>
<div class="management-panel-container">
  <div class="management-panel-content" *ngIf="!loading">
    <cv-alert-bar *ngIf="banner" [variant]="banner.type" [title]="banner.title | translate" [hideCloseButton]="true">
      <div class="management-panel-alertbar" [innerHTML]="banner.content | translate : { errorData: createErrorData }"></div>
    </cv-alert-bar>
  </div>

  <div class="management-panel-loading" *ngIf="isLoading">
    <img src="../assets/img/loading.svg" alt="loading" />
  </div>

  <ng-container *ngIf="isAdmin() && !isLoading">
    <ng-container *ngIf="status === 'Disabled'">
      <div *ngIf="createErrorData" class="management-panel-content">
        <div class="management-panel-sa">
          <p translate>common_CoreviewRegistration</p>
          <cv-button
            buttonType="primary"
            rightIcon="file_copy"
            (click)="copyConsentsUrl()"
            [text]="'common_CopyLinkToClipboard' | translate"
          >
          </cv-button>
        </div>
        <cv-button
          class="m-t-20"
          [text]="'common_ManagementRetryServiceAccountCreation' | translate"
          buttonType="primary"
          leftIcon="refresh"
          (click)="retryServiceAccountCreation($event)"
        ></cv-button>
      </div>
      <ng-container *ngIf="!createErrorData">
        <div class="management-panel-content management-panel-option">
          <cv-radio-button
            [checked]="loginType === 'serviceAccount'"
            (change)="changeLoginType('serviceAccount')"
            cv-data-test="service-account"
            >{{ 'common_ManagementUseServiceAccount' | translate }}</cv-radio-button
          >
          <p class="management-panel-reccomended" translate>common_ReccomendedOption</p>
          <ng-container *ngIf="loginType === 'serviceAccount'">
            <cv-button
              *ngIf="!serviceAccount"
              [text]="'common_ManagementCreateServiceAccount' | translate"
              [buttonType]="'primary'"
              leftIcon="person"
              (click)="createServiceAccount($event)"
            ></cv-button>
            <ng-container *ngIf="serviceAccount">
              <div class="management-panel-content">
                <div class="management-panel-sa">
                  <p>{{ serviceAccount }}</p>
                  <cv-badge variant="green" [text]="'common_Created' | translate" leftIcon="check_circle"></cv-badge>
                </div>
              </div>
              <div class="management-panel-content">
                <cv-alert-bar class="m-t-20" variant="warning" [hideCloseButton]="true">
                  <span translate>common_DisableAdvancedManagementWarning</span>
                </cv-alert-bar>
                <cv-button
                  buttonType="secondary"
                  [text]="'common_DisableAdvacendManagement' | translate"
                  (click)="deleteServiceAccount($event)"
                ></cv-button>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="management-panel-content management-panel-option">
          <cv-radio-button [checked]="loginType === 'globalAdmin'" (change)="changeLoginType('globalAdmin')">{{
            'common_ManagementUseGlobalAdmin' | translate
          }}</cv-radio-button>
        </div>
        <div class="management-panel-content" *ngIf="loginType === 'globalAdmin'">
          <cv-alert-bar class="m-t-20" variant="info" [hideCloseButton]="true">
            <div translate>common_UserAndPasswordInfo</div>
          </cv-alert-bar>
          <form [formGroup]="managementCredentialsForm">
            <cv-input
              [required]="true"
              formControlName="adminUsernameOffice365"
              style="margin: 20px 0; width: 100%; display: block"
              leftIcon="person"
              [label]="'common_Username' | translate"
            ></cv-input>
            <cv-input
              [required]="true"
              formControlName="adminPasswordOffice365"
              style="margin: 20px 0; width: 100%; display: block"
              leftIcon="key"
              inputType="password"
              [showGeneratePassword]="false"
              [label]="'common_Password' | translate"
            ></cv-input>
          </form>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="status === 'Enabled' && serviceAccount">
      <div class="management-panel-content management-panel-autoenable">
        <cv-switch style="display: block; margin-left: -10px" [ngModel]="enableAutoManagement" (checkedChange)="changeAutoEnable()">
          {{ 'administration_AutoEnableManagementSession' | translate }}
          <mat-icon
            [tooltip]="'administration_AutoEnableManagementSessionDescription' | translate"
            class="material-icons-outlined icon-small"
            >info</mat-icon
          >
        </cv-switch>
      </div>
      <header>
        <h3 class="management-panel-title" translate>common_AdvancedManagement</h3>
      </header>
      <div class="management-panel-content">
        <cv-alert-bar class="m-t-20 m-b-20" variant="warning" [hideCloseButton]="true">
          <span translate>common_DisableAdvancedManagementWarning</span>
        </cv-alert-bar>
        <cv-button
          buttonType="secondary"
          [text]="'common_DisableAdvacendManagement' | translate"
          (click)="deleteServiceAccount($event)"
        ></cv-button>
      </div>
    </ng-container>
  </ng-container>
</div>

<footer *ngIf="isManagement() && (status === 'Disabled' || status === 'Enabled')">
  <cv-button (click)="handleClose()" buttonType="secondary" leftIcon="close" [text]="'common_Close' | translate"></cv-button>
  <cv-button
    [disabled]="!turnOnEnabled()"
    *ngIf="status === 'Disabled'"
    style="margin-left: auto"
    rightIcon="done"
    color="success"
    [text]="'common_TurnOnManagementSession' | translate"
    (click)="startManagementSession()"
  ></cv-button>

  <cv-button
    *ngIf="status === 'Enabled'"
    [disabled]="!isAdmin()"
    style="margin-left: auto"
    rightIcon="done"
    color="alert"
    [text]="'common_TurnOffManagementSession' | translate"
    (click)="closeManagementSession()"
  ></cv-button>
</footer>
