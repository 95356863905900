<ng-container *ngIf="isSequence">
  <app-execution-event-sequence
    [event]="event"
    [isCatch]="isCatch"
    [sequenceEvents]="sequenceEvents"
    [position]="position"
  ></app-execution-event-sequence>
</ng-container>
<ng-container *ngIf="event.action.id === 'item::foreach'">
  <app-execution-event-foreach [event]="event" [position]="position"> </app-execution-event-foreach>
</ng-container>
<ng-container *ngIf="!isSequence && event.action.id !== 'item::if-else' && event.action.id !== 'item::foreach'">
  <app-execution-event-card [event]="event" [isCatch]="isCatch" [position]="position"> </app-execution-event-card>
</ng-container>
<ng-container *ngIf="event.action.id === 'item::if-else'">
  <app-execution-event-if-else [ifElseEvent]="event" [showMergeLine]="showMergeLine" [isCatch]="isCatch" [position]="position">
  </app-execution-event-if-else>
</ng-container>
