<ng-container>
  <app-reports
    [class.left-panel-close]="!leftPanelOpen"
    [clientTableConfiguration]="configuration"
    [searchInProgress]="searchInProgress"
    [progressMessage]="progressMessage"
  >
    <div [style.display]="!leftPanelOpen ? null : 'none'" #fakeLeft customSmartPanel></div>
    <cv-smart-panel
      type="button"
      icon="tune"
      [style.position]="leftPanelOpen ? null : 'absolute'"
      (openChange)="fakeLeft.classList.add('fake-left-panel')"
      [title]="'reports_FilterAssistant' | translate"
      style="z-index: 1; left: 16px"
      [(open)]="leftPanelOpen"
      customSmartPanel
    >
      <div style="cursor: default" content>
        <form [formGroup]="form">
          <div class="title-container">
            <div class="title" translate>common_FilterAssistant</div>
          </div>
          <div class="subtitle" translate>reports_FilterAssistantCalendarPermissionSubTitle</div>

          <div class="select-section">
            <cv-autocomplete-input
              label="{{ 'common_UserPrincipalName' | translate }}"
              [placeholder]="'common_UserPrincipalName' | translate"
              [suggestions]="userPrincipalNamesSuggestions"
              leftIcon=""
              formControlName="userPrincipalName"
              (inputChange)="userPrincipalNameSelectionUpdate.next($event)"
              (ngModelChange)="onChangeUserPrincipalName($event)"
              (selectionChanged)="userPrincipalNameSelectionChanged($event)"
              rightIcon="expand_more"
              class="full-width"
              autocomplete="off"
              [required]="true"
            >
            </cv-autocomplete-input>
          </div>
          <div class="select-section">
            <cv-autocomplete-input
              label="{{ 'common_UserWithAccess' | translate }}"
              [placeholder]="'common_UserWithAccess' | translate"
              [suggestions]="userWithAccessSuggestions"
              leftIcon=""
              formControlName="userWithAccess"
              (inputChange)="userWithAccessSelectionUpdate.next($event)"
              (ngModelChange)="onChangeUserWithAccess($event)"
              (selectionChanged)="userWithAccessSelectionChanged($event)"
              rightIcon="expand_more"
              class="full-width"
              autocomplete="off"
              [required]="false"
            >
            </cv-autocomplete-input>
          </div>
        </form>
      </div>

      <div footer>
        <div class="error-container" *ngIf="getError()" style="text-align: left">
          <span translate>{{ getError() }}</span>
        </div>

        <cv-button (click)="reset()" class="m-r-10" buttonType="secondary" [text]="'common_Reset' | translate"> </cv-button>
        <cv-button (click)="search()" primary="secondary" [text]="'common_Search' | translate" [disabled]="isSearchDisabled()"> </cv-button>
      </div>
    </cv-smart-panel>
  </app-reports>
</ng-container>
