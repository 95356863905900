<div class="report-container">
  <div class="title-bar" *ngIf="dashboardDefinition">
    <div class="title-bar-content">
      <div class="first-line">
        {{ reportTitle }}
        <mat-icon
          *ngIf="!!reportDescription"
          (click)="showDescription = !showDescription"
          [ngClass]="{ 'info-active': !!showDescription }"
          class="m-l-5 info material-icons-outlined"
          >info
        </mat-icon>
      </div>
      <div class="m-t-10 f-s-14" *ngIf="!!showDescription">{{ reportDescription }}</div>
    </div>
    <div>
      <app-favorite *ngIf="menu" [menuId]="menu.id" [isFavorite]="menu.isFavorite" entity="dashboards"> </app-favorite>
    </div>
  </div>

  <cv-horizontal-tab-group type="line" (tabChanged)="tabChanged($event)" [activeTab]="tabSelectedIndex + 1" *ngIf="!!dashboardDefinition">
    <cv-tab title="{{ 'licenses_Summary' | translate }}">
      <div class="tab-container">
        <div
          class="main-container"
          [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'"
          *ngIf="tabSelectedIndex == 0"
        >
          <div [style.display]="!leftPanel.open && canSeeLeftPanel() ? null : 'none'" #fakeLeft></div>
          <cv-smart-panel #leftPanel type="button" icon="description" [style.position]="leftPanel.open ? null : 'absolute'" [style.display]="canSeeLeftPanel() ? null : 'none'" (openChange)="fakeLeft.classList.add('fake-left-panel')" [title]="'reports_CustomReports' | translate" style="padding-top: 10px; z-index: 1">
            <div style="cursor: default" content>
              <div class="title-container">
                <div class="title" translate>reports_CustomReports</div>
                <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
              </div>
              <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
              <div class="saved-report-container">
                <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
              </div>
            </div>
          </cv-smart-panel>

          <div [class.left-panel-close]="!leftPanel.open" [class.right-panel-close]="hasActions && !rightPanel.open" class="grid-container" *ngIf="reportDefinition">
            <app-datagrid
              #gridSummary
              [getItems]="getSummaryItems"
              [hideColumnsSelector]="true"
              [gridDefinition]="reportDefinition"
              [savedReportFilterSortModel]="savedReportFilterSortModel"
              (metadataChanged)="metadata = $event"
              (rowSelected)="rowSelectedSummary()"
              style="flex: 1"
            ></app-datagrid>
          </div>

          <app-management-panel
            class="m-t-10"
            [targetEntity]="reportDefinition?.targetEntity"
            [collectionName]="reportDefinition?.dbCollectionName"
            [reportDefinition]="reportDefinition"
            [entityIdField]="reportDefinition?.entityIdField"
            [reportTitle]="reportDefinition?.title"
            [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
            [selectedRows]="selectedRowsSummary"
            [tagsByDefault]="reportDefinition?.actionsTags || []"
            (hasActionsChange)="hasActions = $event"
            #rightPanel
          ></app-management-panel>
        </div>
      </div>
    </cv-tab>

    <cv-tab title="{{ 'licenses_Detail' | translate }}">
      <div class="tab-container">
        <div
          class="main-container"
          [ngClass]="!canSeeLeftPanel() ? 'mainContainer-two' : 'mainContainer-three'"
          *ngIf="tabSelectedIndex === 1"
        >
          <div [style.display]="!leftPanel.open && canSeeLeftPanel() ? null : 'none'" #fakeLeft></div>
          <cv-smart-panel #leftPanel type="button" icon="description" [style.position]="leftPanel.open ? null : 'absolute'" [style.display]="canSeeLeftPanel() ? null : 'none'" (openChange)="fakeLeft.classList.add('fake-left-panel')" [title]="'reports_CustomReports' | translate" icon="description" style="z-index: 1; padding-top: 10px">
            <div style="cursor: default" content>
              <div class="title-container">
                <div class="title" translate>reports_CustomReports</div>
                <mat-icon [tooltip]="'reports_SavedViewsInfo' | translate" class="info material-icons-outlined">info </mat-icon>
              </div>
              <div class="m-l-15 m-b-20" translate>reports_SelectReport</div>
              <div class="saved-report-container">
                <app-custom-reports-list [savedReports]="savedReports"></app-custom-reports-list>
              </div>
            </div>
          </cv-smart-panel>

          <div [class.left-panel-close]="!leftPanel.open" [class.right-panel-close]="!rightPanel.open" class="grid-container" *ngIf="reportDefinition">
            <app-datagrid
              #gridDetail
              [getItems]="getDetailItems"
              [hideColumnsSelector]="true"
              [gridDefinition]="reportDefinition"
              [savedReportFilterSortModel]="savedReportFilterSortModel"
              (metadataChanged)="metadata = $event"
              (rowSelected)="rowSelectedDetail()"
              style="flex: 1"
            ></app-datagrid>
          </div>

          <app-management-panel
            style="padding-top: 10px"
            [targetEntity]="reportDefinition?.targetEntity"
            [collectionName]="reportDefinition?.dbCollectionName"
            [reportDefinition]="reportDefinition"
            [entityIdField]="reportDefinition?.entityIdField"
            [reportTitle]="reportDefinition?.title"
            [workflowItemsSelectionColumnDefinition]="managementColumnDefinition"
            [selectedRows]="selectedRowsDetail"
            [tagsByDefault]="reportDefinition?.actionsTags || []"
            (hasActionsChange)="hasActions = $event"
            #rightPanel
          ></app-management-panel>
        </div>
      </div>
    </cv-tab>
  </cv-horizontal-tab-group>
</div>
