/* eslint-disable @typescript-eslint/naming-convention */
import { ReportsFilters } from '@app/core/models/reports-filters'
import { Dictionary } from '@ngrx/entity'
import { IBeforeClose, RightPanelRef } from '@app/core/services/right-panel.service'
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { DynamoFormV1HelperService } from '@app/modules/workflow/features/form-builder-v1/services/dynamo-form-v1-helper.service'
import { DialogComponent, OptionsType, Suggestion } from '@coreview/coreview-components'
import { cloneDeep, sortBy, uniq, values, first as lodashFisrt, isEqual, isEmpty } from 'lodash-es'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { combineLatest, iif, Observable, of, Subject, timer } from 'rxjs'
import { distinctUntilChanged, catchError, filter, switchMap, pluck, first, map } from 'rxjs/operators'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { ReportsService } from '@app/core/services/reports.service'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { ColDef } from '@ag-grid-community/core'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import {
  CustomPolicyRequest,
  Policy,
  RemediationType,
  ReportDefinitionPolicy,
  ScheduleType,
  ExecutionType,
  ThresholdType,
  ThresholdSeverity,
} from '@app/core/models/playbook'
import { WorkflowHttpService } from '@app/modules/workflow/http/workflow.http'
import { Workflow } from '@app/modules/workflow/models/workflow.model'
import { SummaryCard } from '@app/shared/models/summary-card'
import { selectedOrganization, selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'
import { Helpers } from '@app/shared/utilities/helpers'
import { MatDialog } from '@angular/material/dialog'
import { Constants } from '@app/shared/utilities/constants'
import { selectReportsDefinition } from '@app/store/reports/reports-definition.selectors'
import { RoutesService } from '@app/core/services/routes.service'
import * as dayjs from 'dayjs'
import { WorkflowPreviewDialogComponent } from '../workflow-preview-dialog/workflow-preview-dialog.component'
import { ReportsFiltersComponent } from '@app/modules/reports/components/reports-filters/reports-filters.component'
import { generateCronExpression } from '@app/core/utils/manage-payload'
import { TargetEntityAuditType, TargetEntityBaseType, TargetEntityPolicyType, TargetEntityType } from '@app/core/enums/group-type'
import { PolicyService } from '../../services/policy.service'
import { EnhancedJsonFormData } from '@app/shared/models/enhanced-json-form-data'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { DialogHelperService } from '@app/shared/dialog-helper.service'
import { CronExpressionHelper } from '@app/shared/utilities/cron-expression-helper'
import { PlaybookService } from '../../services/playbook.service'
import { QueryFilter2, childFilter } from '@app/core/models/QueryFilter'
import { TranslateHelper } from '@coreview/coreview-library'
import { TargetHelper } from '@app/shared/utilities/target-helper'
import { Router } from '@angular/router'

type SmartInputType = 'text' | 'number' | 'password'
@Component({
  selector: 'app-new-custom-policy',
  templateUrl: './new-custom-policy.component.html',
  styleUrls: ['./new-custom-policy.component.sass'],
})
export class NewCustomPolicyComponent extends IBeforeClose implements OnInit, OnDestroy {
  @ViewChild(DatagridComponent)
  set datagrid(grid: DatagridComponent) {
    this.grid = grid
  }
  grid!: DatagridComponent

  missingRemediationPermission = Constants.MissingRemediationPermission
  policy: Policy | undefined
  hasPolicyInitialized = false
  changedRemediation = false
  userSuggestions!: Suggestion[]

  customReportId!: string
  reportDefinition!: (ReportDefinition & { since?: string } & { to?: string }) | undefined
  defaultTarget!: string
  customColumns?: CoreViewColumn[]

  formControls = {
    policyDetails: new UntypedFormGroup({
      title: new UntypedFormControl('', [Validators.required], []),
      description: new UntypedFormControl('', [Validators.required]),
      usefulnessDescription: new UntypedFormControl(''),
      categories: new UntypedFormControl([]),
      playbooks: new UntypedFormControl([], [Validators.required]),
      isPolicyEnabled: new UntypedFormControl(true),
    }),
    policyDefinition: new UntypedFormGroup({
      entity: new UntypedFormControl('', [Validators.required]),
      policyExceptionFields: new UntypedFormControl([]),
      customReportId: new UntypedFormControl(null),
      reportName: new UntypedFormControl(null),
      thresholdSeverity: new UntypedFormControl(ThresholdSeverity.Informational),
      isthresholdEnabled: new UntypedFormControl(),
      thresholdType: new UntypedFormControl(ThresholdType.IsNumber),
      thresholdOperator: new UntypedFormControl(),
      thresholdValue: new UntypedFormControl(),
      policyType: new UntypedFormControl(ExecutionType.ScheduleBased),
    }),
    remediationAction: new UntypedFormGroup({
      remediationType: new UntypedFormControl('', [Validators.required]),
      isWorkflowEnabled: new UntypedFormControl(true),
      notificationRecipients: new UntypedFormControl([]),
      sendReport: new UntypedFormControl('IsNotEmpty'),
      format: new UntypedFormControl('XLSX'),
      emailBody: new UntypedFormControl(),
      recipients: new UntypedFormControl([]),
      workflowId: new UntypedFormControl(null),
      scheduleType: new UntypedFormControl(ScheduleType.None),
      recurrenceData: new UntypedFormControl({
        recurrence: 'recurring',
        particularScheduleWeeks: true,
        frequency: '1',
        interval: 1,
        weekdaySelected: null,
        ordinal: null,
        dayMonth: null,
        weekdayMonthSelected: null,
        startDateTime: dayjs(),
        endDateTime: null,
        monthControl: null,
        starting: 'specificTime',
      }),
    }),
  }

  initialValueRemediationAction = { ...this.formControls.remediationAction.value, isWorkflowEnabled: false }

  form: UntypedFormGroup = new UntypedFormGroup(this.formControls)

  activeIndex = 0

  steps = [
    { title: this.translateHelper.instant('playbook_PolicyDetails'), status: 'Available' },
    { title: this.translateHelper.instant('playbook_policyDefinition'), status: 'Available' },
    { title: this.translateHelper.instant('playbook_RemediationAction'), status: 'Available' },
    { title: this.translateHelper.instant('common_ReviewAndComplete'), status: 'Available' },
  ]

  thresholdSeverities: Suggestion[] = [
    {
      value: ThresholdSeverity.Critical,
      displayValue: this.translateHelper.instant('playbook_ThresholdSeverityCritical'),
      icon: 'warning',
      iconColor: '#B01F24',
    },
    {
      value: ThresholdSeverity.Warning,
      displayValue: this.translateHelper.instant('playbook_ThresholdSeverityWarning'),
      icon: 'warning',
      iconColor: '#F5A623',
    },
    {
      value: ThresholdSeverity.Informational,
      displayValue: this.translateHelper.instant('playbook_ThresholdSeverityInformational'),
      icon: 'info',
      iconColor: '#306AC8',
    },
  ]

  thresholdOperations: Suggestion[] = [
    { value: '<', displayValue: this.translateHelper.instant('common_LessThan') },
    { value: '<=', displayValue: this.translateHelper.instant('common_LessThanOrEqual') },
    { value: '>', displayValue: this.translateHelper.instant('common_GreaterThan') },
    { value: '>=', displayValue: this.translateHelper.instant('common_GreaterThanOrEqual') },
  ]

  severityIcon: { icon: string; color: string } = { icon: 'info', color: '#306AC8' }
  filteredCategories!: Suggestion[]
  categories!: Suggestion[]
  filteredCustomPlaybooks!: Suggestion[]
  playbooks!: Suggestion[]
  policyKeySuggestions: Suggestion[] = []

  targetSuggestions: Suggestion[] = [
    { value: 'Audit', displayValue: this.translateHelper.instant('AuditActivities') },
    ...sortBy(
      [...Object.values(TargetEntityType), ...Object.values(TargetEntityBaseType), ...Object.values(TargetEntityPolicyType)].map((x) => ({
        value: x,
        displayValue: this.translateHelper.instant(x),
      })),
      'displayValue'
    ),
    { value: 'CustomReports', displayValue: this.translateHelper.instant('playbook_CustomReports') },
  ]

  customReports: Suggestion[] = []

  workflowName = ''
  originalWorkflowList: Suggestion[] = []
  workflowList: Suggestion[] = []
  showRefreshWorkflowList = false

  workflowRemediationDropdownItem: Suggestion = {
    value: RemediationType.Workflow,
    displayValue: this.translateHelper.instant('playbook_Workflow'),
  }
  scheduleReportRemediationDropdownItem: Suggestion = {
    value: RemediationType.ScheduleReport,
    displayValue: this.translateHelper.instant('playbook_ScheduleReport'),
  }
  sendNotificationRemediationDropdownItem: Suggestion = {
    value: RemediationType.SendNotification,
    displayValue: this.translateHelper.instant('playbook_SendNotification'),
  }

  remediationTypeSuggestion: Suggestion[] = []
  oldRemediationTypeSuggestion: Suggestion[] = []

  componentTitle = 'playbook_CreateCustomPolicy'

  /* for future use when schedule feature drop
  executionsSuggestion: Suggestion[] = [
    {
      value: ExecutionType.ScheduleBased,
      displayValue: this.translateHelper.instant('playbook_ScheduleBased'),
      otherDisplayValue: this.translateHelper.instant('playbook_ScheduleBasedDescription'),
    },
    {
      value: ExecutionType.EventBased,
      displayValue: this.translateHelper.instant('playbook_EventBased'),
      otherDisplayValue: this.translateHelper.instant('playbook_EventBasedDescription'),
    },
  ]
*/
  sendReportSuggestions: Suggestion[] = [
    { value: 'IsEmpty', displayValue: this.translateHelper.instant('reports_IsEmpty') },
    { value: 'IsNotEmpty', displayValue: this.translateHelper.instant('reports_IsNotEmpty') },
    { value: 'Always', displayValue: this.translateHelper.instant('reports_Always') },
  ]

  sendReportFormatSuggestions: Suggestion[] = [
    { value: 'XLSX', displayValue: 'Excel' },
    { value: 'CSV', displayValue: 'CSV' },
    { value: 'PDF', displayValue: 'PDF' },
  ]

  scheduleOptions: Suggestion[] = [
    { value: 'None', displayValue: this.translateHelper.instant('playbook_notSchedule') },
    { value: 'Scheduled', displayValue: this.translateHelper.instant('playbook_schedule') },
  ]

  selectedSchedule!: string
  cronExpression!: string | null | undefined

  allowNewCategories = false
  allowNewPlaybooks = false
  definePolicyKey = false
  gridDefinition!:
    | (ReportDefinition & { apiUrl: string } & { since?: string } & { to?: string } & { propertyId?: string } & {
        reportDefinitionId?: string
      })
    | undefined
  filters!: Record<string, string>
  treeFilters: QueryFilter2 | undefined
  filteredCols!: (CoreViewColumn | undefined)[]

  selectedWorkflow: Suggestion | null | undefined = null
  wfInputSuggestions: (Suggestion & { required: boolean; optionsType?: OptionsType; inputType: SmartInputType })[] = []
  wfBindValues: { label: string; type: string }[] = []

  isSaving = false
  showGrid = false
  hasExecutionInputs = false

  isScheduleEnabled = false
  isWorkflowSelected = false
  companyId!: string
  submitted = false
  summaryCards: SummaryCard[] = []

  reportDefinitions!: Dictionary<ReportDefinition>

  loading = true
  customReportLoading = false

  lastRequest!: any
  advancedFilterOpen = false
  rangeFilter!: { since?: string; to?: string; days?: number } | undefined

  policyId!: string | null
  patchPolicy: any

  canCreatePlaybook = false
  readMode = false

  private readonly CreatePolicyPage = 0
  private readonly PolicyDefinitionPage = 1
  private readonly RemediationActionPage = 2

  private entityLoaded = false

  private destroyed$ = new Subject<boolean>()

  private prevReportFilter!: ReportsFilters | undefined

  constructor(
    private translateHelper: TranslateHelper,
    private playbookService: PlaybookService,
    private rightPanelRef: RightPanelRef,
    private reportsService: ReportsService,
    private dialog: MatDialog,
    private store: Store<RootState>,
    private workflowHttp: WorkflowHttpService,
    private dynamoFormsV1Helper: DynamoFormV1HelperService,
    private cronExpressionHelper: CronExpressionHelper,
    private policyService: PolicyService,
    private sharedHelperService: SharedHelperService,
    private dialogHelperService: DialogHelperService,
    private router: Router,
    private window: Window
  ) {
    super()
    this.prevReportFilter = this.reportsService.reportFilter
  }

  onChangeTitle() {
    this.formControls.policyDetails.get('title')?.setValue(this.formControls.policyDetails.get('title')?.value.trim())
    this.form.updateValueAndValidity()
  }

  onChangeDescription() {
    this.formControls.policyDetails.get('description')?.setValue(this.formControls.policyDetails.get('description')?.value.trim())
    this.form.updateValueAndValidity()
  }

  titleValidator = (control: AbstractControl): Observable<ValidationErrors | null> =>
    timer(500).pipe(
      switchMap(() =>
        this.playbookService.checkPolicyTitle(this.policy?.id, control.value).pipe(catchError(() => of({ isTitleAllowed: false })))
      ),
      map((data) => (data.isTitleAllowed ? null : { titleExists: '' })),
      first()
    )

  ngOnInit(): void {
    if (!this.policyId) {
      this.initData()
    } else {
      this.componentTitle = 'playbook_editPolicy'
      this.playbookService.getPolicy(this.policyId, false).subscribe((resp) => {
        this.policyId = null
        this.policy = resp
        if (this.patchPolicy) {
          this.policy = { ...this.policy, ...this.patchPolicy }
        }

        if (this.policy && !this.policy.canEdit) {
          this.readMode = true
        }

        this.initData()
      })
    }

    this.store.select(selectedOrganizationSkus).subscribe((skus) => {
      this.canCreatePlaybook = !!skus?.includes('FT:SHOWPOLICYCREATION') && !this.isPlaybookAdmin()
    })
  }

  isPlaybookAdmin() {
    return this.sharedHelperService.checkRoles(['playbookAdmin']) && !this.sharedHelperService.checkRoles(['tenantAdmin'])
  }

  getCreatePlaybookErrorMessage(): string {
    return this.canCreatePlaybook ? this.translateHelper.instant('playbook_NoMatchedPlaybookFound') : ''
  }

  initData() {
    if (this.defaultTarget === 'Audit' && !this.policy) {
      this.reportsService.deleteSessionFiltersReports().subscribe(() => (this.reportsService.reportFilter = undefined))
    }
    this.store.select(selectedOrganizationSkus).subscribe((skus) => {
      this.setSkuData(skus)
    })
    this.handleReportTargetAndExecutionType()
    if (this.changedRemediation) {
      this.activeIndex = 2
    }
    this.formControls.policyDetails.get('title')?.addAsyncValidators(this.titleValidator)
    const savedReportParams =
      this.customReportId || this.policy?.reportDefinition?.customReportId
        ? { filters: JSON.stringify({ Guid: '=' + (this.customReportId || this.policy?.reportDefinition?.customReportId) }) }
        : {}
    this.getPolicyComplementaryDataAndInitForms(savedReportParams)
    this.initChangeDetectionForm()
    this.store
      .select(selectedOrganization)
      .pipe(
        filter((x) => !!x),
        first()
      )
      .subscribe((company) => {
        this.companyId = company?.guid || ''
      })

    this.toggleScheduleAvailability()
  }

  ngOnDestroy(): void {
    if (!isEqual(this.prevReportFilter, this.reportsService.reportFilter)) {
      if (!this.prevReportFilter) {
        this.reportsService.deleteSessionFiltersReports().subscribe(() => (this.reportsService.reportFilter = undefined))
      } else if (this.prevReportFilter) {
        this.reportsService
          .saveSessionFiltersReports(this.prevReportFilter)
          .subscribe(() => (this.reportsService.reportFilter = this.prevReportFilter))
        //Maybe we can reset old one
      }
    }
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  validateThreshold(event: any) {
    const value = parseInt(event.target.value, 10)
    const clampedValue = Math.max(value, 0)
    event.target.value = clampedValue
    this.formControls.policyDefinition.get('thresholdValue')?.setValue(clampedValue)
  }

  getThresholdPattern(): RegExp {
    return /^\d+$/
  }

  beforeClose(): Observable<boolean> {
    if (this.submitted || this.form.pristine) {
      return of(true)
    }
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      data: {
        title: this.translateHelper.instant('common_Confirmation'),
        text: this.translateHelper.instant('playbook_ConfirmAction'),
        primaryText: this.translateHelper.instant('common_DiscardChanges'),
        secondaryText: this.translateHelper.instant('common_Cancel'),
        type: 'alert',
        centered: false,
      },
    })
    return dialogRef.afterClosed()
  }

  clickTab(index: number) {
    if (this.steps[index].status === 'NotAvailable') {
      return
    }
    if (index < this.activeIndex) {
      this.activeIndex = index
    }
    if (!this.isStepValid(this.activeIndex)) {
      return
    }
    if (this.gridDefinition) {
      this.gridDefinition.initialUrlFilters = this.filters
      this.gridDefinition.treeFilters = this.treeFilters
    }
    this.activeIndex = index
    if (this.activeIndex === 2) {
      this.checkIfAddSendNotification()
    }
    if (this.activeIndex === 3) {
      this.preparateSummaryCards()
    }
  }

  isStepValid(index: number): boolean {
    switch (index) {
      case this.CreatePolicyPage:
        return this.formControls.policyDetails.valid
      case this.PolicyDefinitionPage:
        return this.formControls.policyDefinition.valid && !!this.gridDefinition?.fields?.length && !!this.policyKeySuggestions?.length
      case this.RemediationActionPage:
        return this.formControls.remediationAction.valid && this.isScheduleDateValid()
      default:
        return false
    }
  }

  canSave(): boolean {
    return [...Array(3).keys()].map((i) => this.isStepValid(i)).reduce((a, b) => a && b)
  }

  changeTab(amount: number) {
    const index = this.activeIndex + amount
    if (this.steps[index].status === 'NotAvailable') {
      this.clickTab(index + amount)
    } else {
      this.clickTab(index)
    }
  }

  cancel() {
    this.rightPanelRef.close()
  }

  clearPolicyExceptionFields() {
    if (this.definePolicyKey === false) {
      this.formControls.policyDefinition.get('policyExceptionFields')?.patchValue([])
    }
  }

  clearSelectedWorkflow(selectedWorkflow: string) {
    if (selectedWorkflow === null) {
      this.form.patchValue({
        remediationAction: { workflowId: null },
      })
    }
  }

  checkIfScheduleIsVisible() {
    if (this.formControls.policyDefinition.get('entity')?.value !== 'Audit') {
      return this.formControls.remediationAction.get('remediationType')?.value !== 'ScheduleReport'
    }
    return this.formControls.remediationAction.get('remediationType')?.value !== 'ScheduleReport' && this.policyTypeIsEventBased()
  }

  save() {
    const reportDefinition = this.getReportDefinition()
    if (!reportDefinition) {
      return
    }
    const request: CustomPolicyRequest | null = this.createCustomPolicyRequest(reportDefinition)
    if (!request) {
      return
    }
    this.submitted = true
    this.handleSavePolicy(request)
  }

  targetChanged(
    event: TargetEntityBaseType | TargetEntityType | TargetEntityAuditType | TargetEntityPolicyType | 'CustomReports',
    loadCustomReports: boolean = true
  ) {
    this.showGrid = false
    this.gridDefinition = undefined
    this.rangeFilter = undefined
    this.policyKeySuggestions = []
    this.definePolicyKey = false
    this.formControls.policyDefinition.get('policyExceptionFields')?.reset()
    this.formControls.policyDefinition
      .get('policyType')
      ?.setValue(event === 'Audit' ? ExecutionType.EventBased : ExecutionType.ScheduleBased)

    if (event === 'CustomReports') {
      if (loadCustomReports) {
        this.searchCustomReport('')
      }
      this.formControls.policyDefinition.get('customReportId')?.setValidators([Validators.required])
      this.formControls.policyDefinition.get('customReportId')?.updateValueAndValidity()
      return
    } else {
      this.formControls.policyDefinition.get('customReportId')?.clearValidators()
      this.formControls.policyDefinition.get('customReportId')?.updateValueAndValidity()
    }
    this.formControls.policyDefinition.get('customReportId')?.setValue(null)

    this.gridDefinition = {
      ...TargetHelper.targetMap[event],
    }

    this.addReportFiltersButtons()

    if (this.policy && !this.entityLoaded) {
      this.loadPolicy()
    }

    if (this.gridDefinition.apiUrl) {
      timer(1).subscribe(() => {
        this.showGrid = true
      })
    }
  }

  customReportSelected(id: string | null | undefined) {
    this.resetReportSelection()

    if (!id) {
      return
    }

    this.customReportLoading = true
    this.reportsService
      .getSavedReportById(id)
      .pipe(
        switchMap((customReport: { reportUrl: string; request: any }) => this.manageCustomReportPolicy(customReport)),
        catchError(() => of({ customReport: null, policy: null }))
      )
      .subscribe((result) => {
        const { customReport, policy: customReportPolicy } = result
        let reportDefinition = null
        if (!customReportPolicy && customReport) {
          reportDefinition = this.findReportDefinition(customReport)
          if (!reportDefinition?.dbCollectionName && !customReport.reportUrl.includes('newCustomReport')) {
            return
          }
        }
        this.handleGridDefinition(customReport, customReportPolicy, reportDefinition)
      })
  }

  getItems = () => (params: ApiDataParameters) => {
    const entity = this.formControls.policyDefinition.get('entity')?.value
    if (entity === 'Audit' && !params?.days && !params?.since && !params?.to) {
      params = { ...params, days: '7' }
    }
    if (this.gridDefinition?.urlParameters) {
      Object.assign(params, this.gridDefinition.urlParameters)
    }
    return {
      items: this.reportsService.getData(this.gridDefinition?.apiUrl || '', this.gridDefinition?.verb || 'get', params),
      cols: this.getCols(),
    }
  }

  getPolicyKeySuggestionsAndBindValues(cols?: { columnDefs: (ColDef & CoreViewColumn)[]; serverCols: CoreViewColumn[] }) {
    if (!this.gridDefinition) {
      return
    }

    try {
      this.filters = this.gridDefinition.verb === 'get' ? JSON.parse(this.grid.getFilters()) : this.grid.getFilters() || {}
    } catch {
      this.filters = {}
    }

    this.treeFilters = this.grid.getTreeFilters()

    this.filteredCols =
      (cols?.columnDefs || this.grid?.columnDefs || [])
        .filter((x) => !x.hide)
        .map((x) => (cols?.serverCols || this.grid?.serverCols || []).find((c) => x.field === c.name))
        .filter((x) => !!x) || []
    this.wfBindValues = this.filteredCols
      .filter((x) => !x?.notAvailable)
      .map((x) => ({ label: x?.originalName || '', type: x?.type || '' }))
    this.policyKeySuggestions = this.filteredCols.map((x) => ({
      value: x?.originalName || '',
      displayValue: x?.translate ? this.translateHelper.instant(x?.translate || '') : x?.originalName,
      data: x,
    }))
    this.gridDefinition.fields = this.policyKeySuggestions.map((x) => x.value)
  }

  updateFormValidity(isRemediationEnabled: boolean) {
    const formWfId = this.formControls.remediationAction.controls.workflowId
    if (isRemediationEnabled) {
      formWfId?.setValidators([Validators.required])
      this.formControls.remediationAction.controls.remediationType?.setValidators([Validators.required])
    } else {
      formWfId?.clearValidators()
      this.formControls.remediationAction.controls.remediationType?.clearValidators()
      this.formControls.remediationAction.controls.recipients?.clearValidators()
      this.formControls.remediationAction.controls.notificationRecipients?.clearValidators()
      this.selectedWorkflow = null
      this.isWorkflowSelected = false
      this.isScheduleEnabled = false
      this.formControls.remediationAction.removeControl('workflowInputMappings')
      this.formControls.remediationAction.reset(this.initialValueRemediationAction)
    }
    formWfId?.updateValueAndValidity()
    this.formControls.remediationAction.controls.remediationType?.updateValueAndValidity()
    this.formControls.remediationAction.controls.recipients?.updateValueAndValidity()

    this.scheduleChanged()
  }

  workflowSelected(workflow: any) {
    this.formControls.remediationAction.removeControl('workflowInputMappings')
    if (workflow) {
      this.form.patchValue({
        remediationAction: { workflowId: workflow.value },
      })
      this.workflowHttp.fetchWorkflowById(workflow.value).subscribe((res) => {
        if (res.data.inputSchema) {
          this.initSmartInputsFields(res)
        } else {
          this.hasExecutionInputs = false
        }
      })
      this.isWorkflowSelected = true
    } else {
      this.isWorkflowSelected = false
      this.hasExecutionInputs = false
    }
  }

  filterWorkflows(wfName: string) {
    this.workflowList = this.originalWorkflowList.filter((wf) => wf?.displayValue.toLocaleLowerCase().includes(wfName?.toLowerCase() || ''))
  }

  handleAutobinding() {
    const bindValues = this.wfBindValues.map((b) => b.label)
    const formGroup = this.formControls.remediationAction.get('workflowInputMappings') as UntypedFormGroup
    const wfMappingsControls = formGroup?.controls || {}
    Object.keys(wfMappingsControls).forEach((control) => {
      if (bindValues.includes(control)) {
        formGroup.get(control)?.setValue(`{{report.${control}}}`)
      }
    })
  }

  scheduleChanged(): void {
    this.isScheduleEnabled = this.formControls.remediationAction.get('scheduleType')?.value !== 'None'
    if (
      this.formControls.remediationAction.get('scheduleType')?.value !== ScheduleType.Scheduled &&
      this.formControls.remediationAction.get('remediationType')?.value !== 'ScheduleReport'
    ) {
      this.formControls.remediationAction.get('recurrenceData')?.clearValidators()
    }
    this.formControls.remediationAction.get('recurrenceData')?.updateValueAndValidity()

    this.toggleScheduleAvailability()
  }

  searchCustomReport(input: string) {
    const params = { filters: JSON.stringify({ ReportName: input }), sort: 'ReportName', sortOrder: 'asc' }
    combineLatest([
      this.reportsService.getSavedReports(params, false, false, Constants.savedReportsCategories.PortalV2).pipe(pluck('savedReports')),
      this.reportsService.getSavedReports(params, true, false, Constants.savedReportsCategories.PortalV2).pipe(pluck('savedReports')),
    ]).subscribe(([savedReports, publicSavedReports]) => {
      this.customReports = sortBy(
        [...(savedReports || []), ...(publicSavedReports || [])]
          .filter((customReport) => this.filterCustomReport(customReport))
          .map((x: { guid: string; reportName: string }) => ({
            value: x.guid,
            displayValue: x.reportName,
          })),
        'displayValue'
      )
    })
  }

  filterCategories(text: string) {
    this.filteredCategories = this.categories.filter((x) => x.displayValue?.toLowerCase().includes(text?.toLowerCase() || ''))
  }

  filterPlaybooks(text: string) {
    this.filteredCustomPlaybooks = this.playbooks.filter((x) => x.displayValue?.toLowerCase().includes(text?.toLowerCase() || ''))
  }

  fillRecurrenceDataFromScheduleReport = () => {
    const recurrence = this.policyService.createRecurrenceFromScheduleReport(this.policy!)
    this.form.patchValue({
      remediationAction: {
        recurrenceData: recurrence,
        format: this.policy?.scheduleReport?.format,
        emailBody: this.policy?.scheduleReport?.text,
        sendReport: this.policy?.scheduleReport?.sendWhen,
        recipients: this.policy?.scheduleReport?.recipients?.map((x) => ({ value: x, displayValue: x })),
      },
    })
  }

  onRemediationTypeChange = (val: any) => {
    this.handleWorkflowChange(val)
    this.handleReportChange(val)
    this.handleNotificationChange(val)
    ;(this.form.controls.remediationAction as UntypedFormGroup).get('notificationRecipients')?.updateValueAndValidity()

    this.scheduleChanged()
  }

  usersInputChanged(input: string) {
    if (input?.trim()?.length >= 3) {
      this.reportsService
        .getUserSuggestions({
          searchedString: input,
          excludeItems: this.formControls.remediationAction.controls.recipients?.value?.map((x: Suggestion) => x.value),
        })
        .subscribe((res) => {
          this.userSuggestions = res.map((x) => ({ value: x.userPrincipalName, displayValue: x.userPrincipalName }))
        })
    } else {
      this.userSuggestions = []
    }
  }

  rangeFilterChanged(event: { since?: string; to?: string; days?: number; isOnStart?: boolean }) {
    this.rangeFilter = event
    this.grid?.refresh()
  }

  openWorkflow() {
    this.dialog.open(WorkflowPreviewDialogComponent, {
      width: '70%',
      height: '70%',
      data: { workflowId: this.selectedWorkflow?.value, isForPlaybook: true },
    })
  }

  openCreateWorkflowPage() {
    this.window.open(this.router.serializeUrl(this.router.createUrlTree(['/workflow/list/builder'])), '_blank')
    this.showRefreshWorkflowList = true
  }

  /*
  notificationRecipients',
          'sendReport',
          'format',
          'emailBody',
          'recipients',
          'workflowId',
          'scheduleType',
          'recurrenceData',
          'remediationType',
          'workflowId',
          'workflowInputMappings',
  */

  preparateSummaryCards() {
    const showingFields = this.createSummaryCardsShowingFields()

    this.summaryCards = [
      {
        title: this.translateHelper.instant('playbook_PolicyDetails'),
        properties: Object.entries(this.formControls.policyDetails.controls).map(([key, control]) =>
          (key === 'playbooks' || key === 'categories')
            ? { [this.translateHelper.instant(Helpers.capitalize(key))]: control.value.map((x: any) => x.displayValue) }
            : {
                [this.translateHelper.instant(Helpers.capitalize(key))]: control.value,
              }
        ),
        editFunction: () => {
          this.activeIndex = 0
        },
      },
      {
        title: this.translateHelper.instant('playbook_policyDefinition'),
        properties: [
          Object.entries(this.formControls.policyDefinition.controls).map(([key, control]) =>
            this.preparePolicyDefinitionCard(key, control)
          ),
          {
            [this.translateHelper.instant('common_Columns')]:
              this.filteredCols?.map((col) => this.translateHelper.instant(col?.translate || '')) || [],
          },
        ],
        editFunction: () => {
          this.activeIndex = 1
        },
      },
      {
        title: this.translateHelper.instant('playbook_RemediationAction'),
        properties: [
          ...Object.entries(this.formControls.remediationAction.controls)
            .filter(([key, _]) => showingFields.some(({ filterFunc, visibleFields }) => !!filterFunc() && visibleFields.includes(key)))
            .map(([key, control]) => this.preparePolicyRemediationActionCard(key, control)),
        ],
        editFunction: () => {
          this.activeIndex = 2
        },
      },
    ]
  }

  clearValueIfNonePicked(value: boolean) {
    if (value) {
      this.setThresholdRequired()
      return
    }

    this.clearThresholdValidators()
    const policyDefinition = this.formControls.policyDefinition
    policyDefinition.patchValue({
      thresholdOperator: null,
      thresholdValue: null,
    })
    policyDefinition.updateValueAndValidity()
  }

  returnDatagridAlertbarMessage(): string {
    return 'playbook_alertbarPolicyTarget'
  }

  datagridAlertbarIsVisible = (): boolean => {
    if (!this.policyTypeIsEventBased()) return false
    return !this.workloadColumnHasFilter()
  }

  createWfInputSuggestion(
    key: string,
    value: any,
    formData: EnhancedJsonFormData
  ): Suggestion & { required: boolean; optionsType?: OptionsType; inputType: SmartInputType } {
    return {
      value: key,
      displayValue: value?.title || '',
      required: !!formData?.required?.find((e) => e === key),
      inputType: 'text',
      optionsType: value?.format === 'date' ? value?.format : 'default',
    }
  }

  refreshWorkflowList() {
    this.workflowHttp
      .fetchActiveWorkflows()
      .pipe(catchError(() => of({ data: { items: [] } })))
      .subscribe((res) => {
        this.originalWorkflowList = res.data.items.map((item: any) => ({ value: item.id, displayValue: item.workflowName }))
        this.workflowList = cloneDeep(this.originalWorkflowList)
      })
  }

  private getCols = () => {
    if (this.customColumns) {
      return of(this.customColumns)
    }
    return this.gridDefinition?.isOnlineUsersType ? this.store.select(selectOnlineuserColumns).pipe(map((cols) => cloneDeep(cols))) : of([])
  }

  private workloadColumnHasFilter(): boolean {
    if (!this.grid?.gridApi) return false
    const filterModel = this.grid.gridApi.getFilterModel()
    if (!filterModel?.workload) return false

    return filterModel.workload.filter.children.length > 0
  }

  private handleReportTargetAndExecutionType() {
    if (this.defaultTarget === 'Reports' || (this.policy?.reportDefinition?.target as any) === 'Reports') {
      this.defaultTarget = 'Reports'
      this.targetSuggestions = [
        ...this.targetSuggestions,
        { value: 'Reports', displayValue: this.translateHelper.instant('playbook_Reports') },
      ]
    }
    if (this.defaultTarget) {
      this.formControls.policyDefinition.get('entity')?.setValue(this.defaultTarget)
      if (this.defaultTarget === 'Audit') {
        this.formControls.policyDefinition.get('policyType')?.setValue(ExecutionType.EventBased)
      }
    }
  }

  private getPolicyComplementaryDataAndInitForms(savedReportParams: any) {
    combineLatest([
      this.playbookService.getCategories().pipe(catchError(() => of([]))),
      this.playbookService.getPlaybooks().pipe(catchError(() => of([]))),
      this.workflowHttp.fetchActiveWorkflows().pipe(catchError(() => of({ data: { items: [] } }))),
      this.reportsService
        .getSavedReports(savedReportParams, false, false, Constants.savedReportsCategories.PortalV2)
        .pipe(pluck('savedReports')),
      this.reportsService
        .getSavedReports(savedReportParams, true, false, Constants.savedReportsCategories.PortalV2)
        .pipe(pluck('savedReports')),
      this.store.select(selectReportsDefinition),
    ]).subscribe(([cats, ps, workflowRes, savedReports, publicSavedReports, reportDefinitions]) => {
      this.reportDefinitions = reportDefinitions
      this.categories = sortBy(
        cats.filter((x) => x.isCustomCategory).map((x) => ({ value: x.id, displayValue: x.title })),
        'displayValue'
      )
      this.filteredCategories = this.categories
      this.playbooks = sortBy(
        ps.map((x) => ({
          value: x.id,
          displayValue: x.playbookGroupType === 'OutOfTheBoxPlaybook' ? this.translateHelper.instant(x.title) : x.title,
          otherDisplayValue: x.playbookGroupType === 'OutOfTheBoxPlaybook' ? 'CoreView' : 'Custom',
        })),
        'displayValue'
      )
      this.originalWorkflowList = workflowRes.data.items.map((item: any) => ({ value: item.id, displayValue: item.workflowName }))
      this.filteredCustomPlaybooks = this.playbooks
      this.workflowList = cloneDeep(this.originalWorkflowList)
      if (this.policy) {
        this.initEditCustomPolicy()
      }
      this.customReports = sortBy(
        [...(savedReports || []), ...(publicSavedReports || [])]
          .filter((customReport) => this.filterCustomReport(customReport))
          .map((x: { guid: string; reportName: string }) => ({
            value: x.guid,
            displayValue: x.reportName,
          })),
        'displayValue'
      )

      if (this.customReportId) {
        this.formControls.policyDefinition.get('entity')?.setValue('CustomReports')
        this.formControls.policyDefinition.get('customReportId')?.setValue(this.customReportId)
        this.formControls.policyDefinition.get('customReportId')?.disable()
        this.form.updateValueAndValidity()
        this.loadReport()
      } else if (this.reportDefinition) {
        this.form.updateValueAndValidity()
        this.loadReport()
      }

      this.checkIfAddSendNotification()

      this.loading = false
    })
  }

  private setSkuData(skus: any) {
    this.remediationTypeSuggestion = [this.workflowRemediationDropdownItem, this.scheduleReportRemediationDropdownItem]
    this.oldRemediationTypeSuggestion = [...this.remediationTypeSuggestion]
  }

  private isThhresholdEnabled() {
    const { policyDefinition } = this.form.getRawValue()
    return (
      (policyDefinition.entity !== 'Audit' &&
        policyDefinition.thresholdType &&
        policyDefinition.thresholdOperator &&
        policyDefinition.thresholdValue !== null &&
        policyDefinition.thresholdValue !== undefined) ||
      false
    )
  }

  private loadPolicy() {
    if (!this.policy) return
    this.filters = this.policy.reportFilters || {}
    this.treeFilters = this.policy.reportTreeFilters
    this.rangeFilter =
      !!this.policy.timeRangeFilter?.days || this.policy.timeRangeFilter?.since || this.policy.timeRangeFilter?.to
        ? {
            days: this.policy.timeRangeFilter?.days as any,
            since: this.policy.timeRangeFilter?.since,
            to: this.policy.timeRangeFilter?.to,
          }
        : undefined

    let obs

    if (this.policy.globalAndReportTreeFilters || this.policy.groupMembershipFilter) {
      this.reportsService.reportFilter = {
        targetEntity: this.policy.policyType === ExecutionType.EventBased ? 'Audit' : undefined,
        reportTreeFilters: this.policy.globalAndReportTreeFilters,
        membershipReportFilters: this.policy.groupMembershipFilter,
      }
      obs = this.reportsService.saveSessionFiltersReports(this.reportsService.reportFilter)
    } else {
      obs = of(void 0)
    }

    obs.pipe(switchMap(() => this.store.select(selectReportsDefinition))).subscribe((defs) => {
      if (!this.policy) return
      this.setGridDefinition(defs)
      this.addReportFiltersButtons()

      if (this.policy.policyExceptionFields && this.policy.policyExceptionFields.length > 0) {
        this.formControls.policyDefinition.get('policyExceptionFields')?.setValue(this.policy.policyExceptionFields)
        this.definePolicyKey = true
        this.advancedFilterOpen = true
      }
      this.entityLoaded = true

      if (this.gridDefinition?.apiUrl) {
        this.customReportLoading = false
        this.showGrid = true
      }
    })
  }

  private setGridDefinition(defs: Dictionary<ReportDefinition>) {
    if (!this.policy) return 

    this.gridDefinition = {
      ...this.gridDefinition,
      ...this.policy.reportDefinition,
      sortOrder: this.policy.reportDefinition.sortOrder ?? null,
      apiUrl: this.policy.reportDefinition.url || '',
      entity: undefined,
      dbCollectionName: this.policy.reportDefinition.entity,
      entityDisplayField:
        this.policy.exceptionsDisplayFields && this.policy.exceptionsDisplayFields.length > 0
          ? this.policy.exceptionsDisplayFields[0]
          : undefined,
      defaultDaysFilter: this.rangeFilter?.days as any,
      since: this.rangeFilter?.since,
      to: this.rangeFilter?.to,
      initialUrlFilters: this.filters,
      treeFilters: this.treeFilters,
      urlParameters: this.policy.urlParameters,
      targetEntity: lodashFisrt(
        uniq(
          values(defs)
            .filter(
              (x) =>
                (x?.url?.toLocaleLowerCase() === this.policy?.reportDefinition.url?.toLocaleLowerCase() ||
                  x?.url?.toLocaleLowerCase() === '/' + this.policy?.reportDefinition.url?.toLocaleLowerCase() ||
                  x?.url?.toLocaleLowerCase() + '/' === this.policy?.reportDefinition.url?.toLocaleLowerCase()) &&
                !!x?.targetEntity
            )
            .map((x) => x?.targetEntity)
        )
      ),
    }
  }

  private addReportFiltersButtons() {
    if (!this.gridDefinition) {
      return
    }

    this.gridDefinition.filterActions = [
      {
        text: 'reports_ReportsFilters',
        buttonType: 'tertiary',
        icon: 'filter_alt',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        cvDataTest: 'reports-filters',
        isVisible: () => {
          if (this.gridDefinition?.verb !== 'post') return false
          if (this.policyTypeIsEventBased()) return this.workloadColumnHasFilter()
          return ReportsFiltersComponent.supportedTargetTypes.includes(this.gridDefinition?.targetEntity ?? '')
        },
        onClick: () => {
          this.reportsService.showReportsFiltersPanel(
            this.policyTypeIsEventBased() ? 'Audit' : this.gridDefinition?.targetEntity,
            this.grid,
            false,
            lodashFisrt(this.grid?.gridApi?.getFilterModel()?.workload?.filter?.children?.map((x: childFilter) => x.queryFilter?.value)),
            this.grid?.gridApi?.getFilterModel()?.operation?.filter?.children?.map((x: childFilter) => x.queryFilter?.value)
          )
        },
        visibility: 'custom',
      },
      {
        text: 'reports_ClearReportsFilters',
        buttonType: 'tertiary',
        icon: 'filter_alt_off',
        classIcon: 'mat-icon-no-color',
        leftClassStyle: 'outlined',
        cvDataTest: 'clear-reports-filters',
        color: 'alert',
        isVisible: () => !isEmpty(this.reportsService.reportFilter),
        onClick: () => {
          this.reportsService.deleteSessionFiltersReports().subscribe((_) => {
            this.reportsService.reportFilter = undefined
            this.grid.refresh()
          })
        },
        visibility: 'custom',
      },
    ]
  }

  private policyTypeIsEventBased(): boolean {
    return this.formControls.policyDefinition.get('policyType')?.value === ExecutionType.EventBased
  }

  private filterCustomReport(customReport: { reportUrl: string; request: any }) {
    if (!customReport.reportUrl) return false

    if (customReport.reportUrl.includes('/policy/')) {
      return false
    }
    const reportDefinition = this.findReportDefinition(customReport)
    return (
      !!reportDefinition?.dbCollectionName &&
      !reportDefinition.url?.includes('auditactivities') &&
      !reportDefinition.actionsTags?.includes('playbook_DisablePolicyCreation')
    )
  }

  private findReportDefinition(customReport: { reportUrl: string; request: any }) {
    const route = RoutesService.reportRoutes.find((x) => x.path === customReport.reportUrl.replace('/reports/', '').replace('reports/', ''))
    return this.reportDefinitions[route?.data?.entity || ''] as ReportDefinition
  }

  private initSmartInputsFields({ data }: Workflow.Dto.V1.WorkflowDetail.Response) {
    this.dynamoFormsV1Helper.getFormattedJsonFormData(data.inputSchema).subscribe((formData) => {
      if (formData) {
        const inputMappingControls = new UntypedFormGroup({})
        this.wfInputSuggestions = []
        this.hasExecutionInputs = formData.properties && Object.keys(formData.properties).length > 0
        Object.entries(formData.properties).forEach(([key, value]) =>
          this.createControlForInput(key, value, inputMappingControls, formData)
        )
        this.formControls.remediationAction.addControl('workflowInputMappings', inputMappingControls)
        this.initializePolicy(this.policy)
      }
    })
  }

  private createControlForInput(key: string, value: any, inputMappingControls: UntypedFormGroup, formData: EnhancedJsonFormData) {
    this.wfInputSuggestions.push(this.createWfInputSuggestion(key, value, formData))
    const control = formData?.required?.find((e) => e === key)
      ? new UntypedFormControl('', Validators.required)
      : new UntypedFormControl('')
    inputMappingControls.addControl(key, control)
  }

  private initializePolicy(policy: Policy | undefined) {
    if (policy?.workflowInputMappings && !this.hasPolicyInitialized) {
      this.hasPolicyInitialized = true
      const formGroup = this.formControls.remediationAction.get('workflowInputMappings') as UntypedFormGroup
      Object.keys(formGroup?.controls).forEach((control) => {
        if (policy?.workflowInputMappings) {
          let value = policy.workflowInputMappings[control]
          if (value?.includes('report::')) {
            value = `{{${value.replace('report::', 'report.')}}}`
          }
          formGroup.get(control)?.setValue(value)
        }
      })
    }
  }

  private initChangeDetectionForm(): void {
    this.formControls.remediationAction
      .get('recurrenceData')
      ?.valueChanges.pipe(
        filter((x) => !!x),
        distinctUntilChanged(isEqual),
        generateCronExpression()
      )
      .subscribe((newValue) => {
        this.cronExpression = newValue.cronExpression
      })

    this.formControls.policyDefinition
      .get('thresholdSeverity')
      ?.valueChanges.pipe(
        filter((x) => !!x),
        distinctUntilChanged(isEqual)
      )
      .subscribe((newValue) => {
        this.onSeverityChanged(newValue)
      })
  }

  private onSeverityChanged(newValue: any) {
    if (newValue !== 'Informational') {
      if (!this.formControls.policyDefinition.get('thresholdType')?.value) {
        this.formControls.policyDefinition.get('thresholdType')?.setValue(ThresholdType.IsNumber)
        this.formControls.policyDefinition.get('thresholdOperator')?.setValue(null)
        this.formControls.policyDefinition.get('thresholdValue')?.setValue(null)
      }

      this.formControls.policyDefinition.get('isthresholdEnabled')?.setValue(true)

      this.severityIcon = { icon: 'warning', color: newValue === 'Warning' ? '#F5A623' : '#B01F24' }
    } else {
      this.severityIcon = { icon: 'info', color: '#306AC8' }

      const thresholdOperator = this.formControls.policyDefinition.get('thresholdOperator')?.value
      const thresholdValue = this.formControls.policyDefinition.get('thresholdValue')?.value
      const hasThresholdSet = thresholdOperator || thresholdValue

      if (!hasThresholdSet) {
        this.formControls.policyDefinition.get('isthresholdEnabled')?.setValue(false)
        this.clearValueIfNonePicked(false)
      }
    }
  }

  private isScheduleDateValid(): boolean {
    if (
      this.formControls.remediationAction.get('scheduleType')?.value === ScheduleType.Scheduled ||
      this.formControls.remediationAction.get('remediationType')?.value === 'ScheduleReport'
    ) {
      return (
        this.formControls.remediationAction.get('recurrenceData')?.disabled ||
        !!this.formControls.remediationAction.get('recurrenceData')?.valid
      )
    }

    return true
  }

  private checkIfAddSendNotification() {
    if (this.formControls.policyDefinition.get('entity')?.value?.toLowerCase() === 'audit') {
      if (
        this.treeFilters &&
        this.treeFilters.children.length >= 2 &&
        (this.treeFilters.children[0].queryFilter?.operation.toLowerCase() === 'equals' ||
          this.treeFilters.children[0].children?.some((x) => x.queryFilter?.operation.toLowerCase() === 'equals')) &&
        (this.treeFilters.children[1].queryFilter?.operation.toLowerCase() === 'equals' ||
          this.treeFilters.children[1].children?.some((x) => x.queryFilter?.operation.toLowerCase() === 'equals'))
      ) {
        this.remediationTypeSuggestion = [this.sendNotificationRemediationDropdownItem]
        /* when schedule report for audit use this
      this.remediationTypeSuggestion.push(this.sendNotificationRemediationDropdownItem)
      */
      } else {
        this.remediationTypeSuggestion = []
      }
      this.remediationTypeSuggestion.push(this.workflowRemediationDropdownItem)
    } else {
      this.remediationTypeSuggestion = [...this.oldRemediationTypeSuggestion]
    }
  }

  private getExceptionsDisplayFields(): string[] {
    if (!this.gridDefinition) {
      return []
    }

    switch (this.formControls.policyDefinition.get('entity')?.value) {
      case 'CustomReports':
      case 'Reports':
        return this.gridDefinition.entityDisplayField ? [this.gridDefinition.entityDisplayField] : []
      case 'User':
      case 'Mailbox':
      case 'SharePointInactiveUsers':
        return ['UserPrincipalName']
      case 'Teams':
        return ['DisplayName']
      case 'O365Group':
      case 'DistributionGroup':
        return ['Name']
      case 'MailContact':
        return ['PrimarySmtpAddress']
      case 'SharePointSites':
        return ['siteUrl']
      default:
        return []
    }
  }

  private getReportDefinition(): ReportDefinitionPolicy | undefined {
    if (!this.gridDefinition) {
      return undefined
    }

    const entityIdField = this.gridDefinition.entityIdField || (this.gridDefinition as any)?.propertyId
    const reportDefinition = {
      ...this.gridDefinition,
      sortOrder: this.gridDefinition.sortOrder,
      url: this.gridDefinition.apiUrl,
      entityIdField: entityIdField ? Helpers.capitalize(entityIdField) : '',
      target: this.formControls.policyDefinition.get('entity')?.value,
      customReportId: this.formControls.policyDefinition.get('customReportId')?.value,
      customReportName: this.formControls.policyDefinition.get('customReportId')?.value
        ? this.customReports.find((x) => x.value === this.formControls.policyDefinition.get('customReportId')?.value)?.displayValue
        : undefined,
      reportName: this.formControls.policyDefinition.get('reportName')?.value,
    }

    const entity = this.formControls.policyDefinition.get('entity')?.value
    reportDefinition.entity = this.getReportDefinitionEntity(entity, reportDefinition.dbCollectionName)

    return reportDefinition
  }

  private getReportDefinitionEntity(entity: string, dbCollectionName: string | undefined): string | undefined {
    switch (entity) {
      case 'CustomReports':
      case 'Reports':
        return dbCollectionName
      case 'User':
      case 'Mailbox':
      case 'SharePointInactiveUsers': {
        return 'OnlineUsers'
      }
      case 'Teams':
      case 'O365Group': {
        return 'Office365Groups'
      }
      case 'MailContact': {
        return 'Recipients'
      }
      case 'DistributionGroup': {
        return 'DistributionGroups'
      }
      case 'SharePointSites': {
        return 'SharePointSiteUsageDetailsReports'
      }
      case 'SecurityGroup': {
        return 'SecurityGroups'
      }
      default:
        return undefined
    }
  }

  private initEditCustomPolicy() {
    const remediationType = this.policy?.remediationType
    this.form.patchValue({
      policyDetails: {
        title: this.policy?.title,
        description: this.policy?.description,
        usefulnessDescription: this.policy?.howThisAffect,
        categories: this.getSuggestionsByIds(this.policy?.categoryIds, this.categories),
        playbooks: this.getSuggestionsByIds(this.policy?.playbookIds, this.playbooks),
        isPolicyEnabled: this.policy?.isPolicyEnabled,
      },
      policyDefinition: {
        entity: this.policy?.reportDefinition.target,
        policyExceptionFields: this.policy?.policyExceptionFields,
        customReportId: this.policy?.reportDefinition.customReportId,
        thresholdType: this.policy?.isThresholdEnabled ? this.policy?.thresholdType : ThresholdType.IsNumber,
        thresholdOperator: this.policy?.isThresholdEnabled ? this.policy?.thresholdOperator : null,
        thresholdValue: this.policy?.isThresholdEnabled ? this.policy?.thresholdValue : null,
        thresholdSeverity: this.policy?.thresholdSeverity,
        isthresholdEnabled: this.policy?.isThresholdEnabled,
        reportName: this.policy?.reportDefinition?.reportName,
        policyType: this.policy?.policyType,
      },
      remediationAction: {
        isWorkflowEnabled: this.policy?.isWorkflowEnabled,
        remediationType,
        workflowId: this.policy?.workflowId,
        scheduleType: this.policy?.scheduleType,
        notificationRecipients: this.policy?.notificationRecipients?.map((x) => ({ value: x, displayValue: x })) || [],
      },
    })

    if (this.policy?.remediationType === 'ScheduleReport') {
      this.fillRecurrenceDataFromScheduleReport()
    }

    this.onRemediationTypeChange(this.policy?.remediationType)

    if (this.policy?.reportDefinition.customReportId) {
      this.customReportSelected(this.policy?.reportDefinition.customReportId)
    }

    if (this.policy?.reportDefinition.target) {
      this.targetChanged(this.policy?.reportDefinition.target, false)
    }

    if (this.policy?.workflowId) {
      this.selectedWorkflow = this.originalWorkflowList.find((wf) => this.policy?.workflowId === wf.value)
      this.workflowSelected(this.selectedWorkflow)
    }
    if (this.policy?.scheduleType === ScheduleType.Scheduled) {
      this.isScheduleEnabled = true
    }
    if (this.policy?.schedule?.cronExpression) {
      this.cronExpression = this.policy?.schedule?.cronExpression
      const recurrence = this.policyService.createRecurrenceFromCronExpression(
        this.cronExpression,
        this.policy.schedule.startDatetime,
        this.policy.schedule.endDatetime
      )
      this.formControls.remediationAction.get('recurrenceData')?.patchValue(recurrence)
    }

    this.form.markAllAsTouched()
  }

  private loadReport() {
    if (!this.reportDefinition) {
      return
    }
    this.reportDefinition = {
      ...this.reportDefinition,
      groupedActions: undefined,
      filterActions: undefined,
    }
    this.gridDefinition = {
      ...this.reportDefinition,
      apiUrl: this.reportDefinition.url || '',
      entity: undefined,
      reportDefinitionId: this.reportDefinition.entity,
    }

    this.addReportFiltersButtons()

    this.filters = this.reportDefinition.initialUrlFilters || {}
    this.treeFilters = this.reportDefinition.treeFilters
    this.rangeFilter =
      !!this.reportDefinition?.defaultDaysFilter || this.reportDefinition?.since || this.reportDefinition?.to
        ? {
            days: this.reportDefinition?.defaultDaysFilter,
            since: this.reportDefinition?.since,
            to: this.reportDefinition?.to,
          }
        : undefined
    this.showGrid = true
    if (this.defaultTarget === 'Reports') {
      this.formControls.policyDefinition.get('reportName')?.setValue(this.reportDefinition.title || '')
    }
  }

  private createCustomPolicyRequest(reportDefinition: ReportDefinitionPolicy): CustomPolicyRequest | null {
    const { policyDetails, policyDefinition, remediationAction } = this.form.getRawValue()

    if (!(policyDetails && policyDefinition && remediationAction)) {
      return null
    }

    return {
      title: policyDetails.title,
      description: policyDetails.description,
      howThisAffect: policyDetails.usefulnessDescription,
      playbookIds: this.extractOldElementsId(policyDetails.playbooks),
      newPlaybookTitles: this.extractNewElementsTitles(policyDetails.playbooks),
      newCategoryTitles: this.extractNewElementsTitles(policyDetails.categories),
      categoryIds: this.extractOldElementsId(policyDetails.categories),
      companyId: this.companyId,
      isPolicyEnabled: policyDetails.isPolicyEnabled,
      isThresholdEnabled: policyDefinition.isthresholdEnabled,
      thresholdType: ThresholdType.IsNumber,
      thresholdOperator: policyDefinition.thresholdOperator,
      thresholdValue: policyDefinition.thresholdValue,
      policyType: policyDefinition.policyType,
      thresholdSeverity: policyDefinition.thresholdSeverity,
      reportUrl: 'api/' + this.gridDefinition?.apiUrl,
      reportDefinition,
      reportFilters: this.filters || {},
      reportTreeFilters: this.treeFilters,
      urlParameters: this.gridDefinition?.urlParameters,
      reportFieldsForWorkflow:
        this.filteredCols?.map((col) => (col?.name ? col.name[0].toUpperCase() + col.name.slice(1) : ''))?.filter((x) => !!x?.length) || [],
      policyExceptionFields: policyDefinition.policyExceptionFields,
      isWorkflowEnabled: remediationAction.isWorkflowEnabled,
      workflowId: remediationAction?.workflowId,
      remediationType: remediationAction.remediationType,
      workflowInputMappings: Object.fromEntries(
        Object.entries(remediationAction.workflowInputMappings || {}).map(([k, v]: [string, any]) => [
          k,
          v?.match(/({{[^}]*}})/g)?.length ? v.replace('{{', '').replace('}}', '').replace('report.', 'report::') : v,
        ])
      ),
      scheduleReport: this.policyService.createScheduledReport(remediationAction, this.lastRequest),
      scheduleType: remediationAction.remediationType === 'ScheduleReport' ? ScheduleType.Scheduled : remediationAction.scheduleType,
      notificationRecipients: remediationAction.notificationRecipients.map((x: Suggestion) => x.value),
      schedule:
        remediationAction.scheduleType === 'None'
          ? undefined
          : {
              cronExpression: this.cronExpression || '',
              startDatetime: remediationAction.recurrenceData.startDateTime,
              endDatetime: remediationAction.recurrenceData.endDateTime,
            },
      exceptionsDisplayFields: this.getExceptionsDisplayFields(),
      timeRangeFilter:
        !!this.rangeFilter?.days || this.rangeFilter?.since || this.rangeFilter?.to
          ? { days: this.rangeFilter?.days, since: this.rangeFilter?.since, to: this.rangeFilter?.to }
          : undefined,
      globalAndReportTreeFilters: this.reportsService.reportFilter?.reportTreeFilters,
      groupMembershipFilter: this.reportsService.reportFilter?.membershipReportFilters,
    }
  }

  private handleSavePolicy(request: CustomPolicyRequest) {
    if (this.policy) {
      this.playbookService.updateCustomPolicy(this.policy.id || '', request).subscribe(
        () => {
          this.rightPanelRef.close(this.policy?.id)
          if (this.policy?.reportUrl && this.policy.reportUrl.includes('api/onlineusers')) {
            this.notifySucces()
          }
        },
        () => this.notifyError()
      )
    } else {
      this.playbookService.createCustomPolicy(request).subscribe(
        (resp) => this.rightPanelRef.close(resp.id),
        () => this.notifyError()
      )
    }
  }

  private notifyError() {
    this.dialogHelperService.notifyUser('error', 'common_ErrorTitle', 'common_ErrorMessage')
  }

  private notifySucces() {
    this.dialogHelperService.notifyUser('success', 'common_SaveChange', 'playbook_OnlineUsersUpdated')
  }

  private resetReportSelection() {
    this.showGrid = false
    this.gridDefinition = undefined
    this.rangeFilter = undefined
    this.policyKeySuggestions = []
    this.definePolicyKey = false
    this.formControls.policyDefinition.get('policyExceptionFields')?.reset()
  }

  private manageCustomReportPolicy(customReport: any): Observable<any> {
    if (customReport.reportUrl.includes('/policy/')) {
      return this.playbookService
        .getPolicy(customReport.reportUrl.substring(customReport.reportUrl.indexOf('/policy/') + '/policy/'.length), false)
        .pipe(map((policy) => ({ customReport, policy })))
    }
    return of({ customReport, policy: null })
  }

  private handleGridDefinition(customReport: any, customReportPolicy: any, reportDefinition: ReportDefinition | null) {
    this.gridDefinition = this.createGridDefinition(customReport, customReportPolicy, reportDefinition)
    this.addReportFiltersButtons()
    if (customReport) {
      this.setReportFilters(customReport)
    }
    if (this.policy && !this.entityLoaded) {
      if (!customReport && this.policy.reportDefinition.customReportId) {
        this.customReports = [
          {
            value: this.policy.reportDefinition.customReportId,
            displayValue: this.policy.reportDefinition.customReportName || this.policy.reportDefinition.customReportId,
          },
        ]
      }
      this.loadPolicy()
    } else if (this.gridDefinition) {
      this.showGridDefinition()
    }
  }

  private createGridDefinition(customReport: any, customReportPolicy: any, reportDefinition: ReportDefinition | null): any {
    if (customReportPolicy) {
      return {
        ...customReportPolicy.reportDefinition,
        apiUrl: customReportPolicy.reportDefinition.url || '',
        fields: customReport.request.fields,
        initialUrlFilters: customReport.request.filters,
        treeFilters: customReport.request.treeFilters,
        urlParameters: customReportPolicy.urlParameters,
        sortField: customReport.request.sort,
        sortOrder: customReport.request.sortOrder,
        entity: undefined,
        defaultDaysFilter: customReport.request.days,
        since: customReport.request.since,
        to: customReport.request.to,
        dbCollectionName: customReportPolicy.reportDefinition.entity,
        entityDisplayField:
          customReportPolicy.exceptionsDisplayFields && customReportPolicy.exceptionsDisplayFields.length > 0
            ? customReportPolicy.exceptionsDisplayFields[0]
            : undefined,
        reportDefinitionId: customReportPolicy.reportDefinition.reportDefinitionId,
      }
    }
    return {
      ...reportDefinition,
      apiUrl: reportDefinition?.url || '',
      fields: customReport.request.fields,
      initialUrlFilters: customReport.request.filters,
      treeFilters: customReport.request.treeFilters,
      sortField: customReport.request.sort,
      sortOrder: customReport.request.sortOrder,
      entity: undefined,
      defaultDaysFilter: customReport.request.days,
      since: customReport.request.since,
      to: customReport.request.to,
      reportDefinitionId: reportDefinition?.entity || '',
    }
  }

  private setReportFilters(customReport: any) {
    this.filters = customReport.request.filters
    this.treeFilters = customReport.request.treeFilters

    this.rangeFilter =
      !!customReport.request?.days || customReport.request?.since || customReport.request?.to
        ? {
            days: customReport.request?.days,
            since: customReport.request?.since,
            to: customReport.request?.to,
          }
        : undefined

    if (customReport.request.reportTreeFilters || customReport.request.savedReportParams?.groupMembershipFilter) {
      this.reportsService.reportFilter = {
        reportTreeFilters: customReport.request.reportTreeFilters,
        membershipReportFilters: customReport.request.savedReportParams.groupMembershipFilter,
      }
    }
  }

  private showGridDefinition() {
    iif(
      () => !!this.reportsService.reportFilter,
      this.reportsService.saveSessionFiltersReports(this.reportsService.reportFilter),
      timer(1)
    ).subscribe(() => {
      this.customReportLoading = false
      this.showGrid = true
    })
  }

  private handleReportChange(val: any) {
    if (val === RemediationType.ScheduleReport) {
      this.formControls.remediationAction.get('scheduleType')?.setValue(ScheduleType.None, { emitEvent: false })
      this.selectedWorkflow = null
      this.isWorkflowSelected = false
      this.formControls.remediationAction.removeControl('workflowInputMappings')
      if (!(this.form.controls.remediationAction as UntypedFormGroup).contains('recipients')) {
        ;(this.form.controls.remediationAction as UntypedFormGroup).addControl(
          'recipients',
          new UntypedFormControl([], [Validators.required])
        )
      }
    } else {
      this.formControls.remediationAction.removeControl('workflowInputMappings')
      if ((this.form.controls.remediationAction as UntypedFormGroup).contains('recipients')) {
        ;(this.form.controls.remediationAction as UntypedFormGroup).removeControl('recipients')
      }
    }
  }

  private handleWorkflowChange(val: any) {
    if (val === RemediationType.Workflow) {
      if (!(this.form.controls.remediationAction as UntypedFormGroup).contains('workflowId')) {
        ;(this.form.controls.remediationAction as UntypedFormGroup).addControl(
          'workflowId',
          new UntypedFormControl(null, [Validators.required])
        )
        if (this.policy?.workflowId) {
          this.form.patchValue({
            remediationAction: {
              workflowId: this.policy?.workflowId,
            },
          })
        }
      }
    } else {
      if ((this.form.controls.remediationAction as UntypedFormGroup).contains('workflowId')) {
        ;(this.form.controls.remediationAction as UntypedFormGroup).removeControl('workflowId')
      }
    }
  }

  private handleNotificationChange(val: any) {
    if (val === RemediationType.SendNotification) {
      if (this.policy?.notificationRecipients) {
        this.form.patchValue({
          remediationAction: {
            notificationRecipients: this.policy?.notificationRecipients?.map((x) => ({ value: x, displayValue: x })) || [],
          },
        })
      }

      ;(this.form.controls.remediationAction as UntypedFormGroup).get('notificationRecipients')?.setValidators([Validators.required])
    } else {
      ;(this.form.controls.remediationAction as UntypedFormGroup).get('notificationRecipients')?.clearValidators()
    }
  }

  private createSummaryCardsShowingFields(): any[] {
    return [
      {
        filterFunc: () => !this.formControls.remediationAction.get('isWorkflowEnabled')?.value,
        visibleFields: ['isWorkflowEnabled'],
      },
      {
        filterFunc: () => this.formControls.remediationAction.get('remediationType')?.value === 'ScheduleReport',
        visibleFields: [
          'isWorkflowEnabled',
          'sendReport',
          'format',
          'emailBody',
          'scheduleType',
          'recipients',
          'recurrenceData',
          'remediationType',
        ],
      },
      {
        filterFunc: () => this.formControls.remediationAction.get('remediationType')?.value === 'Workflow',
        visibleFields: ['isWorkflowEnabled', 'scheduleType', 'recurrenceData', 'remediationType', 'workflowId', 'workflowInputMappings'],
      },
      {
        filterFunc: () => this.formControls.remediationAction.get('remediationType')?.value === 'SendNotification',
        visibleFields: ['isWorkflowEnabled', 'remediationType', 'notificationRecipients'],
      },
    ]
  }
  private preparePolicyDefinitionCard(key: string, control: { value: any }) {
    let returnValue = {}

    if (control.value === null || ['policyType', 'reportName', 'thresholdOperator', 'thresholdValue', 'thresholdType'].includes(key)) {
      returnValue = {}
    } else {
      switch (key) {
        case 'policyExceptionFields':
          returnValue = {
            [this.translateHelper.instant(Helpers.capitalize(key))]: control.value.map((item: any) =>
              this.translateHelper.instant(this.policyKeySuggestions.find((s) => s.value === item)?.value || item)
            ),
          }
          break
        case 'customReportId':
          returnValue = {
            [this.translateHelper.instant('playbook_CustomReport')]: this.customReports.find((x) => x.value === control.value)
              ?.displayValue,
          }
          break
        case 'thresholdOperator':
          returnValue = {
            [this.translateHelper.instant(Helpers.capitalize(key))]: this.thresholdOperations.find((op) => op.value === control.value)
              ?.displayValue,
          }
          break
        default:
          returnValue = { [this.translateHelper.instant(Helpers.capitalize(key))]: control.value }
          break
      }
    }

    return returnValue
  }
  private preparePolicyRemediationActionCard(key: string, control: { value: any }) {
    let returnValue = {}

    switch (key) {
      case 'workflowId':
        returnValue = {
          [this.translateHelper.instant('Workflow')]: this.selectedWorkflow?.displayValue || this.translateHelper.instant('common_Disable'),
        }
        break
      case 'recipients':
      case 'notificationRecipients':
        returnValue = { [this.translateHelper.instant(Helpers.capitalize(key))]: control.value?.map((x: any) => x.value).join(',') }
        break
      case 'recurrenceData':
        if (
          this.cronExpression &&
          !!this.formControls.remediationAction.get('isWorkflowEnabled')?.value &&
          (this.formControls.remediationAction.get('scheduleType')?.value !== 'None' ||
            this.formControls.remediationAction.get('remediationType')?.value === 'ScheduleReport')
        ) {
          returnValue = {
            [this.translateHelper.instant(Helpers.capitalize(key))]: this.cronExpressionHelper.getSchedulingString({
              cronExpression: this.cronExpression,
              startDatetime: this.formControls.remediationAction.get('recurrenceData')?.value.startDateTime,
              endDatetime: this.formControls.remediationAction.get('recurrenceData')?.value?.endDateTime,
            }),
          }
        } else {
          returnValue = {}
        }
        break
      case 'isWorkflowEnabled':
        returnValue = { [this.translateHelper.instant('IsRemediationEnabled')]: control.value }
        break
      default:
        returnValue =
          !!control.value && typeof control.value === 'object'
            ? {
                [this.translateHelper.instant(Helpers.capitalize(key))]: Object.entries(control.value).map(([k, c]) => [
                  this.translateHelper.instant(Helpers.capitalize(k)) + ': ' + c,
                ]),
              }
            : { [this.translateHelper.instant(Helpers.capitalize(key))]: control.value }
        break
    }

    return returnValue
  }

  private toggleScheduleAvailability(): void {
    this.toggleRecurrenceDataAvailability()
    this.toggleScheduleTypeAvailability()
  }

  private toggleRecurrenceDataAvailability(): void {
    const remediationType = this.formControls.remediationAction.get('remediationType')?.value
    const userCannotScheduleWorkflow = this.policy && !this.policy.canSchedule && remediationType === RemediationType.Workflow

    const recurrenceDataControl = this.formControls.remediationAction.get('recurrenceData')

    if (userCannotScheduleWorkflow) {
      recurrenceDataControl?.disable()
    } else {
      recurrenceDataControl?.enable()
    }
  }

  private toggleScheduleTypeAvailability(): void {
    const scheduleTypeControl = this.formControls.remediationAction.get('scheduleType')
    const scheduleType = scheduleTypeControl?.value

    const isNotThePolicyOwner = this.policy && !this.policy?.canSchedule && scheduleType === ScheduleType.None

    if (isNotThePolicyOwner) {
      scheduleTypeControl?.disable()
    } else {
      scheduleTypeControl?.enable()
    }
  }

  private setThresholdRequired() {
    this.formControls.policyDefinition.get('thresholdType')?.addValidators(Validators.required)
    this.formControls.policyDefinition.get('thresholdOperator')?.addValidators(Validators.required)
    this.formControls.policyDefinition.get('thresholdValue')?.addValidators(Validators.required)
  }

  private clearThresholdValidators() {
    this.formControls.policyDefinition.get('thresholdType')?.clearValidators()
    this.formControls.policyDefinition.get('thresholdOperator')?.clearValidators()
    this.formControls.policyDefinition.get('thresholdValue')?.clearValidators()
  }

  private extractOldElementsId(array: Suggestion[]): string[] {
    return array.filter((x: any) => x.value !== '-1').map((x: any) => x.value)
  }

  private extractNewElementsTitles(array: Suggestion[]): string[] {
    return array.filter((x: any) => x.value === '-1').map((x: any) => x.displayValue)
  }

  private getSuggestionsByIds(array?: string[], suggestions?: Suggestion[]) : Suggestion[] | undefined {
    return array?.map(id => suggestions?.find((c => c.value === id)))
                 .filter((x): x is Suggestion => !!x)
  }
}
