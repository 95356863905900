import { ICellRendererParams } from '@ag-grid-community/core';
import { AfterViewChecked, ChangeDetectorRef, Component } from '@angular/core';
import { Constants } from '@app/shared/utilities/constants';

@Component({
  selector: 'app-chip-cell-renderer',
  templateUrl: './chip-cell-renderer.component.html',
  styleUrls: ['./chip-cell-renderer.component.sass']
})
export class ChipCellRendererComponent implements AfterViewChecked {
  public items: any[] = [];
  public selectedItem = '';
  params!: any

  constructor(private cdRef: ChangeDetectorRef) { }


  ngAfterViewChecked() {
    //explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.cdRef.detectChanges()
  }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params
    this.items = this.formatItem(params.value)
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.items = params.value
  }

  formatItem(item: string): any[] {
    return [{ name: item, description: item }]
  }

  getChipColor(item: any) {
    if(!this.params?.param?.entity) {
      return 'primary'
    }
    return Constants.chipColorsMap[this.params.param.entity]?.[item.name] || 'primary'
  }
}
