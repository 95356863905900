<div class="elements-container" [ngClass]="{ 'elements-container-empty': type === 'Empty' || type === 'Empty2' }">
  <div class="title-container">
    <img class="flex-item icon-img" src="{{ getIconImg(type) }}" alt="{{ type !== 'Empty' && type !== 'Empty2' ? (type | translate) : '' }}" />
    <span class="flex-item title" translate [ngClass]="{ empty: type === 'Empty' || type === 'Empty2' }">{{
      type !== 'Empty' && type !== 'Empty2' ? type : ''
    }}</span>
    <span class="flex-item m-l-5 limit" *ngIf="allowSeeAll && !showItemsCount && limit && items.length > limit">({{ limit }}+)</span>
    <span class="flex-item m-l-5 limit" *ngIf="showItemsCount">({{ items.length }})</span>
    <span class="flex-item line-2 m-l-10"></span>
    <span
      class="flex-item m-l-10 see-all-results"
      style="white-space: nowrap"
      *ngIf="allowSeeAll && limit && items.length > limit"
      (click)="clickSeeAllResults(type)"
      translate
      >SeeMoreResults</span
    >
  </div>
  <div class="report-items-container">
    <div
      class="report-items-repeater"
      *ngFor="let i of items | slice : 0 : limit; index as j"
      (click)="clickManageItem($event, i)"
      (mouseenter)="showOnHover[j] = true"
      (mouseleave)="showOnHover[j] = false"
    >
      <div class="reports-item spacer">
        <div class="reports-item-child" [ngClass]="showOnHover[j] && isElmentManageable(i) ? 'manageHover' : ''">
          <span class="circle-icon-container"
            >
            <span class="circle-icon">
              <em [ngClass]="type === 'Empty' || type === 'Empty2' ? 'empty ms-Icon' : i.classIcon"></em>
            </span>

        </span>
          <div
            *ngIf="type !== 'Empty' && type !== 'Empty2'"
            class="result-name-container"
            [tooltip]="i.name"
            [display]="checkEllipsis.isEllipsed"
            tooltipClass="cv-tooltip"
          >
            <div class="result-name" [class.only-name]="!i.email && !i.category" [innerHtml]="i.name" appCheckEllipsis #checkEllipsis="appCheckEllipsis">{{ i.name }}</div>
            <div class="result-name-sub" *ngIf="i.email">{{ i.email }}</div>
            <div class="result-name-sub" *ngIf="i.category">{{ i.category }}</div>
          </div>
          <div class="result-name-container" *ngIf="type === 'Empty' || type === 'Empty2'">
            <div class="result-name-empty"></div>
          </div>
        </div>
        <cv-button
          *ngIf="isElmentManageable(i) && showOnHover[j]"
          class="manage-btn"
          buttonType="tertiary"
          [leftIcon]="'settings'"
          [text]="'Manage'"
          (click)="clickManageableElement(i)"
        ></cv-button>
      </div>
    </div>
  </div>
</div>
