import { selectDashboardLoaded } from './../../store/dashboards/dashboards.selectors'
import { DashboardDefinition } from './../models/DashboardDefinition'
import { Injectable } from '@angular/core'
import { Route, RunGuardsAndResolvers } from '@angular/router'
import { Observable } from 'rxjs'
import { tap, filter, switchMap, first } from 'rxjs/operators'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { getDashboards } from '@app/store/dashboards/dashboards.actions'
import { selectDashboards } from '@app/store/dashboards/dashboards.selectors'
import { DashboardComponent } from '@app/modules/dashboard/pages/dashboard/dashboard.component'
import { AuthGuard } from '../guards/auth.guard'
import { accessLevels } from './roleAccessMapping.service'
import { selectMenuLoaded, selectNonFilteredMenus } from '@app/store/menu/menu.selectors'
import { loadAllMenu } from '@app/store/menu/menu.actions'
import { ReportsComponent } from '@app/modules/reports/reports.component'
import { SavedReportResolver } from '@app/shared/resolvers/saved-report.resolver'
import { Helpers } from '@app/shared/utilities/helpers'
import { reportRoutes } from '@app/modules/reports/routes-definition'
import { GenericCenterComponent } from '@app/modules/reports/pages/generic-center/generic-center.component'
import { MspMultitenantDashboardComponent } from '@app/modules/multitenant/components/msp-multitenant-dashboard/msp-multitenant-dashboard.component'

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  static dashboardRoutes: Route[] = []
  static reportRoutes: Route[] = []
  static readonly multitenantRoutes: Route[] = []

  constructor(private store: Store<RootState>) {}

  dispatchMenuAndDashboards() {
    this.store.dispatch(getDashboards())
    this.store.dispatch(loadAllMenu())
  }

  loadDashboardRoutes(): Observable<any> {
    return this.store.select(selectDashboardLoaded).pipe(
      filter((x) => !!x),
      switchMap(() => this.store.select(selectDashboards)),
      tap((dashboards: (DashboardDefinition | undefined)[]) => {
        RoutesService.dashboardRoutes = dashboards
          .filter((x) => !x?.route.includes('playbook') && !x?.route.includes('reports') && !x?.route.includes('multitenant'))
          .map((x) => ({
            path: x?.route.replace('/dashboard/', ''),
            component: DashboardComponent,
            data: { accessLevel: accessLevels.user },
            canActivate: [AuthGuard],
            pathMatch: 'full',
          }))
      }),
      first()
    )
  }
  
  loadMultitenantRoutes(): Observable<any> {
    return this.store.select(selectMenuLoaded).pipe(
      filter((x) => !!x),
      switchMap(() => this.store.select(selectNonFilteredMenus)),
      tap(({ flatMenuAll }) => {
        (RoutesService as any).multitenantRoutes = flatMenuAll
          .filter((x) => x?.route?.includes('multitenant'))
          .map((x) => {            
            return {
              path: x?.route?.replace('multitenant/', '') || '/',
              data: {
                accessLevel: accessLevels[Helpers.downcase(x.accessLevel || 'User')],
                entity: 'multitenantDashboard',
              },
              component: MspMultitenantDashboardComponent,
              canActivate: [AuthGuard],
              runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' as RunGuardsAndResolvers,
              pathMatch: 'full',
            }
          })        
      }),
      first()
    )
  }

  loadReportRoutes(): Observable<any> {
    return this.store.select(selectMenuLoaded).pipe(
      filter((x) => !!x),
      switchMap(() => this.store.select(selectNonFilteredMenus)),
      tap(({ flatMenuAll }) => {
        RoutesService.reportRoutes = flatMenuAll
          .filter((x) => !x?.route?.startsWith('/playbook') && !x?.route?.startsWith('/dashboard') && !x?.route?.startsWith('/healthcheck') && !x?.route?.includes('multitenant'))          
          .map((x) => {
            const rr = reportRoutes.find((f: any) => f.path === x?.route?.replace('reports/', ''))
            return {
              path: x?.route?.replace('reports/', '') || '/',
              data: {
                accessLevel: accessLevels[Helpers.downcase(x.accessLevel || 'User')],
                entity: rr?.data?.entity,
              },
              resolve: { savedReport: SavedReportResolver },
              component: rr?.component || ReportsComponent,
              canActivate: [AuthGuard],
              runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' as RunGuardsAndResolvers,
              pathMatch: 'full',
            }
          })
        RoutesService.reportRoutes.push({
          path: 'usercard/groups/:entity/:upn',
          data: {
            accessLevel: accessLevels.user,
            entity: 'questo_va_gestito',
          },
          component: GenericCenterComponent,
        })
      }),
      first()
    )
  }
}
