<div class="universal-search-loading-container">
  <div class="content">
    <div class="main-content">
      <div class="recent-searches">
        <div class="recent-search-item"></div>
        <div class="recent-search-item"></div>
        <div class="recent-search-item"></div>
        <div class="recent-search-item"></div>
        <div class="recent-search-item"></div>
        <div class="recent-search-item"></div>
      </div>
      <div class="reports-actions">
        <div class="reports">
          <div class="report-item"></div>
          <div class="report-item"></div>
          <div class="report-item"></div>
        </div>
        <div class="actions">
          <div class="action-item"></div>
          <div class="action-item"></div>
          <div class="action-item"></div>
          <div class="action-item"></div>
          <div class="action-item"></div>
          <div class="action-item"></div>
          <div class="action-item"></div>
          <div class="action-item"></div>
        </div>
      </div>
    </div>
  </div>
</div>
