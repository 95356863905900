import { RightPanelService } from '@app/core/services/right-panel.service'
import { Component, OnInit, OnDestroy, ViewChildren, QueryList, Type, ViewChild } from '@angular/core'
import { combineLatest, Subject, of, timer, forkJoin } from 'rxjs'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter, startWith, takeUntil, switchMap, tap, take, first, delay } from 'rxjs/operators'
import { DashboardService } from '@app/core/services/dashboard.service'
import { selectMenuByRoute, selectFavorites } from '@app/store/menu/menu.selectors'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { NavItem } from '@coreview/coreview-library/models/nav-item'
import { ButtonComponent, HorizontalTabGroupComponent, Suggestion } from '@coreview/coreview-components'
import { BreadcrumbService, TranslateHelper } from '@coreview/coreview-library'
import { cloneDeep, isEqual, trimStart } from 'lodash-es'
import { AbstractCardComponent } from '@app/shared/components/cards/abstract-card.component'
import { selectedOrganization, selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { ReportsService } from '@app/core/services/reports.service'
import { ServerResponse } from '@app/core/models/ServerResponse'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { GridOptions } from '@ag-grid-community/core'
import dayjs from 'dayjs'
import { SelectionAction } from '@app/core/models/ReportDefinition'
import { BuildColParameter } from '@app/shared/utilities/build-col-ag-grid'
import { ClientDatagridComponent } from '@app/shared/components/client-datagrid/client-datagrid.component'
import { CustomOption } from '@app/modules/cards/Card'
import { ServicesUsageFilterComponent } from '../../components/services-usage-filter/services-usage-filter.component'
import { Organization } from '@app/core/models/Organization'
import { DashboardDefinition, DashboardTab } from '@app/core/models/DashboardDefinition'
import { selectDashboardByRoute } from '@app/store/dashboards/dashboards.selectors'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { Helpers } from '@app/shared/utilities/helpers'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChildren(AbstractCardComponent)
  set setCards(cards: QueryList<AbstractCardComponent>) {
    this.cards = cards
  }

  @ViewChild(HorizontalTabGroupComponent)
  set setTabGroup(tabGroup: HorizontalTabGroupComponent) {
    this.tabGroup = tabGroup
  }

  @ViewChild('grid')
  set setGrid(grid: DatagridComponent) {
    if (grid) {
      this.grid = grid
    }
  }

  @ViewChild('clientGrid')
  set setClientGrid(grid: ClientDatagridComponent) {
    if (grid) {
      this.clientGrid = grid
    }
  }

  grid!: DatagridComponent
  clientGrid!: ClientDatagridComponent

  tabGroup!: HorizontalTabGroupComponent

  dashboardDefinition!: DashboardDefinition | undefined
  data: any

  showDescription = false

  menu!: NavItem | undefined

  daysValue: { since?: string; to?: string; days?: number; isOnStart?: boolean } = { days: 30, isOnStart: true }
  componentFilter: any = {}

  organization!: Organization | undefined

  loading = false
  loadingTable = false
  pathParams!: any
  showNoDataWarning = false

  public columnsParameters!: BuildColParameter
  snapshotToCompare!: string
  snapshotToView!: string
  snapshotsDates: Suggestion[] = []
  gridOptionsSnapshots: GridOptions = {
    defaultColDef: {
      sortable: true,
      resizable: false,
      filter: true,
      floatingFilter: true,
      filterParams: {
        suppressAndOrCondition: true,
      },
    },
    columnDefs: [],
    groupIncludeTotalFooter: true
  }

  private destroyed$: Subject<boolean> = new Subject()
  private route!: string
  private cards!: QueryList<AbstractCardComponent>
  private skus: readonly string[] | undefined = []

  private filtersComponentsMap: Record<string, Type<unknown>> = {
    servicesUsageFilter: ServicesUsageFilterComponent,
  }

  constructor(
    private dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<RootState>,
    private breadcrumbService: BreadcrumbService,
    private translateHelper: TranslateHelper,
    private rightPanelService: RightPanelService,
    private sharedHelperService: SharedHelperService,
    private service: ReportsService
  ) { }

  ngOnInit(): void {
    this.pathParams = this.activatedRoute.snapshot.params

    this.loading = true
    this.router.events
      .pipe(
        filter((eventRes: any) => eventRes instanceof NavigationEnd),
        startWith(this.router),
        takeUntil(this.destroyed$),
        tap((event) => (this.route = event.url)),
        switchMap((event) => combineLatest([this.store.select(selectDashboardByRoute(this.route)),
          this.store.select(selectedOrganization).pipe(
            filter((x) => !!x),
            first()
          )
          ])
        ),
        tap(([definition, organization]) => {
          this.dashboardDefinition = cloneDeep(definition)
          this.organization = organization
        }),
        switchMap(([definition]) =>
          combineLatest([
            this.dashboardService.getDashboardDetails(),
            definition?.dataUrl ? this.dashboardService.getData(definition.dataUrl, definition.dataProperty || '', this.daysValue) : of({}),
            definition?.initDataUrl ? this.dashboardService.getData(definition.initDataUrl, definition.initDataProperty || '') : of({})
          ])
        )
      )
      .subscribe(([genericData, detailsData, initialsData]) => {
        if (this.dashboardDefinition?.titleFunction) {
          this.dashboardDefinition.title = new Function('data', this.dashboardDefinition.titleFunction)(genericData)
        }

        if (this.dashboardDefinition?.descriptionFunction) {
          this.dashboardDefinition.description = new Function('data', this.dashboardDefinition.descriptionFunction)(genericData)
        }

        this.dashboardLoaded(detailsData, genericData, initialsData)

        this.loadMenuAndFavorite()

        timer(10).subscribe(() => {
          this.loadCards()
        })
      })

    this.store
      .select(selectedOrganizationSkus)
      .pipe(
        tap((skus) => (this.skus = skus)),
        delay(10)
      )
      .subscribe(() => this.tabGroup?.click(0))


    this.breadcrumbService.addComponent({
      type: ButtonComponent,
      data: {
        buttonType: 'tertiary',
        text: this.translateHelper.instant('common_Reload'),
        leftIcon: 'autorenew',
        size: 'tiny',
      },
      events: {
        clicked: (e: any) => this.reloadDashboardData(),
      },
    })

    this.sharedHelperService.manageScroll('.cards-container')
  }

  public showWarnigMessages() {
    if (this.dashboardDefinition?.warningMessage) {
      if (this.dashboardDefinition?.warningMessage?.condition === 'all-true') {
        this.showNoDataWarning = this.cards.reduce((prev, curr) => prev && !!curr.showNoDataWarning && curr.showNoDataWarning(), true as boolean)
      } else if (this.dashboardDefinition?.warningMessage?.condition === 'any-true')
        this.showNoDataWarning = this.cards.reduce((prev, curr) => prev || !!curr.showNoDataWarning && curr.showNoDataWarning(), this.showNoDataWarning)
    }
  }

  public dashboardLoaded(detailsData: any, genericData: any, initialsData: any) {
    if (this.dashboardDefinition?.dataMapFunction) {
      detailsData = new Function('data', 'pathParams', this.dashboardDefinition.dataMapFunction)(detailsData, this.pathParams)
    }

    this.data = { ...genericData, ...detailsData }

    this.buildOptionsInFilterActionsForTabs(initialsData)
  }

  public loadCards() {
    forkJoin(this.cards?.map((x) => x.load()))
    .subscribe(_ => {
      this.showWarnigMessages()
      this.loading = false
    })
  }

  buildOptionsInFilterActionsForTabs(initialsData: any) {
    if (!!this.dashboardDefinition?.tabs && this.dashboardDefinition?.tabs.length > 0) {
      this.dashboardDefinition?.tabs.forEach((tab: DashboardTab) => {
        tab.cards?.forEach((card: any) => {
          if (card.type === 'table') {
            card.reportDefinition.filterActions?.forEach((action: SelectionAction) => {
              if (action.mapResultFunction) {
                action.options = new Function('data', 'dayjs', action.mapResultFunction)(initialsData, dayjs)
              }
            });
          }
          else if (card.type === 'chart') {
            card.charts?.forEach((chart: any) => {
              if (chart.options?.genericDropdownFilters) {
                chart.options.genericDropdownFilters.forEach((genericFilter: any) => {
                  if (genericFilter.mapResultFunction) {
                    genericFilter.options = new Function('data', 'dayjs', genericFilter.mapResultFunction)(initialsData, dayjs)
                  }

                  if (!!genericFilter.apiParamsToUpdate && !!genericFilter.apiParamsFunction) {
                    chart.apiParams[genericFilter.apiParamsToUpdate] = new Function('data', genericFilter.apiParamsFunction)(initialsData)
                  }
                });
              }
            })
          }
          else if (card.type === 'custom-chart') {
            card.customOptions?.forEach((option: CustomOption) => {
              if (option.getDataFromInit) {
                if (option.mapResultFunction) {
                  option.suggestions = option.mapResultFunction(initialsData, { dayjs })

                  if (option.suggestions) {
                    option.value = option.suggestions
                    option.displayValue = option.suggestions
                  }
                }
              }
            })
          }
        })
      });
    }
  }

  ngOnDestroy() {
    this.breadcrumbService.clear()
    this.sharedHelperService.cleanupScrollListeners('.cards-container')
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  rangeFilterChanged(daysFilter: { since?: string; to?: string; days?: number; isOnStart?: boolean }) {
    if (!isEqual(this.daysValue, daysFilter)) {
      this.daysValue = daysFilter
      this.reloadDashboardData()
    }
  }

  openFilterPanel() {
    if (this.dashboardDefinition?.filterComponent) {
      const panelRef = this.rightPanelService.open({
        type: this.filtersComponentsMap[this.dashboardDefinition?.filterComponent],
        data: { organization: this.organization, filter: this.componentFilter, width: '50%' },
      })

      panelRef.afterClosed().subscribe((f: any) => {
        this.componentFilter = f || {}
        this.loading = true
        timer(10).subscribe(() => {
          this.cards.forEach((x) => x.load()?.subscribe())
          this.loading = false
        })
      })
    }
  }

  tabChanged() {
    this.loading = true
    timer(10).subscribe(() => {
      this.loadCards()
    })
  }

  getRefreshData(card: any): any {
    return (params: ApiDataParameters) => ({
      // eslint-disable-next-line max-len
      items: this.getItems$(card.reportDefinition?.url || '', card.reportDefinition?.verb || 'post', this.getReportParams(card, params)),
      cols: card.reportDefinition?.isOnlineUsersType ? this.onlineUsersColumns() : null,
    })
  }

  getPageData$(card: any): any {
    return of(card.reportDefinition)
  }

  getItems$ = (reportUrl: string, verb: Verb, params: any) => (this.service.getData(reportUrl, verb, params) || of({} as ServerResponse<any>))
  onlineUsersColumns = () => this.store.select(selectOnlineuserColumns)

  getReportParams(card: any, params: ApiDataParameters): any {
    const reportParams = { ...params }
    if (card.reportDefinition?.urlParameters) {
      Object.assign(reportParams, card.reportDefinition.urlParameters)
    }
    return reportParams
  }

  reloadDashboardData() {
    this.loading = true
    combineLatest([
      this.dashboardService.getDashboardDetails(),
      this.dashboardDefinition?.dataUrl ? this.dashboardService.getData(this.dashboardDefinition.dataUrl, this.dashboardDefinition.dataProperty || '', this.daysValue) : of({}),
      this.dashboardDefinition?.initDataUrl ? this.dashboardService.getData(this.dashboardDefinition.initDataUrl, this.dashboardDefinition.initDataProperty || '') : of({})
    ])
      .pipe(take(1))
      .subscribe(([genericData, detailsData, initialsData]) => {

        this.dashboardLoaded(detailsData, genericData, initialsData)

        timer(10).subscribe(() => {
          this.loadCards()

          this.grid?.refresh()
          this.clientGrid?.refresh()
        })
      })
  }

  private loadMenuAndFavorite() {
    combineLatest([this.store.select(selectMenuByRoute(trimStart(this.route, '/'))), this.store.select(selectFavorites)])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([menu, favorites]) => {
        this.menu = menu
        if (this.menu) {
          this.menu.isFavorite = !!favorites.favoriteMenus.find((x: any) => this.menu?.id === x)
        }
        this.breadcrumbService.updatePath(this.getPath())
      })
  }

  private getPath(): string[] {
    return Helpers.getPath(this.menu, this.translateHelper)
  }
}
