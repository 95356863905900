
import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { Organization } from "@app/core/models/Organization"
import { CustomOption } from "@app/modules/cards/Card"
import { LicenseHelper } from "@app/modules/management/services/license.helper"
import { Constants } from "@app/shared/utilities/constants"
import { TranslateHelper } from '@coreview/coreview-library'
import { Suggestion } from "@coreview/coreview-components/utils/types"
import { ReportsComponentHelper } from "@app/shared/utilities/reports-component-helper"

@Injectable({
  providedIn: 'root',
})
export class LicenseOptimizationCenterService {

  exportOptionsWithoutPdf = Constants.exportOptions.filter(options => options.key !== 'pdf')

  constructor(private router: Router,
    private translateHelper: TranslateHelper,
    private window: Window,
    public reportsComponentHelper: ReportsComponentHelper,
  ) {}

  getExportAction(onClick: any, options?: any) {
    return {
      ...this.reportsComponentHelper.exportAction,
      isMenuButton: true,
      options: options ?? Constants.exportOptions,
      onClick,
    }
  }

  getSaveCustomReportAction(onClick: any) {
    return {
      ...this.reportsComponentHelper.saveCustomReportAction,
      onClick
    }
  }

  getScheduleAction(onClick: any) {
    return {
      ...this.reportsComponentHelper.scheduleAction,
      onClick,
    }
  }

  goToUrlClick(url: string, params?: any) {
    const serializedUrl = this.router.serializeUrl(this.router.createUrlTree([url], { queryParams: params }));
    this.window.open(serializedUrl, '_blank');
  }

  public getLicenseOptimizationCenterDefinition(params: {
    cardSavingOppsTableDisplayByOnClick: (event: any, key: string) => void,
    cardSavingOppsTableViewOnClick: (event: any, key: string) => void,
    cardSavingOppsTableCompareToOnClick: (event: any, key: string) => void,
    cardDuplicatedPlansTableOnClick: () => void,
    cardLicensesServiceUsageOnClick: (event: any, key: string) => void,
    cardLicensesAdvancedFiltersOnClick: () => void,
    cardOptimizationLicensesOnClick: () => void,
    licenseHelper: LicenseHelper,
    exportReport: (key: string) => void,
    exportReportLicenseByUser: (key: string) => void,
    saveView: () => void,
    schedule: () => void,
    serviceUsageGroupByItems: string[],
    organization?: Organization
  }
  ): { tabs: any[] } {
    /* eslint-disable @typescript-eslint/quotes */
    const licenseOptimizationDashboardDefinition = {
      route: '/license/optimizationCenter/*',
      initDataUrl: 'licensepool/savingopportunities/dropdown',
      dataUrl: 'licensepool/savingopportunities/counters',
      title: 'OptimizationCenter',
      description: 'dashboard_OptimizationCenterDescription',
      tabs: [
        {
          title: 'licenses_Savings',
          icon: '',
          role: 'tenantAdmin',
          cards: [
            {
              hideButtons: true,
              leftInfos: [
                {
                  iconColor: 'var(--alert-color)',
                  icon: 'person',
                  functionProperty: 'return data.disabledLicensedUsers',
                  text: 'licenses_DisabledLicensedUsers',
                  description: 'licenses_DisabledLicensedUsersDescription',
                },
              ],
              clickFunction: () => this.goToUrlClick('/reports/users/users', { PrimaryLicensesCount: '>0', BlockCredential: true }),
            },
            {
              hideButtons: true,
              title: '',
              leftInfos: [
                {
                  iconColor: 'var(--grey-color)',
                  icon: 'person',
                  functionProperty: 'return data.inactiveLicensedUsers',
                  text: 'licenses_InactiveUsers',
                  description: 'licenses_InactiveUsersDescription',
                },
              ],
              clickFunction: () =>
                this.goToUrlClick('/reports/users/users', { PrimaryLicensesCount: '>0', BlockCredential: false, IsActive90: false }),
            },
            {
              hideButtons: true,
              title: '',
              leftInfos: [
                {
                  iconColor: 'var(--warning-color)',
                  icon: 'person',
                  functionProperty: 'return data.overLicensedUsers',
                  text: 'licenses_OverLicensedUsers',
                  description: 'licenses_OverLicensedUsersDescription',
                },
              ],
              clickFunction: () =>
                this.goToUrlClick('/reports/users/users', { PrimaryLicensesCount: '>1', BlockCredential: false, IsActive90: true }),
            },
            {
              hideButtons: true,
              title: '',
              leftInfos: [
                {
                  iconColor: 'var(--primary-color)',
                  icon: 'person',
                  functionProperty: 'return data.totalUsers',
                  text: 'licenses_TotalUsers',
                  description: 'licenses_TotalUsersDescription',
                },
              ],
              clickFunction: () => this.goToUrlClick('/reports/users/users'),
            },
            {
              title: 'licenses_UnnecessarySpendingTrend',
              type: 'custom-chart',
              customOptions: [
                {
                  name: 'common_DisplayAs',
                  type: 'select',
                  value: '3',
                  displayValue: 'common_3Months',
                  icon: 'date_range',
                  suggestions: [
                    { value: '3', displayValue: 'common_3Months' },
                    { value: '6', displayValue: 'common_6Months' },
                    { value: '12', displayValue: 'common_12Months' },
                    { value: '24', displayValue: 'common_24Months' },
                  ],
                  paramKey: 'months',
                },
                {
                  name: 'common_View',
                  type: 'select',
                  value: 'InactiveLicensedUsers',
                  displayValue: 'licenses_InactiveLicensedUsers',
                  icon: 'visibility',
                  suggestions: [
                    { value: 'InactiveLicensedUsers', displayValue: 'licenses_InactiveLicensedUsers' },
                    { value: 'DisabledLicensedUsers', displayValue: 'licenses_DisabledLicensedUsers' },
                    { value: 'OverLicensedUsers', displayValue: 'licenses_OverLicensedUsers' },
                    { value: 'InactiveLicensedAccountsCost', displayValue: 'licenses_InactiveLicensedAccountsCost' },
                    { value: 'DisabledLicensedAccountsCost', displayValue: 'licenses_DisabledLicensedAccountsCost' },
                    { value: 'OverLicensedAccountsCost', displayValue: 'licenses_OverLicensedAccountsCost' },
                  ],
                  paramKey: 'field',
                },
              ],
              charts: [
                {
                  apiUrl: '/licensepool/savingopportunities/chart/overspending',
                  resultItem: 'overspending',
                  apiParams: {
                    metadata: true,
                    field: 'InactiveLicensedUsers',
                    months: '3',
                  },
                  mapResultFunction:
                    "if(data.errorCode) { return { responseError: data } } else data.overspending = {categories: data.chart.series[0].data.map(x => dayjs.utc(x.name).format('YYYY-MM')), series: [{name:'Spending',data:data.chart.series[0].data.map(x => (x.y))}]}; return data;",
                  useDataSeries: true,
                  useDataCategories: true,
                  config: {
                    title: {
                      text: '',
                    },
                    xAxis: {
                      type: 'datetime',
                    },
                    chart: {
                      id: 'chartContainer',
                      type: 'column',
                    },
                    yAxis: {
                      min: 0.0,
                      title: {
                        text: '',
                        align: 'high',
                      },
                      allowDecimals: false,
                    },
                  },
                },
              ],
            },
            {
              title: 'licenses_CompareSnapshots',
              // description: 'licenses_CompareSnapshotsDescription',
              type: 'custom-chart',
              customOptions: [
                {
                  name: 'common_DisplayAs',
                  type: 'select',
                  value: 'Number',
                  displayValue: 'licenses_Number',
                  icon: 'visibility',
                  suggestions: [
                    { value: 'Number', displayValue: 'licenses_Number' },
                    { value: 'Currency', displayValue: 'licenses_Currency' },
                    { value: 'Percentage', displayValue: 'licenses_Percentage' },
                  ],
                  paramKey: 'displayBy',
                },
                {
                  name: 'licenses_CompareSnapshotsChartView',
                  type: 'select',
                  icon: 'date_range',
                  getDataFromInit: true,
                  value: null,
                  displayValue: null,
                  suggestions: [],
                  paramKey: 'target',
                  paramMapFunction: 'if(Array.isArray(data) && data.length > 0) { return data[0].value } else { return data }',
                  paramMapFunctionDisplayValue: 'if(Array.isArray(data) && data.length > 0) { return data[0].displayValue } else { return data }',
                  mapResultFunction: (data: any, services: { dayjs: any }) => {
                    if(data.errorCode === 'NoContent') {
                      return []
                    }
                    const sugg = data.dateTimes.map((x: any, i: any) => ({
                      value: services.dayjs.utc(x).format('YYYY-MM'),
                      displayValue: 'View_' + services.dayjs.utc(x).format('YYYY-MM'),
                    }))
                    return sugg
                  },
                  checkIsDisabledSuggestionFunction: (suggestion: Suggestion, customOptions: CustomOption[]) => {
                    const valueToCheck = customOptions.find((c) => c.name === 'licenses_CompareSnapshotsChartCompareTo')?.value
                    return suggestion.value === valueToCheck
                  },
                },
                {
                  name: 'licenses_CompareSnapshotsChartCompareTo',
                  type: 'select',
                  icon: 'date_range',
                  getDataFromInit: true,
                  value: null,
                  displayValue: null,
                  suggestions: [],
                  paramKey: 'source',
                  paramMapFunction:
                    'if(Array.isArray(data) && data.length > 0) { if(data.length === 1) { return data[0].value } else { return data[1].value } } else { return data }',
                  paramMapFunctionDisplayValue:
                    'if(Array.isArray(data) && data.length > 0) { if(data.length === 1) { return data[0].displayValue } else { return data[1].displayValue } } else { return data }',
                  mapResultFunction: (data: any, services: { dayjs: any }) => {
                    if(data.errorCode === 'NoContent') {
                      return []
                    }
                    const sugg = data.dateTimes.map((x: any, i: any) => ({
                      value: services.dayjs.utc(x).format('YYYY-MM'),
                      displayValue: 'CompareTo_' + services.dayjs.utc(x).format('YYYY-MM'),
                    }))
                    return sugg
                  },
                  checkIsDisabledSuggestionFunction: (suggestion: Suggestion, customOptions: CustomOption[]) => {
                    const valueToCheck = customOptions.find((c) => c.name === 'licenses_CompareSnapshotsChartView')?.value
                    return suggestion.value === valueToCheck
                  },
                },
              ],
              charts: [
                {
                  apiUrl: '/licensepool/savingopportunities/chart/snapshots',
                  resultItem: 'snapshots',
                  apiParams: {
                    metadata: true,
                    displayBy: 'Number',
                  },
                  mapResultFunction:
                    "if(data.errorCode) { return { responseError: data } } else data.snapshots = { categories: data.chart.series.map(x => dayjs.utc(x.name).format('YYYY-MM')), series: [{name:'Disabled', data: [data.chart.series[0].data.find(d => d.name === 'Disabled').y, data.chart.series[1].data.find(d => d.name === 'Disabled').y] }, {name:'Inactive', data: [data.chart.series[0].data.find(d => d.name === 'Inactive').y, data.chart.series[1].data.find(d => d.name === 'Inactive').y] }, {name:'OverLicensed', data: [data.chart.series[0].data.find(d => d.name === 'OverLicensed').y, data.chart.series[1].data.find(d => d.name === 'OverLicensed').y] }]}; return data;",
                  useDataSeries: true,
                  useDataCategories: true,
                  config: {
                    title: {
                      text: '',
                    },
                    chart: {
                      id: 'chartContainer',
                      type: 'column',
                    },
                    yAxis: {
                      min: 0.0,
                      title: {
                        text: '',
                        align: 'high',
                      },
                      allowDecimals: false,
                    },
                  },
                },
              ],
            },
            {
              loading: false,
              title: 'savingOppsTable',
              type: 'table',
              reportDefinition: {
                gridOptions: {
                  groupIncludeTotalFooter: true,
                },
                entity: 'savingOpps',
                title: '',
                rowSelection: 'none',
                urlParameters: {
                  displayBy: 'Number',
                },
                fields: [
                  'Name',
                  'TotalUsers',
                  'WithoutPrimaryLicenseUsers',
                  'WithPrimaryLicenseUsers',
                  'DisabledLicensedUsers',
                  'InactiveLicensedUsers',
                  'OverLicensedUsers',
                ],
                responseItemProp: 'licensePoolSavingOpportunities',
                sortField: 'Name',
                sortOrder: 'asc',
                url: '/licensepool/report/savingopportunities',
                verb: 'get',
                filterActions: [
                  {
                    text: 'licenses_DisplayBy',
                    showSelectionText: true,
                    buttonType: 'tertiary',
                    isMenuButton: true,
                    options: [
                      { key: 'Number', text: 'Number' },
                      { key: 'Currency', text: 'Currency' },
                      { key: 'Percentage', text: 'Percentage' },
                    ],
                    icon: '',
                    onClick: params.cardSavingOppsTableDisplayByOnClick
                  },
                  {
                    text: 'licenses_View',
                    showSelectionText: true,
                    buttonType: 'tertiary',
                    isMenuButton: true,
                    options: [],
                    mapResultFunction:
                      "return data.dateTimes?.map((x, i) => { return { key: dayjs.utc(x), text: dayjs.utc(x).format('YYYY-MM'), isSelected: i === 0 }}) || [];",
                    icon: '',
                    onClick: params.cardSavingOppsTableViewOnClick
                  },
                  {
                    text: 'licenses_CompareTo',
                    showSelectionText: true,
                    buttonType: 'tertiary',
                    isMenuButton: true,
                    options: [],
                    mapResultFunction:
                      "return [...[{ key: 'None', text: 'None', isSelected: true }], ...(data.dateTimes?.map((x, i) => { return { key: dayjs.utc(x), text: dayjs.utc(x).format('YYYY-MM'), isDisabled: i === 0 }}) || [])];",
                    otherFilterToExcludeValue: 'licenses_View',
                    icon: '',
                    onClick: params.cardSavingOppsTableCompareToOnClick
                  },
                ],
                /* eslint-disable @typescript-eslint/naming-convention */
                operationsOnCols: params.licenseHelper.getSavingsOperationsOnCols(),
                /* eslint-enable @typescript-eslint/naming-convention */
              },
            },
          ],
        },
        {
          title: 'licenses_Licenses',
          icon: '',
          role: 'tenantAdmin',
          calendarType: 'ChooseLastDays',
          daysList: [30, 90],
          defaultDaysFilter: 90,
          cards: [
            {
              loading: false,
              title: 'licenseOptimizationTable',
              type: 'table',
              reportDefinition: {
                targetEntity: 'User',
                entity: 'licenseOptimization',
                isOnlineUsersType: true,
                actionsTags: ['reports_WithWorkflow', 'category_Users', 'report_User', 'category_Mailbox', 'LegacyLicenseFilter'],
                title: 'licenses_Licenses',
                rowSelection: 'multiple',
                fields: [
                  'UserPrincipalName',
                  'DisplayName',
                  'RecipientTypeDetails',
                  'Company',
                  'Department',
                  'Domain',
                  'Country',
                  'Office',
                  'LicensePoolGroupName',
                  'LastLogonTime',
                  'OneDriveLastAction',
                  'SharePointLastAction',
                  'LyncLastActivityTime',
                  'OfficeProPlusCount',
                  'YammerLastActivityDate',
                  'TeamsLastActivityDate',
                  'PowerBILastAction',
                  'MsVisioLastAction',
                  'MsProjectLastAction',
                  'WhenCreatedUTC',
                  'LicensePoolDiscriminator',
                  'Cost',
                  'Currency',
                ],
                responseItemProp: 'items',
                sortField: 'DisplayName',
                sortOrder: 'asc',
                url: '/licenses/optmization/' + params.organization?.guid + '/onlineusersbyactiveplansgroup',
                verb: 'post',
                filterActions: [
                  {
                    text: 'licenses_AdvancedFilters',
                    buttonType: 'tertiary',
                    icon: 'filter_alt',
                    visibility: 'custom',
                    onClick: params.cardOptimizationLicensesOnClick
                  },
                ],
                groupedActions: [
                  this.getExportAction((data: any[], key: string | null) => { params.exportReport(key!) }),
                  this.getSaveCustomReportAction(() => { params.saveView() }),
                  this.getScheduleAction(() => { params.schedule() }),
                ],
                operationsOnCols: params.licenseHelper.getLicensesOperationsOnCols(),
                lockedColumns: ['UserPrincipalName'],
              },
            },
          ],
        },
        {
          title: 'licenses_LicenseByUser',
          icon: '',
          role: 'tenantAdmin',
          cards: [
            {
              loading: false,
              title: 'licenseByUserTable',
              type: 'table',
              reportDefinition: {
                actionsTags: ['licenseByUser', 'reports_WithWorkflow'],
                entity: 'licenseByUser',
                title: 'licenses_LicenseByUser',
                isOnlineUsersType: true,
                fields: ['UserPrincipalName', 'DisplayName', 'LicensePoolGroupName', 'AccountSku', 'CopilotInteractionDate', 'MicrosoftServicePlans', 'ServicePlans'],
                responseItemProp: 'licenseDetails',
                sortField: 'DisplayName',
                sortOrder: 'asc',
                url: '/licences',
                verb: 'post',
                filterActions: [],
                groupedActions: [
                  this.getExportAction((data: any[], key: string | null) => {params.exportReportLicenseByUser(key!)}, this.exportOptionsWithoutPdf),
                  this.getSaveCustomReportAction(() => { params.saveView() }),
                  this.getScheduleAction(() => { params.schedule() }),
                ],
                operationsOnCols: params.licenseHelper.getLicenseByUserOperationsOnCols(),
                targetEntity: 'User'
              },
            },
          ],
        },
        {
          title: 'licenses_ServiceUsage',
          icon: '',
          role: 'tenantAdmin',
          calendarType: 'ChooseLastDays',
          daysList: [30, 60, 90],
          defaultDaysFilter: 30,
          cards: [
            {
              loading: false,
              title: 'serviceUsageTable',
              type: 'table',
              reportDefinition: {
                actionsTags: ['report_ServiceUsage', 'reports_WithWorkflow', 'feature_NonVTenantSensitive', 'LegacyLicenseFilter'],
                entity: '',
                title: 'licenses_ServiceUsage',
                isOnlineUsersType: false,
                urlParameters: {
                  groupBy: 'User',
                  includeCosts: true,
                  includeNewUsers: false,
                },
                fields: ['Licenses', 'UserPrincipalName', 'DisplayName', 'RecipientTypeDetails', 'Company', 'Department', 'Domain', 'Country', 'Office', 'LicensePoolGroupName', 'LastLogonTime', 'OneDriveLastAction', 'O365ProPlusLastActivationDate', 'SharePointLastAction', 'LyncLastActivityTime', 'OfficeProPlusCount', 'YammerLastActivityDate', 'TeamsLastActivityDate', 'PowerBILastAction', 'CrmLastActivityDate', 'AdLastLogonDate', 'LastAzureADLogon', 'WhenCreatedUTC', 'LicensePoolDiscriminator', 'Cost', 'Currency', 'M365AppLastActivityDate', 'CopilotInteractionDate', 'MsVisioLastAction', 'MsProjectLastAction'],
                responseItemProp: 'items',
                sortField: 'UserPrincipalName',
                sortOrder: 'asc',
                url: '/onlineusers/grouped',
                verb: 'get',
                filterActions: [
                  {
                    text: 'licenses_GroupBy',
                    showSelectionText: true,
                    buttonType: 'tertiary',
                    isMenuButton: true,
                    options: params.serviceUsageGroupByItems.map((i: string) => ({
                      key: i,
                      text: this.translateHelper.instant('common_' + i),
                    })),
                    icon: '',
                    onClick: params.cardLicensesServiceUsageOnClick
                  },
                  {
                    text: 'licenses_AdvancedFilters',
                    buttonType: 'tertiary',
                    icon: 'filter_alt',
                    visibility: 'custom',
                    onClick: params.cardLicensesAdvancedFiltersOnClick
                  },
                ],
                groupedActions: [
                  this.getExportAction((data: any[], key: string | null) => { params.exportReport(key!) }),
                  this.getSaveCustomReportAction(() => { params.saveView() }),
                  this.getScheduleAction(() => { params.schedule() })                  
                ],
                operationsOnCols: params.licenseHelper.getServiceUsageOperationsOnCols(),
                entityIdField: 'userPrincipalName',
                targetEntity: 'User'
              },
            },
          ],
        },
        {
          title: 'licenses_DuplicatedPlans',
          icon: '',
          role: 'tenantAdmin',
          cards: [
            {
              loading: false,
              title: 'duplicatedPlansTable',
              type: 'table',
              reportDefinition: {
                actionsTags: ['reports_WithWorkflow', 'report_User', 'LegacyLicenseFilter'],
                targetEntity: 'User',
                entity: 'duplicatedPlans',
                isOnlineUsersType: true,
                title: 'licenses_DuplicatedPlans',
                rowSelection: 'multiple',
                fields: ['UserPrincipalName', 'EnabledFriendlyServicePlans', 'DisabledFriendlyServicePlans', 'DisplayName', 'Company', 'Department', 'Country'],
                lockedColumns: ['UserPrincipalName', 'EnabledFriendlyServicePlans', 'DisabledFriendlyServicePlans'],
                responseItemProp: 'items',
                sortField: 'DisplayName',
                sortOrder: 'asc',
                url: '/licenses/optmization/' + params.organization?.guid + '/onlineusersduplicatedplans',
                verb: 'post',
                filterActions: [
                  {
                    text: 'licenses_AdvancedFilters',
                    buttonType: 'tertiary',
                    icon: 'filter_alt',
                    visibility: 'custom',
                    onClick: params.cardDuplicatedPlansTableOnClick
                  },
                ],
                groupedActions: [
                  this.getExportAction((data: any[], key: string | null) => { params.exportReport(key!) }),
                  this.getSaveCustomReportAction(() => { params.saveView() }),
                  this.getScheduleAction(() => { params.schedule() })                  
                ],
                operationsOnCols: params.licenseHelper.getLicensesOperationsOnCols(),
              },
            },
          ],
        },
      ],
    }

    return licenseOptimizationDashboardDefinition
  }
}
