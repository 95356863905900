import { Category, Policy } from "@app/core/models/playbook"
import { Observable } from "rxjs"
import { map } from "rxjs/operators";

export class CategoryUtils {
  static getCategoryTitlesIdsObservableMap(categories$: Observable<Category[]>) : Observable<Map<string, string>> {
    return categories$.pipe(
      map(categories => CategoryUtils.getCategoryTitlesIdsMap(categories))
    )
  }

  static getCategoryTitlesIdsMap(categories: Category[]) : Map<string, string> {
    return categories.reduce((acc, category) => {
      acc.set(category.id, category.title)
      return acc
    }, new Map<string, string>())
  }

  static getCategoryTitlesByIds(policy: Policy, categories: Category[]): Array<string> | undefined {
    const categoryMap = new Map<string, string>()
    categories.forEach(category => {
      categoryMap.set(category.id, category.title)
    })

    const titles = policy.categoryIds
      ?.map(categoryId => categoryMap.get(categoryId))
      .filter(title => title !== undefined) as Array<string>

    return titles
  }

  static getTitlesFromCategoryMap(policy: Policy, categoriesTitleById: Map<string, string>): Array<string> | undefined {
    const titles = policy.categoryIds
      ?.map(categoryId => categoriesTitleById.get(categoryId))
      .filter(title => title !== undefined) as Array<string>

    return titles
  }
}