import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core'
import { NgControl } from '@angular/forms'
import { ManagementAccountSkuDetail } from '@app/core/models/account-sku-details'
import { ServicePlan } from '@app/shared/models/service-plan'
import { ApplicationInsightService } from '@app/core/services/application-insight.service'
import { LicenseService } from '@app/core/services/license.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { BaseControlComponent, Suggestion, ToastService } from '@coreview/coreview-components'
import { every } from 'lodash-es'
import { combineLatest, of, Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, pluck, tap } from 'rxjs/operators'
import { CustomComponentReady } from '@coreview/coreview-dynamoforms'

@Component({
  selector: 'app-service-plans-picker',
  templateUrl: './service-plans-picker.component.html',
  styleUrls: ['./service-plans-picker.component.sass'],
})

export class ServicePlansPickerComponent extends BaseControlComponent implements OnInit, CustomComponentReady {
  viewType: 'ServicePlan' | 'SKU' = 'ServicePlan'
  viewTypes: Suggestion[] = []
  msServicePlans: ServicePlan[] = []

  servicePlans: ServicePlanModel[] = []
  licenses: LicenseModel[] = []

  searchFilter: Subject<string> = new Subject<string>()
  searchValueLowerCase = ''

  loading = true

  nextStateMapping: Record<SelectionStatus, SelectionStatus> = {
    indeterminate: 'selected',
    selected: 'deselected',
    deselected: 'indeterminate',
  }

  @Input()
  get value(): {
    servicePlansToBeAdded: string[];
    servicePlansToBeRemoved: string[];
    skuIdsToBeAddOrModWithPlansAdd: { id: string; services: string }[];
    skuIdsToBeAddOrModWithPlansRemove: { id: string; services: string }[];
    servicePlansToBeAddedSummary: string[];
    servicePlansToBeRemovedSummary: string[];
    skusToBeAddedSummary: { sku: string; services: string[] }[];
    skusToBeRemovedSummary: { sku: string; services: string[] }[];
  } {
    return this.getValue()
  }

  set value(model) {
    /* we don't need a set value as the initial state is always empty, but don't remove this */
  }

  @Output() customComponentReady = new EventEmitter<void>()

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private licenseService: LicenseService,
    private storage: LocalstorageService,
    private translateHelper: TranslateHelper,
    private toastService: ToastService,
    private appInsights: ApplicationInsightService,
    private cdRef: ChangeDetectorRef
  ) {
    super(ngControl)
  }

  ngOnInit(): void {
    this.viewTypes = [
      { value: 'ServicePlan', displayValue: this.translateHelper.instant('licenses_ServicePlans') },
      { value: 'SKU', displayValue: this.translateHelper.instant('common_SKUS') },
    ]

    this.searchFilter.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.searchValueLowerCase = value.toLowerCase()
      if (value) {
        this.applyFilter()
      } else {
        this.clearFilter()
      }
    })

    combineLatest([
      this.licenseService.getManagementAccountSkuDetails(),
      this.licenseService.getAccountSkuDetailsServicePlans(),
      this.storage.getServicePlans().length
        ? of(this.storage.getServicePlans())
        : this.licenseService.getServicePlans().pipe(
          pluck('servicePlans'),
          tap((servicePlans) => this.storage.setServicePlans(servicePlans))
        ),
    ]).subscribe(
      ([accSkuDetails, servicePlans, msServicePlans]) => {
        this.msServicePlans = msServicePlans
        this.servicePlans = this.getMappedServicePlans(servicePlans).sort((a, b) => a.serviceName.localeCompare(b.serviceName))
        this.licenses = this.getMappedLicenses(accSkuDetails)

        this.loading = false
        this.customComponentReady.emit()
      },
      (err) => {
        this.loading = false
        this.customComponentReady.emit()

        this.appInsights.trackError(err)
        this.toastService.open({
          id: 'error',
          variant: 'error',
          title: this.translateHelper.instant('common_Error'),
          message: this.translateHelper.combineTranslations('generic_ObjectCouldNotBeRetrieved', 'licenses_Licenses'),
        })
      }
    )
  }

  toggleLicenseSelection(license: LicenseModel) {
    const newStatus = this.getNewStatus(license.status)
    license.status = newStatus
    license.servicePlans.forEach((sp) => {
      sp.status = newStatus
    })
    this.cdRef.detectChanges()
    this.onChange(this.getValue())
  }

  togglePlanSelection(servicePlan: ServicePlanModel, license?: LicenseModel) {
    servicePlan.status = this.getNewStatus(servicePlan.status)

    if (this.viewType === 'SKU' && !!license) {
      license.status = this.getNewLicenseStatus(license)
    }
    this.cdRef.detectChanges()
    this.onChange(this.getValue())
  }

  filterChanged(term: string) {
    this.searchFilter.next(term)
  }

  applyFilter() {
    if (this.viewType === 'ServicePlan') {
      this.servicePlans.forEach((sp) => {
        sp.hidden = this.shouldHideServicePlan(sp)
      })
    } else {
      this.licenses.forEach((l) => {
        const foundInLicence = l.label.toLowerCase().includes(this.searchValueLowerCase)
        if (foundInLicence) {
          l.hidden = false
          l.servicePlans.forEach((sp) => {
            sp.hidden = false
          })
        } else {
          l.servicePlans.forEach((sp) => {
            sp.hidden = this.shouldHideServicePlan(sp)
          })
          l.hidden = !l.servicePlans.some((sp) => !sp.hidden)
        }
        l.expanded = !l.hidden
      })
    }
  }

  onChangeViewType() {
    this.onChange(this.getValue())
  }

  showNoResultsMessage(): boolean {
    if (this.viewType === 'ServicePlan') {
      return this.servicePlans.every((x) => x.hidden)
    }
    return this.licenses.every((x) => x.hidden)
  }

  private shouldHideServicePlan(servicePlan: ServicePlanModel) {
    return (
      !servicePlan.serviceName?.toLowerCase().includes(this.searchValueLowerCase) &&
      !servicePlan.msServiceName?.toLowerCase().includes(this.searchValueLowerCase)
    )
  }

  private clearFilter(): void {
    this.servicePlans.forEach((sp) => {
      sp.hidden = false
    })
    this.licenses.forEach((l) => {
      l.hidden = false
      l.expanded = false
      l.servicePlans.forEach((sp) => {
        sp.hidden = false
      })
    })
  }

  private getMappedServicePlans(servicePlans: { _id: string; name: string }[]): ServicePlanModel[] {
    return servicePlans.map((sp) => {
      const servicePlanName = this.msServicePlans.find((x) => x.msServiceName === sp._id)?.serviceName || sp.name
      return {
        servicePlanId: sp._id,
        serviceName: servicePlanName,
        msServiceName: servicePlanName !== sp._id ? sp._id : '',
        status: 'indeterminate',
      }
    })
  }

  private getMappedLicenses(accountSkuDetails: ManagementAccountSkuDetail[]): LicenseModel[] {
    return accountSkuDetails.map((sku) => ({
      id: sku.id,
      label: sku.label,
      servicePlans: this.getMappedServicePlans(sku.servicePlans.map((sp) => ({ _id: sp.id, name: sp.label }))),
      status: 'indeterminate',
    }))
  }

  private getNewStatus(currentStatus: SelectionStatus): SelectionStatus {
    return this.nextStateMapping[currentStatus]
  }

  private getNewLicenseStatus(license: LicenseModel): SelectionStatus {
    const allPlansSelected = every(license.servicePlans, (sp) => sp.status === 'selected')
    if (allPlansSelected) {
      return 'selected'
    }
    return every(license.servicePlans, (sp) => sp.status === 'deselected') ? 'deselected' : 'indeterminate'
  }

  private getValue(): {
    servicePlansToBeAdded: string[];
    servicePlansToBeRemoved: string[];
    skuIdsToBeAddOrModWithPlansAdd: { id: string; services: string }[];
    skuIdsToBeAddOrModWithPlansRemove: { id: string; services: string }[];
    servicePlansToBeAddedSummary: string[];
    servicePlansToBeRemovedSummary: string[];
    skusToBeAddedSummary: { sku: string; services: string[] }[];
    skusToBeRemovedSummary: { sku: string; services: string[] }[];
  } {
    let servicePlansToBeAdded: string[] = []
    let servicePlansToBeRemoved: string[] = []
    const skuIdsToBeAddOrModWithPlansAdd: { id: string; services: string }[] = []
    const skuIdsToBeAddOrModWithPlansRemove: { id: string; services: string }[] = []

    let servicePlansToBeAddedSummary: string[] = []
    let servicePlansToBeRemovedSummary: string[] = []
    const skusToBeAddedSummary: { sku: string; services: string[] }[] = []
    const skusToBeRemovedSummary: { sku: string; services: string[] }[] = []

    if (this.viewType === 'ServicePlan') {
      const added = this.servicePlans.filter((sp) => sp.status === 'selected')
      const removed = this.servicePlans.filter((sp) => sp.status === 'deselected')

      servicePlansToBeAdded = added.map((sp) => sp.servicePlanId)
      servicePlansToBeRemoved = removed.map((sp) => sp.servicePlanId)

      servicePlansToBeAddedSummary = this.getPlansListForSummary(added)
      servicePlansToBeRemovedSummary = this.getPlansListForSummary(removed)
    } else {
      this.licenses.forEach((item) => {
        if (item.status === 'selected') {
          skuIdsToBeAddOrModWithPlansAdd.push({
            id: item.id,
            services: item.servicePlans.map((sp) => sp.servicePlanId).join(','),
          })
          skusToBeAddedSummary.push({ sku: item.label, services: this.getPlansListForSummary(item.servicePlans) })
        } else if (item.status === 'deselected') {
          skuIdsToBeAddOrModWithPlansRemove.push({
            id: item.id,
            services: item.servicePlans.map((sp) => sp.servicePlanId).join(','),
          })
          skusToBeRemovedSummary.push({ sku: item.label, services: this.getPlansListForSummary(item.servicePlans) })
        } else {
          const added = item.servicePlans.filter((i) => i.status === 'selected')
          const removed = item.servicePlans.filter((i) => i.status === 'deselected')

          if (added.length) {
            skuIdsToBeAddOrModWithPlansAdd.push({
              id: item.id,
              services: added.map((sp) => sp.servicePlanId).join(','),
            })
            skusToBeAddedSummary.push({ sku: item.label, services: this.getPlansListForSummary(added) })
          }
          if (removed.length) {
            skuIdsToBeAddOrModWithPlansRemove.push({
              id: item.id,
              services: removed.map((sp) => sp.servicePlanId).join(','),
            })
            skusToBeRemovedSummary.push({ sku: item.label, services: this.getPlansListForSummary(removed) })
          }
        }
      })
    }

    return {
      servicePlansToBeAdded,
      servicePlansToBeRemoved,
      skuIdsToBeAddOrModWithPlansAdd,
      skuIdsToBeAddOrModWithPlansRemove,
      servicePlansToBeAddedSummary,
      servicePlansToBeRemovedSummary,
      skusToBeAddedSummary,
      skusToBeRemovedSummary,
    }
  }

  private getPlansListForSummary(plans: ServicePlanModel[]): string[] {
    return plans.map((sp) => sp.serviceName + (sp.msServiceName ? ` (${sp.msServiceName})` : ''))
  }
}

type SelectionStatus = 'selected' | 'deselected' | 'indeterminate'
type LicenseModel = Pick<ManagementAccountSkuDetail, 'id' | 'label'> & {
  status: SelectionStatus;
  servicePlans: ServicePlanModel[];
  expanded?: boolean;
  hidden?: boolean;
}
type ServicePlanModel = Omit<ServicePlan, 'serviceType' | 'msServiceType'> & { status: SelectionStatus; hidden?: boolean }
