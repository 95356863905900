import { Component, Input, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { NavItem } from '@coreview/coreview-library/models/nav-item'
import { filter, debounceTime, take } from 'rxjs/operators'
import { Constants } from '@app/shared/utilities/constants'
import { Store } from '@ngrx/store'
import { RootState } from '@app/store/RootState.type'
import { selectedOrganization } from '@app/store/organizations/organizations.selectors'
import { Organization } from '@app/core/models/Organization'
import { UserSettingsService } from '@app/core/services/user-settings.service'
import { MenuService } from '@app/core/services/menu.service'
import { LoadingService } from '@app/core/services/loading.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { TranslateHelper } from '@coreview/coreview-library'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.sass'],
})
export class NavigationComponent implements OnInit {
  @Input() menus: NavItem[] = []
  @Input() isMenuOpen = false

  selectedItem!: NavItem | null
  selectedMenu!: NavItem | null
  selectedSubMenu!: NavItem | null

  loading = false
  favoritesMenuId = Constants.menuIds.favorites

  activeRoute!: string
  flatMenu: NavItem[] = []
  menuWithSeparator: NavItem[] = []
  bottomMenu: NavItem[] = []
  selectedOrg!: Organization | undefined

  showExpirationBanner = false
  showImportErrorBanner = false
  remainingDays!: number

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private store: Store<RootState>,
    private userSettingsService: UserSettingsService,
    private menuService: MenuService,
    private storage: LocalstorageService,
    private translateHelper: TranslateHelper,
    private sharedHelperService: SharedHelperService,
  ) {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.activeRoute = event.urlAfterRedirects
    })
  }

  ngOnInit(): void {
    dayjs.extend(utc)
    this.loadingService.loading$.pipe(debounceTime(500)).subscribe((x) => (this.loading = x))
    this.loadingService.loading$.pipe(filter((x) => !x)).subscribe((x) => (this.loading = x))
    this.store
      .select(selectedOrganization)
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((selOrg) => {
        this.selectedOrg = selOrg
        this.showImportErrorBanner = !!selOrg?.verified && this.bannerNotClosedRecently()
        this.manageExpiration()  
        this.bottomMenu = this.menuService.getBottomMenuByOrg(this.selectedOrg)
      })
  }

  getExpirationMessage() {
    if (this.selectedOrg?.subscriptionLevel === 'TRIAL' || this.selectedOrg?.subscriptionLevel === 'POV')
      return this.translateHelper.instant('generic_TrialExpiresDays', { days: this.remainingDays.toString() })
    else return this.translateHelper.instant('generic_CvSubscriptionExpiresDays', { days: this.remainingDays?.toString() })
  }

  getRemainingDays(expirationDate: string) {
    return Helpers.differenceDays(dayjs(expirationDate), dayjs())
  }

  closeExpirationBanner() {
    this.showExpirationBanner = false
    this.storage.setIsBannerClosed()
  }

  closeImportErrorBanner() {
    this.showImportErrorBanner = false
    this.storage.setWhenClosedImportErrorBanner()
  }

  saveMenuOpenPreference(isMenuOpen: boolean) {
    this.isMenuOpen = isMenuOpen
    this.userSettingsService.updateExtraUserSettings('isMenuOpen', isMenuOpen).subscribe()
  }

  private manageExpiration() {
    this.remainingDays = this.getRemainingDays(this.selectedOrg!.expirationDate)
    if (this.remainingDays < 0) {
      this.router.navigate([{ outlets: { forbidden: 'unauthorized' } }], { skipLocationChange: false }).catch((_: any) => _)
    }
    this.showExpirationBanner = this.isExpirationBannerVisible()
  }

  private isExpirationBannerVisible() {
    return (
      (this.selectedOrg?.subscriptionLevel === 'TRIAL' ||
        this.selectedOrg?.subscriptionLevel === 'POV' ||
        (this.selectedOrg?.subscriptionLevel === 'PRODUCTION' && this.remainingDays <= 30 && this.sharedHelperService.isAdmin())) &&
      !this.storage.getIsBannerClosed()
    )
  }

  private bannerNotClosedRecently(): boolean {
    const bannerClosedAt = this.storage.getWhenClosedImportErrorBanner()
    return !bannerClosedAt || dayjs().diff(dayjs(bannerClosedAt), 'hour') >= 24
  }
}
