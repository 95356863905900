import { GridApi, GridOptions } from '@ag-grid-community/core'
import { IReportChartDefinition } from '@app/modules/reports/report-chart-definitions'
import { ButtonColour, ButtonType } from '@coreview/coreview-components'
import { CoreViewColumn } from './CoreViewColumn'
import { QueryFilter, QueryFilter2 } from './QueryFilter'
import { TargetEntityAuditType, TargetEntityBaseType, TargetEntityCustomActionType, TargetEntityPolicyType, TargetEntityTeamsType, TargetEntityType, TargetEntityWorkflowType } from '../enums/group-type'
import { ReportDefinitionBase } from '@coreview/coreview-library/models/ReportDefinition'

// do not change the order of priority, otherwise bugs will occur
export const comparableVerbs: Record<string, string> = {
  contains: '',
  notContains: '!!',
  equals: '=',
  notEqual: '<>',
  startsWith: '^',
  endsWith: '$',
  equalsTrue: '',
  equalsFalse: '',
  isEmpty: '',
  isNotEmpty: '',
  lessThanOrEqual: '<=',
  greaterThanOrEqual: '>=',
  lessThan: '<',
  greaterThan: '>',
  inRange: 'Between ',
  sizeGreaterThan: 'size>',
  sizeLessThan: 'size<',
  //multiselect: '+',
}

export const comparableDateVerbs: Record<string, string> = {
  equals: 'Between ',
  lessThan: '<',
  greaterThan: '>',
  inRange: 'Between ',
  atLeastOnce: '',
  lastNDays: 'Last N days',
  neverUsed: '',
  notInLastNDays: 'Not in last N days',
  greaterThanOrEqual: '>=',
  lessThanOrEqual: '<=',
  nextNDays: 'Next N days',
}

export const comparableVerbsWithNoValues: Record<string, string> = {
  equalsTrue: 'true',
  equalsFalse: 'false',
  isEmpty: 'IsEmpty',
  isNotEmpty: 'IsNotEmpty',
  neverUsed: 'Never Used',
  atLeastOnce: 'At Least Once',
}

export type ComparableVerbsKey = keyof typeof comparableVerbs

export type FilterDefinition = {
  filterType: string;
  type: ComparableVerbsKey;
  filter?: string | number | string[];
  dateFrom?: string;
  dateTo?: string;
  filterTo?: string | number;
}

export type FilterReportColumn = Record<string, FilterDefinition>

export type ExternalFilter = {
  title: string;
  icon?: string;
  filter: Record<string, string>;
  enum: { key: string; text: string; icon?: string | undefined; iconPosition?: 'left' | 'right' | undefined }[];
  onClick: string;
}

export type RowSelection = 'multiple' | 'single' | 'none' | undefined

export interface ReportDefinition extends ReportDefinitionBase {
  tabs?: ReportDefinition[];
  title?: string;
  targetEntity?: TargetEntityBaseType | TargetEntityType | TargetEntityPolicyType | TargetEntityCustomActionType | TargetEntityAuditType | TargetEntityWorkflowType | TargetEntityTeamsType;
  urlParameters?: Record<string, string>;
  urlRouteParameters?: Record<string, string>;
  pivotUrl?: string;
  fieldsWithDays?: string[];
  filters?: FilterReportColumn;
  initialFilters?: FilterReportColumn; //Used to pass filters to the pivot table and use a different sintax to be sent to the api before the AgGrid setFilterModel
  initialUrlFilters?: Record<string, string>;
  hasChooseDataCenter?: boolean;

  // range filters
  calendarType?: string;
  defaultDaysFilter?: 0 | 1 | 60 | 3 | 7 | 14 | 30 | 90 | 180;
  daysList?: (1 | 3 | 7 | 14 | 30 | 60 | 90 | 180 | 0)[];
  limitMonths?: number;
  limitDays?: number;

  //this is an operation for each row on an existing column
  operationsOnCols?: OperationsOnCols[] | undefined;
  //this is a  operation for each row for an existing or not existing column
  operationsColumns?: OperationColumn[] | undefined;
  chartAccessLevel?: string;
  chart?: IReportChartDefinition[];

  defaultHiddenFields?: string[]; //Helena said it is used to hide required fields
  rowSelection?: RowSelection;
  selectionActions?: SelectionAction[];
  groupedActions?: SelectionAction[];
  filterActions?: SelectionAction[];
  detailCellRenderer?: any;
  detailRowHeight?: number;
  detailCellRenderParams?: any;
  gridOptions?: GridOptions;
  sortedFields?: string[];
  cellClassRules?: CellClassRules[];
  lockedColumns?: string[];

  actionsTags?: string[];
  externalFilters?: ExternalFilter[];
  analyzerInfo?: { filters: QueryFilter[] };
  treeFilters?: QueryFilter2;

  isClientDataGrid?: boolean;

  entityIdField?: string;
  entityDisplayField?: string;
  dbCollectionName?: string;
  availableFilters?: string[];

  notAllowedActions?: string[];
  enableAnonymousData?: boolean;

  hidePolicyExceptions?: boolean
  hasOpenInFull?: boolean

  isOnlineUsersType?: boolean;
}

export interface CellClassRules {
  prop: string;
  class?: string;
  value?: string;
  func?: string;
}

export type ActionButtonVisibility = 'always' | 'singleRow' | 'multiRow' | 'noRow' | 'custom'
export interface SelectionAction {
  isMenuButton?: boolean;
  options?: SelectionActionOptions[];
  buttonType?: ButtonType;
  color?: ButtonColour;
  text: string;
  showSelectionText?: boolean;
  inlineTitle?: string;
  icon?: string;
  rightIcon?: string;
  classIcon?: string;
  leftClassStyle?: 'outlined' | 'filled';
  disabled?: boolean;
  visibility: ActionButtonVisibility;
  cvDataTest?: string;
  tooltip?: string;
  isVisible?: (selectedRows: any[], displayedRows?: number) => boolean;
  isPivotAction?: boolean;
  filterRows?: (selectedRows: any[]) => boolean;
  onClick: (selectedRows: any[], key: string | null, gridApi?: GridApi) => void;
  mapResultFunction?: string;
  style?: string;
}

export interface SelectionActionOptions {
  key: string;
  text: string;
  icon?: string;
  isSelected?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
}

export interface OperationsOnCols extends Partial<CoreViewColumn> {
  type: string;
  readonly predefinedOperation?: 'card' | 'edit';
  readonly field?: string;
  readonly fieldId?: string;
  readonly param?: any;
  readonly name: string;
  // builds query params, key is the filter name, value is the property from where to fetch the value
  readonly props?: Record<string, string | ((rowNode: any, myparams: any, queryParams: any) => any)>;
  // builds query params, key is the filter name, value is the final value
  readonly fixedQueryParams?: Record<string, string>;
  readonly external?: boolean;
}

export interface OperationColumn extends OperationsOnCols {
  readonly position?: number;
}

export interface ChartSeriesSelection {
  id: string;
  label: string;
  isSelected: boolean;
  isLocked?: boolean;
}
