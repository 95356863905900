import { EditCellRendererComponent } from './../components/datagrid/edit-renderer/edit-cell-renderer.component'
import { LoadingCellRendererComponent } from './../components/datagrid/loading-cell-renderer/loading-cell-renderer.component'
import { TooltipRendererComponent } from './../components/datagrid/tooltip-renderer/tooltip-renderer.component'
import { BadgeRendererComponent } from './../components/datagrid/badge-renderer/badge-renderer.component'
import { TaskTitleRendererComponent } from './../components/datagrid/task-title-renderer/task-title-renderer.component'
/* eslint-disable max-len */
import { ColDef, GridApi, ProcessCellForExportParams, ValueFormatterParams } from '@ag-grid-community/core'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import {
  comparableDateVerbs,
  comparableVerbs,
  comparableVerbsWithNoValues,
  FilterDefinition,
  FilterReportColumn,
  OperationColumn,
  OperationsOnCols,
} from '@app/core/models/ReportDefinition'
import { TranslateHelper, AsyncTaskProgressState, AsyncTaskState, LibBuildColAgGrid } from '@coreview/coreview-library'
import { ReportsService } from '@app/core/services/reports.service'
import { Helpers } from './helpers'
import dayjs from 'dayjs'
import { Injectable } from '@angular/core'
import { IconButtonRendererComponent } from '../components/client-datagrid/icon-button-renderer/icon-button-renderer.component'
import { CustomLinkRendererComponent } from '../components/client-datagrid/custom-link-renderer/custom-link-renderer.component'
import { ActionCellRendererComponent } from '../components/datagrid/action-cell-renderer/action-cell-renderer.component'
import { ArrayCellRendererComponent } from '../components/datagrid/array-cell-renderer/array-cell-renderer.component'
import { CustomDetailComponent } from '../components/datagrid/custom-detail/custom-detail.component'
import { DictionaryCellRendererComponent } from '../components/datagrid/dictionary-cell-renderer/dictionary-cell-renderer.component'
import { DateFilterComponent } from '../components/datagrid/filters/date-filter/date-filter.component'
import { GenericFloatingFilterComponent } from '../components/datagrid/filters/generic-floating-filter/generic-floating-filter.component'
import { SingleSelectFilterComponent } from '../components/datagrid/filters/single-select-filter/single-select-filter.component'
import {
  MULTISELECT_OPTIONS,
  MultiSelectFilterComponent,
} from '../components/datagrid/filters/multi-select-filter/multi-select-filter.component'
import { SelectAllHeaderComponent } from '../components/datagrid/headers/select-all-header/select-all-header.component'
import { TooltipHeaderComponent } from '../components/datagrid/headers/tooltip-header/tooltip-header.component'
import { IconRendererComponent } from '../components/datagrid/icon-renderer/icon-renderer.component'
import { LinkCellRendererComponent } from '../components/datagrid/link-cell-renderer/link-cell-renderer.component'
import { IconDialogButtonRendererComponent } from '../components/icon-dialog-button-renderer/icon-dialog-button-renderer.component'
import { RolesCellRendererComponent } from '@app/modules/administrations/pages/manage-operators/cell-renderer/roles-cell-renderer.component'
import { IconMenuButtonRendererComponent } from '../components/icon-menu-button-renderer/icon-menu-button-renderer.component'
import { Observable, of } from 'rxjs'
import { QueryFilter, QueryFilter2, childFilter } from '@app/core/models/QueryFilter'
import { ChipCellRendererComponent } from '../components/datagrid/chip-cell-renderer/chip-cell-renderer.component'
import { ObjectCellRenderComponent } from '../components/datagrid/object-cell-render/object-cell-render.component'
import { BooleanRendererComponent } from '../components/datagrid/boolean-renderer/boolean-renderer.component'
import { Constants } from './constants'
import { FlatDictionaryCellRendererComponent } from '../components/datagrid/flat-dictionary-cell-renderer/flat-dictionary-cell-renderer.component'
import { EditableCellRendererComponent } from '../components/datagrid/editable-cell-renderer/editable-cell-renderer.component'
import { PersistableCellEditorComponent } from '../components/datagrid/persistable-cell-editor/persistable-cell-editor.component'
import {
  TargetEntityAuditType,
  TargetEntityBaseType,
  TargetEntityCustomActionType,
  TargetEntityPolicyType,
  TargetEntityTeamsType,
  TargetEntityType,
  TargetEntityWorkflowType,
} from '@app/core/enums/group-type'
import { SharedHelperService } from '../shared.helper.service'
import { UserSettingColumn } from '@coreview/coreview-library/models/user-settings'
import { AccountSkuDetail } from '@app/core/models/account-sku-details'
import { map } from 'rxjs/operators'
import { SwitchRendererComponent } from '../components/datagrid/switch-renderer/switch-renderer.component'
import {
  appRoleValueMultiselectFilterDefinition,
  licensesLegacyMultiselectFilterDefinition,
  licensesMultiselectFilterDefinition,
  scopeMultiselectFilterDefinition,
  valueMultiselectFilterDefinition,
} from './build-col-ag-grid.definitions'
import { ArrayLinkCellRendererComponent } from '../components/datagrid/array-link-cell-renderer/array-link-cell-renderer.component'

@Injectable({
  providedIn: 'root',
})
export class BuildColAgGrid {
  //#region Filter options
  filterOptions = {
    suppressAndOrCondition: true,
  }

  frameworkComponents = {
    linkCellRenderer: LinkCellRendererComponent,
    actionCellRenderer: ActionCellRendererComponent,
    iconButtonRenderer: IconButtonRendererComponent,
    iconRenderer: IconRendererComponent,
    iconDialogButtonRendererComponent: IconDialogButtonRendererComponent,
    iconMenuButtonRenderer: IconMenuButtonRendererComponent,
    dictionaryCellRenderer: DictionaryCellRendererComponent,
    flatDictionaryCellRenderer: FlatDictionaryCellRendererComponent,
    selectAllHeader: SelectAllHeaderComponent,
    genericFloatingFilter: GenericFloatingFilterComponent,
    dateFilter: DateFilterComponent,
    customDetail: CustomDetailComponent,
    customLinkRendererComponent: CustomLinkRendererComponent,
    tooltipHeader: TooltipHeaderComponent,
    singleSelectFilter: SingleSelectFilterComponent,
    multiSelectFilter: MultiSelectFilterComponent,
    arrayRenderer: ArrayCellRendererComponent,
    arrayLinkCellRenderer: ArrayLinkCellRendererComponent,
    rolesCellRendererComponent: RolesCellRendererComponent,
    badgeRenderer: BadgeRendererComponent,
    taskTitleRenderer: TaskTitleRendererComponent,
    tooltipRenderer: TooltipRendererComponent,
    loadingCellRenderer: LoadingCellRendererComponent,
    editCellRendererer: EditCellRendererComponent,
    chipCellRendererComponent: ChipCellRendererComponent,
    objectRenderer: ObjectCellRenderComponent,
    booleanRenderer: BooleanRendererComponent,
    editableCellRenderer: EditableCellRendererComponent,
    switchRenderer: SwitchRendererComponent,
    hiddenCellRenderer: () => null,
  }

  filterOptionsString = {
    filterOptions: [
      {
        debounceMs: 5500,
        buttons: ['apply'],
        displayKey: 'contains',
        displayName: this.translateHelper.instant('common_Contains'),
        predicate: ([filterValue]: string[], cellValue: string) =>
          this.convertToComparableString(cellValue).includes(this.convertToComparableString(filterValue)),
      },
      {
        debounceMs: 5000,
        displayKey: 'notContains',
        displayName: this.translateHelper.instant('common_DoesNotContain'),
        predicate: ([filterValue]: string[], cellValue: string) =>
          !this.convertToComparableString(cellValue).includes(this.convertToComparableString(filterValue)),
      },
      {
        debounceMs: 5000,
        displayKey: 'equals',
        displayName: this.translateHelper.instant('common_Equals'),
        predicate: ([filterValue]: string[], cellValue: string) =>
          this.convertToComparableString(cellValue) === this.convertToComparableString(filterValue),
      },
      {
        debounceMs: 5000,
        displayKey: 'notEqual',
        displayName: this.translateHelper.instant('common_NotEqual'),
        predicate: ([filterValue]: string[], cellValue: string) =>
          this.convertToComparableString(cellValue) !== this.convertToComparableString(filterValue),
      },
      {
        debounceMs: 5000,
        displayKey: 'startsWith',
        displayName: this.translateHelper.instant('common_StartsWith'),
        predicate: ([filterValue]: string[], cellValue: string) =>
          this.convertToComparableString(cellValue).startsWith(this.convertToComparableString(filterValue)),
      },
      {
        debounceMs: 5000,
        displayKey: 'endsWith',
        displayName: this.translateHelper.instant('common_EndsWith'),
        predicate: ([filterValue]: string[], cellValue: string) =>
          this.convertToComparableString(cellValue).endsWith(this.convertToComparableString(filterValue)),
      },
      {
        displayKey: 'isEmpty',
        displayName: this.translateHelper.instant('common_IsEmpty'),
        predicate: ([filterValue]: any[], cellValue: any) => !cellValue?.length,
        numberOfInputs: 0,
      },
      {
        displayKey: 'isNotEmpty',
        displayName: this.translateHelper.instant('common_IsNotEmpty'),
        predicate: ([filterValue]: any[], cellValue: any) => !!cellValue?.length,
        numberOfInputs: 0,
      },
    ],
    suppressAndOrCondition: true,
  }

  filterOptionsNumber = {
    filterOptions: [
      {
        displayKey: 'equals',
        displayName: this.translateHelper.instant('common_Equals'),
        predicate: ([filterValue]: any, cellValue: any) => cellValue === filterValue,
      },
      {
        displayKey: 'notEqual',
        displayName: this.translateHelper.instant('common_NotEqual'),
        predicate: ([filterValue]: any, cellValue: any) => cellValue !== filterValue,
      },
      {
        displayKey: 'lessThan',
        displayName: this.translateHelper.instant('common_LessThan'),
        predicate: ([filterValue]: any, cellValue: any) => cellValue < filterValue,
      },
      {
        displayKey: 'lessThanOrEqual',
        displayName: this.translateHelper.instant('common_LessThanOrEqual'),
        predicate: ([filterValue]: any, cellValue: any) => cellValue <= filterValue,
      },
      {
        displayKey: 'greaterThan',
        displayName: this.translateHelper.instant('common_GreaterThan'),
        predicate: ([filterValue]: any, cellValue: any) => cellValue > filterValue,
      },
      {
        displayKey: 'greaterThanOrEqual',
        displayName: this.translateHelper.instant('common_GreaterThanOrEqual'),
        predicate: ([filterValue]: any, cellValue: any) => cellValue >= filterValue,
      },
      {
        displayKey: 'inRange',
        displayName: this.translateHelper.instant('common_InRange'),
        numberOfInputs: 2,
        predicate: ([fv1, fv2]: any[], cellValue: any) => cellValue >= fv1 && cellValue <= fv2,
      },
    ],
    suppressAndOrCondition: true,
  }

  filterOptionsArray = {
    filterOptions: [
      {
        displayKey: 'contains',
        displayName: this.translateHelper.instant('common_Contains'),
        predicate: ([filterValue]: any, cellValue: any) =>
          ((cellValue as string[]) || []).some((x) => x.toLowerCase().includes(filterValue.toLowerCase())),
      },
      {
        displayKey: 'notContains',
        displayName: this.translateHelper.instant('common_DoesNotContain'),
        predicate: ([filterValue]: any, cellValue: any) =>
          !((cellValue as string[]) || []).some((x) => x.toLowerCase().includes(filterValue.toLowerCase())),
      },
      {
        displayKey: 'equals',
        displayName: this.translateHelper.instant('common_Equals'),
        predicate: ([filterValue]: any, cellValue: any) => ((cellValue as string[]) || []).some((x) => x.includes(filterValue)),
      },
      {
        displayKey: 'notEqual',
        displayName: this.translateHelper.instant('common_NotEqual'),
        predicate: ([filterValue]: any, cellValue: any) => !((cellValue as string[]) || []).some((x) => x.includes(filterValue)),
      },
      {
        displayKey: 'isEmpty',
        displayName: this.translateHelper.instant('common_IsEmpty'),
        predicate: ([filterValue]: any, cellValue: any) => !cellValue?.length,
        numberOfInputs: 0,
      },
      {
        displayKey: 'isNotEmpty',
        displayName: this.translateHelper.instant('common_IsNotEmpty'),
        predicate: ([filterValue]: any, cellValue: any) => !!cellValue?.length,
        numberOfInputs: 0,
      },
      {
        displayKey: 'sizeGreaterThan',
        displayName: this.translateHelper.instant('common_SizeIsGreatherThan'),
        predicate: ([filterValue]: any, cellValue: any) => cellValue?.length > filterValue,
      },
      {
        displayKey: 'sizeLessThan',
        displayName: this.translateHelper.instant('common_SizeIsLessThan'),
        predicate: ([filterValue]: any, cellValue: any) => cellValue?.length < filterValue,
      },
    ],
    suppressAndOrCondition: true,
  }

  filterOptionsDictionary = {
    filterOptions: [
      {
        displayKey: 'contains',
        displayName: this.translateHelper.instant('common_Contains'),
        predicate: ([filterValue]: any, cellValue: any) => ((cellValue as string[]) || []).some((x) => x.includes(filterValue)),
      },
      {
        displayKey: 'notContains',
        displayName: this.translateHelper.instant('common_DoesNotContain'),
        predicate: ([filterValue]: any, cellValue: any) => !((cellValue as string[]) || []).some((x) => x.includes(filterValue)),
      },
      {
        displayKey: 'isEmpty',
        displayName: this.translateHelper.instant('common_IsEmpty'),
        predicate: ([filterValue]: any, cellValue: any) => !cellValue?.length,
        numberOfInputs: 0,
      },
      {
        displayKey: 'isNotEmpty',
        displayName: this.translateHelper.instant('common_IsNotEmpty'),
        predicate: ([filterValue]: any, cellValue: any) => !!cellValue?.length,
        numberOfInputs: 0,
      },
    ],
    suppressAndOrCondition: true,
  }

  filterOptionsBool = {
    filterOptions: [
      {
        displayKey: 'empty',
        displayName: this.translateHelper.instant('common_Select'),
        predicate: ([filterValue]: any, cellValue: any) => true,
        numberOfInputs: 0,
      },
      {
        displayKey: 'equalsTrue',
        displayName: this.translateHelper.instant('common_True'),
        predicate: ([filterValue]: any, cellValue: any) => cellValue === 'true' || cellValue === true,
        numberOfInputs: 0,
      },
      {
        displayKey: 'equalsFalse',
        displayName: this.translateHelper.instant('common_False'),
        predicate: ([filterValue]: any, cellValue: any) => !cellValue || cellValue === 'false',
        numberOfInputs: 0,
      },
    ],
    suppressAndOrCondition: true,
  }

  generalFilters = new Map([
    ['tableDetail', { filterParams: this.filterOptionsString, filter: 'agTextColumnFilter' }],
    ['string', { filterParams: this.filterOptionsString, filter: 'agTextColumnFilter' }],
    ['percentage', { filterParams: this.filterOptionsNumber, filter: 'agNumberColumnFilter' }],
    ['number', { filterParams: this.filterOptionsNumber, filter: 'agNumberColumnFilter' }],
    ['double', { filterParams: this.filterOptionsNumber, filter: 'agNumberColumnFilter' }],
    ['int', { filterParams: this.filterOptionsNumber, filter: 'agNumberColumnFilter' }],
    ['currency', { filterParams: this.filterOptionsNumber, filter: 'agNumberColumnFilter' }],
    ['date', { filter: 'dateFilter', disableParentFilter: true }],
    ['dateWithSecondsLocalTime', { filter: 'dateFilter', disableParentFilter: true }],
    ['dateWithSeconds', { filter: 'dateFilter', disableParentFilter: true }],
    ['dateUnixTimestamp', { filter: 'dateFilter', disableParentFilter: true }],
    ['select', { filterParams: this.filterOptionsDictionary, filter: 'agTextColumnFilter' }],
    [
      'monthYear',
      {
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [{ key: 'equals', value: 'common_Equals' }],
          defaultFilterType: 'equals',
          columnFilterType: 'monthYear',
        },
        filter: 'singleSelectFilter',
        disableParentFilter: true,
      },
    ],
    ['array', { filterParams: this.filterOptionsArray, filter: 'agTextColumnFilter' }],
    ['object', { filterParams: this.filterOptionsDictionary, filter: 'agTextColumnFilter' }],
    ['enum', { filterParams: this.filterOptions, filter: 'agSetColumnFilter' }],
    ['dictionary', { filterParams: this.filterOptionsDictionary, filter: 'agTextColumnFilter' }],
    ['flatDictionary', { filterParams: this.filterOptionsDictionary, filter: 'agTextColumnFilter' }],
    ['bool', { filterParams: this.filterOptionsBool, filter: 'agTextColumnFilter', disableParentFilter: true }],
    ['checkbox', { filterParams: this.filterOptionsBool, filter: 'agTextColumnFilter' }],
    ['none', { filter: 'false' }],
    [
      'Scope',
      {
        filterParams: {
          ...scopeMultiselectFilterDefinition,
          options: this.reportsService.getFilterValues('enterpriseApps/delegatedPermissionsAvailable/', 'items'),
        },
        filter: 'multiSelectFilter',
        disableParentFilter: true,
      },
    ],
    [
      'ScopeByPrincipals',
      {
        filterParams: {
          ...scopeMultiselectFilterDefinition,
          options: this.reportsService.getFilterValues('enterpriseApps/delegatedPermissionsAvailableByPrincipal/', 'items'),
        },
        filter: 'multiSelectFilter',
        disableParentFilter: true,
      },
    ],
  ])
  //#endregion

  //#region Cell Renderer Map
  cellRendererMap: Record<string, any> = {
    array: { cellRenderer: 'arrayRenderer', autoHeight: true },
    iconButton: { cellRenderer: 'iconButtonRenderer', isRowMaster: (value: any) => false },
    icon: { cellRenderer: 'iconRenderer', isRowMaster: (value: any) => false },
    actions: { cellRenderer: 'actionCellRenderer', isRowMaster: (value: any) => false },
    link: { cellRenderer: 'linkCellRenderer', isRowMaster: (value: any) => false },
    clone: { cellRenderer: 'cloneCellRenderer', isRowMaster: (value: any) => false },
    chip: { cellRenderer: 'chipCellRendererComponent', isRowMaster: (value: any) => false },
    iconDialog: { cellRenderer: 'iconDialogButtonRendererComponent', isRowMaster: (value: any) => false },
    switchRenderer: { cellRenderer: 'switchRenderer', isRowMaster: (value: any) => false },
    dictionary: {
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: 'dictionaryRenderer',
      isRowMaster: (value: any): boolean => value instanceof Object && Object.keys(value).length > 0,
    },
    flatDictionary: {
      cellRenderer: 'flatDictionaryCellRenderer',
      autoHeight: true,
    },
    customDetail: {
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: 'customDetail',
      detailCellRenderer: 'customDetail',
      detailCellRenderParams: {
        field: 'description',
      },
      isRowMaster: (value: any) => !!value,
    },
    tableDetail: {
      cellRenderer: 'agGroupCellRenderer',
      isRowMaster: (value: any) => !!value,
    },
    object: { cellRenderer: 'objectRenderer', autoHeight: true },
    bool: { cellRenderer: 'booleanRenderer', isRowMaster: (value: any) => false },
    persistableCellEditor: {
      editable: true,
      cellRenderer: EditableCellRendererComponent,
      cellEditor: PersistableCellEditorComponent,
      suppressKeyboardEvent: () => true,
    },
    int: {
      cellStyle: { 'text-align': 'center' },
    },
    double: {
      cellStyle: { 'text-align': 'center' },
    },
  }
  //#endregion
  selectionColumn = {
    type: 'selection',
    translate: 'selection',
    originalName: 'selection',
    name: 'selection',
    position: -10,
    notSelectable: true,
    agColDef: {
      ...Constants.defaultSelectColumnDefinition,
      suppressSizeToFit: true,
    },
  }

  constructor(
    private translateHelper: TranslateHelper,
    private reportsService: ReportsService,
    private sharedHelperService: SharedHelperService
  ) {}

  humanizeDuration = (params: any): string => Helpers.humanizeDuration(params.value) ?? this.translateHelper.instant('common_NeverUsed')

  doubleFormatter = (params: any): string => Helpers.round(params.value)

  dateUnixTimestampGetter = (params: any): any => Helpers.getUnixTimestampDate(params.data[params.colDef.field])

  dateFormatter = (params: any): string => Helpers.formatDate(params.value) || this.translateHelper.instant('common_NeverUsed')

  dictionaryFormatter = (params: any): string =>
    `(${params.value instanceof Object ? Object.keys(params.value).length : 0}) ${params.column.userProvidedColDef.headerName}`

  percentageFormatter = (params: any): string => `${params.value || 0}%`

  // eslint-disable-next-line @typescript-eslint/member-ordering
  valueFormatterMap: Record<string, any> = {
    date: this.dateFormatter,
    dateWithSecondsLocalTime: this.dateFormatter,
    dateWithSeconds: this.dateFormatter,
    dateUnixTimestamp: this.dateFormatter,
    dictionary: this.dictionaryFormatter,
    duration: this.humanizeDuration,
    double: this.doubleFormatter,
    monthYear: (d: { value: string }) => Helpers.dateToYearMonth(d.value),
    percentage: this.percentageFormatter,
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  valueGetterMap: Record<string, any> = {
    dateUnixTimestamp: this.dateUnixTimestampGetter,
  }

  cellRendererDef = (
    op: OperationsOnCols | undefined,
    inlineCellRenderer: any,
    inlineCellRendererParam: any,
    columnType: string | undefined,
    columnName: string,
    isPivot: boolean
  ) => {
    if (isPivot) {
      return {
        cellRenderer: undefined,
        cellRendererParams: undefined,
      }
    }
    let renderer = {}

    if (columnType) {
      renderer = this.cellRendererMap[columnType]
      if (renderer && !op) {
        return renderer
      }
    }
    if (op) {
      return {
        ...renderer,
        ...this.cellRendererMap[op.type],
        cellRendererParams: { param: op },
        cellEditorParams: op.type === 'persistableCellEditor' && op.param.cellEditorParams ? op.param.cellEditorParams : undefined,
        cellRendererSelector: op.param?.cellRendererSelector,
      }
    } else {
      //these are used in the Administration pages
      return {
        ...renderer,
        cellRenderer: inlineCellRenderer?.[columnName] ? inlineCellRenderer[columnName] : undefined,
        cellRendererParams:
          inlineCellRendererParam?.[columnName] ? inlineCellRendererParam[columnName] : undefined,
      }
    }
  }

  typedFiltersLookup = (
    filterName: string | undefined,
    columnType: string | undefined,
    companyId: string,
    values?: string[],
    explicitFilterType?: string,
    availableFilters: string[] | null = null
  ):
    | {
        filter?: string
        filterParams?: { suppressAndOrCondition?: boolean; options?: Observable<any>; filterOptions?: any[] }
        floatingFilter?: boolean
        disableParentFilter?: boolean
      }
    | undefined => {
    if (filterName) {
      let namedTypeFilters = new Map([
        [
          'RecipientTypeDetails',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('onlineusers/recipienttypedetails/', 'recipientTypeDetails'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'RecipientType',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('onlineusers/recipienttypes/', 'recipientTypes'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'MailboxType',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('mailboxes/types/', 'itemsList'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'MailboxStorage',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('recipients/mailboxstorage/', 'itemsList'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'AccountType',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('onlineusers/accounttype/', 'itemsList'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'MultiFactorAuthState',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('onlineusers/MultiFactorAuthState/', 'itemsList'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'DevicePolicyApplied',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('mobiledevices/DevicePolicyApplied/', 'itemsList'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'AutoReplyState',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('mailboxes/autoreplyconfigurations/autoreplystate/', 'itemsList'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'RetentionPolicy',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('mailboxes/retentionpolicies', 'items', { companyId }),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'AddressBookPolicy',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('mailboxes/addressbookpolicies', 'items', { companyId }),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'AsyncTaskState',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: of(Object.keys(AsyncTaskState)),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'AsyncTaskProgressState',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: of(Object.keys(AsyncTaskProgressState)),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'Workload',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('managementapi/workload/', 'itemsList'),
            },
            filter: 'multiSelectFilter',
          },
        ],
        [
          'Licenses',
          {
            filterParams: {
              ...licensesMultiselectFilterDefinition,
              options: this.reportsService
                .getFilterValues('accountsku/', 'accountSkuDetails', {
                  pageSize: 1000,
                })
                .pipe(
                  map((response: any = []) => {
                    const accountSkuDetails = response as AccountSkuDetail[]
                    return accountSkuDetails?.map((item) => item?.sku).sort((a, b) => a.localeCompare(b))
                  })
                ),
            },
            filter: 'multiSelectFilter',
          },
        ],
        [
          'LicensesLegacy',
          {
            filterParams: {
              ...licensesLegacyMultiselectFilterDefinition,
              options: this.reportsService
                .getFilterValues('accountsku/', 'accountSkuDetails', {
                  pageSize: 1000,
                })
                .pipe(
                  map((response: any = []) => {
                    const accountSkuDetails = response as AccountSkuDetail[]
                    return accountSkuDetails?.map((item) => item?.sku).sort((a, b) => a.localeCompare(b))
                  })
                ),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'Operation',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues(
                'managementapi/operation/',
                (items: { operations: { id: string; operation: string; workload: string }[] }) =>
                  items.operations.filter((f) => !!f.operation).map((f) => ({ id: f.id, value: f.operation, groupBy: f.workload }))
              ),
              parentController: 'workload',
            },
            filter: 'multiSelectFilter',
          },
        ],
        [
          'EventType',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('mailtraffic/eventtypes/', 'itemsList'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'Direction',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: this.reportsService.getFilterValues('mailtraffic/directions/', 'itemsList'),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'PrincipalType',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: of(['SecurityGroup', 'User']),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'AppRoleValue',
          {
            filterParams: {
              ...appRoleValueMultiselectFilterDefinition,
              options: this.reportsService.getFilterValues('enterpriseApps/appPermissionsAvailable/', 'items'),
            },
            filter: 'multiSelectFilter',
          },
        ],
        [
          'Value',
          {
            filterParams: {
              ...valueMultiselectFilterDefinition,
              options: this.reportsService.getFilterValues('registeredApps/permissionsAvailable/', 'items'),
            },
            filter: 'multiSelectFilter',
          },
        ],
        [
          'ChangeType',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: of(['Added', 'Changed', 'Removed']),
            },
            filter: 'singleSelectFilter',
          },
        ],
        [
          'SyncAction',
          {
            filterParams: {
              suppressAndOrCondition: true,
              options: of(['Backup', 'Deploy']),
            },
            filter: 'singleSelectFilter',
          },
        ],
      ])

      const teamsPolicyFilters = this.reportsService.generateFiltersForTeamsPolicies()

      namedTypeFilters = new Map([...namedTypeFilters, ...teamsPolicyFilters])
      const fType = namedTypeFilters.get(filterName)
      if (fType) {
        return { ...fType, disableParentFilter: true }
      }
    } else {
      return { filter: 'agTextColumnFilter', floatingFilter: false }
    }

    if (columnType) {
      if (columnType === 'enum' && !!values) {
        return {
          filterParams: {
            suppressAndOrCondition: true,
            options: of(values),
          },
          filter: 'singleSelectFilter',
          disableParentFilter: true,
        }
      }

      const filter = this.generalFilters.get(explicitFilterType || columnType)
      if (availableFilters?.length) {
        if (filter?.filterParams && !!(filter?.filterParams as any)?.filterOptions?.length) {
          ;(filter.filterParams as any).filterOptions = (filter.filterParams as any).filterOptions.filter((x: { displayKey: string }) =>
            availableFilters.includes(x.displayKey)
          )
        } else if (filter) {
          filter.filterParams = { ...filter.filterParams, availableFilters } as any
        }
      }
      return filter
    }

    return {}
  }

  buildCols(
    param: BuildColParameter,
    _defaultHiddenFields: string[] | undefined,
    companyId: string,
    userSettingsColumns?: UserSettingColumn[],
    _lockedColumns?: string[],
    availableFilters: string[] | null = null,
    useLegacyLicenseFilter = false
  ): (ColDef & CoreViewColumn)[] {
    const allcols = !param?.allcols
      ? []
      : [
          ...param.allcols
            .filter((c) => param.selectedCols?.includes(c.originalName))
            .map((c: CoreViewColumn) => ({
              ...this.columnMap(
                c,
                param,
                _defaultHiddenFields?.includes(c.originalName || '') === true,
                !!_lockedColumns?.includes(c.originalName || ''),
                companyId,
                userSettingsColumns,
                availableFilters,
                useLegacyLicenseFilter
              ),
              ...c.agColDef,
            }))
            .sort(
              (i1, i2) =>
                (param.selectedCols || []).findIndex((c) => c === i1.originalName) -
                (param.selectedCols || []).findIndex((c) => c === i2.originalName)
            ),

          ...param.allcols
            .filter((c) => !param.selectedCols?.includes(c.originalName))
            .map((c: CoreViewColumn) => ({
              ...this.columnMap(c, param, true, false, companyId, userSettingsColumns, null, useLegacyLicenseFilter),
              ...c.agColDef,
              availableFilters,
            }))
            .sort((i1, i2) => (i1.headerName as string).localeCompare(i2.headerName as string)),
        ]

    const cols = allcols.sort((a, b) => (a.position || 0) - (b.position || 0))
    return cols as any //TODO: disambiguous the property type declared in both CoreViewColumn and ColDef
  }

  columnMap(
    c: CoreViewColumn,
    param: BuildColParameter,
    hide: boolean,
    lockVisible: boolean,
    companyId: string,
    userSettingsColumns?: UserSettingColumn[],
    availableFilters: string[] | null = null,
    useLegacyLicenseFilter = false
  ): ColDef & CoreViewColumn {
    const typedFilters = this.typedFiltersLookup(
      LibBuildColAgGrid.determineFilterName(useLegacyLicenseFilter, c.filter?.name, param.isClientDataGrid),
      c.type,
      companyId,
      (c as any).availableValues,
      c.filter?.type === 'string' ? undefined : c.filter?.type,
      availableFilters
    )

    const col = LibBuildColAgGrid.getColumn(c.originalName, userSettingsColumns)
    const pinned = col?.pinned
    const sort = col?.sort
    const sortIndex = col?.sortIndex !== undefined ? col.sortIndex : undefined
    const headerName = c.translate || c.originalName ? this.translateHelper.instant(c.translate || c.originalName) : ''

    return {
      headerName: headerName,
      field: c.name,
      originalName: c.originalName,
      ...typedFilters,
      sortable: c.sortName !== 'no_sort' && !!c.type,
      valueFormatter: c.type ? this.valueFormatterMap[c.type] : null,
      valueGetter: c.type ? this.valueGetterMap[c.type] : null,
      sortingOrder: ['asc', 'desc'],
      sortName: c.sortName !== 'no_sort' ? c.sortName : undefined,
      hide,
      floatingFilterComponent: 'genericFloatingFilter',
      floatingFilterComponentParams: {
        suppressFilterButton:
          (c.name && param.filtersToDisable && param.filtersToDisable.map((x) => x.toLowerCase()).indexOf(c.name.toLowerCase()) >= 0) ||
          (c.filterName &&
            param.filtersToDisable &&
            param.filtersToDisable.map((x) => x.toLowerCase()).indexOf(c.filterName.toLowerCase()) >= 0),
        disableParentFilter: typedFilters?.disableParentFilter ?? this.generalFilters.get(c.type)?.disableParentFilter,
      },
      menuTabs: [],
      suppressMenu: true,
      editable: c.agColDef?.editable ? c.agColDef?.editable : false, // keep this line before cellRendererDef as it can override editable prop
      ...this.cellRendererDef(c.cellOperation, param.cellRenderer, param.cellRendererParams, c.type, c.name, param.isPivot || false),
      cellClassRules: this.getCellClassRules(c.name, param.cellClassRules),
      position: c.position !== undefined ? c.position : this.getColumnPosition(c.originalName, userSettingsColumns),
      notAvailable: c.notAvailable ? c.notAvailable : false,
      notSelectable: c.notSelectable ? c.notSelectable : false,
      lockVisible,
      valueParser: c.agColDef?.valueParser || null,
      unSortIcon: true,
      ...(pinned && { pinned }),
      ...(sort && { sort }),
      ...(sortIndex !== undefined && { sortIndex }),
      headerComponent: 'tooltipHeader',
      headerComponentParams: {
        tooltip: headerName,
      },
    }
  }

  getCellClassRules(name: any, param: any): any {
    return param ? param[name] : null
  }

  isMasterDetailColumn(columnType: string, value: any): boolean {
    return this.cellRendererMap[columnType].isRowMaster && this.cellRendererMap[columnType].isRowMaster(value)
  }

  dictionaryFilterToFilterDefinition(
    dictionaryFilter: Record<string, string>,
    columns: CoreViewColumn[],
    filterKeysToKeepCapitized?: string[]
  ): FilterReportColumn {
    const filter = {} as FilterReportColumn
    Object.keys(dictionaryFilter).forEach((key) => {
      const k = filterKeysToKeepCapitized && filterKeysToKeepCapitized.indexOf(key) >= 0 ? key : Helpers.downcase(key)
      filter[k] = this.getFilterDefinition(key, dictionaryFilter, columns)
    })
    return filter
  }

  getFilterDefinition(field: string, dictionaryFilter: Record<string, string>, columns: CoreViewColumn[]): FilterDefinition {
    const filterDefinition = {} as FilterDefinition
    const coreviewColumn = columns.find((x) => x.originalName?.toLowerCase() === field?.toLowerCase())

    filterDefinition.filterType = coreviewColumn && coreviewColumn.type ? coreviewColumn.type : 'text'
    if (['double', 'int', 'currency'].includes(filterDefinition.filterType)) {
      filterDefinition.filterType = 'number'
    }

    const operator = this.getFilterOperator(filterDefinition.filterType, dictionaryFilter[field])

    filterDefinition.type = operator || 'contains'
    filterDefinition.filter =
      coreviewColumn?.type === 'date'
        ? dictionaryFilter[field].replace(comparableDateVerbs[filterDefinition.type], '')
        : dictionaryFilter[field].replace(comparableVerbs[filterDefinition.type], '')

    if (filterDefinition.filterType === 'date') {
      filterDefinition.dateFrom =
        filterDefinition.type === 'equals' || filterDefinition.type === 'inRange'
          ? filterDefinition.filter.split('&')[0].trim()
          : filterDefinition.filter

      if (operator === 'inRange') {
        filterDefinition.dateTo =
          filterDefinition.type === 'equals' || filterDefinition.type === 'inRange'
            ? filterDefinition.filter.split('&')[1].trim()
            : filterDefinition.filter
      }
    }

    if (operator === 'inRange') {
      const filters = filterDefinition.filter.split('&')
      filterDefinition.filter = filters[0].trim()
      filterDefinition.filterTo = filters.length > 1 ? filters[1].trim() : ''
    }

    return filterDefinition
  }

  getFilterOperator(type: string, filter: string) {
    if (type === 'date') {
      const operator =
        Object.keys(comparableVerbsWithNoValues).find(
          (key) => !!comparableVerbsWithNoValues[key] && filter.startsWith(comparableVerbsWithNoValues[key])
        ) || Object.keys(comparableDateVerbs).find((key) => !!comparableDateVerbs[key] && filter.startsWith(comparableDateVerbs[key]))
      if (operator === 'equals' || operator === 'inRange') {
        const dateFrom = dayjs(filter.replace(comparableDateVerbs[operator], '').split('&')[0].trim(), { utc: true }).startOf('day')
        const dateTo = dayjs(filter.replace(comparableDateVerbs[operator], '').split('&')[1].trim(), { utc: true }).startOf('day')
        return dateFrom.isSame(dateTo) ? 'equals' : 'inRange'
      }
      return operator
    } else {
      return (
        Object.keys(comparableVerbsWithNoValues).find(
          (key) => !!comparableVerbsWithNoValues[key] && filter.startsWith(comparableVerbsWithNoValues[key])
        ) || Object.keys(comparableVerbs).find((key) => !!comparableVerbs[key] && filter.startsWith(comparableVerbs[key]))
      )
    }
  }

  mapTreeFilters(treeFilter: QueryFilter2) {
    const f = this.extractFilters(treeFilter.children, {})

    treeFilter.children
      .filter((c) => !c.queryFilter)
      .forEach((ai: any) => {
        this.extractFilters(ai.children, f)
      })

    Object.keys(f).forEach((g) => {
      f[g] = {
        filter: f[g],
        filterType: 'multiselect',
        type: 'multiselect',
      } as any
    })

    return f
  }

  mapSavedTreeFilters(filtersFromSaved: QueryFilter2, filtersFromDefinition?: QueryFilter2): Record<string, QueryFilter2> {
    const mappedSaved = this.mapTreeFilters(filtersFromSaved)

    if (!filtersFromDefinition) {
      return mappedSaved
    } else {
      const savedFilters: any = {}
      const mappedDefinition = this.mapTreeFilters(filtersFromDefinition)

      Object.keys(mappedSaved).forEach((k: string) => {
        const objSaved = mappedSaved[k] as any
        const objDefinition = mappedDefinition[k] as any

        objSaved.filter.children.forEach((cs: childFilter) => {
          const child = objDefinition
            ? objDefinition.filter.children.find((cd: childFilter) => cd.queryFilter?.value === cs.queryFilter?.value)
            : undefined

          if (!savedFilters[k]) {
            savedFilters[k] = { ...objSaved, filter: { ...objSaved.filter, children: [] } }
          }

          savedFilters[k].filter.children.push({ ...cs, unchecked: !child })
        })
      })

      return savedFilters
    }
  }

  stringToArrayMultiselectFilters(filters: FilterReportColumn) {
    Object.values(filters)
      .filter((f) => f.filterType === 'multiselect')
      .forEach((f) => {
        if (typeof f.filter === 'string') {
          f.filter = f.filter.replace('+[', '').replace(']', '').split(',')
        }
      })
  }

  getContextMenuItems(gridApi: GridApi) {
    return [
      {
        name: this.translateHelper.instant('common_Copy'),
        action: () => {
          gridApi.copySelectedRangeToClipboard({ includeHeaders: false })
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>',
      },
      {
        name: this.translateHelper.instant('common_CopyWithHeaders'),
        action: () => {
          gridApi.copySelectedRangeToClipboard({ includeHeaders: true })
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>',
      },
    ]
  }

  processCellForClipboard(params: ProcessCellForExportParams) {
    const colDef: ColDef<any> = params.column.getColDef()
    if (typeof colDef.valueFormatter === 'function') {
      return colDef.valueFormatter({
        ...params,
        data: params.node?.data,
        colDef,
      } as ValueFormatterParams)
    } else if (colDef.cellRenderer === 'flatDictionaryCellRenderer' && params.value instanceof Object) {
      return Object.entries(params.value)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([key, value]) => key + ': ' + value)
        .join(', ')
    }

    return params.value
  }

  isLinkRenderer(rendererKey?: string): boolean {
    return !!rendererKey && ['linkCellRenderer', 'actionCellRenderer', 'customLinkRendererComponent'].includes(rendererKey)
  }

  buildOperationColumn(
    allColumns: CoreViewColumn[],
    operation: OperationColumn,
    hideOpenCard: boolean,
    targetEntity?:
      | TargetEntityBaseType
      | TargetEntityType
      | TargetEntityPolicyType
      | TargetEntityCustomActionType
      | TargetEntityAuditType
      | TargetEntityWorkflowType
      | TargetEntityTeamsType
  ) {
    const cFound = allColumns.find((c) => c.name === operation.name)
    if (cFound) {
      this.setExistingOperationColumn(operation, cFound)
    } else if (this.isPredefinedOperation(operation.predefinedOperation)) {
      this.getPredefinedOperation(operation, hideOpenCard, targetEntity).subscribe((op) => {
        if (op) {
          allColumns.push({
            ...op,
            originalName: op.originalName || '',
            translate: op.translate || '',
          })
        }
      })
    } else {
      if (!!operation.agColDef && !!operation.agColDef.cellRendererParams && !!operation.agColDef.cellRendererParams.clickFunction) {
        operation.agColDef.cellRendererParams = {
          ...operation.agColDef.cellRendererParams,
          ...{
            onClick:
              this.reportsService.operationsColumnsFunctionsMap[operation.agColDef.cellRendererParams.clickFunction] ||
              operation.agColDef.cellRendererParams.clickFunction,
          },
        }
      }

      allColumns.push({
        ...operation,
        ...{
          name: operation.field || operation.name || '',
          translate: operation.translate || '',
          originalName: operation.originalName || '',
          sortName: 'no_sort',
        },
      })
    }
  }

  private convertToComparableString = (value: any): string => {
    switch (typeof value) {
      case 'string':
        return value.toLocaleLowerCase()
      case 'number':
      case 'boolean':
        return value.toString().toLocaleLowerCase()
      case 'object':
        if (value === null) {
          return ''
        } else if (typeof value.toString === 'function') {
          return value.toString().toLocaleLowerCase()
        }
        return ''
      default:
        return ''
    }
  }

  private setExistingOperationColumn(op: OperationColumn, existingCol: CoreViewColumn) {
    if (op?.agColDef?.cellRendererParams?.clickFunction) {
      op.agColDef.cellRendererParams = {
        ...op.agColDef.cellRendererParams,
        ...{ onClick: this.reportsService.operationsColumnsFunctionsMap[op.agColDef.cellRendererParams.clickFunction] },
      }
    }
    existingCol.agColDef = op.agColDef || existingCol.agColDef
    if (Object.keys(op).includes('notSelectable')) {
      existingCol.notSelectable = op.notSelectable
    }
    existingCol.translate = op.translate ?? existingCol.translate
  }

  private isPredefinedOperation(operation?: 'card' | 'edit') {
    return operation && ['card', 'edit'].includes(operation)
  }

  private getPredefinedOperation(
    operation: OperationColumn,
    hideOpenCard: boolean,
    targetEntity?:
      | TargetEntityBaseType
      | TargetEntityType
      | TargetEntityPolicyType
      | TargetEntityCustomActionType
      | TargetEntityAuditType
      | TargetEntityWorkflowType
      | TargetEntityTeamsType
  ): Observable<OperationColumn | null> {
    if (operation.predefinedOperation === 'card' && !hideOpenCard) {
      return of(this.getOpenCardOperation(operation.param))
    } else if (operation.predefinedOperation === 'edit' && this.sharedHelperService.isManagement() && targetEntity) {
      return this.reportsService.getEditOperation(targetEntity)
    }
    return of(null)
  }

  private getOpenCardOperation(params: any = {}): OperationColumn {
    return {
      type: 'iconButton',
      originalName: 'common_OpenCardInNewTab',
      name: 'common_OpenCardInNewTab',
      position: -3,
      notSelectable: true,
      sortName: 'no_sort',
      agColDef: {
        ...Constants.defaultOperationColumnsDefinition,
        colId: 'openCard',
        pinned: 'left',
        lockPinned: true,
        hide: false,
        cellRenderer: 'iconButtonRenderer',
        cellRendererParams: {
          icon: 'open_in_new',
          tooltipKey: 'common_OpenCardInNewTab',
          ...params,
        },
      },
    }
  }

  private getColumnPosition(columnOriginalName: string | undefined, userSettingsColumns?: UserSettingColumn[]) {
    const columnFound = userSettingsColumns?.find((usColumn) => usColumn.originalName === columnOriginalName)
    if (columnFound) {
      return (columnFound?.position || 0) + 2
    } else {
      return 2
    }
  }

  private extractFilters = (children: { queryFilter?: QueryFilter }[], initialValue: any) =>
    children
      .filter((ai) => !!ai.queryFilter && MULTISELECT_OPTIONS.includes(Helpers.downcase(ai.queryFilter.operation)))
      .reduce((pr: Record<string, QueryFilter2>, ai) => {
        const dcName = Helpers.downcase(ai.queryFilter?.name || '')
        if (pr[dcName]) {
          pr[dcName].children.push({ queryFilter: ai.queryFilter })
        } else {
          pr[Helpers.downcase(ai.queryFilter?.name || '')] = { operation: 'OR', children: [{ queryFilter: ai.queryFilter }] }
        }
        return pr
      }, initialValue)
}

export interface BuildColParameter {
  allcols: CoreViewColumn[]
  selectedCols?: (string | undefined)[]
  filtersToDisable?: string[]
  cellRenderer?: Record<string, any>
  cellRendererParams?: Record<string, any>
  defaultHiddenField?: string[]
  sortedFields?: string[]
  cellClassRules?: any
  isPivot?: boolean
  isClientDataGrid?: boolean
}
