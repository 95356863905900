import { Injectable } from '@angular/core'
import { ManagementHelperService } from '@app/shared/utilities/management.helper.service'
import { ToastService } from '@coreview/coreview-components'
import { TranslateService } from '@ngx-translate/core'
import { SelectedOrganization } from '@coreview/coreview-library/models/selected-organization'
import { Role } from '../models/authenticated-user'
import { LocalstorageService } from './localstorage.service'

@Injectable({
  providedIn: 'root',
})
export class ActionsHelperService {
  selectedOrganization: SelectedOrganization
  loggedUser: { userName: string; roles: Role[] }

  constructor(
    private toastService: ToastService,
    private translateHelper: TranslateService,
    private managementHelperService: ManagementHelperService,
    private storage: LocalstorageService
  ) {
    this.selectedOrganization = this.storage.selectedOrganization
    this.loggedUser = this.storage.getLoggedUser()
  }

  checkAction = () => {
    if (this.getLocationHost() === 'www.4ward365.local' || this.getLocationHost().startsWith('localhost')) {
      this.onLocalAlert()
      return true
    }
    if (this.checkDemo()) {
      this.notManagementAction()
      return false
    } else if (!this.managementHelperService.isManagementEnabled()) {
      this.managementIsNotActiveAlert()
      return false
    }
    return true
  }

  private onLocalAlert = () =>
    this.toastService.open(
      { id: 'info', variant: 'info', title: this.translateHelper.instant('ActionExecutedInLocal') },
      { autoclose: true, timeout: 5000 }
    )

  private notManagementAction = () =>
    this.toastService.open(
      { id: 'info', variant: 'info', title: this.translateHelper.instant('DemoUserNotManagementAction') },
      { autoclose: true, timeout: 5000 }
    )

  private managementIsNotActiveAlert = () =>
    this.toastService.open(
      { id: 'info', variant: 'info', title: this.translateHelper.instant('ManagementIsNotActiveAlert') },
      { autoclose: true, timeout: 5000 }
    )

  private getLocationHost() {
    return this.getWindowLocation().host
  }

  private getWindowLocation() {
    return window.location
  }

  private checkDemoCompany = () => this.selectedOrganization?.isDemo
  private checkDemoUser = () => this.selectedOrganization?.isDemo && this.loggedUser.userName?.indexOf('demo') >= 0
  private checkDemo = () => this.checkDemoUser() || this.checkDemoCompany()
}
