import { AgFilterComponent } from '@ag-grid-community/angular'
import { IDoesFilterPassParams, AgPromise, IFilterParams } from '@ag-grid-community/core'
import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core'
import { comparableDateVerbs, comparableVerbsWithNoValues } from '@app/core/models/ReportDefinition'
import dayjs from 'dayjs'
import { debounceTime, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.sass'],
})
export class DateFilterComponent implements OnInit, OnDestroy, AgFilterComponent {
  params!: IFilterParams
  value!: any
  numberValue!: any

  options: { value: string; display: string }[] = [
    { value: 'atLeastOnce', display: 'common_AtLeastOnce' },
    { value: 'inRange', display: 'common_Between' },
    { value: 'equals', display: 'common_Equals' },
    { value: 'greaterThan', display: 'common_IsGreaterThan' },
    { value: 'greaterThanOrEqual', display: 'common_IsGreaterThanOrEqual' },
    { value: 'lessThan', display: 'common_IsLessThan' },
    { value: 'lessThanOrEqual', display: 'common_IsLessThanOrEqual' },
    { value: 'lastNDays', display: 'common_LastNDays' },
    { value: 'neverUsed', display: 'common_NeverUsed' },
    { value: 'notInLastNDays', display: 'common_NotInLastNDays' },
    { value: 'nextNDays', display: 'common_NextNDays' },
  ]

  type:
    | ''
    | 'atLeastOnce'
    | 'inRange'
    | 'equals'
    | 'greaterThan'
    | 'greaterThanOrEqual'
    | 'lessThan'
    | 'lessThanOrEqual'
    | 'lastNDays'
    | 'neverUsed'
    | 'notInLastNDays'
    | 'nextNDays' = ''

  filterChanged$ = new EventEmitter<string>()
  destroyed$ = new EventEmitter<void>()

  constructor() {}

  ngOnInit(): void {
    this.filterChanged$.pipe(debounceTime(500), takeUntil(this.destroyed$)).subscribe(() => this.params.filterChangedCallback({  source: 'columnFilter'}))
  }

  ngOnDestroy(): void {
    this.destroyed$.emit()
    this.destroyed$.complete()
  }

  isFilterActive(): boolean {
    if (!this.type) {
      return false
    }
    if (['atLeastOnce', 'neverUsed'].includes(this.type)) {
      return true
    }
    if (this.type === 'inRange') {
      return !!this.value?.startDate && !!this.value?.endDate
    }
    if (['notInLastNDays', 'lastNDays', 'nextNDays'].includes(this.type)) {
      return !!this.numberValue
    }
    return !!this.value
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const value = this.params.valueGetter(params as any)

    return this.filterDatePass(value)
  }

  filterDatePass(value: any): boolean {
    switch (this.type) {
      case '':
        return true
      case 'atLeastOnce':
        return !!value
      case 'equals':
        return !!value && dayjs(value) >= this.value.utc(true).startOf('day') && dayjs(value) <= this.value.utc(true).endOf('day')
      case 'greaterThan':
        return !!value && dayjs(value) > this.value.utc(true).endOf('day')
      case 'greaterThanOrEqual':
        return !!value && dayjs(value) >= this.value.utc(true).startOf('day')
      case 'inRange':
        return (
          !!value &&
          dayjs(value) >= this.value.startDate.utc(true).startOf('day') &&
          dayjs(value) <= this.value.endDate.utc(true).endOf('day')
        )
      case 'lastNDays':
        return !!value && dayjs(value) >= dayjs().add(-this.numberValue, 'day').utc(true).endOf('day')
      case 'lessThan':
        return !!value && dayjs(value) < this.value.utc(true).startOf('day')
      case 'lessThanOrEqual':
        return !!value && dayjs(value) < this.value.utc(true).endOf('day')
      case 'neverUsed':
        return !value
      case 'notInLastNDays':
        return !!value && dayjs(value) < dayjs().add(-this.numberValue, 'day').utc(true).endOf('day')
      case 'nextNDays': {
        const today = dayjs().utc(true).startOf('day')
        const futureDate = today.add(this.numberValue, 'day').endOf('day')
        return !!value && dayjs(value) >= today && dayjs(value) <= futureDate
      }
      default:
        return false
    }
  }

  getModel() {
    const model = this.isFilterActive() ? { filterType: 'date', type: this.type, ...this.getValue() } : undefined
    return model
  }

  modelChanged() {
    if (!this.type || this.isFilterActive()) {
      this.filterChanged$.emit()
    }
  }

  setModel(model: any): void | AgPromise<void> {
    if (model) {
      this.type = model.type || ''
      if (['notInLastNDays', 'lastNDays', 'nextNDays'].includes(this.type)) {
        this.numberValue = model.filter
      } else {
        this.value = model.dateTo ? { startDate: dayjs(model.dateFrom), endDate: dayjs(model.dateTo) } : dayjs(model.dateFrom)
      }
    } else {
      this.type = ''
    }
  }

  agInit(params: IFilterParams): void {
    this.params = params
    if ((params as any).availableFilters?.length) {
      this.options = this.options.filter((x) => (params as any).availableFilters.includes(x.value))
    }
  }

  onFloatingFilterChanged(type: any, value: string) {
    this.type = type || ''
    if (!this.type) {
      this.numberValue = null
      this.value = null
    } else if (['notInLastNDays', 'lastNDays', 'nextNDays'].includes(this.type)) {
      this.numberValue = value
    } else {
      this.value = value
    }
    this.params.filterChangedCallback({ source: 'columnFilter' })
  }

  getModelAsString?(model: any): string {
    return !model.type ? '' : this.getDateFiltersVerb(model) + this.getDateFiltersValue(model)
  }

  private getDateFiltersVerb = (filterModel: any): string => comparableDateVerbs[filterModel.type]

  private getDateFiltersValue = (filterModel: any): any => {
    if (filterModel.type === 'equals') {
      filterModel.dateFrom = dayjs(filterModel.dateFrom).utc(true).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      filterModel.dateTo = dayjs(filterModel.dateFrom).utc(true).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      return filterModel.dateFrom + ' & ' + filterModel.dateTo
    } else if (filterModel.type === 'inRange') {
      filterModel.dateFrom = dayjs(filterModel.dateFrom).utc(true).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      filterModel.dateTo = dayjs(filterModel.dateTo).utc(true).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      return filterModel.dateFrom + ' & ' + filterModel.dateTo
    } else if (['notInLastNDays', 'lastNDays', 'nextNDays'].includes(filterModel.type)) {
      return filterModel.filter
    } else if (comparableVerbsWithNoValues.hasOwnProperty(filterModel.type)) {
      return comparableVerbsWithNoValues[filterModel.type as string]
    } else if (['greaterThan'].includes(filterModel.type)) {
      return dayjs(filterModel.dateFrom).utc(true).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    } else if (['greaterThanOrEqual'].includes(filterModel.type)) {
      return dayjs(filterModel.dateFrom).utc(true).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    } else if (['lessThan'].includes(filterModel.type)) {
      return dayjs(filterModel.dateFrom).utc(true).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    } else if (['lessThanOrEqual'].includes(filterModel.type)) {
      return dayjs(filterModel.dateFrom).utc(true).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    } else {
      return dayjs(filterModel.dateFrom).utc(true).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
  }

  private getValue() {
    return ['notInLastNDays', 'lastNDays', 'nextNDays'].includes(this.type)
      ? { filter: this.numberValue }
      : !this.value
      ? { dateFrom: null }
      : typeof this.value === 'object' && this.value.startDate
      ? { dateFrom: this.value.startDate, dateTo: this.value.endDate }
      : { dateFrom: this.value }
  }
}
