import { LocalstorageService } from '@core/services/localstorage.service'
import { ApiclientService } from './../../services/apiclient.service'
import { Component } from '@angular/core'
import { ToastService } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.sass'],
})
export class ForbiddenComponent {
  constructor(private apiclientService: ApiclientService, private storage: LocalstorageService, private toastService: ToastService, private translateHelper: TranslateHelper) {}

  sendEmail(): void {
    const companyId = this.storage.selectedOrganization.id
    this.apiclientService.getData(`/${companyId}/email/send`, 'post', {
        companyId: companyId,
        recipients: ['expansionrequest@coreview.com'],
        subject: 'CoreView Portal Access Expansion Request',
        body: `Dear CoreView Sales Team,<br/><br/>

        I am interested in expanding my access to the CoreView Portal and would like to be contacted by a sales representative. My details are as follows:<br/><br/>

        User Name: ${this.storage.getLoggedUser().displayName}<br/>
        UPN: ${this.storage.getLoggedUser().userName}<br/>
        Email: ${this.storage.getLoggedUser().email}<br/>
        Company Name: ${this.storage.selectedOrganization.text}<br/>
        Company ID: ${companyId}<br/><br/>

        I currently have limited access to the portal and would like to discuss options for expanding my capabilities. Could you please have a sales representative contact me to discuss available upgrades or additional services?<br/><br/>

        Thank you for your assistance. I look forward to hearing from your team.<br/><br/>

        Best regards,<br/>
        ${this.storage.getLoggedUser().displayName}`,
      },
    ).subscribe((_) => {
      this.toastService.open({
        id:'success',
        variant: 'success',
        title: this.translateHelper.instant('common_Success'),
        message: this.translateHelper.instant('common_EmailSent'),
      })
    },
    (_) => {
      this.toastService.open({
        id: 'error',
        variant: 'error',
        title: this.translateHelper.instant('common_Error'),
        message: this.translateHelper.instant('common_EmailError'),
      })
    })
  }
}
