<ng-container *ngIf="!isMultipleIcons">
    <mat-icon
        *ngIf="!isFabricIcon && !hideIcon()"
        style="vertical-align: middle; margin-bottom: 4px;"
        [ngClass]="getIconClass()"
        [ngStyle]="{'color': getColor()}">
        {{ getIcon() }}
    </mat-icon>
    <em
        *ngIf="isFabricIcon && !hideIcon()"
        style="vertical-align: middle; margin-bottom: 4px;"
        [ngClass]="icon">
    </em>
    <span *ngIf="showValue">
        {{params.getValue()}}
    </span>
</ng-container>

<ng-container *ngIf="isMultipleIcons">
    <span *ngFor="let icon of getIcons()" style="display: inline-block; width: 60px;">
        <mat-icon
            *ngIf="!icon.isFabricIcon && (!icon.hideIcon || !icon.hideIcon())"
            style="vertical-align: middle; margin-bottom: 4px;"
            [ngClass]="icon.getIconClass()"
            [ngStyle]="{'color': icon.getColor()}">
            {{ icon.getIcon() }}
        </mat-icon>
        <em
            *ngIf="icon.isFabricIcon && (!icon.hideIcon || !icon.hideIcon())"
            style="vertical-align: middle; margin-bottom: 4px;"
            [ngClass]="icon.icon">
        </em>
        <span *ngIf="showValue">
            {{icon.getValue()}}
        </span>
    </span>
</ng-container>

