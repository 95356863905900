import { RootState } from '@app/store/RootState.type'
import { LoggerService } from './../services/logger.service'
import { AuthenticationService } from '@app/core/services/authentication.service'
import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, filter, take } from 'rxjs/operators'
import { ApplicationInsightService } from '../services/application-insight.service'
import { environment } from '@environments/environment'
import { Store } from '@ngrx/store'
import { LocalstorageService } from '../services/localstorage.service'
import { Helpers } from '@app/shared/utilities/helpers'
import { MatDialog } from '@angular/material/dialog'
import { DialogComponent } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'
import { maskPasswords } from '../utils/mask-passwords'

const LOG_TAG = 'PortalV2 HttpErrorsInterceptor'

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  constructor(
    private appInsights: ApplicationInsightService,
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService,
    private store: Store<RootState>,
    private dialog: MatDialog,
    private storage: LocalstorageService,
    private translateHelper: TranslateHelper
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        this.store.pipe(take(1)).subscribe({
          next: (state) => {
            const message =
              LOG_TAG +
              '| version: ' +
              environment.appVersion +
              '| user:' +
              (this.storage.getLoggedUser()?.userName ?? 'anonymous')+
              '| request:' +
              JSON.stringify(maskPasswords(request)) +
              // '| localStorage: ' +
              // JSON.stringify(localStorage) +
              // '| store: ' +
              // JSON.stringify(state) +
              '| error: ' +
              JSON.stringify(this.getDataForHttpError(errorResponse))

            if (request.url !== this.loggerService.getLogErrorUrl()
              && errorResponse.status !== 401) {
              this.loggerService.logError(message).subscribe()
            } else {
              console.error(message)
            }
          },
        })

        if (errorResponse.status === 401 && this.authenticationService.isBasePortalUrl(request.url) && !request.url.includes('v2/logs/error')) {
          this.checkErrorManagedTenant()

          if (request.url === this.authenticationService.getAvailabilityUrl()) {
            if (this.storage.getLoggedUser()?.userId) {
              this.authenticationService.logout()
            }
          } else if (request.url.indexOf('api/organization/') >= 0) {
            return throwError({ ...errorResponse, ...{ status: 401 }})
          }
          else {
            return this.authenticationService.checkAvailability()
          }
        }

        if (errorResponse.status === 0) {
          this.appInsights.trackException(errorResponse, 'Unable to Connect to the Server')
          return throwError({ ...errorResponse, ...{ status: 472 }}) //https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
        }
        return throwError(errorResponse)
      })
    )
  }

  private checkErrorManagedTenant() {
    const selectedTenantId = Helpers.getSelectedTenantFromUrl()
    if(selectedTenantId && this.storage.getLoggedUser()?.organizationId && this.storage.selectedOrganization.id !== this.storage.getLoggedUser()?.organizationId) {
      this.storage.selectedOrganization = { id: this.storage.getLoggedUser()?.organizationId, text: this.storage.getLoggedUser()?.organizationId }

      this.translateHelper.reloadLang(navigator.language)
      this.translateHelper.use(navigator.language)

      const dialogRef = this.dialog.open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translateHelper.instant('common_Error'),
          text: this.translateHelper.instant('common_CannotManageOrganizationError'),
          type:'alert',
          hideCloseButton: true
        },
        disableClose: true
      })

      dialogRef.
        afterClosed()
        .pipe(filter((x) => !!x))
        .subscribe()
    }
  }

  private getDataForHttpError(error: HttpErrorResponse): any {
    const status = error.status || 0
    const detail = error.message || ''

    if (status === 0) {
      return { status, message: `Http failure with status 0 (probably a CORS error)`, detail, url: error.url }
    }

    if (status === 401) {
      return { status, message: `Auth expired, logging out with status 401`, detail, url: error.url }
    }
    return { status, message: `Http failure with status ${status}`, detail, url: error.url }
  }
}
