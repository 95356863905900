import { Category, Playbook, PlaybookTitleWithPolicyCount, Policy } from '@app/core/models/playbook'
import { CustomPolicyRequest } from './../../../core/models/playbook'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { combineLatest, Observable, of } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { OwnedPolicy, PolicyOwnerCandidate } from '@app/core/models/PolicyOwnerCandidate'

@Injectable({
  providedIn: 'root',
})
export class PlaybookService {
  constructor(private apiClient: ApiclientService, private httpClient: HttpClient, private sharedHelperService: SharedHelperService) {}

  getPolicies(): Observable<Policy[]> {
    if(this.sharedHelperService.isAdmin() || this.sharedHelperService.checkRoles(['playbookAdmin', 'playbookManager', 'playbookGlobalViewOnly'])) {
      const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/`
      return this.httpClient.get<any>(url, { withCredentials: true })
    } else {
      return of([])
    }
  }

  updatePolicy(policy: Policy): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policy.id}`
    return this.httpClient.put<any>(url, { policy }, { withCredentials: true })
  }

  patchPolicy(policyId: string, policy: { isPolicyEnabled: boolean; isWorkflowEnabled: boolean }): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}`
    return this.httpClient.patch<any>(url, { policy }, { withCredentials: true })
  }

  runPolicyWorkflow(policyId: string, entityIds: string[]): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}/workflow/runForItems`
    return this.httpClient.post<any>(url, { entityIds }, { withCredentials: true })
  }

  runPolicyWorkflowAllItems(policyId: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}/workflow/run`
    return this.httpClient.post<any>(url, {}, { withCredentials: true })
  }

  getPolicyWorkflowExecutionsCount(policyWorkflowId: string, serviceTag: string) {
    const params = {
      fields: ['Id'],
      filters: {
        ServiceTags: '=' + serviceTag,
      },
      pageSize: 1,
      pageNumber: 1,
      itemsPerPage: 1,
      sort: 'StartAt',
      sortOrder: 'desc',
      groupParameters: [],
      showSensitiveData: false,
      metadata: true,
    }
    const url = `${this.apiClient.getWorkflowPlaybookBaseApi()}api/v2/executions/${policyWorkflowId}`
    return this.httpClient.post<any>(url, params, { withCredentials: true }).pipe(
      map((data: any) => {
        return data?.metadata.totalCount ?? 0
      })
    )
  }

  removePolicyExceptions(policyId: string, entityIds: string[]): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}/exceptions/removeByEntities`
    return this.httpClient.post<any>(url, { entityIds }, { withCredentials: true })
  }

  removeLegacyPolicyExceptions(kpiId: string, items: any[], responseType: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/kpis/exceptions/remove`
    return this.httpClient.put<any>(url, { kpiId, items, responseType }, { withCredentials: true })
  }

  removeAllPolicyExceptions(policyId: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}/exceptions/removeAll`
    return this.httpClient.post<any>(url, {}, { withCredentials: true })
  }

  getPolicyExceptions(policyId: string, entityIds: string[]): Observable<{ entityId: string; expirationDateUtc?: Date; notes: string }[]> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}/exceptions/getByEntities`
    return this.httpClient.post<any>(url, { entityIds }, { withCredentials: true })
  }

  submitPolicyExceptions(
    policyId: string,
    exceptions: { entityId: string; expirationDateUtc?: string }[],
    notes: string
  ): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}/exceptions/addByEntities`
    return this.httpClient.post<any>(url, { exceptions, notes }, { withCredentials: true })
  }

  submitLegacyPolicyExceptions(
    kpiId: string,
    expirationDate: string | null | undefined,
    notes: string,
    items: any[],
    responseType: string,
    kpiReportColumns: string[]
  ): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/kpis/exceptions/add`
    return this.httpClient.post<any>(
      url,
      { kpiId, expirationDate, notes, items, responseType, kpiReportColumns },
      { withCredentials: true }
    )
  }

  calculateMetrics(policyId: string) {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/metrics/calculate`
    return this.httpClient.post<any>(url, { policyId }, { withCredentials: true })
  }

  getCategories(): Observable<Category[]> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/categories`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  deleteCategories(id: string): Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/categories/${id}`
    return this.httpClient.delete<any>(url, { withCredentials: true })
  }

  getCustomPlaybooks(): Observable<Playbook[]> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/playbooks/custom`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  getPlaybooks(): Observable<Playbook[]> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/playbooks`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  /**
   * Creates a custom playbook with the provided details.
   *
   * @param customPlaybook - An object containing the details of the playbook to be created.
   * @param customPlaybook.title - The title of the custom playbook.
   * @param customPlaybook.description - The description of the custom playbook.
   * @param customPlaybook.policyIds - An array of policy IDs associated with the custom playbook.
   *
   * @returns An Observable that contains the created playbook id.
   */
  createCustomPlaybook(customPlaybook: { title: string; description: string; policyIds: string[] }): Observable<string> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/playbooks/custom`
    return this.httpClient.post(url, { customPlaybookCreationDto: customPlaybook }, { responseType: 'text', withCredentials: true })
  }

  updateCustomPlaybook(
    playbookId: string,
    editedCustomPlaybook: { title: string; description: string; policyIds: string[]; addedIds: string[]; removedIds: string[] }
  ): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/playbooks/custom/${playbookId}`
    return this.httpClient.put<any>(url, { playbookId, editedCustomPlaybook }, { withCredentials: true })
  }

  createCustomPolicy(customPolicy: CustomPolicyRequest): Observable<Policy> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/custom`
    return this.httpClient.post<any>(url, { policyCreating: customPolicy }, { withCredentials: true })
  }

  updateCustomPolicy(policyId: string, customPolicy: CustomPolicyRequest): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/custom/${policyId}`
    return this.httpClient.put<any>(url, { policyEditing: customPolicy }, { withCredentials: true })
  }

  getPolicy(policyId: string, skipCalculations: boolean = true): Observable<Policy> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}?skipCalculations=${skipCalculations}`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  getLegacyPolicy(policyId: string): Observable<Policy> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/legacy/${policyId}`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  deleteCustomPolicy(policyId: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}/custom`
    return this.httpClient.delete<any>(url, { withCredentials: true })
  }

  deleteLegacyPolicy(policyId: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/kpi/?id=${policyId}`
    return this.httpClient.delete<any>(url, { withCredentials: true })
  }

  deleteLegacyPlaybook(playbookId: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/customdashboard/?id=${playbookId}`
    return this.getLegacyPoliciesByLegacyPlaybook(playbookId).pipe(
      switchMap((policies) =>
        combineLatest([...policies.filter((x) => x.playbookIds?.length === 1).map((p) => this.deleteLegacyPolicy(p.id ?? '')), of(void 0)])
      ),
      switchMap(() => this.httpClient.delete<any>(url, { withCredentials: true }))
    )
  }

  getLegacyPoliciesByLegacyPlaybook(playbookId: string): Observable<Policy[]> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/playbook/legacy/${playbookId}`
    return this.httpClient.get<any>(url, { withCredentials: true })
  }

  checkPolicyTitle(policyId: string | null | undefined, title: string): Observable<{ isTitleAllowed: boolean }> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/verifyTitle`
    return this.httpClient.post<any>(url, { title, policyId }, { withCredentials: true })
  }

  checkPlaybookTitle(playbookId: string | null | undefined, title: string): Observable<{ isTitleAllowed: boolean }> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/playbooks/verifyTitle`
    return this.httpClient.post<any>(url, { title, playbookId }, { withCredentials: true })
  }

  createTemplateFromPolicy(policyId: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/templates/createFromPolicy`
    return this.httpClient.post<any>(url, { policyId }, { withCredentials: true })
  }

  getPlaybookTitleWithPolicyCount(): Observable<PlaybookTitleWithPolicyCount[]> {
    if(this.sharedHelperService.isAdmin() || this.sharedHelperService.checkRoles(['playbookAdmin', 'playbookManager', 'playbookGlobalViewOnly'])) {
      const url = `${this.apiClient.basePortalApiUrl}/playbook/playbooks/titleWithPolicyCount`
      return this.httpClient.get<any>(url, { withCredentials: true })
    } else {
      return of([])
    }
  }

  getPolicyOwnerCandidates(resultLimit: number, usernameFilter: string = ''): Observable<PolicyOwnerCandidate[]> {
    let url = `${this.apiClient.basePortalApiUrl}/v0/playbook/policies/ownerCandidates?resultLimit=${resultLimit}`

    if (!!usernameFilter && usernameFilter.trim().length > 0) {
      url += `&usernameFilter=${usernameFilter}`
    }

    return this.httpClient.get<PolicyOwnerCandidate[]>(url, { withCredentials: true })
  }

  updatePolicyOwner(policyId: string, newOwner: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}/owner`
    return this.httpClient.patch<any>(url, { newOwner }, { withCredentials: true })
  }

  updatePoliciesOwner(oldOwners: string[], newOwner: string): Observable<void> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/batch/owner`
    return this.httpClient.patch<any>(url, { oldOwners, newOwner}, { withCredentials: true })
  }

  getPoliciesOwnedBy(operators: string[], groupIds: string[]): Observable<Record<string, OwnedPolicy[]>> {
    const baseUrl = `${this.apiClient.basePortalApiUrl}/playbook/policies/ownedBy`

    return this.httpClient.post<Record<string, OwnedPolicy[]>>(baseUrl, { operators, groupIds }, { withCredentials: true })
  }

  isCategoryNameAvailable(categoryTitle: string) : Observable<boolean> {
    const baseUrl = `${this.apiClient.basePortalApiUrl}/playbook/categories/titleAvailable`
    const urlWithParams = `${baseUrl}?title=${categoryTitle}`

    return this.httpClient
      .get<{ available: boolean }>(urlWithParams, { withCredentials: true })
      .pipe(map(response => response.available))
  }

  updateCategoryTitle(id: string, newTitle: string) : Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/categories/${id}`
    return this.httpClient.put<any>(url, { title: newTitle }, { withCredentials: true })
  }

  updatePoliciesCategory(categoryId: string, policyIdsToAdd: string[], policiesIdsToRemove: string[]) : Observable<any> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/categories`

    return this.httpClient.put<any>(url, { categoryId, policyIdsToAdd, policiesIdsToRemove }, { withCredentials: true })
  }

  duplicatePolicy(policyId: string, duplicateWorkflow: boolean = false) : Observable<Policy> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/policies/${policyId}/duplicate`
    return this.httpClient.post<Policy>(url, { duplicateWorkflow }, {withCredentials: true})
  }

  getGradePolicies(): Observable<any[]> {
    const url = `${this.apiClient.basePortalApiUrl}/playbook/governance/benchmark/policies`
    return this.httpClient.get<any[]>(url, { withCredentials: true })
  }
}
