import { Component } from '@angular/core'
import { AgRendererComponent } from '@ag-grid-community/angular'
import { ICellRendererParams } from '@ag-grid-community/core'
@Component({
  selector: 'app-switch-renderer',
  templateUrl: `./switch-renderer.component.html`,
  styleUrls: ['./switch-renderer.component.sass'],
})
export class SwitchRendererComponent implements AgRendererComponent {
  public tooltip = ''
  public displayValue = ''
  public value!: boolean
  public switchDisabled = false
  public params!: any

  agInit(params: any): void {
    this.params = params
    this.value = params.value

    if (params?. tooltip) {
      this.tooltip = params.tooltip
    }

    if(params?.tooltipFunction instanceof Function) {
      this.tooltip = params.tooltipFunction(params.data)
    }

    if (params?. displayValue) {
      this.displayValue = params.displayValue
    }
    if (params?.disabledFunction instanceof Function) {
      this.switchDisabled = params.disabledFunction(params.data)
    }
    else if (params?. disabled) {
      this.switchDisabled = params.disabled
    }
  }


  refresh(params: ICellRendererParams) {
    return true
  }

  valueSwitched(updatedValue: boolean) {
    if (this.params.onClick instanceof Function) {
      const ref = this // for undoing or updating switch button
      this.params.onClick({ rowData: this.params.node.data, updatedValue, ref })
    } else {
      this.value = updatedValue
    }
  }
}
