import { EnhancedPropertyFormDescriptorOptions } from '@app/shared/models/enhanced-json-form-data'
import { Verb } from '@core/models/PageDataCommonClasses'

export type ApiRoutePath = {
  name: string
  route: string
  verb?: Verb
  params?: string
  format: 'suggester' | 'suggester-string' | 'suggester-button' | 'text' | 'extended'
  options: Partial<EnhancedPropertyFormDescriptorOptions>
  baseUrl?: string
}

export class FilterableSourceDecoratorDefinitions {
  public static readonly filterableMapDataSources: Array<ApiRoutePath> = [
    {
      name: 'targetfieldsbyentity',
      route: '/datasources/targetfieldsbyentity',
      params: 'return { query: query }',
      format: 'suggester',
      verb: 'get',
      options: {
        enumResponseKeyProp: 'id',
        enumResponseDisplayProp: 'data',
        enumResponseProp: ['data', 'items'],
      },
      baseUrl: 'workflow',
    },
    {
      name: 'teamspolicyvalues',
      route: '/datasources/teamspolicyvalues',
      params: 'return { filter: query }',
      format: 'suggester',
      verb: 'get',
      options: {
        enumResponseKeyProp: 'id',
        enumResponseDisplayProp: 'data',
        enumResponseProp: ['data', 'items'],
      },
      baseUrl: 'workflow',
    },
    {
      name: 'assignablephonenumbers',
      route: '/datasources/assignablephonenumbers',
      params: 'return { filter: query }',
      format: 'suggester',
      verb: 'get',
      options: {
        enumResponseKeyProp: 'id',
        enumResponseDisplayProp: 'data',
        enumResponseProp: ['data', 'items'],
      },
      baseUrl: 'workflow',
    },
    {
      name: 'approleassignments-by-serviceprincipal',
      route: '/datasources/approleassignments-by-serviceprincipal',
      params: 'return { filter: query }',
      format: 'suggester',
      verb: 'get',
      options: {
        enumResponseKeyProp: 'id',
        enumResponseDisplayProp: 'data',
        enumResponseProp: ['data', 'items'],
      },
      baseUrl: 'workflow',
    },
    {
      name: 'oauth2permissiongrants-by-serviceprincipal',
      route: '/datasources/oauth2permissiongrants-by-serviceprincipal',
      params: 'return { filter: query }',
      format: 'suggester',
      verb: 'get',
      options: {
        enumResponseKeyProp: 'id',
        enumResponseDisplayProp: 'data',
        enumResponseProp: ['data', 'items'],
      },
      baseUrl: 'workflow',
    },
  ]
}
