<div class="panel-container" *ngIf="!!policy">
  <div class="content-container">
    <div class="panel-title-container">
      <div class="panel-title uppercase" translate>playbook_EditPredefinedPolicy</div>
      <div class="panel-subtitle">{{ policy.title }}</div>
    </div>
    <div *ngIf="policy.policyType !== 'EventBased'" [ngClass]="'matched-items-bar'">
      <span class="matched-items-desc">{{ 'playbook_matchedItemDescription' | translate : { count: policy.matchedItemsCount } }}</span>

    </div>
    <div class="panel-content">
      <div class="policy-settings">
        <span class="title" translate>playbook_policySettings</span>
        <div class="switch-block">
          <cv-switch
            [disabled]="readMode"
            cv-data-test="enablePolicyToggle"
            (checkedChange)="changePolicyEnablement()"
            [value]="policy.isPolicyEnabled"
          >
            {{ 'playbook_enablePolicy' | translate }}</cv-switch
          >
          <span class="form-desc" style="padding-left: 48px" translate>playbook_makePolicyAvailableDesc</span>
        </div>
        <div>
          <cv-form-builder
            *ngIf="!!jsonFormDataPolicy"
            [form]="formPolicy"
            [jsonFormData]="jsonFormDataPolicy"
            (formReady)="onPolicySettingsReady()"
          >
          </cv-form-builder>
        </div>
        <div class="threshold-block" style="padding-bottom: 15px" *ngIf="policy.policyType !== 'EventBased'">
          <div class="interval-form">
            <cv-select
              style="width: 100%"
              [(ngModel)]="policy.thresholdSeverity"
              [label]="'playbook_thresholdSeverity' | translate"
              [suggestions]="[
              {
                value: 'Critical',
                displayValue: 'playbook_ThresholdSeverityCritical' | translate,
                icon: 'warning',
                iconColor: '#B01F24',
              },
              {
                value: 'Warning',
                displayValue: 'playbook_ThresholdSeverityWarning' | translate,
                icon: 'warning',
                iconColor: '#F5A623',
              },
              {
                value: 'Informational',
                displayValue:'playbook_ThresholdSeverityInformational' | translate,
                icon: 'info',
                iconColor: '#306AC8',
              },
            ]"
              [disabled]="true"
              [icon]="severity.value === 'Informational' ? 'info' : 'warning'"
              [iconColor]="severity.value === 'Informational' ? '#306AC8' : severity.value === 'Warning' ? '#F5A623' : '#B01F24'"
              [required]="true"
              #severity
            >
            </cv-select>
            <cv-checkbox
              [(ngModel)]="policy.isThresholdEnabled"
              [info]="'playbook_ApplyThresholdTooltipDesc' | translate"
              [disabled]="!policy.isPolicyEnabled || severity.value !== 'Informational'"
              style="width: 100%"
            >
              {{'playbook_ApplyViolationThreshold' | translate}}
            </cv-checkbox>
            <cv-select
              class="grow-1"
              [(ngModel)]="policy.thresholdOperator"
              [label]="'playbook_thresholdOperation' | translate"
              [suggestions]="thresholdOperations"
              [disabled]="!policy.isPolicyEnabled"
              [required]="true"
            />
            <cv-input
              style="flex: 1"
              [ngClass]="policy.thresholdType === 'Percentage' ? 'percentage' : ''"
              [(ngModel)]="policy.thresholdValue"
              (input)="validateThreshold($event)"
              inputType="number"
              [disabled]="!policy.isPolicyEnabled"
              [min]="'0'"
              [max]="policy.thresholdType === 'Percentage' ? '100' : ''"
              [pattern]="getThresholdPattern()"
              [errorMessage]="'common_IncorrectValue' | translate"
              [required]="true"
            />
          </div>
        </div>
      </div>
      <div class="workflow-settings">
        <span class="title" translate>playbook_workflowSettings</span>
        <cv-alert-bar [hideCloseButton]="true" style="margin: 10px 0" *ngIf="policy.remediationType === RemediationType.SendNotification">
          <span translate>playbook_EventBasedPolicyDisclaimer</span>
        </cv-alert-bar>
        <div class="switch-block">
          <cv-switch
            style="width: fit-content;"
            [tooltip]="(!policy.canEnableRemediation ? 'playbook_RemediationNotIncludedinSubscription' : '') | translate"
            (checkedChange)="remediationEnablementChanged()"
            [value]="policy.isWorkflowEnabled"
            [ngClass]="!policy.canEnableRemediation ? missingRemediationPermission : ''"
            [disabled]="!policy.isPolicyEnabled || !policy.canEnableRemediation"
            cv-data-test="enableWorkflowToggle"
          >
            {{ 'playbook_enablePolicyWorkflow' | translate }}</cv-switch
          >
          <span class="form-desc" style="padding-left: 48px" translate>playbook_workflowSettingsDesc</span>
        </div>
        <div class="workflow-details">
          <div class="dynamic-content grow-1">
            <ng-container *ngIf="policy.remediationType === RemediationType.Workflow">
              <span translate>playbook_workflowFixAction</span>
              <div>
                <cv-form-builder
                  [form]="formWorkflow"
                  *ngIf="!!jsonFormDataWorkflow"
                  [jsonFormData]="jsonFormDataWorkflow"
                  (formReady)="onWorkflowSettingsReady()"
                >
                </cv-form-builder>
              </div>
              <cv-checkbox
                (checkedChange)="sendEmailWhenWorkflowFailsChanged()"
                [value]="policy.sendEmailWhenWorkflowFails"
                [ngClass]="!policy.canEnableRemediation ? missingRemediationPermission : ''"
                [disabled]="!policy.canEdit || !policy.isPolicyEnabled || !policy.canEnableRemediation"
                cv-data-test="sendEmailCheckbox"
              >
                <span translate>playbook_sendEmailWorkflowFail</span>
              </cv-checkbox>
              <cv-button
                class="margin-top: 10px"
                *ngIf="policy.isWorkflowEnabled && policy.workflowId"
                buttonType="tertiary"
                [text]="'Preview' | translate"
                (click)="openWorkflow()"
              ></cv-button>
              <form [formGroup]="formEmail" style="display: flex; padding-bottom: 15px" *ngIf="policy.sendEmailWhenWorkflowFails">
                <cv-input
                  style="flex: 1"
                  [label]="'playbook_sendWarningTo' | translate"
                  formControlName="emailAddressWhenWorkflowFails"
                  type="email"
                  [disabled]="!policy.isPolicyEnabled"
                  [errorMessage]="
                    (formEmail.get('emailAddressWhenWorkflowFails')?.errors?.required
                      ? ''
                      : formEmail.get('emailAddressWhenWorkflowFails')?.errors?.pattern
                      ? 'common_patternNotMatching'
                      : ''
                    ) | translate
                  "
                  [required]="true"
                  cv-data-test="sendEmailInput"
                >
                </cv-input>
              </form>
            </ng-container>
            <ng-container *ngIf="policy.remediationType === RemediationType.ScheduleReport">
              <span translate>playbook_ScheduleReport</span>
              <form [formGroup]="formSendReport">
                <div class="setting-selector">
                  <cv-select
                    class="grow-1"
                    formControlName="sendReport"
                    [label]="'reports_SendWhen' | translate"
                    [suggestions]="sendReportSuggestions"
                  >
                  </cv-select>
                </div>
                <div class="setting-selector form-element-spacer">
                  <cv-select
                    class="grow-1"
                    formControlName="format"
                    [label]="'playbook_format' | translate"
                    [suggestions]="sendReportFormatSuggestions"
                  >
                  </cv-select>
                </div>
                <cv-textarea
                  class="form-element-spacer"
                  style="width: 100%"
                  [label]="'playbook_EmailBody' | translate"
                  formControlName="emailBody"
                ></cv-textarea>

                <cv-multi-input
                  (inputChange)="usersInputChanged($event)"
                  [suggestions]="userSuggestions"
                  class="form-element-spacer"
                  style="width: 100%"
                  [label]="'playbook_Recipients' | translate"
                  formControlName="recipients"
                  [forceSelection]="false"
                  type="mail"
                  [rightIcon]="'expand_more'"
                  [required]="true"
                ></cv-multi-input>
              </form>
            </ng-container>
            <ng-container *ngIf="policy.remediationType === RemediationType.SendNotification">
              <span translate>playbook_SendNotification</span>
              <form [formGroup]="formSendNotification">
                <cv-multi-input
                  (inputChange)="usersInputChanged($event)"
                  [suggestions]="userSuggestions"
                  class="form-element-spacer"
                  style="width: 100%"
                  type="mail"
                  [label]="'playbook_Recipients' | translate"
                  formControlName="notificationRecipients"
                  [forceSelection]="false"
                  [rightIcon]="'expand_more'"
                  [required]="true"
                ></cv-multi-input>
              </form>
            </ng-container>
          </div>
          <div class="schedule-box grow-1">
            <ng-container *ngIf="policy.remediationType !== RemediationType.SendNotification">
              <span translate>playbook_workflowSchedule</span>
              <div class="setting-selector" *ngIf="policy.remediationType === RemediationType.Workflow">
                <cv-select
                  class="grow-1"
                  (selectionChanged)="scheduleChanged($event)"
                  [(ngModel)]="selectedSchedule"
                  [label]="'playbook_workflowRecurrence' | translate"
                  [suggestions]="scheduleOptions"
                  [disabled]="!policy.isWorkflowEnabled || !policy.isPolicyEnabled"
                  cv-data-test="scheduleSelect"
                >
                </cv-select>
              </div>
              <div
                class="schedule-form"
                style="flex-direction: column; margin-top: -10px"
                *ngIf="isNotReset"
                [style.display]="policy.scheduleType === 'Scheduled' || policy.remediationType === 'ScheduleReport' ? null : 'none'"
              >
                <form [formGroup]="formSchedule">
                  <app-recurrence
                    [hideParticularScheduleWeeks]="true"
                    [checkStartDate]="false"
                    [onlyRecurring]="true"
                    [initialDatesValid]="hadSchedule"
                    [customIncompatibleErrorMessage]="'playbook_EndDateError'"
                    formControlName="recurrenceData"
                  ></app-recurrence>
                </form>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-container">
    <div class="footer-buttons">
      <cv-button
        cv-data-test="closePanel"
        leftIcon="close"
        buttonType="secondary"
        text="{{ 'common_Cancel' | translate }}"
        (click)="closePanel()"
      ></cv-button>
      <cv-button
        *ngIf="!readMode"
        leftIcon="refresh"
        color="alert"
        buttonType="secondary"
        text="{{ 'common_Reset' | translate }}"
        cv-data-test="resetPolicy"
        (click)="resetPolicy()"
      >
      </cv-button>
    </div>
    <div>
      <cv-button
        *ngIf="!readMode"
        leftIcon="check"
        customBackground="#2E8540"
        text="{{ 'common_Save' | translate }}"
        [disabled]="checkIfPossibleSave()"
        (click)="save()"
        cv-data-test="savePolicy"
        [attr.cv-policy-template-id]="policy.policyTemplateId"
      ></cv-button>
    </div>
  </div>
</div>
<app-datagrid
  *ngIf="gridDefinition"
  style="max-height: 0px; visibility: hidden"
  [getItems]="getItems()"
  [gridDefinition]="gridDefinition"
  (metadataChanged)="lastRequest = $event.queryRequest"
  [rangeFilters]="rangeFilter"
></app-datagrid>
