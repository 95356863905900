import { NavigationExtras, Router } from '@angular/router'
import { CardDefinition, CardWidget } from '@app/modules/cards/Card'
import { TranslateHelper } from '@coreview/coreview-library'
import { Constants } from '@app/shared/utilities/constants'

const baseDeleteAgColDef = {
  field: 'delete',
  maxWidth: 100,
  hide: false,
  cellStyle: () => ({ padding: '0px', 'text-align': 'center', border: 'none !important' }),
  sortable: false,
  filter: false,
  resizable: false,
  suppressMovable: true,
  suppressMenu: true,
  suppressColumnsToolPanel: true,
}

const callQualityMap: Record<string, any> = {
  Poor: { icon: Constants.commonClassIcons.inactive, colorIcon: Constants.activeInactiveColors.inactive },
  Good: { icon: Constants.commonClassIcons.active, colorIcon: Constants.activeInactiveColors.active },
  Unclassified: { icon: Constants.commonClassIcons.unknown, colorIcon: Constants.activeInactiveColors.unknown },
}

const openMemberUsercard = {
  type: 'string',
  originalName: 'common_OpenCardInNewTab',
  predefinedOperation: 'card',
  param: {
    redirectUrl: 'usercard/{userPrincipalName}'
  },
  name: 'common_OpenCardInNewTab',
}

export interface DistributionGroupCardParams {
  translateHelper: TranslateHelper
  router: Router
  id: string
  isManagement: boolean
  clickOnActionCard: Function
  getEmailAddresses: Function
  getManagedBy: Function
  populateFunc: (card: CardWidget, item: any) => void
  clickOnActionInline: ($event: any, actionId: number, name?: any) => boolean
  canSeeDeleteAction: boolean
}

export const getDistributionGroupCardDefinition = (params: DistributionGroupCardParams): CardDefinition | undefined => {
  const {
    translateHelper,
    router,
    id,
    isManagement,
    clickOnActionCard,
    getEmailAddresses,
    getManagedBy,
    populateFunc,
    clickOnActionInline,
    canSeeDeleteAction,
  } = params

  const openMemberCard = {
    ...openMemberUsercard,
    param: {
      hideIcon: (rowData: any) => {
        return !['User', 'UserMailbox', 'MailUser', 'MailUniversalDistributionGroup'].includes(rowData.recipientType)
      },
      onClick: (params: { event: any; rowData: any }) => {
        let url = ''
        switch (params.rowData.recipientType) {
          case 'User':
          case 'UserMailbox':
          case 'MailUser':
            url = `usercard/${encodeURIComponent(params.rowData.userPrincipalName)}`
            break;
          case 'MailUniversalDistributionGroup':
            url = `card/distributionGroup/${encodeURIComponent(params.rowData.userPrincipalName)}`
            break;
        }
        if (url) {
          window.open(`${window.location.origin}/${url}`, '_blank')
        }
      },
    }
  }

  return {
    id: 'distributionGroup',
    pageSettings: {
      path: [
        translateHelper.instant('Reports'),
        translateHelper.instant('Groups'),
        {
          text: translateHelper.instant('DistributionGroups'),
          route: 'reports/groups/distributionGroups',
        },
      ],
      icon: 'group',
      hasManagement: true,
    },
    cards: [
      {
        id: 1,
        title: 'GroupDetails',
        options: {
          size: 1,
          type: 'info-generic',
          maxNumberInfos: 12,
          api: {
            url: '/distributiongroup',
            verb: 'get',
            responseItemProp: 'distributionGroup',
            urlRouteParameters: {
              distributionGroupId: 'id',
            },
          },
          pageSettings: {
            title: 'displayName',
            description: 'description',
            tooltip: { title: 'DistributionGroupType', prop: 'groupType' },
          },
          secondaryButtons: [
            {
              label: 'management_RemoveDistributionGroup',
              icon: 'ms-Icon ms-Icon--Delete',
              func: clickOnActionCard,
              actionId: 603,
              action: '',
              disabled: !isManagement,
              hidden: true,
            },
            {
              label: 'management_RemoveOnPremisesDistributionGroup',
              icon: 'ms-Icon ms-Icon--Delete',
              func: clickOnActionCard,
              actionId: 1535,
              action: '',
              disabled: !isManagement,
              hidden: true,
            },
            {
              label: 'management_UpdateDistributionGroup',
              icon: 'ms-Icon ms-Icon--PageEdit',
              func: clickOnActionCard,
              actionId: 602,
              action: '',
              disabled: !isManagement,
              hidden: true,
            },
            {
              label: 'management_EditOnPremisesDistributionGroup',
              icon: 'ms-Icon ms-Icon--PageEdit',
              func: clickOnActionCard,
              actionId: 1533,
              action: '',
              disabled: !isManagement,
              hidden: true,
            },
          ],
        },
        defaultFields: [
          'DisplayName',
          'MaxSendSize',
          'HiddenFromAddressListsEnabled',
          'PrimarySmtpAddress',
          'MaxReceiveSize',
          'RequireSenderAuthenticationEnabled',
          getEmailAddresses,
          'MemberJoinRestriction',
          'GroupType',
          getManagedBy,
          'MemberDepartRestriction',
          'IsDirSynced',
        ],
        populateFunc,
        infos: [],
      },
      {
        id: 2,
        title: 'MembersAndOwners',
        options: {
          size: 1,
          type: 'table-tab',
          tabs: [
            {
              id: 1,
              title: 'Members',
              icon: 'person',
              reportDefinition: {
                hasOpenInFull: true,
                rowSelection: 'none',
                fields: ['UserPrincipalName', 'DisplayName', 'RecipientType'],
                operationsColumns: canSeeDeleteAction
                  ? [
                      openMemberCard,
                      {
                        type: 'iconDialog',
                        name: 'Delete',
                        translate: 'common_Delete',
                        position: -1,
                        agColDef: {
                          ...baseDeleteAgColDef,
                          cellRendererParams: {
                            disabled: !isManagement,
                            icon: 'delete',
                            actionId: Constants.managementActionsIds.removeDistributionGroupMember,
                            action: 'removeDistributionGroupMember',
                            title: 'common_RemoveUserFromGroup',
                            text: 'common_DeleteThisMember',
                            primaryButton: { key: 'delete', text: 'Delete' },
                            secondaryButton: { key: 'cancel', text: 'Cancel' },
                            type: 'alert',
                            onClick: (event: any) => {
                              if (event.key === 'delete') {
                                event.clickEvent.stopPropagation()

                                if (
                                  clickOnActionInline(
                                    event,
                                    Constants.managementActionsIds.removeDistributionGroupMember,
                                    'removeDistributionGroupMember'
                                  )
                                ) {
                                  event.component.title = 'usercard_actionInProgressTitle'
                                  event.component.text = 'usercard_actionInProgressDescription'
                                  event.component.primaryButton = { key: 'close', text: 'usercard_actionInProgressButtonTitle' }
                                  event.component.secondaryButton = null
                                  event.component.type = 'success'
                                  event.component.centered = true
                                }
                              }
                            },
                          },
                        },
                      },
                    ]
                  : [openMemberCard],
                responseItemProp: 'distributionGroupMembers',
                sortField: 'UserPrincipalName',
                sortOrder: 'asc',
                url: '/distributiongroups/member',
                verb: 'get',
                filters: {
                  isMember: {
                    filterType: 'text',
                    filter: '',
                    type: 'equalsTrue',
                  },
                },
                urlRouteParameters: {
                  distributionGroupId: 'id',
                },
                selectionActions: [
                  {
                    text: 'card_GoToOriginReport',
                    buttonType: 'tertiary',
                    icon: 'arrow_forward',
                    visibility: 'noRow',
                    onClick: (data: any[]) => {
                      const navigationExtras: NavigationExtras = { queryParams: { DistributionGroupGuid: id, IsMember: true } }
                      router.navigate(['/reports/groups/distributionGroupMembers'], navigationExtras).catch((_: any) => _)
                    },
                    tooltip: 'card_GoToOriginReport_tooltip',
                  },
                ],
              },
            },
            {
              id: 2,
              title: 'Owners',
              icon: 'admin_panel_settings',
              reportDefinition: {
                hasOpenInFull: true,
                rowSelection: 'none',
                fields: ['UserPrincipalName', 'DisplayName', 'RecipientType'],
                operationsColumns: [openMemberCard],
                responseItemProp: 'distributionGroupMembers',
                sortField: 'UserPrincipalName',
                sortOrder: 'asc',
                url: '/distributiongroups/member',
                verb: 'get',
                filters: {
                  isOwner: {
                    filterType: 'text',
                    filter: '',
                    type: 'equalsTrue',
                  },
                },
                urlRouteParameters: {
                  distributionGroupId: 'id',
                },
                selectionActions: [
                  {
                    text: 'card_GoToOriginReport',
                    buttonType: 'tertiary',
                    icon: 'arrow_forward',
                    visibility: 'noRow',
                    onClick: (data: any[]) => {
                      const navigationExtras: NavigationExtras = { queryParams: { DistributionGroupGuid: id, IsOwner: true } }
                      router.navigate(['/reports/groups/distributionGroupMembers'], navigationExtras).catch((_: any) => _)
                    },
                    tooltip: 'card_GoToOriginReport_tooltip',
                  },
                ],
              },
            },
          ],
        },
        infos: [],
      },
    ],
  }
}

export interface SecurityGroupCardParams {
  translateHelper: TranslateHelper
  router: Router
  id: string
  isManagement: boolean
  clickOnActionCard: Function
  getManagedBy: Function
  populateFunc: (card: CardWidget, item: any) => void
  clickOnActionInline: ($event: any, actionId: number, name?: any) => boolean
  canSeeDeleteAction: boolean
}

export const getSecurityGroupCardDefinition = (params: SecurityGroupCardParams): CardDefinition | undefined => {
  const openMemberCard = {
    ...openMemberUsercard,
    param: {
      hideIcon: (rowData: any) => {
        return !['User', 'Group'].includes(rowData.groupMemberType)
      },
      onClick: (params: { event: any; rowData: any }) => {
        let url = ''
        switch (params.rowData.groupMemberType) {
          case 'User':
            url = `usercard/${encodeURIComponent(params.rowData.userPrincipalName)}`
            break;
          case 'Group':
            url = `card/securityGroup/${encodeURIComponent(params.rowData.userPrincipalName)}`
            break;
        }
        if (url) {
          window.open(`${window.location.origin}/${url}`, '_blank')
        }
      },
    }
  }

  return {
    id: 'securityGroup',
    pageSettings: {
      path: [
        params.translateHelper.instant('Reports'),
        params.translateHelper.instant('Groups'),
        {
          text: params.translateHelper.instant('SecurityGroups'),
          route: 'reports/groups/securityGroups',
        },
      ],
      icon: 'group',
      hasManagement: true,
    },
    cards: [
      {
        id: 1,
        title: 'GroupDetails',
        options: {
          size: 1,
          type: 'info-generic',
          maxNumberInfos: 9,
          api: {
            url: '/securityGroup',
            verb: 'get',
            responseItemProp: 'securityGroup',
            urlRouteParameters: {
              groupId: 'id',
            },
          },
          pageSettings: {
            title: 'displayName',
            description: 'description',
          },
          secondaryButtons: [
            {
              label: 'management_RemoveSecurityGroup',
              icon: 'ms-Icon ms-Icon--Delete',
              func: params.clickOnActionCard,
              actionId: 920,
              action: '',
              disabled: !params.isManagement,
              hidden: true,
            },
            {
              label: 'management_RemoveOnPremisesSecurityGroup',
              icon: 'ms-Icon ms-Icon--Delete',
              func: params.clickOnActionCard,
              actionId: 1541,
              action: '',
              disabled: !params.isManagement,
              hidden: true,
            },
            {
              label: 'management_UpdateSecurityGroup',
              icon: 'ms-Icon ms-Icon--PageEdit',
              func: params.clickOnActionCard,
              actionId: 915,
              action: '',
              disabled: !params.isManagement,
              hidden: true,
            },
            {
              label: 'management_EditOnPremisesSecurityGroup',
              icon: 'ms-Icon ms-Icon--PageEdit',
              func: params.clickOnActionCard,
              actionId: 1539,
              action: '',
              disabled: !params.isManagement,
              hidden: true,
            },
          ],
        },
        defaultFields: [
          'Name',
          'OrganizationalUnit',
          'IsDirSynced',
          'DisplayName',
          'MailNickname',
          'LastDirSyncTime',
          params.getManagedBy,
          'GroupScope',
          'DynamicMembership',
        ],
        populateFunc: params.populateFunc,
        infos: [],
      },
      {
        id: 2,
        title: 'MembersAndOwners',
        options: {
          size: 1,
          type: 'table-tab',
          tabs: [
            {
              id: 1,
              title: 'Members',
              icon: 'person',
              reportDefinition: {
                hasOpenInFull: true,
                rowSelection: 'none',
                fields: ['UserPrincipalName', 'DisplayName', 'RecipientType', 'GroupMemberType', 'GroupDynamicMembership'],
                lockedColumns: ['GroupDynamicMembership'],
                operationsColumns: params.canSeeDeleteAction
                  ? [
                      openMemberCard,
                      {
                        type: 'iconDialog',
                        name: 'Delete',
                        translate: 'common_Delete',
                        position: -1,
                        agColDef: {
                          ...baseDeleteAgColDef,
                          cellRendererParams: {
                            hideIcon: (rowData: any) => !!rowData.groupDynamicMembership || rowData.groupMemberType !== 'User',
                            disabled: !params.isManagement,
                            icon: 'delete',
                            actionId: Constants.managementActionsIds.removeSecurityGroupMember,
                            action: 'removeSecurityGroupMember',
                            title: 'common_RemoveUserFromGroup',
                            text: 'common_DeleteThisMember',
                            primaryButton: { key: 'delete', text: 'Delete' },
                            secondaryButton: { key: 'cancel', text: 'Cancel' },
                            type: 'alert',
                            onClick: (event: any) => {
                              if (event.key === 'delete') {
                                event.clickEvent.stopPropagation()

                                if (
                                  params.clickOnActionInline(
                                    event,
                                    Constants.managementActionsIds.removeSecurityGroupMember,
                                    'removeSecurityGroupMember'
                                  )
                                ) {
                                  event.component.title = 'usercard_actionInProgressTitle'
                                  event.component.text = 'usercard_actionInProgressDescription'
                                  event.component.primaryButton = { key: 'close', text: 'usercard_actionInProgressButtonTitle' }
                                  event.component.secondaryButton = null
                                  event.component.type = 'success'
                                  event.component.centered = true
                                }
                              }
                            },
                          },
                        },
                      },
                    ]
                  : [openMemberCard],
                responseItemProp: 'securityGroupMembers',
                sortField: 'UserPrincipalName',
                sortOrder: 'asc',
                url: '/securitygroups/members',
                verb: 'get',
                filters: {
                  isMember: {
                    filterType: 'text',
                    filter: '',
                    type: 'equalsTrue',
                  },
                },
                urlRouteParameters: {
                  group: 'id',
                },
                selectionActions: [
                  {
                    text: 'card_GoToOriginReport',
                    buttonType: 'tertiary',
                    icon: 'arrow_forward',
                    visibility: 'noRow',
                    onClick: (data: any[]) => {
                      const navigationExtras: NavigationExtras = { queryParams: { SecurityGroupGuid: params.id, IsMember: true } }
                      params.router.navigate(['/reports/groups/securityGroupMembers'], navigationExtras).catch((_: any) => _)
                    },
                    tooltip: 'card_GoToOriginReport_tooltip',
                  },
                ],
              },
            },
            {
              id: 2,
              title: 'Owners',
              icon: 'admin_panel_settings',
              reportDefinition: {
                hasOpenInFull: true,
                rowSelection: 'none',
                fields: ['UserPrincipalName', 'DisplayName', 'RecipientType', 'GroupDynamicMembership'],
                lockedColumns: ['GroupDynamicMembership'],
                operationsColumns: params.canSeeDeleteAction
                  ? [
                      openMemberUsercard,
                      {
                        type: 'iconDialog',
                        name: 'Delete',
                        translate: 'common_Delete',
                        position: -1,
                        agColDef: {
                          ...baseDeleteAgColDef,
                          cellRendererParams: {
                            hideIcon: (rowData: any) => !!rowData.groupDynamicMembership,
                            disabled: !params.isManagement,
                            icon: 'delete',
                            actionId: Constants.managementActionsIds.removeSecurityGroupMember,
                            action: 'removeSecurityGroupMember',
                            title: 'common_RemoveUserFromGroup',
                            text: 'common_DeleteThisMember',
                            primaryButton: { key: 'delete', text: 'Delete' },
                            secondaryButton: { key: 'cancel', text: 'Cancel' },
                            type: 'alert',
                            onClick: (event: any) => {
                              if (event.key === 'delete') {
                                event.clickEvent.stopPropagation()

                                if (
                                  params.clickOnActionInline(
                                    event,
                                    Constants.managementActionsIds.removeSecurityGroupMember,
                                    'removeSecurityGroupMember'
                                  )
                                ) {
                                  event.component.title = 'usercard_actionInProgressTitle'
                                  event.component.text = 'usercard_actionInProgressDescription'
                                  event.component.primaryButton = { key: 'close', text: 'usercard_actionInProgressButtonTitle' }
                                  event.component.secondaryButton = null
                                  event.component.type = 'success'
                                  event.component.centered = true
                                }
                              }
                            },
                          },
                        },
                      },
                    ]
                  : [openMemberUsercard],
                responseItemProp: 'securityGroupMembers',
                sortField: 'UserPrincipalName',
                sortOrder: 'asc',
                url: '/securitygroups/members',
                verb: 'get',
                filters: {
                  isOwner: {
                    filterType: 'text',
                    filter: '',
                    type: 'equalsTrue',
                  },
                },
                urlRouteParameters: {
                  group: 'id',
                },
                selectionActions: [
                  {
                    text: 'card_GoToOriginReport',
                    buttonType: 'tertiary',
                    icon: 'arrow_forward',
                    visibility: 'noRow',
                    onClick: (data: any[]) => {
                      const navigationExtras: NavigationExtras = { queryParams: { SecurityGroupGuid: params.id, IsOwner: true } }
                      params.router.navigate(['/reports/groups/securityGroupMembers'], navigationExtras).catch((_: any) => _)
                    },
                    tooltip: 'card_GoToOriginReport_tooltip',
                  },
                ],
              },
            },
          ],
        },
        infos: [],
      },
    ],
  }
}

export interface M365GroupCardParams {
  translateHelper: TranslateHelper
  router: Router
  id: string
  isManagement: boolean
  clickOnActionCard: Function
  populateFunc: (card: CardWidget, item: any) => void
  clickOnActionInline: ($event: any, actionId: number, name?: any) => boolean
  canSeeDeleteAction: boolean
}

export const getM365GroupCardDefinition = (params: M365GroupCardParams): CardDefinition | undefined => {
  return {
    id: 'm365Group',
    dataUrl: '/office365Group',
    getDataUrlParams: (data: any) => ({ office365GroupId: data.id }),
    mapResult: (data: any) => data.office365Group,
    pageSettings: {
      path: [
        params.translateHelper.instant('Reports'),
        params.translateHelper.instant('Groups'),
        {
          text: params.translateHelper.instant('Office365Groups'),
          route: 'reports/groups/office365Groups',
        },
      ],
      lastPathProperty: 'displayName',
      icon: 'group',
      hasManagement: true,
      title: 'displayName',
      description: 'notes',
    },
    cards: [
      {
        id: 1,
        title: 'GroupDetails',
        options: {
          size: 1,
          type: 'info-generic',
          maxNumberInfos: 21,
          useGlobalData: true,
          secondaryButtons: [
            {
              label: 'management_RemoveO365Group',
              icon: 'ms-Icon ms-Icon--Delete',
              func: params.clickOnActionCard,
              actionId: 720,
              action: '',
              disabled: !params.isManagement,
              hidden: true,
            },
            {
              label: 'management_UpdateO365Group',
              icon: 'ms-Icon ms-Icon--PageEdit',
              func: params.clickOnActionCard,
              actionId: 715,
              action: '',
              disabled: !params.isManagement,
              hidden: true,
            },
          ],
        },
        defaultFields: [
          'DisplayName',
          'AccessType',
          'AutoSubscribeNewMembers',
          'PrimarySmtpAddress',
          'WhenCreatedUTC',
          'IsTeamGroup',
          'EmailAddresses',
          'whenChangedUTC',
          'SharePointDocumentsUrl',
          'Domain',
          'IsMailboxConfigured',
          'SharePointSiteUrl',
          'Alias',
          'RequireSenderAuthenticationEnabled',
          'SharePointNotebookUrl',
          'IsDirSynced',
          'HiddenFromAddressListsEnabled',
          'CalendarUrl',
          'DynamicMembership',
          '',
          '',
        ],
        populateFunc: params.populateFunc,
        infos: [],
      },
      {
        id: 2,
        title: 'MembersAndOwners',
        options: {
          size: 1,
          type: 'table-tab',
          tabs: [
            {
              id: 1,
              title: 'Members',
              icon: 'person',
              reportDefinition: {
                hasOpenInFull: true,
                rowSelection: 'none',
                fields: ['UserPrincipalName', 'DisplayName', 'RecipientType', 'GroupDynamicMembership'],
                lockedColumns: ['GroupDynamicMembership'],
                operationsColumns: params.canSeeDeleteAction
                  ? [
                      openMemberUsercard,
                      {
                        type: 'iconDialog',
                        name: 'Delete',
                        translate: 'common_Delete',
                        position: -1,
                        agColDef: {
                          ...baseDeleteAgColDef,
                          cellRendererParams: {
                            hideIcon: (rowData: any) => !!rowData.groupDynamicMembership,
                            disabled: !params.isManagement,
                            icon: 'delete',
                            actionId: Constants.managementActionsIds.removeO365GroupMember,
                            action: 'removeO365GroupMember',
                            title: 'common_RemoveUserFromGroup',
                            text: 'common_DeleteThisMember',
                            primaryButton: { key: 'delete', text: 'Delete' },
                            secondaryButton: { key: 'cancel', text: 'Cancel' },
                            type: 'alert',
                            onClick: (event: any) => {
                              if (event.key === 'delete') {
                                event.clickEvent.stopPropagation()

                                if (
                                  params.clickOnActionInline(
                                    event,
                                    Constants.managementActionsIds.removeO365GroupMember,
                                    'removeO365GroupMember'
                                  )
                                ) {
                                  event.component.title = 'usercard_actionInProgressTitle'
                                  event.component.text = 'usercard_actionInProgressDescription'
                                  event.component.primaryButton = { key: 'close', text: 'usercard_actionInProgressButtonTitle' }
                                  event.component.secondaryButton = null
                                  event.component.type = 'success'
                                  event.component.centered = true
                                }
                              }
                            },
                          },
                        },
                      },
                    ]
                  : [openMemberUsercard],
                responseItemProp: 'office365GroupMembers',
                sortField: 'DisplayName',
                sortOrder: 'asc',
                url: '/office365GroupMembers',
                verb: 'get',
                filters: {
                  isMember: {
                    filterType: 'text',
                    filter: '',
                    type: 'equalsTrue',
                  },
                },
                urlRouteParameters: {
                  office365GroupId: 'id',
                },
                selectionActions: [
                  {
                    text: 'card_GoToOriginReport',
                    buttonType: 'tertiary',
                    icon: 'arrow_forward',
                    visibility: 'noRow',
                    onClick: (data: any[]) => {
                      const navigationExtras: NavigationExtras = { queryParams: { Office365GroupGuid: params.id, IsMember: true } }
                      params.router.navigate(['/reports/groups/office365GroupMembers'], navigationExtras).catch((_: any) => _)
                    },
                    tooltip: 'card_GoToOriginReport_tooltip',
                  },
                ],
              },
            },
            {
              id: 2,
              title: 'Owners',
              icon: 'admin_panel_settings',
              reportDefinition: {
                hasOpenInFull: true,
                rowSelection: 'none',
                fields: ['UserPrincipalName', 'DisplayName', 'RecipientType', 'GroupDynamicMembership'],
                lockedColumns: ['GroupDynamicMembership'],
                operationsColumns: params.canSeeDeleteAction
                  ? [
                      openMemberUsercard,
                      {
                        type: 'iconDialog',
                        name: 'Delete',
                        translate: 'common_Delete',
                        position: -1,
                        agColDef: {
                          ...baseDeleteAgColDef,
                          cellRendererParams: {
                            hideIcon: (rowData: any) => !!rowData.groupDynamicMembership,
                            disabled: !params.isManagement,
                            icon: 'delete',
                            actionId: Constants.managementActionsIds.removeO365GroupMember,
                            action: 'removeO365GroupMember',
                            title: 'common_RemoveUserFromGroup',
                            text: 'common_DeleteThisMember',
                            primaryButton: { key: 'delete', text: 'Delete' },
                            secondaryButton: { key: 'cancel', text: 'Cancel' },
                            type: 'alert',
                            onClick: (event: any) => {
                              if (event.key === 'delete') {
                                event.clickEvent.stopPropagation()

                                if (
                                  params.clickOnActionInline(
                                    event,
                                    Constants.managementActionsIds.removeO365GroupMember,
                                    'removeO365GroupMember'
                                  )
                                ) {
                                  event.component.title = 'usercard_actionInProgressTitle'
                                  event.component.text = 'usercard_actionInProgressDescription'
                                  event.component.primaryButton = { key: 'close', text: 'usercard_actionInProgressButtonTitle' }
                                  event.component.secondaryButton = null
                                  event.component.type = 'success'
                                  event.component.centered = true
                                }
                              }
                            },
                          },
                        },
                      },
                    ]
                  : [openMemberUsercard],
                responseItemProp: 'office365GroupMembers',
                sortField: 'DisplayName',
                sortOrder: 'asc',
                url: '/office365GroupMembers',
                verb: 'get',
                filters: {
                  isOwner: {
                    filterType: 'text',
                    filter: '',
                    type: 'equalsTrue',
                  },
                },
                urlRouteParameters: {
                  office365GroupId: 'id',
                },
                selectionActions: [
                  {
                    text: 'card_GoToOriginReport',
                    buttonType: 'tertiary',
                    icon: 'arrow_forward',
                    visibility: 'noRow',
                    onClick: (data: any[]) => {
                      const navigationExtras: NavigationExtras = { queryParams: { Office365GroupGuid: params.id, IsOwner: true } }
                      params.router.navigate(['/reports/groups/office365GroupMembers'], navigationExtras).catch((_: any) => _)
                    },
                    tooltip: 'card_GoToOriginReport_tooltip',
                  },
                ],
              },
            },
          ],
        },
        infos: [],
      },
    ],
  }
}

export interface TeamsGroupCardParams {
  translateHelper: TranslateHelper
  router: Router
  id: string
  isManagement: boolean
  clickOnActionCard: Function
  populateFunc: (card: CardWidget, item: any) => void
  clickOnActionInline: ($event: any, actionId: number, name?: any) => boolean
  canSeeDeleteAction: boolean
  canSeeUpdateChannelAction: boolean
}

export const getTeamsGroupCardDefinition = (params: TeamsGroupCardParams): CardDefinition | undefined => {
  return {
    id: 'teamsGroup',
    pageSettings: {
      path: [
        params.translateHelper.instant('Reports'),
        params.translateHelper.instant('Groups'),
        {
          text: params.translateHelper.instant('TeamsGroups'),
          route: 'reports/teams/teamsGroups',
        },
      ],
      icon: 'group',
      hasManagement: true,
    },
    cards: [
      {
        id: 1,
        title: 'GroupDetails',
        options: {
          size: 1,
          type: 'info-generic',
          maxNumberInfos: 12,
          api: {
            url: '/teams/group/{id}',
            verb: 'get',
            responseItemProp: 'item',
            urlRouteParameters: {
              '{id}': 'id',
            },
          },
          pageSettings: {
            title: 'displayName',
            description: 'description',
          },
          secondaryButtons: [
            {
              label: 'management_RemoveTeamGroup',
              icon: 'ms-Icon ms-Icon--Delete',
              func: params.clickOnActionCard,
              actionId: 1605,
              action: '',
              disabled: !params.isManagement,
              hidden: true,
            },
            {
              label: 'management_UpdateTeamGroup',
              icon: 'ms-Icon ms-Icon--PageEdit',
              func: params.clickOnActionCard,
              actionId: 1603,
              action: '',
              disabled: !params.isManagement,
              hidden: true,
            },
          ],
        },
        defaultFields: [
          'DisplayName',
          'WhenCreatedUTC',
          'IsMailboxConfigured',
          'PrimarySmtpAddress',
          'WhenChangedUTC',
          'IsDirSynced',
          'AccessType',
          'WhenRenewedUTC',
          '',
          'Language',
          'CvSyncDate',
          '',
        ],
        populateFunc: params.populateFunc,
        infos: [],
      },
      {
        id: 2,
        title: 'MembersAndOwners',
        options: {
          size: 1,
          type: 'table-tab',
          tabs: [
            {
              id: 1,
              title: 'Members',
              icon: 'person',
              reportDefinition: {
                hasOpenInFull: true,
                rowSelection: 'none',
                fields: ['UserPrincipalName', 'DisplayName', 'RecipientType', 'IsExternal', 'GroupDynamicMembership'],
                lockedColumns: ['GroupDynamicMembership'],
                defaultHiddenFields: ['Office365GroupGuid'],
                operationsColumns: params.canSeeDeleteAction
                  ? [
                      openMemberUsercard,
                      {
                        type: 'iconDialog',
                        name: 'Delete',
                        translate: 'common_Delete',
                        position: -1,
                        notSelectable: true,
                        agColDef: {
                          ...baseDeleteAgColDef,
                          cellRendererParams: {
                            hideIcon: (rowData: any) => !!rowData.groupDynamicMembership,
                            disabled: !params.isManagement,
                            icon: 'delete',
                            actionId: Constants.managementActionsIds.removeTeamsGroupMember,
                            action: 'removeTeamsGroupMember',
                            title: 'common_RemoveUserFromGroup',
                            text: 'common_DeleteThisMember',
                            primaryButton: { key: 'delete', text: 'Delete' },
                            secondaryButton: { key: 'cancel', text: 'Cancel' },
                            type: 'alert',
                            onClick: (event: any) => {
                              if (event.key === 'delete') {
                                event.clickEvent.stopPropagation()

                                if (
                                  params.clickOnActionInline(
                                    event,
                                    Constants.managementActionsIds.removeTeamsGroupMember,
                                    'removeTeamsGroupMember'
                                  )
                                ) {
                                  event.component.title = 'usercard_actionInProgressTitle'
                                  event.component.text = 'usercard_actionInProgressDescription'
                                  event.component.primaryButton = { key: 'close', text: 'usercard_actionInProgressButtonTitle' }
                                  event.component.secondaryButton = null
                                  event.component.type = 'success'
                                  event.component.centered = true
                                }
                              }
                            },
                          },
                        },
                      },
                    ]
                  : [openMemberUsercard],
                responseItemProp: 'items',
                sortField: 'DisplayName',
                sortOrder: 'asc',
                url: '/teams/group/{id}/members',
                verb: 'get',
                filters: {
                  isMember: {
                    filterType: 'text',
                    filter: '',
                    type: 'equalsTrue',
                  },
                },
                urlRouteParameters: {
                  '{id}': 'id',
                },
                selectionActions: [
                  {
                    text: 'card_GoToOriginReport',
                    buttonType: 'tertiary',
                    icon: 'arrow_forward',
                    visibility: 'noRow',
                    onClick: (data: any[]) => {
                      const navigationExtras: NavigationExtras = { queryParams: { Office365GroupGuid: params.id, IsMember: true } }
                      params.router.navigate(['/reports/teams/teamsMembers'], navigationExtras).catch((_: any) => _)
                    },
                    tooltip: 'card_GoToOriginReport_tooltip',
                  },
                ],
              },
            },
            {
              id: 2,
              title: 'Owners',
              icon: 'admin_panel_settings',
              reportDefinition: {
                hasOpenInFull: true,
                rowSelection: 'none',
                fields: ['UserPrincipalName', 'DisplayName', 'RecipientType', 'IsExternal', 'GroupDynamicMembership'],
                lockedColumns: ['GroupDynamicMembership'],
                operationsColumns: params.canSeeDeleteAction
                  ? [
                      openMemberUsercard,
                      {
                        type: 'iconDialog',
                        name: 'Delete',
                        translate: 'common_Delete',
                        position: -1,
                        agColDef: {
                          ...baseDeleteAgColDef,
                          cellRendererParams: {
                            hideIcon: (rowData: any) => !!rowData.groupDynamicMembership,
                            disabled: !params.isManagement,
                            icon: 'delete',
                            actionId: Constants.managementActionsIds.removeTeamsGroupMember,
                            action: 'removeTeamsGroupMember',
                            title: 'common_RemoveUserFromGroup',
                            text: 'common_DeleteThisMember',
                            primaryButton: { key: 'delete', text: 'Delete' },
                            secondaryButton: { key: 'cancel', text: 'Cancel' },
                            type: 'alert',
                            onClick: (event: any) => {
                              if (event.key === 'delete') {
                                event.clickEvent.stopPropagation()

                                if (
                                  params.clickOnActionInline(
                                    event,
                                    Constants.managementActionsIds.removeTeamsGroupMember,
                                    'removeTeamsGroupMember'
                                  )
                                ) {
                                  event.component.title = 'usercard_actionInProgressTitle'
                                  event.component.text = 'usercard_actionInProgressDescription'
                                  event.component.primaryButton = { key: 'close', text: 'usercard_actionInProgressButtonTitle' }
                                  event.component.secondaryButton = null
                                  event.component.type = 'success'
                                  event.component.centered = true
                                }
                              }
                            },
                          },
                        },
                      },
                    ]
                  :  [openMemberUsercard],
                responseItemProp: 'items',
                sortField: 'DisplayName',
                sortOrder: 'asc',
                url: '/teams/group/{id}/members',
                verb: 'get',
                filters: {
                  isOwner: {
                    filterType: 'text',
                    filter: '',
                    type: 'equalsTrue',
                  },
                },
                urlRouteParameters: {
                  '{id}': 'id',
                },
                selectionActions: [
                  {
                    text: 'card_GoToOriginReport',
                    buttonType: 'tertiary',
                    icon: 'arrow_forward',
                    visibility: 'noRow',
                    onClick: (data: any[]) => {
                      const navigationExtras: NavigationExtras = { queryParams: { Office365GroupGuid: params.id, IsOwner: true } }
                      params.router.navigate(['/reports/teams/teamsMembers'], navigationExtras).catch((_: any) => _)
                    },
                    tooltip: 'card_GoToOriginReport_tooltip',
                  },
                ],
              },
            },
            {
              id: 3,
              title: 'Channels',
              icon: 'groups',
              reportDefinition: {
                hasOpenInFull: true,
                rowSelection: 'none',
                fields: ['DisplayName', 'Email', 'Description', 'WebUrl', 'TeamGroupId', 'TeamGroupName'],
                defaultHiddenFields: ['TeamGroupId', 'TeamGroupName'],
                operationsOnCols: [
                  {
                    field: 'webUrl',
                    fieldId: 'webUrl',
                    type: 'button',
                    label: 'OpenInTeams',
                  },
                ],
                operationsColumns: params.canSeeUpdateChannelAction
                ? [
                    {
                      type: 'iconButton',
                      name: 'Edit',
                      translate: 'common_Edit',
                      position: -1,
                      agColDef: {
                          ...baseDeleteAgColDef,
                          cellRendererParams: {
                            icon: 'edit',
                            clickFunction: 'editTeamsGroupChannelFunction'
                        }
                      }
                    }
                  ]
                : [],
                responseItemProp: 'items',
                sortField: 'DisplayName',
                sortOrder: 'asc',
                url: '/teams/group/{id}/channels/',
                verb: 'get',
                urlRouteParameters: {
                  '{id}': 'id',
                },
                selectionActions: [
                  {
                    text: 'card_GoToOriginReport',
                    buttonType: 'tertiary',
                    icon: 'arrow_forward',
                    visibility: 'noRow',
                    onClick: (data: any[]) => {
                      const navigationExtras: NavigationExtras = { queryParams: { TeamGroupId: params.id } }
                      params.router.navigate(['/reports/teams/teamsChannels'], navigationExtras).catch((_: any) => _)
                    },
                    tooltip: 'card_GoToOriginReport_tooltip',
                  },
                ],
              },
            },
          ],
        },
        infos: [],
      },
    ],
  }
}

export const getCallGroupCardDefinition = (
  translateHelper: TranslateHelper,
  populateFunc: (card: CardWidget, item: any) => void,
  clickOnActionInline: ($event: any, actionId: number, name?: any) => boolean
): CardDefinition | undefined => ({
  id: 'call',
  pageSettings: {
    path: [
      translateHelper.instant('Reports'),
      translateHelper.instant('Teams'),
      {
        text: translateHelper.instant('UserCallQuality'),
        route: 'reports/teams/userCallQuality',
      },
    ],
    hasManagement: false,
    hideSmartPanel: true,
    title: 'id',
    style: 'grid',
  },
  dataUrl: 'teams/callQuality',
  getDataUrlParams: (data: any) => ({ callId: data.id }),
  mapResult: (data: any) => data.items[0],
  cards: [
    {
      id: 1,
      title: 'CallDetails',
      options: {
        size: 1,
        type: 'info',
        maxNumberInfos: 12,
        useGlobalData: true,
      },
      defaultFields: [
        'organizerDisplayName',
        'organizerCountry',
        'organizerDepartment',
        'numberOfAttendees',
        'attendeesType',
        'callStartDateTime',
      ],
      populateFunc: populateFunc,
      infos: [],
    },
    {
      id: 2,
      title: 'CallQuality',
      options: {
        mini: true,
        size: 1,
        type: 'status',
        useGlobalData: true,
      },
      mapInfos: (data: any) => [
        {
          id: 1,
          title: data.callQuality,
          ...callQualityMap[data.callQuality],
        },
      ],
      infos: [],
    },
    {
      id: 3,
      title: 'CallVideoQuality',
      options: {
        mini: true,
        size: 1,
        type: 'status',
        useGlobalData: true,
      },
      mapInfos: (data: any) => [
        {
          id: 1,
          title: data.callVideoQuality,
          ...callQualityMap[data.callVideoQuality],
        },
      ],
      infos: [],
    },
    {
      id: 4,
      title: 'CallAudioQuality',
      options: {
        mini: true,
        size: 1,
        type: 'status',
        useGlobalData: true,
      },
      mapInfos: (data: any) => [
        {
          id: 1,
          title: data.callAudioQuality,
          ...callQualityMap[data.callAudioQuality],
        },
      ],
      infos: [],
    },
    {
      id: 5,
      title: 'CallScreenShareQuality',
      options: {
        mini: true,
        size: 1,
        type: 'status',
        useGlobalData: true,
      },
      mapInfos: (data: any) => [
        {
          id: 1,
          title: data.callVbssQuality,
          ...callQualityMap[data.callVbssQuality],
        },
      ],
      infos: [],
    },
    {
      id: 6,
      title: 'card_QualityByAttendees_Title',
      description: 'card_QualityByAttendees_Description',
      options: {
        type: 'chart',
        //columnSpan: 2,
      },
      charts: [
        {
          apiUrl: 'teams/userCallQualityById/groupBy',
          name: 'common_Session',
          getApiParams: (params: any) => ({
            callId: params.id,
            groupBy: 'SessionQuality',
          }),
          useDataSeries: true,
          useDataCategories: true,
          mapResultFunction: (data) => ({
            categories: data.items.map((x: any) => x.group),
            series: [{ name: 'Count', data: data.items.map((x: any) => x.count) }],
          }),
          config: {
            chart: {
              type: 'bar',
            },
            title: {
              text: '',
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              allowDecimals: false,
            },
          },
        },
        {
          apiUrl: 'teams/userCallQualityById/groupBy',
          name: 'common_Audio',
          getApiParams: (params: any) => ({
            callId: params.id,
            groupBy: 'SessionAudioQuality',
          }),
          useDataSeries: true,
          useDataCategories: true,
          mapResultFunction: (data) => ({
            categories: data.items.map((x: any) => x.group),
            series: [{ name: 'Count', data: data.items.map((x: any) => x.count) }],
          }),
          config: {
            chart: {
              type: 'bar',
            },
            title: {
              text: '',
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              allowDecimals: false,
            },
          },
        },
        {
          apiUrl: 'teams/userCallQualityById/groupBy',
          name: 'common_Video',
          getApiParams: (params: any) => ({
            callId: params.id,
            groupBy: 'SessionVideoQuality',
          }),
          useDataSeries: true,
          useDataCategories: true,
          mapResultFunction: (data) => ({
            categories: data.items.map((x: any) => x.group),
            series: [{ name: 'Count', data: data.items.map((x: any) => x.count) }],
          }),
          config: {
            chart: {
              type: 'bar',
            },
            title: {
              text: '',
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              allowDecimals: false,
            },
          },
        },
        {
          apiUrl: 'teams/userCallQualityById/groupBy',
          name: 'common_Vbss',
          getApiParams: (params: any) => ({
            callId: params.id,
            groupBy: 'SessionVbssQuality',
          }),
          useDataSeries: true,
          useDataCategories: true,
          mapResultFunction: (data) => ({
            categories: data.items.map((x: any) => x.group),
            series: [{ name: 'Count', data: data.items.map((x: any) => x.count) }],
          }),
          config: {
            chart: {
              type: 'bar',
            },
            title: {
              text: '',
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: '',
                align: 'high',
              },
              allowDecimals: false,
            },
          },
        },
      ],
    },
    {
      id: 7,
      title: 'card_AttendeesBy_Title',
      description: 'card_AttendeesBy_Description',
      options: {
        type: 'chart',
      },
      charts: [
        {
          apiUrl: 'teams/userCallQualityById/groupBy',
          name: 'common_Department',
          getApiParams: (params: any) => ({
            callId: params.id,
            groupBy: 'Department',
          }),
          useDataSeries: true,
          mapResultFunction: (data) => ({
            series: [{ name: 'Department', data: data.items.map((x: any) => ({ name: x.group, y: x.count })) }],
          }),
          config: {
            chart: {
              type: 'pie',
            },
            title: {
              text: '',
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false,
                },
                showInLegend: true,
              },
            },
          },
        },
        {
          apiUrl: 'teams/userCallQualityById/groupBy',
          name: 'common_Country',
          getApiParams: (params: any) => ({
            callId: params.id,
            groupBy: 'Country',
          }),
          useDataSeries: true,
          mapResultFunction: (data) => ({
            series: [{ name: 'Department', data: data.items.map((x: any) => ({ name: x.group, y: x.count })) }],
          }),
          config: {
            chart: {
              type: 'pie',
            },
            title: {
              text: '',
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false,
                },
                showInLegend: true,
              },
            },
          },
        },
        {
          apiUrl: 'teams/userCallQualityById/groupBy',
          name: 'common_Company',
          getApiParams: (params: any) => ({
            callId: params.id,
            groupBy: 'Company',
          }),
          useDataSeries: true,
          mapResultFunction: (data) => ({
            series: [{ name: 'Department', data: data.items.map((x: any) => ({ name: x.group, y: x.count })) }],
          }),
          config: {
            chart: {
              type: 'pie',
            },
            title: {
              text: '',
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: false,
                },
                showInLegend: true,
              },
            },
          },
        },
      ],
    },
    {
      id: 8,
      title: 'common_Members',
      options: {
        size: 1,
        type: 'table-tab',
        hideTabs: true,
        tabs: [
          {
            id: 2,
            reportDefinition: {
              hasOpenInFull: true,
              rowSelection: 'multiple',
              fields: ['CallRecordId', 'DisplayName', 'UserPrincipalName', 'Company', 'Country', 'Domain'],
              responseItemProp: 'items',
              sortField: 'CallStartDateTime',
              sortOrder: 'desc',
              url: '/teams/userCallQuality',
              verb: 'get',
              urlRouteParameters: {
                callId: 'id',
              },
            },
          },
        ],
        columnSpan: 3,
        rowSpan: 4,
      },
    },
  ],
})
