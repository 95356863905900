import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { Observable, timer } from 'rxjs'

@Component({
  selector: 'app-consent-apps',
  templateUrl: './consent-apps.component.html',
  styleUrls: ['./consent-apps.component.sass'],
})
export class ConsentAppsComponent implements OnInit {
  isGivenConsent = false
  notGivenConsentDescription = ''

  constructor(
    private apiClient: ApiclientService,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private window: Window
  ) {}

  ngOnInit(): void {
    const error = this.activatedRoute.snapshot.queryParams['error']
    if (error === 'access_denied') {
      
      this.isGivenConsent = false
      this.notGivenConsentDescription = this.activatedRoute.snapshot.queryParams['error_description']
      return
    }

    const workload = this.activatedRoute.snapshot.queryParams['state']
    if (workload) {
      const [wlKey, clientId] = workload.toLowerCase().split('|')
      this.consents[wlKey.toLowerCase().split(';')[0]](clientId).subscribe((_) => timer(5000).subscribe(() => this.window.open('/')))
      this.isGivenConsent = true
    }
  }

  getImage = () => {
    if(this.isGivenConsent)
      return '../assets/img/consent.svg'
    else
      return '../assets/img/consent-not-given.svg'
  }

  getTitle = () => {
    if(this.isGivenConsent)
      return 'administration_ConsentGivenTitles'
    else
      return 'administration_ConsentNotGivenTitles'
  }

  getInnerHtmlDescription = () => {
    if(this.isGivenConsent)
      return 'administration_ConsentGivenDescription'
    else if(this.notGivenConsentDescription)
      return this.notGivenConsentDescription
    return 'administration_ConsentNotGivenDescription'
  }

  urlParams = () => {
    return {
      tenantId: this.activatedRoute.snapshot.queryParams['tenant'],
      adminConsent: this.activatedRoute.snapshot.queryParams['admin_consent'],
    }
  }

  postConsent = (url: string, params?: Record<string, string>) => {
    const urlparams = this.urlParams()
    return this.httpClient.post<void>(
      `${this.apiClient.basePortalApiUrl}${url}`,
      { tenantId: urlparams.tenantId, adminConsent: urlparams.adminConsent, ...params },
      { withCredentials: true }
    )
  }

  consents: Record<string, (clientId?: string) => Observable<void>> = {
    sharepointmanagement: () => this.postConsent('/sharepoint/enableapi'),
    graphmanagement: () => this.postConsent('/graphManagement/enableapi/'),
    graphconsents: () => this.postConsent('/graph/grantGraphConsent/'),
    management: () => this.postConsent('/office365/enableapi/'),
    registration: () => this.postConsent('/office365/enableapi/'),
    bitlockerconsent: () => this.postConsent('/graph/bitLockerConsentUrl/'),
    coreviewexchangeintegration: (clientId?: string) => this.postConsent('/organization/exchangeintegrationapp', { clientId: clientId! }),
    coreviewteamsintegration: (clientId?: string) => this.postConsent('/organization/teamsintegrationapp', { clientId: clientId! }),
  }

  gotoHome(): void {
    location.href = '/administrations/organization/my'
  }
}
