import { GridApi, GridOptions, IRowNode, RowSelectedEvent } from '@ag-grid-community/core'
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { AccountSkuDetails } from '@app/core/models/AccountSkuDetails'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { CoreViewColumn } from '@app/core/models/CoreViewColumn'
import { AdvancedFilterConfiguration, LicensePoolInfoFilter } from '@app/core/models/LicensePool'
import { Verb } from '@app/core/models/PageDataCommonClasses'
import { ReportDefinition, SelectionAction } from '@app/core/models/ReportDefinition'
import { NavItem } from '@coreview/coreview-library/models/nav-item'
import { SavedReport } from '@app/core/models/saved-report'
import { AdministrationsService } from '@app/core/services/administrations.service'
import { BreadcrumbService, TranslateHelper } from '@coreview/coreview-library'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { ReportExportService } from '@app/core/services/report-export.service'
import { ReportsService } from '@app/core/services/reports.service'
import { RightPanelService } from '@app/core/services/right-panel.service'
import { AdvancedFiltersComponent } from '@app/modules/reports/components/license-pool-center/advanced-filters/advanced-filters.component'
import { ManageCostsComponent } from '@app/modules/reports/components/license-pool-center/manage-costs/manage-costs.component'
import { ClientDatagridComponent } from '@app/shared/components/client-datagrid/client-datagrid.component'
import { DatagridComponent } from '@app/shared/components/datagrid/datagrid.component'
import { TargetSelectionDefinition, TargetSelectionOptions } from '@app/shared/components/target-selection/target-selection.component'
import { BuildColParameter } from '@app/shared/utilities/build-col-ag-grid'
import { Constants } from '@app/shared/utilities/constants'
import { Helpers } from '@app/shared/utilities/helpers'
import { ReportsComponentHelper } from '@app/shared/utilities/reports-component-helper'
import { RootState } from '@app/store/RootState.type'
import * as Actions from '@app/store/license-pool-center/license-pool-center.actions'
import * as Selectors from '@app/store/license-pool-center/license-pool-center.selectors'
import { selectFavorites, selectFlatMenusAll } from '@app/store/menu/menu.selectors'
import { selectLastMessage } from '@app/store/messages/messages.selectors'
import { getUserSettings, updateUserSettingsExtraLive } from '@app/store/userSettings/userSettings.actions'
import { Store } from '@ngrx/store'
import { ButtonComponent, SmartPanelComponent, Suggestion } from '@coreview/coreview-components'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { Subject, combineLatest, of, timer } from 'rxjs'
import { filter, map, startWith, takeUntil } from 'rxjs/operators'
import { LicensePoolFilterFullReportType, LicensePoolFilterType } from '../../components/license-left-panel/license-left-panel.component'
import { LicensePoolHelperService } from '../../services/license-pool-helper.service'
import { LicensePoolService } from '../../services/license-pool.service'
import {
  getColumnsParametersLicensePoolCostClient,
  getColumnsParametersLicensePoolClientLicense,
  getGridOptionsLicensePoolCostClient,
  getGridOptionsSnapshots,
  getColumnDefsLicensePoolsCost,
} from './license-pool-center.definitions'
import { selectedOrganizationSkus } from '@app/store/organizations/organizations.selectors'

@Component({
  selector: 'app-license-pool-center',
  templateUrl: './license-pool-center.component.html',
  styleUrls: ['./license-pool-center.component.sass'],
})
export class LicensePoolCenterComponent implements OnInit, OnDestroy {
  @ViewChildren(SmartPanelComponent) panels!: QueryList<SmartPanelComponent>

  @ViewChild(DatagridComponent)
  set setGrid(grid: DatagridComponent) {
    this.grid = grid
  }

  @ViewChild(ClientDatagridComponent)
  set setGridClient(grid: ClientDatagridComponent) {
    this.gridClient = grid
  }

  v2TabSelected = Constants.menuIds.licensePoolReport
  tabSelectedIndex = 0

  detailCellRenderParamsLp!: any
  detailCellRenderParamsLpClient!: any
  detailCellRenderParamsSnapshots!: any

  advancedFiltersConfiguration: AdvancedFilterConfiguration = {
    licenses: [],
    hasShowOnlyPoolsWithAllocation: true,
  }
  showOnlyPoolsWithAllocation = true
  selectedLicenses: string[] = []
  isSavedReport = false

  licensePoolConfiguration?: any
  noLpConfigured = false
  v2ReportTypes: Record<number, any> = {
    [Constants.menuIds.licensePoolReport]: { type: 'pool', titleKey: 'reports_PoolReport', titleTranslated: '' },
    [Constants.menuIds.licensePoolCostReport]: { type: 'cost', titleKey: 'reports_CostReport', titleTranslated: '' },
  }
  lpReportModeV3:
    | LicensePoolFilterFullReportType.FullReportMasterData
    | LicensePoolFilterFullReportType.FullReportGroupedBySKU
    | LicensePoolFilterFullReportType.FullReportGroupedByPools
    | LicensePoolFilterType.OverAllocatedOnPool
    | LicensePoolFilterType.OutOfCapacityOnInventory = LicensePoolFilterFullReportType.FullReportMasterData

  v3ReportTitleKey: Record<string, string> = {
    [LicensePoolFilterType.FullReport]: 'licenses_FullReport',
    [LicensePoolFilterType.OverAllocatedOnPool]: 'licenses_OverAlloratedOnPool',
    [LicensePoolFilterType.OutOfCapacityOnInventory]: 'licenses_OutOfCapacityOnInventory',
    [LicensePoolFilterFullReportType.FullReportMasterData]: 'licenses_FullReportMasterData',
    [LicensePoolFilterFullReportType.FullReportGroupedByPools]: 'licenses_FullReportGroupedByPools',
    [LicensePoolFilterFullReportType.FullReportGroupedBySKU]: 'licenses_FullReportGroupedBySKU',
  }

  snapshotToCompare!: string
  snapshotToCompareTimestamp!: string
  snapshotToView!: string
  snapshotToViewTimestamp!: string
  snapshotsDates: Suggestion[] = []

  licensePoolsCostGridDefinition!: ReportDefinition
  reportDefinition!: ReportDefinition

  selectionActions: SelectionAction[] = []
  groupedActions!: SelectionAction[]
  filterActions!: SelectionAction[]
  clientSelectionActions!: SelectionAction[]

  menu?: NavItem
  savedReportIdFilter?: string // do not use this property, it gets cleaned up after first api call
  savedReportId?: string
  savedReports: SavedReport[] = []
  metadata!: any
  savedReport!: SavedReport | null
  reportTitle?: string
  reportTitleFullscreen?: string
  filterTitleFullscreen?: string

  reportDescription?: string

  isUpdateInProgress = false
  operatorHasNoLPAssigned = false
  savedReportFilterSortModel?: { filterModel: any; sortModel: { sortField: string; sortOrder: 'asc' | 'desc' } }
  menuIds = Constants.menuIds
  isOpenInFull = false

  containerStyle = ''
  horizontalTabsStyle: 'line' | 'hiddenTabs' = 'line'

  grid!: DatagridComponent
  gridClient!: ClientDatagridComponent
  refreshV3Grid: Subject<boolean> = new Subject()

  isCustomReportNotFound = false

  columnsParametersLicensePoolClientLicense = getColumnsParametersLicensePoolClientLicense(this.router)

  gridOptionsLicensePoolCostClient: GridOptions = getGridOptionsLicensePoolCostClient

  columnsParametersLicensePoolCostClient: BuildColParameter = getColumnsParametersLicensePoolCostClient(this.router)

  gridOptionsSnapshots: GridOptions = getGridOptionsSnapshots

  columnDefsLicensePoolsCost: CoreViewColumn[] = getColumnDefsLicensePoolsCost

  getGrid() {
    return this.gridClient || this.grid
  }

  canSeeCustomReportsBySku = true //#bug 40906

  private exportUrl = 'licensepool/report/summary'
  private exportVerb: Verb = 'post'
  private route!: string
  private targetLicenses: LicensePoolInfoFilter[] = []
  private targetLicensesReportOnly: LicensePoolInfoFilter[] = []
  private isFirstLoadSavedReport = true

  private destroyed$: Subject<boolean> = new Subject()

  constructor(
    private store: Store<RootState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public storage: LocalstorageService,
    private breadcrumbService: BreadcrumbService,
    private translateHelper: TranslateHelper,
    private reportsService: ReportsService,
    private reportExportService: ReportExportService,
    private rightPanelService: RightPanelService,
    private administrationsService: AdministrationsService,
    public licenseService: LicensePoolService,
    public licensePoolHelperService: LicensePoolHelperService,
    private reportsComponentHelper: ReportsComponentHelper
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    this.activatedRoute = activatedRoute
    this.savedReportIdFilter = this.activatedRoute.snapshot.queryParams.SavedReportId
    this.savedReportId = this.activatedRoute.snapshot.queryParams.SavedReportId

    const isSavedReport = typeof this.savedReportId === 'string'

    this.store.dispatch(Actions.setIsSavedReport({ isSavedReport }))
    if (isSavedReport === false) {
      this.store.dispatch(getUserSettings())
    }

    for (const report in this.v2ReportTypes) {
      if (this.v2ReportTypes[report].hasOwnProperty('titleKey')) {
        this.v2ReportTypes[report].titleTranslated = this.translateHelper.instant(this.v2ReportTypes[report].titleKey)
      }
    }
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((routeData) => {
      this.savedReport = routeData.savedReport
      this.isCustomReportNotFound = this.isLegacyCustomReport()

      if (!this.isCustomReportNotFound) {
        this.licensePoolHelperService.savedReport = this.savedReport
        this.loadInitialData()

        this.addBreadcrumbReload()

        this.initializeSnapshotFiltersOptions()
        this.initializeFilterActions()
        this.initializeGroupedActions()
        this.initializeSelectionActions()
        this.initializeGridDefinitions()
        this.initializeLicensePoolService()

        this.store
          .select(selectLastMessage)
          .pipe(filter((x) => !!x))
          .subscribe((x) => {
            if (x?.type === 'NotifyTaskStatus' && x.body.title === 'LicensePoolUpdateAssigned') {
              if (x.body.state === 'Finished') {
                // Sometimes, the update is finished but when calling the api
                // the data is not ready yet (old allocation values), this is to avoid that issue
                timer(1000).subscribe(() => {
                  this.isUpdateInProgress = false
                })
              } else if (x.body.state === 'Progress') {
                this.isUpdateInProgress = true
              } else if (x.body.state === 'Failed') {
                this.isUpdateInProgress = false
              }
            }
          })

        this.store
          .select(Selectors.selectShowOnlyPoolsWithAllocation)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((isShow) => {
            this.showOnlyPoolsWithAllocation = isShow
          })

        this.store
          .select(Selectors.selectSelectedLicenses)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((licenses) => {
            this.selectedLicenses = licenses
          })

        this.store
          .select(Selectors.selectLeftPanelReport)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((report) => {
            this.lpReportModeV3 = report as
              | LicensePoolFilterFullReportType.FullReportMasterData
              | LicensePoolFilterFullReportType.FullReportGroupedBySKU
              | LicensePoolFilterFullReportType.FullReportGroupedByPools
              | LicensePoolFilterType.OverAllocatedOnPool
              | LicensePoolFilterType.OutOfCapacityOnInventory

            this.setTitleFullScreen()

            if (!this.savedReportId || this.isFirstLoadSavedReport === false) {
              this.resetSavedReport()
              this.setSavedReportsParams()
              this.isFirstLoadSavedReport = false
            }

            this.store
              .select(selectedOrganizationSkus)
              .pipe(
                takeUntil(this.destroyed$),
                filter((x) => !!x)
              )
              .subscribe((skus) => {
                this.loadSavedReports()
              })
          })

        this.store
          .select(Selectors.selectIsOpenInFull)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((isOpenInFull) => {
            this.isOpenInFull = isOpenInFull
            this.configureOpenInFullscreen()
          })
      }
    })
  }

  ngOnDestroy(): void {
    this.breadcrumbService.clear()
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  handleGotoLicenseReport(): void {
    this.router.navigate(['/reports/license/poolCenter']).catch((_: any) => _)
  }

  configureOpenInFullscreen() {
    if (this.isOpenInFull) {
      this.containerStyle = 'grid-template-columns: 1fr auto'
      this.horizontalTabsStyle = 'hiddenTabs'
      this.breadcrumbService.hide()
      this.store.dispatch(updateUserSettingsExtraLive({ key: 'isMenuOpen', value: false }))
    } else {
      this.containerStyle = 'grid-template-columns: auto 1fr auto'
      this.horizontalTabsStyle = 'line'
      this.breadcrumbService.show()
    }
  }

  isRowMasterLp = () => true

  isRowMasterSnapshots = () => true

  canSeeTab = (id: number) => this.licenseService.canSeeTab(id)

  getLicensePoolsCost = (params: ApiDataParameters): any => ({
    items: this.getLicensePoolCall(params).pipe(
      map((res: any) => {
        if (res.commandTask?.status === 'Progress') {
          this.isUpdateInProgress = true
          return null
        } else if (res.errorCode === 'NoContent') {
          this.operatorHasNoLPAssigned = true
        }
        return res
      })
    ),
    cols: of(this.columnDefsLicensePoolsCost),
  })

  getLicensePoolsClient = (): any => {
    this.reportDefinition = {
      title: this.reportTitle + '_LicensePool',
      fields: [],
      responseItemProp: 'licensePoolGroupReports',
      sortField: 'Name',
      sortOrder: 'asc',
      verb: 'get',
      url: '/licensepool/report',
    }

    this.detailCellRenderParamsLpClient = {
      getTableData: (data: any) => data.items.sort((a: any, b: any) => a.sku.localeCompare(b.sku)),
      columnDefs: [],
    }

    this.detailCellRenderParamsLpClient.columnDefs = [
      {
        field: 'sku',
        filter: 'agTextColumnFilter',
        type: 'tableDetail',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: this.v2ReportTypes[this.v2TabSelected].type === 'pool' ? 'consumed' : 'totalConsumedCost',
        filter: 'agNumberColumnFilter',
        cellRenderer: this.v2ReportTypes[this.v2TabSelected].type === 'pool' ? 'linkCellRenderer' : '',
        cellRendererParams: {
          param: {
            name: 'consumed',
            field: 'consumed',
            param: '/reports/users/users',
            props: {
              LicensePoolGroupName: (data: any, queryParams: any) => {
                queryParams.LicensePoolGroupName = '=' + data.parentNode?.name
                return queryParams
              },
              Licenses: (data: any, queryParams: any) => {
                queryParams.Licenses = '=' + data.sku
                return queryParams
              },
            },
            type: 'link',
            external: false,
          },
        },
      },
      {
        field: 'active',
        filter: 'agNumberColumnFilter',
        type: 'active',
      },
    ]

    return this.administrationsService
      .getLicensePoolsReport({ pageNumber: 1, pageSize: 2000000, sort: 'Name', sortOrder: 'asc' })
      .pipe(map((data) => this.licenseService.buildLpData(data)))
  }

  private getManageLicenseAllocationConfiguration(
    data?: any,
    skus?: any
  ): {
    licensePools: { targetSelectionDefinition: TargetSelectionDefinition; targetSelectionConfiguration: TargetSelectionOptions }
    licenses: { targetSelectionDefinition: TargetSelectionDefinition; targetSelectionConfiguration: TargetSelectionOptions }
  } {
    return {
      licensePools: {
        targetSelectionDefinition: {
          rowSelection: 'multiple',
          entity: 'licensePools',
          targetPropertyId: 'name',
          lockedColumns: ['Name', 'Users', 'TotalAssigned', 'TotalConsumed'],
          fields: ['Name', 'Users', 'TotalAssigned', 'TotalConsumed', 'TotalAvailable'],
          url:
            '/licensepoolcenter/groupbypoolname/' +
            this.v2ReportTypes[this.v2TabSelected].type +
            '/' +
            this.storage.selectedOrganization?.id,
          responseItemProp: 'pools',
          sortField: 'Name',
          sortOrder: 'asc',
          verb: 'get' as Verb,
          isOnlineUsersType: true,
          hideColumnsSelector: true,
          selectionActions: [],
          selectedItems: data,
        },
        targetSelectionConfiguration: {
          columnDefs: [
            {
              originalName: 'Name',
              filterName: 'Name',
              name: 'name',
              translate: 'PoolName',
              filter: { type: 'string', name: 'Name' },
              type: 'string',
              agColDef: {
                sort: 'asc',
              },
            },
            {
              originalName: 'Users',
              name: 'users',
              translate: 'TotalUsers',
              filter: { type: 'int', name: 'users' },
              type: 'int',
            },
            {
              originalName: 'TotalAssigned',
              name: 'totalAssigned',
              translate: 'AssignedLicenses',
              filter: { type: 'int', name: 'totalAssigned' },
              type: 'int',
            },
            {
              originalName: 'TotalConsumed',
              name: 'totalConsumed',
              translate: 'ConsumedLicenses',
              filter: { type: 'int', name: 'totalConsumed' },
              type: 'int',
            },
            {
              originalName: 'TotalAvailable',
              name: 'totalAvailable',
              translate: 'TotalAvailable',
              filter: { type: 'int', name: 'totalAvailable' },
              type: 'int',
            },
          ],
        },
      },
      licenses: {
        targetSelectionDefinition: {
          isClientDatagrid: true,
          rowSelection: 'multiple',
          targetPropertyId: 'skuId',
          entity: 'LicensesEntity',
          selectionActions: [],
          getTargetsToSelect: of([...this.targetLicenses]),
          fields: [],
          sortField: '',
          sortOrder: null,
          verb: 'get',
          responseItemProp: '',
          selectedItems: skus,
        },
        targetSelectionConfiguration: {
          selectedItemsFields: this.buildManageAllocatorLicensesTable(),
          fields: this.buildManageAllocatorLicensesTable(),
        },
      },
    }
  }

  getLicensePoolSnapshots = (): any => {
    this.reportDefinition = {
      title: this.reportTitle + '_Snapshots',
      fields: [],
      responseItemProp: '',
      sortField: '',
      sortOrder: null,
      verb: 'get',
    }

    const columnDefs = [
      {
        field: 'name',
        filter: 'agTextColumnFilter',
        type: 'tableDetail',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: this.translateHelper.instant('TotalUsers'),
        children: [
          {
            field: 'users_' + this.snapshotToCompare,
            headerName: this.snapshotToCompare,
            headerClass: 'total-users-first',
            filter: 'agNumberColumnFilter',
            hide: !this.snapshotToCompare,
          },
          {
            field: 'users_' + this.snapshotToView,
            headerName: this.snapshotToView,
            headerClass: this.snapshotToCompare ? 'total-users-second' : 'total-users',
            filter: 'agNumberColumnFilter',
          },
        ],
      },
      {
        headerName: this.translateHelper.instant('Assigned'),
        children: [
          {
            field: 'assignedLicenses_' + this.snapshotToCompare,
            headerName: this.snapshotToCompare,
            headerClass: 'total-users-first',
            filter: 'agNumberColumnFilter',
            hide: !this.snapshotToCompare,
          },
          {
            field: 'assignedLicenses_' + this.snapshotToView,
            headerName: this.snapshotToView,
            headerClass: this.snapshotToCompare ? 'total-users-second' : 'total-users',
            filter: 'agNumberColumnFilter',
          },
        ],
      },
      {
        headerName: this.translateHelper.instant('Consumed'),
        children: [
          {
            field: 'consumedLicenses_' + this.snapshotToCompare,
            headerName: this.snapshotToCompare,
            headerClass: 'total-users-first',
            filter: 'agNumberColumnFilter',
            hide: !this.snapshotToCompare,
          },
          {
            field: 'consumedLicenses_' + this.snapshotToView,
            headerName: this.snapshotToView,
            headerClass: this.snapshotToCompare ? 'total-users-second' : 'total-users',
            filter: 'agNumberColumnFilter',
          },
        ],
      },
      {
        headerName: this.translateHelper.instant('Available'),
        children: [
          {
            field: 'availableLicenses_' + this.snapshotToCompare,
            headerName: this.snapshotToCompare,
            headerClass: 'total-users-first',
            filter: 'agNumberColumnFilter',
            hide: !this.snapshotToCompare,
          },
          {
            field: 'availableLicenses_' + this.snapshotToView,
            headerName: this.snapshotToView,
            headerClass: this.snapshotToCompare ? 'total-users-second' : 'total-users',
            filter: 'agNumberColumnFilter',
          },
        ],
      },
    ]

    this.detailCellRenderParamsSnapshots = {
      tableDataField: 'items',
      columnDefs: [
        {
          field: 'sku',
          filter: 'agTextColumnFilter',
          type: 'tableDetail',
          cellRenderer: 'agGroupCellRenderer',
        },
        {
          headerName: this.translateHelper.instant('Assigned'),
          children: [
            {
              field: 'assignedLicenses_' + this.snapshotToCompare,
              headerName: this.snapshotToCompare,
              filter: 'agNumberColumnFilter',
              hide: !this.snapshotToCompare,
            },
            {
              field: 'assignedLicenses_' + this.snapshotToView,
              headerName: this.snapshotToView,
              filter: 'agNumberColumnFilter',
            },
          ],
        },
        {
          headerName: this.translateHelper.instant('Consumed'),
          children: [
            {
              field: 'consumedLicenses_' + this.snapshotToCompare,
              headerName: this.snapshotToCompare,
              filter: 'agNumberColumnFilter',
              hide: !this.snapshotToCompare,
            },
            {
              field: 'consumedLicenses_' + this.snapshotToView,
              headerName: this.snapshotToView,
              filter: 'agNumberColumnFilter',
            },
          ],
        },
        {
          headerName: this.translateHelper.instant('Available'),
          children: [
            {
              field: 'availableLicenses_' + this.snapshotToCompare,
              headerName: this.snapshotToCompare,
              filter: 'agNumberColumnFilter',
              hide: !this.snapshotToCompare,
            },
            {
              field: 'availableLicenses_' + this.snapshotToView,
              headerName: this.snapshotToView,
              filter: 'agNumberColumnFilter',
            },
          ],
        },
      ],
    }

    this.gridOptionsSnapshots.columnDefs = columnDefs

    if (!!this.gridClient?.gridApi) {
      this.gridClient?.gridApi.setColumnDefs([])
      this.gridClient?.gridApi.setColumnDefs(columnDefs)
    }

    const olderTimestamp =
      this.snapshotToCompareTimestamp && dayjs(this.snapshotToCompareTimestamp).isBefore(dayjs(this.snapshotToViewTimestamp))
        ? this.snapshotToCompareTimestamp
        : this.snapshotToViewTimestamp

    return this.licenseService
      .getLicensePoolSnapshot({
        pageNumber: 1,
        pageSize: 2000000,
        sort: 'TimeStamp',
        sortOrder: 'asc',
        months: 6,
        timeStamp: olderTimestamp,
      })
      .pipe(
        map((data) => {
          if (data.commandTask?.status === 'Progress') {
            return null
          }

          return this.licenseService.buildSnapshotsData(
            {
              toView: data.licensePoolSnapshots.find((s: any) => Helpers.toYYYYMM(new Date(s.timeStamp)) === this.snapshotToView),
              toCompare: this.snapshotToCompare
                ? data.licensePoolSnapshots.find((s: any) => Helpers.toYYYYMM(new Date(s.timeStamp)) === this.snapshotToCompare)
                : undefined,
            },
            this.snapshotToView,
            this.snapshotToCompare
          )
        })
      )
  }

  getSnapshotsToViewDates() {
    return this.snapshotsDates.filter((d: Suggestion) => d.displayValue !== this.snapshotToCompare)
  }

  getSnapshotsToCompareDates() {
    return this.snapshotsDates.filter((d: Suggestion) => d.displayValue !== this.snapshotToView)
  }

  snapshotToCompareChanged($event: any) {
    this.snapshotToCompareTimestamp = $event
    this.snapshotToCompare = Helpers.toYYYYMM(new Date($event))
  }

  snapshotToViewChanged($event: any) {
    this.snapshotToViewTimestamp = $event
    this.snapshotToView = Helpers.toYYYYMM(new Date($event))
  }

  resetSnapshotsFilters() {
    this.initializeSnapshotsFilters()
    this.applySnaphotsFilters()
  }

  applySnaphotsFilters() {
    this.gridClient?.refresh()
  }

  tabChanged(params: any) {
    this.tabSelectedIndex = params.index
    this.resetSavedReport()

    const lpV2TabSelected = Object.keys(this.v2ReportTypes).find((key) => {
      const reportId = parseInt(key, 10)
      return this.v2ReportTypes[reportId].titleTranslated === params.button.title
    })

    if (lpV2TabSelected) {
      this.v2TabSelected = parseInt(lpV2TabSelected, 10)
    }

    this.setSavedReportsParams()
    this.loadSavedReports()
  }

  canSeeLeftPanel() {
    return this.canSeeCustomReportsBySku
  }

  private initializeLicensePoolService() {
    this.licensePoolHelperService.saveViewObservable.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadSavedReports()
      if (this.panels.first) {
        this.panels.first.open = true
      }
    })

    this.licensePoolHelperService.scheduleObservable.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.loadSavedReports()
      if (this.panels.first) {
        this.panels.first.open = true
      }
    })
  }

  private handleSave() {
    this.metadata.queryRequest.fields = this.reportsComponentHelper.getVisibleFields(this.grid.columnApi)
    this.licensePoolHelperService.saveView(this.metadata, this.reportDefinition, this.grid.columnApi)
  }

  private getAdvancedFiltersConfiguration() {
    const configuration: AdvancedFilterConfiguration = { ...this.advancedFiltersConfiguration }
    if (this.selectedLicenses && this.selectedLicenses.length > 0) {
      configuration.selectedLicenses = [...this.selectedLicenses]
    } else {
      delete configuration.selectedLicenses
    }

    configuration.hasShowOnlyPoolsWithAllocation = this.isPoolReportV3Selected()

    if (this.licensePoolConfiguration?.reportOnly) {
      configuration.hasShowOnlyPoolsWithAllocation = false
      configuration.licenses = [...this.targetLicensesReportOnly]
    }

    return configuration
  }

  handleOpenAdvancedFilters() {
    const configuration = this.getAdvancedFiltersConfiguration()
    const showOnlyPoolsWithAllocation = this.showOnlyPoolsWithAllocation

    this.rightPanelService
      .open({
        type: AdvancedFiltersComponent,
        data: {
          configuration,
          showOnlyPoolsWithAllocation,
          width: '30%',
        },
      })
      .afterClosed()
      .subscribe((f: any) => {
        if (f) {
          this.applyAdvancedFiltersConfiguration(f)
        }
      })
  }

  private applyAdvancedFiltersConfiguration(data: {
    config: AdvancedFilterConfiguration
    showOnlyPoolsWithAllocation: boolean
    selectedLicenses: string[]
  }) {
    if (data) {
      this.store.dispatch(Actions.setShowOnlyPoolsWithAllocation({ isShow: data.showOnlyPoolsWithAllocation }))
      if (data.selectedLicenses && data.selectedLicenses.length > 0) {
        this.store.dispatch(Actions.setSelectedLicenses({ licenses: data.selectedLicenses }))
      }
      this.advancedFiltersConfiguration = cloneDeep(data.config)
      this.reloadGrid()
    }
  }

  getReportParams = (params: ApiDataParameters): any => {
    const reportParams = { ...params }

    if (this.savedReportIdFilter) {
      Object.assign(reportParams, { savedReportId: this.savedReportIdFilter })
      this.savedReportIdFilter = undefined
    }
    return reportParams
  }

  private setTitles() {
    this.reportTitle = this.savedReport ? this.savedReport.reportName : this.translateHelper.instant('PoolCenter')
    this.reportTitleFullscreen = this.translateHelper.instant('PoolCenter')
    this.licensePoolHelperService.reportTitle = this.reportTitle || ''
    this.reportDescription = this.savedReport ? this.savedReport.description : this.translateHelper.instant(`descriptions_PoolCenter`)
    this.setTitleFullScreen()
  }

  private setTitleFullScreen() {
    this.filterTitleFullscreen = this.savedReport
      ? this.savedReport.reportName
      : this.translateHelper.instant(this.v3ReportTitleKey[this.lpReportModeV3])
  }

  private loadMenuInfo() {
    combineLatest([this.store.select(selectFlatMenusAll), this.store.select(selectFavorites)])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([{ flatMenuAll }, favorites]) => {
        this.menu = flatMenuAll.find((x: NavItem) => {
          const urlToFind = this.savedReportId ? this.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '') : this.route
          return '/' + x.route === urlToFind
        })
        if (this.menu) {
          this.licensePoolHelperService.route = this.menu?.route
          this.setIsFavorite(favorites)
          this.loadSavedReports()
          this.breadcrumbService.updatePath([...this.getPath(), this.reportTitle || '-'])
        } else {
          this.breadcrumbService.updatePath([this.translateHelper.instant('common_Reports'), this.reportTitle || '-'])
        }
      })
  }

  private resetSavedReport() {
    this.savedReport = null
    this.licensePoolHelperService.savedReport = null
    this.savedReportId = undefined
    this.reportTitle = this.translateHelper.instant('PoolCenter')
    this.breadcrumbService.updatePath([...this.getPath(), this.reportTitle || '-'])
    this.store.dispatch(Actions.setIsSavedReport({ isSavedReport: false }))
  }

  private getLicensePoolCall(params: ApiDataParameters): any {
    this.reportDefinition = {
      title: this.reportTitle + '_PoolReport',
      fields: [],
      responseItemProp: '',
      sortField: '',
      sortOrder: null,
      verb: 'get',
      url:
        '/licensepoolcenter/groupbypoolname/' + this.v2ReportTypes[this.v2TabSelected].type + '/' + +this.storage.selectedOrganization?.id,
    }

    const paramsToPass: any = this.getReportParams(params)

    if (this.selectedLicenses && this.selectedLicenses.length > 0) {
      paramsToPass.advancedFilters = { selectedSkuIds: this.selectedLicenses }
    }

    return this.licenseService.getLicensePoolsGroupByName(
      paramsToPass,
      this.v2ReportTypes[this.v2TabSelected].type,
      this.storage.selectedOrganization?.id
    )
  }

  private selectDetailRows(masterGridApi: GridApi, event: any) {
    setTimeout(() => {
      const node = masterGridApi.getSelectedNodes().find((r) => r.id !== event.node.id)
      const detail = masterGridApi.getDetailGridInfo('detail_' + node?.id)

      detail?.api?.getSelectedNodes().forEach((n: IRowNode<any>) => {
        masterGridApi
          .getDetailGridInfo('detail_' + event.node.id)
          ?.api?.getRowNode('' + n.id)
          ?.setSelected(true)
      })
    }, 100)
  }

  private deselectDetailRow(masterGridApi: GridApi, event: any) {
    const detail = masterGridApi.getDetailGridInfo('detail_' + event.node.id)

    detail?.api?.getRenderedNodes().forEach((n: IRowNode<any>) => {
      n.setSelected(false)
    })
  }

  private buildManageAllocatorLicensesTable(): { key: string; value: string; sort: 'asc' | 'desc' | null }[] {
    return [
      { key: 'sku', value: 'Sku', sort: 'asc' },
      { key: 'notAssigned', value: 'Available', sort: null },
      { key: 'total', value: 'Active', sort: null },
    ]
  }

  private initializeSavedReportPageSettings(savedReport: SavedReport) {
    if (savedReport.apiUrl.includes('/cost/')) {
      this.v2TabSelected = Constants.menuIds.licensePoolCostReport
      if (this.canSeeTab(Constants.menuIds.licensePoolReport)) {
        this.tabSelectedIndex = 1
      } else {
        this.tabSelectedIndex = 0
      }
    } else {
      this.lpReportModeV3 = this.getSavedReportType(savedReport)
      this.store.dispatch(Actions.setLeftPanelReport({ report: this.lpReportModeV3 }))
    }
  }

  private initializeSnapshotFiltersOptions() {
    this.licenseService.getLicensePoolSnapshotTimestamps().subscribe((snapshots) => {
      this.snapshotsDates = (snapshots || []).map((s) => ({
        value: s,
        displayValue: Helpers.toYYYYMM(new Date(s)),
      }))
      this.initializeSnapshotsFilters()
    })
  }

  private initializeSnapshotsFilters() {
    this.snapshotToCompare = ''
    this.snapshotToCompareTimestamp = ''
    this.snapshotToView = Helpers.toYYYYMM(new Date())
    if (this.snapshotsDates.length) {
      this.snapshotToViewTimestamp = this.snapshotsDates[this.snapshotsDates.length - 1].value
    }
  }

  private initializeFilterActions() {
    this.filterActions = [
      {
        text: 'licenses_AdvancedFilters',
        buttonType: 'tertiary',
        icon: 'filter_alt',
        visibility: 'custom',
        onClick: () => {
          this.handleOpenAdvancedFilters()
        },
      },
    ]
  }

  private initializeGroupedActions() {
    this.groupedActions = [
      { 
        ...this.reportsComponentHelper.scheduleAction, 
        onClick: () => { 
          this.licensePoolHelperService.schedule(this.metadata, this.grid.columnApi)
        } 
      },
      { 
        ...this.reportsComponentHelper.saveCustomReportAction, 
        onClick: () => { 
          this.handleSave() 
        } 
      },
      { 
        ...this.reportsComponentHelper.exportAction, 
        isMenuButton: true,
        options: this.isDisableExportPdf() ? Constants.exportOptionsNoPdf : Constants.exportOptions,
        onClick: (data: any[], key: string | null) => {
          this.exportReport(key ?? '')
        }
      }
    ]
  }

  private initializeSelectionActions() {
    if (this.licensePoolHelperService.canManagePools()) {
      this.selectionActions = [
        {
          text: 'licenses_ManageCosts',
          buttonType: 'tertiary',
          icon: 'settings',
          visibility: 'custom',
          isVisible: (selectedRows: any) => this.v2TabSelected === Constants.menuIds.licensePoolCostReport,
          onClick: (data: any) => {
            this.rightPanelService
              .open({
                type: ManageCostsComponent,
                data: {
                  configuration: this.getManageLicenseAllocationConfiguration(data),
                  width: '100%',
                },
              })
              .afterClosed()
              .subscribe((f: boolean) => {
                if (f) {
                  this.isUpdateInProgress = true
                }
              })
          },
        },
      ]
    }

    this.clientSelectionActions = [
      {
        isMenuButton: true,
        text: 'common_Export',
        buttonType: 'tertiary',
        icon: 'download',
        visibility: 'noRow',
        options: Constants.exportOptionsNoPdf,
        onClick: (data: any[], key: string | null) => {
          this.gridClient.exportClientReport(this.reportTitle!, key || '')
        },
      },
    ]
  }

  private initializeGridDefinitions() {
    this.detailCellRenderParamsLp = {
      masterDetailSelection: (masterGridApi: GridApi) => {
        masterGridApi.addEventListener('rowGroupOpened', (event: any) => {
          if (event.expanded && event.node.selected) {
            this.selectDetailRows(masterGridApi, event)
          }
        })

        masterGridApi.addEventListener('rowSelected', (event: any) => {
          if (event.node.selected && event.node.expanded) {
            this.selectDetailRows(masterGridApi, event)
          } else if (!event.node.selected) {
            this.deselectDetailRow(masterGridApi, event)
          }
        })
      },
      onChildRowSelected: (event: RowSelectedEvent, masterGridApi: GridApi, rowId: string) => {
        if (event.node.isSelected() && event.node.id) {
          if (masterGridApi.getRowNode(rowId.replace('detail_', ''))?.selectable) {
            masterGridApi.getRowNode(rowId.replace('detail_', ''))?.setSelected(true)
          }
        } else if (!event.node.isSelected() && !masterGridApi.getRowNode(rowId.replace('detail_', ''))?.isSelected()) {
          return
        }

        const nodes = masterGridApi.getSelectedNodes().filter((r) => r.rowIndex !== +rowId.replace('detail_', ''))
        nodes.forEach((n: IRowNode<any>) => {
          const detail = masterGridApi.getDetailGridInfo('detail_' + n.id)
          detail?.api?.forEachNode((n1: IRowNode<any>, i: number) => {
            if (n1.id === event.node.id) {
              n.setSelected(event.node.isSelected() || false)
            }
          })
        })
      },
    }

    this.licensePoolsCostGridDefinition = {
      entity: 'licensePoolsCostGridDefinition',
      title: 'notused',
      fields: ['Name', 'Users', 'ActiveUsers', 'TotalAssignedCost', 'TotalConsumedCost', 'Currency'],
      defaultHiddenFields: [],
      sortField: 'Name',
      sortOrder: 'asc',
      verb: 'post' as Verb,
      isOnlineUsersType: true,
      responseItemProp: 'pools',
      detailCellRenderer: 'customDetail',
      detailCellRenderParams: {
        getTableData: (data: any) => data.licensePoolInfos.sort((a: any, b: any) => a.sku.localeCompare(b.sku)),
        columnDefs: [
          { field: 'sku', type: 'string', name: 'sku', translate: 'Licenses' },
          { field: 'assigned', type: 'int', name: 'assigned', translate: 'Assigned' },
          {
            field: 'assignedCost',
            type: 'double',
            name: 'assignedCost',
            translate: 'AssignedCost',
            valueFormatter: (params: any): string => Helpers.round(params.value),
          },
          { field: 'consumed', type: 'int', name: 'consumed', translate: 'Consumed' },
          {
            field: 'consumedCost',
            type: 'double',
            name: 'consumedCost',
            translate: 'ConsumedCost',
            valueFormatter: (params: any): string => Helpers.round(params.value),
          },
        ],
      },
      groupedActions: this.groupedActions,
      selectionActions: this.selectionActions,
      filterActions: this.filterActions,
    }
  }

  private exportReport(key: string) {
    this.reportExportService
      .exportGeneric(
        this.getExportedItems,
        {
          fields: [],
          queryRequest: {
            sort: '',
            sortOrder: 'asc',
          },
          totalCount: 0,
        },
        this.reportDefinition.verb,
        key,
        this.reportTitle || '',
        this.exportUrl
      )
      .subscribe()
  }

  private getExportedItems = (params: ApiDataParameters & { asyncExport: boolean; reportNameContainer: string }, format: string) =>
    this.reportsService.getExportedData(this.exportUrl, this.exportVerb, params, format) || of({} as any)

  private setSavedReportsParams() {
    if (this.savedReport) {
      this.savedReportFilterSortModel = {
        filterModel: this.savedReport.request.filters,
        sortModel: { sortField: this.savedReport.request.sort, sortOrder: this.savedReport.request.sortOrder },
      }
    } else {
      this.savedReportFilterSortModel = {
        filterModel: null,
        sortModel: {
          sortField: 'Name',
          sortOrder: 'asc',
        },
      }
    }
  }

  private setIsFavorite(favorites: any) {
    if (this.menu) {
      if (this.savedReportId) {
        this.menu.isFavorite = !!favorites.favoriteSavedReports.find(
          (x: any) =>
            this.menu?.route === x.route.replace(`${Constants.savedReportString}${this.savedReportId}`, '') &&
            this.savedReportId === x.savedReportId
        )
      } else {
        this.menu.isFavorite = !!favorites.favoriteMenus.find((x: any) => this.menu?.id === x)
      }
    }
  }

  private getFilteredSavedReports(res: SavedReport[], reportType: string, reportOnly: boolean) {
    return res.filter((r: SavedReport) => {
      const apiUrlLower = r.apiUrl.toLowerCase()
      const hasReportType = apiUrlLower.indexOf('/' + reportType) >= 0
      const hasReportOnly = apiUrlLower.indexOf('reportonly') >= 0

      if (reportOnly) {
        return hasReportType && hasReportOnly
      } else {
        return hasReportType && !hasReportOnly
      }
    })
  }

  private getUrlReport(report: string) {
    const reportInput = report.toLowerCase()
    let reportOutput = reportInput

    if (reportInput === LicensePoolFilterType.OverAllocatedOnPool.toLowerCase()) {
      reportOutput = LicensePoolFilterFullReportType.FullReportGroupedByPools
    }
    if (reportInput === LicensePoolFilterType.OutOfCapacityOnInventory.toLowerCase()) {
      reportOutput = LicensePoolFilterFullReportType.FullReportGroupedBySKU
    }
    return reportOutput.toLowerCase()
  }

  private loadSavedReports() {
    if(!this.canSeeCustomReportsBySku) {
      return
    }

    const reportType = this.isPoolReportV3Selected()
      ? this.getUrlReport(this.lpReportModeV3.toLowerCase())
      : this.v2ReportTypes[this.v2TabSelected].type.toLowerCase()

    const reportOnly = this.isPoolReportV3Selected() ? this.licensePoolConfiguration?.reportOnly || false : false

    this.reportsService
      .getViews({ pageSize: 50, pageNumber: 1, route: this.menu?.route, sortOrder: 'asc', sortField: 'reportName' })
      .subscribe((res) => {
        this.savedReports = this.getFilteredSavedReports(res, reportType, reportOnly)
      })
  }

  private getPath(): string[] {
    return Helpers.getPath(this.menu?.parent, this.translateHelper)
  }

  private isPoolReportV3Selected(): boolean {
    return this.tabSelectedIndex === 0 && this.canSeeTab(this.menuIds.licensePoolReport)
  }

  private isDisableExportPdf(): boolean {
    return this.v2TabSelected === Constants.menuIds.licensePoolCostReport || this.v2TabSelected === Constants.menuIds.licensePoolReport
  }

  private isLegacyCustomReport() {
    return !!this.savedReport?.apiUrl.includes('/pool/')
  }

  private loadInitialData() {
    this.licenseService
      .arePermissionsLoaded()
      .pipe(
        filter((x) => !!x),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        if (this.savedReport) {
          this.initializeSavedReportPageSettings(this.savedReport)
        }

        combineLatest([
          this.router.events.pipe(
            filter((eventRes: any) => eventRes instanceof NavigationEnd),
            startWith(this.router)
          ),
          this.licenseService.getLicensePoolsGroupByName(
            { pageNumber: 1, pageSize: 1, sort: 'Name' },
            this.v2ReportTypes[Constants.menuIds.licensePoolReport].type,
            this.storage.selectedOrganization?.id
          ),
          this.licenseService.getAccountSkuDetails(),
          this.administrationsService.getLicensePoolConfiguration(),
        ])
          .pipe(takeUntil(this.destroyed$))
          .subscribe(([routerEvent, licensePoolsRes, skus, configuration]) => {
            this.route = routerEvent.url

            this.setSavedReportsParams()
            this.setTitles()
            this.loadMenuInfo()

            const poolLicenses =
              licensePoolsRes.pools?.length && licensePoolsRes.pools[0].licensePoolInfos ? licensePoolsRes.pools[0].licensePoolInfos : []
            this.targetLicenses = poolLicenses.map((poolLicense: any) => ({
              skuId: poolLicense.skuId,
              sku: poolLicense.sku,
              notAssigned: poolLicense.notAssigned,
            }))

            this.licensePoolConfiguration = configuration?.licensePoolConfiguration
            if (!this.licensePoolConfiguration) {
              this.noLpConfigured = true
            }

            if (this.licensePoolConfiguration?.reportOnly) {
              this.targetLicensesReportOnly = skus.map((sku: AccountSkuDetails) => ({
                skuId: sku.accountSkuId,
                sku: sku.sku,
                notAssigned: sku.remainingUnits,
              }))

              if (this.lpReportModeV3 === LicensePoolFilterType.OverAllocatedOnPool || this.lpReportModeV3 === LicensePoolFilterType.OutOfCapacityOnInventory) {
                this.store.dispatch(Actions.setLeftPanelReport({ report: LicensePoolFilterFullReportType.FullReportMasterData }))
              }
            }

            this.advancedFiltersConfiguration.licenses = this.licensePoolConfiguration?.reportOnly
              ? [...this.targetLicensesReportOnly]
              : [...this.targetLicenses]
          })
      })
  }

  private addBreadcrumbReload() {
    this.breadcrumbService.addComponent({
      type: ButtonComponent,
      data: {
        buttonType: 'tertiary',
        text: this.translateHelper.instant('common_Reload'),
        leftIcon: 'autorenew',
        size: 'tiny',
      },
      events: {
        clicked: (e: any) => {
          this.breadcrumbReload()
        },
      },
    })
  }

  private breadcrumbReload() {
    if (this.isUpdateInProgress || this.operatorHasNoLPAssigned) {
      this.isUpdateInProgress = false
      this.operatorHasNoLPAssigned = false
    } else {
      this.reloadGrid()
    }
  }

  private reloadGrid() {
    if (this.isPoolReportV3Selected()) {
      this.refreshV3Grid.next(true)
    } else {
      this.getGrid()?.refresh()
    }
  }

  private getSavedReportType(savedReport: SavedReport): LicensePoolFilterFullReportType | LicensePoolFilterType.OverAllocatedOnPool | LicensePoolFilterType.OutOfCapacityOnInventory {
    if (savedReport.apiUrl.toLowerCase().includes(LicensePoolFilterFullReportType.FullReportGroupedByPools.toLowerCase())) {
      if (savedReport.request?.advancedFilters?.displayOnlyOverAllocated) {
        return LicensePoolFilterType.OverAllocatedOnPool
      }
      return LicensePoolFilterFullReportType.FullReportGroupedByPools
    } else if (savedReport.apiUrl.toLowerCase().includes(LicensePoolFilterFullReportType.FullReportGroupedBySKU.toLowerCase())) {
      if (savedReport.request?.advancedFilters?.displayOnlyLowOnInventory) {
        return LicensePoolFilterType.OutOfCapacityOnInventory
      }
      return LicensePoolFilterFullReportType.FullReportGroupedBySKU
    }
    return LicensePoolFilterFullReportType.FullReportMasterData
  }
}
