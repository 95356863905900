import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { RootState } from '@app/store/RootState.type';
import { Suggestion } from '@coreview/coreview-components';
import { TranslateHelper } from '@coreview/coreview-library';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import * as Actions from '@app/store/license-pool-center/license-pool-center.actions'
import * as Selectors from '@app/store/license-pool-center/license-pool-center.selectors'

@Component({
  selector: 'app-msp-multitenant-dashboard-left-panel',
  templateUrl: './msp-multitenant-dashboard-left-panel.component.html',
  styleUrls: ['./msp-multitenant-dashboard-left-panel.component.sass']
})
export class MspMultitenantDashboardLeftPanelComponent implements OnDestroy {
  @Output() reportTypeChange = new EventEmitter()

  isOpenPanel$ = this.store.select(Selectors.selectLeftPanelIsOpen)

  reportType: Suggestion[] = [
    {
      value: MspMultiTenantDashboardType.GroupedByTenant,
      displayValue: this.translateHelper.instant('common_GroupedByTenant'),
    },
    {
      value: MspMultiTenantDashboardType.GroupedByPlaybook,
      displayValue: this.translateHelper.instant('common_GroupedByPlaybook'),
    },
  ]
  
  selectedReportType = this.reportType[0].value

  private readonly destroyed$: Subject<boolean> = new Subject()

  constructor(private readonly translateHelper: TranslateHelper, private readonly store: Store<RootState>) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  openChangePanelCustomReport(isOpen: boolean) {
    this.store.dispatch(Actions.setLeftPanelIsOpen({ isOpen }))
  }

  onChangeReportTypeSelection(
    event:
      | MspMultiTenantDashboardType.GroupedByTenant
      | MspMultiTenantDashboardType.GroupedByPlaybook
  ) {
    this.selectedReportType = event
    this.reportTypeChange.emit(event)
  }
}

export enum MspMultiTenantDashboardType {
  GroupedByTenant = 'GroupedByTenant',
  GroupedByPlaybook = 'GroupedByPlaybook',
}