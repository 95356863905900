import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SharedHelperService } from '@app/shared/shared.helper.service'
import { Observable, of } from 'rxjs'
import { pluck } from 'rxjs/operators'
import { MenuHelp } from '../models/menu-help'
import { NavItem } from '@coreview/coreview-library/models/nav-item'
import { Playbook } from '../models/playbook'
import { ApiclientService } from './apiclient.service'
import { Organization } from '../models/Organization'
import { intersection } from 'lodash-es'

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(
    private apiclient: ApiclientService,
    private httpClient: HttpClient,
    private sharedHelperService: SharedHelperService,
    private apiclientService: ApiclientService,
    private location: Location
  ) {}

  getMenusAll(): Observable<{menuAll: Array<NavItem>, menuFiltered:Array<NavItem>}> {
    return this.httpClient.get<{menuAll: Array<NavItem>, menuFiltered:Array<NavItem>}>(`${this.apiclient.basePortalApiUrl}/v2/menus/all/?metadata=false`, { withCredentials: true })
  }

  getFavorites(): Observable<any> {
    return this.httpClient.get(`${this.apiclient.basePortalApiUrl}/v2/menus/favorites?metadata=false`, { withCredentials: true })
  }

  getMenuHelp(): Observable<MenuHelp[]> {
    return this.httpClient
      .get(`${this.apiclient.basePortalApiUrl}/v2/menus/help?metadata=true`, { withCredentials: true, params: { pageSize: 10 } })
      .pipe(pluck('items'))
  }

  getPlaybookItems(): Observable<Playbook[]> {
    if (this.sharedHelperService.isAdmin() || this.sharedHelperService.checkRoles(['playbookAdmin', 'playbookManager', 'playbookGlobalViewOnly'])) {
      const url = this.apiclient.basePortalApiUrl + '/playbook/playbooks'
      return this.httpClient.get<any>(url, { params: { onlyAllowed: true }, withCredentials: true })
    } else {
      return of([])
    }
  }

  getBottomMenuByOrg(org: Organization | undefined): NavItem[] {
    if (org?.portalSkus &&  intersection(org.portalSkus, ['CSKU:PPMSP', 'PP:MULTITENANT']).length > 0) {
      return [
        {
          title: 'APPS',
          icon: 'ms-Icon ms-Icon--AppIconDefault',
          id: 100000,
          sortId: 100000,
          accessLevel: 'User',
          children: [
            {
              title: 'CoreView',
              icon: 'cv-logo-icon',
              id: 1000001,
              sortId: 1000001,
              accessLevel: 'User',
              route: '',
              children: [],
              active: true,
            },
            {
              title: org.portalSkus.includes('CSKU:PPMSP') ? 'PartnerApp' : 'MultiTenantHub',
              icon: 'ms-Icon ms-Icon--Org',
              id: 1000002,
              sortId: 1000002,
              accessLevel: 'User',
              route: this.apiclientService.getPartnerPortalUrl(this.location.origin),
              isExternalUrl: true,
              children: [],
            },
          ],
        },
      ]
    }

    return []
  }
}
