import { ToastService } from '@coreview/coreview-components'
import { TranslateHelper } from '@coreview/coreview-library'

export class ToastServiceHelpers {

  static showTranslatedSuccessToast(toastService: ToastService, translateHelper: TranslateHelper, message: string): void {
    toastService.open({
      id: 'success',
      variant: 'success',
      title: translateHelper.instant('common_Success'),
      message: message,
    })
  }
  static showSuccessToast(toastService: ToastService, translateHelper: TranslateHelper, message: string): void {
    ToastServiceHelpers.showTranslatedSuccessToast(toastService, translateHelper, translateHelper.instant(message))
  }

  static showTranslatedErrorToast(toastService: ToastService, translateHelper: TranslateHelper, message: string): void {
    toastService.open({
      id: 'error',
      variant: 'error',
      title: translateHelper.instant('common_Error'),
      message: message,
    })
  }

  static showErrorToast(toastService: ToastService, translateHelper: TranslateHelper, message: string): void {
    ToastServiceHelpers.showTranslatedErrorToast(toastService, translateHelper, translateHelper.instant(message))
  }

  static saveSuccessToast(toastService: ToastService, translateHelper: TranslateHelper, objectType: string, objectName: string): void {
    ToastServiceHelpers.showTranslatedSuccessToast(
      toastService,
      translateHelper,
      translateHelper.combineTranslations('generic_ObjectSuccessfullySaved', objectType, objectName)
    )
  }

  static saveErrorToast(toastService: ToastService, translateHelper: TranslateHelper, objectType: string, objectName: string): void {
    ToastServiceHelpers.showTranslatedErrorToast(
      toastService,
      translateHelper,
      translateHelper.combineTranslations('generic_ObjectCouldNotBeSaved', objectType, objectName)
    )
  }

  static deleteSuccessToast(toastService: ToastService, translateHelper: TranslateHelper, objectType: string, objectName: string): void {
    ToastServiceHelpers.showTranslatedSuccessToast(
      toastService,
      translateHelper,
      translateHelper.combineTranslations('generic_ObjectSuccessfullyRemoved', objectType, objectName)
    )
  }

  static deleteErrorToast(toastService: ToastService, translateHelper: TranslateHelper, objectType: string, objectName: string): void {
    ToastServiceHelpers.showTranslatedErrorToast(
      toastService,
      translateHelper,
      translateHelper.combineTranslations('generic_ObjectCouldNotBeRemoved', objectType, objectName)
    )
  }

  static deleteSuccessPluralToast(toastService: ToastService, translateHelper: TranslateHelper, objectType: string): void {
    ToastServiceHelpers.showTranslatedSuccessToast(
      toastService,
      translateHelper,
      translateHelper.combineTranslations('generic_ObjectSuccessfullyRemoved_Plural', objectType)
    )
  }

  static deleteErrorPluralToast(toastService: ToastService, translateHelper: TranslateHelper, objectType: string): void {
    ToastServiceHelpers.showTranslatedErrorToast(
      toastService,
      translateHelper,
      translateHelper.combineTranslations('generic_ObjectCouldNotBeRemoved_Plural', objectType)
    )
  }
}
