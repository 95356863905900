import { EnhancedJsonFormData, EnhancedPropertyFormDescriptor } from './../../../../shared/models/enhanced-json-form-data'
/* eslint-disable @typescript-eslint/naming-convention */
import { Recurrence } from './../../../../shared/components/recurrence/recurrence.component'
import { ReportDefinition } from '@app/core/models/ReportDefinition'
import { PlaybookService } from './../../services/playbook.service'
import { IBeforeClose, RightPanelRef } from '@app/core/services/right-panel.service'
import {
  DialogComponent,
  Suggestion,
  ToastService,
  ExtendedRichInputComponent,
  NewExtendedInputComponent,
} from '@coreview/coreview-components'
import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import dayjs from 'dayjs'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { Observable, of, Subject } from 'rxjs'
import { FormBuilderComponent } from '@coreview/coreview-dynamoforms'
import { DynamoFormHelperService } from '@app/shared/utilities/dynamo-form.helper.service'
import { Policy, RemediationType, ScheduleType, ThresholdType } from '@app/core/models/playbook'
import { TranslateHelper } from '@coreview/coreview-library'
import { MatDialog } from '@angular/material/dialog'
import isEqual from 'lodash-es/isEqual'
import { WorkflowPreviewDialogComponent } from '../workflow-preview-dialog/workflow-preview-dialog.component'
import { generateCronExpression } from '@app/core/utils/manage-payload'
import { ReportsService } from '@app/core/services/reports.service'
import { PolicyService } from '../../services/policy.service'
import { QueryFilter2 } from '@app/core/models/QueryFilter'
import { Store } from '@ngrx/store'
import { RootState } from '@app/store/RootState.type'
import { ApiDataParameters } from '@app/core/models/ApiDataParameters'
import { selectOnlineuserColumns } from '@app/store/onlineuser-columns/onlineuser-columns.selectors'
import { cloneDeep, uniq, values, first as lodashFisrt } from 'lodash-es'
import { selectReportsDefinition } from '@app/store/reports/reports-definition.selectors'
import { ApiclientService } from '@app/core/services/apiclient.service'
import { LocalstorageService } from '@app/core/services/localstorage.service'
import { froalaToolbarButtons } from '@app/modules/workflow/features/workflow-builder/models/worflow-froala-buttons'
import { Constants } from '@app/shared/utilities/constants'

@Component({
  selector: 'app-edit-policy',
  templateUrl: './edit-policy.component.html',
  styleUrls: ['./edit-policy.component.sass'],
})
export class EditPolicyComponent extends IBeforeClose implements OnInit, OnDestroy {
  @ViewChildren(FormBuilderComponent)
  set formBuilderComp(formBuilders: QueryList<FormBuilderComponent>) {
    if (formBuilders) {
      this.formBuilderPolicy = formBuilders.first
      this.formBuilderWorkflow = formBuilders.last
    }
  }
  thresholdOperations: Suggestion[] = [
    { value: '<', displayValue: this.translateHelper.instant('common_LessThan') },
    { value: '<=', displayValue: this.translateHelper.instant('common_LessThanOrEqual') },
    { value: '>', displayValue: this.translateHelper.instant('common_GreaterThan') },
    { value: '>=', displayValue: this.translateHelper.instant('common_GreaterThanOrEqual') },
  ]

  scheduleOptions: Suggestion[] = [
    { value: 'None', displayValue: this.translateHelper.instant('playbook_notSchedule') },
    { value: 'Scheduled', displayValue: this.translateHelper.instant('playbook_schedule') },
  ]

  sendReportSuggestions: Suggestion[] = [
    { value: 'IsEmpty', displayValue: this.translateHelper.instant('reports_IsEmpty') },
    { value: 'IsNotEmpty', displayValue: this.translateHelper.instant('reports_IsNotEmpty') },
    { value: 'Always', displayValue: this.translateHelper.instant('reports_Always') },
  ]

  sendReportFormatSuggestions: Suggestion[] = [
    { value: 'XLSX', displayValue: 'Excel' },
    { value: 'CSV', displayValue: 'CSV' },
    { value: 'PDF', displayValue: 'PDF' },
  ]

  userSuggestions!: Suggestion[]
  selectedSchedule!: string

  formBuilderPolicy?: FormBuilderComponent
  formBuilderWorkflow?: FormBuilderComponent

  jsonFormDataPolicy!: EnhancedJsonFormData | undefined
  jsonFormDataWorkflow!: EnhancedJsonFormData | undefined
  startingScheduleValues!: {
    startDatetime: dayjs.Dayjs | null
    endDatetime: dayjs.Dayjs | null
    cronExpression: string
  }

  missingRemediationPermission = Constants.MissingRemediationPermission
  policy!: Policy
  readMode = false
  isPolicyProvided = true
  policyId!: string
  patchPolicy: any
  reportDefinition!: ReportDefinition
  isSaved = false
  changedRemediation = false
  thresholdTypes: Suggestion[] = [
    { value: ThresholdType.IsNumber, displayValue: this.translateHelper.instant('playbook_ThresholdAsNumber') },
    { value: ThresholdType.IsPercentage, displayValue: this.translateHelper.instant('playbook_ThresholdAsPercentage') },
  ]

  cronExpression!: string | null | undefined

  isDateSetFromDb = false

  formPolicy: UntypedFormGroup = this.fb.group({})
  formWorkflow: UntypedFormGroup = this.fb.group({})
  formSendReport: UntypedFormGroup = this.fb.group({
    sendReport: new UntypedFormControl('IsNotEmpty'),
    format: new UntypedFormControl('XLSX'),
    emailBody: new UntypedFormControl(),
    recipients: new UntypedFormControl([]),
  })
  formSendNotification: UntypedFormGroup = this.fb.group({
    notificationRecipients: new UntypedFormControl([]),
  })
  formSchedule: UntypedFormGroup = this.fb.group({
    recurrenceData: new UntypedFormControl({
      recurrence: 'recurring',
      particularScheduleWeeks: true,
      frequency: '1',
      interval: 1,
      weekdaySelected: null,
      ordinal: null,
      dayMonth: null,
      weekdayMonthSelected: null,
      startDateTime: dayjs(),
      endDateTime: null,
      monthControl: null,
      starting: 'specificTime',
    }),
  })

  formEmail = this.fb.group({
    emailAddressWhenWorkflowFails: new UntypedFormControl(undefined, Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)),
  })

  policyToShow!: Policy
  lastRequest!: any
  gridDefinition!:
    | (ReportDefinition & { apiUrl: string } & { since?: string } & { to?: string } & { propertyId?: string } & {
        reportDefinitionId?: string
      })
    | undefined
  treeFilters: QueryFilter2 | undefined
  rangeFilter!: { since?: string; to?: string; days?: number } | undefined
  forms = [this.formPolicy, this.formWorkflow, this.formSendReport, this.formSendNotification, this.formEmail]
  hadSchedule = false
  isNotReset = true

  readonly RemediationType = RemediationType
  private destroyed$ = new Subject<boolean>()

  constructor(
    private toastService: ToastService,
    private translateHelper: TranslateHelper,
    private fb: UntypedFormBuilder,
    private dynamoFormsHelper: DynamoFormHelperService,
    private rightPanelRef: RightPanelRef,
    private reportsService: ReportsService,
    private dialog: MatDialog,
    private playbookService: PlaybookService,
    private policyService: PolicyService,
    private store: Store<RootState>,
    private apiClient: ApiclientService,
    private storage: LocalstorageService
  ) {
    super()
  }

  ngOnInit(): void {
    if (this.isPolicyProvided) {
      this.initData()
    } else {
      this.playbookService.getPolicy(this.policyId, false).subscribe((resp) => {
        this.policy = resp
        if (this.patchPolicy) {
          this.policy = { ...this.policy, ...this.patchPolicy }
        }
        this.initData()
      })
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }

  onPolicySettingsReady(): void {
    if (this.policy.policySettings) {
      this.formPolicy.patchValue(this.policy.policySettings)
      this.formPolicy.markAsPristine()
    }
  }

  onWorkflowSettingsReady(): void {
    if (this.policy.workflowSettings) {
      this.formWorkflow.patchValue(this.policy.workflowSettings)
      this.formWorkflow.markAsPristine()
      if (!this.policy.isWorkflowEnabled || !this.policy.isPolicyEnabled || !this.policy.canEnableRemediation) {
        this.formWorkflow.disable()
      }
    }
  }

  checkIfPossibleSave(): boolean {
    const isAnyFormInvalid = this.forms.some((form) => !form.disabled && !form.valid)
    const isScheduleInvalid = this.selectedSchedule === 'Scheduled' && !!this.formSchedule.get('recurrenceData')?.invalid
    const isThresholdValueInvalid = (!this.policy.thresholdValue && this.policy.thresholdValue !== 0) || this.policy.thresholdValue < 0
    const isThresholdInvalid =
      this.policy.policyType !== 'EventBased' && (!this.policy.thresholdType || !this.policy.thresholdOperator || isThresholdValueInvalid)
    return isAnyFormInvalid || isScheduleInvalid || isThresholdInvalid || this.isPolicyUnchanged()
  }

  save(): void {
    this.policy.policySettings = this.formPolicy.value
    this.policy.workflowSettings = this.policy.javascriptFunctionForWorkflow
      ? Function('data', 'policySettings', this.policy.javascriptFunctionForWorkflow)(this.formWorkflow.value, this.formPolicy.value)
      : this.formWorkflow.value
    if (this.policy.remediationType === RemediationType.SendNotification) {
      this.policy.notificationRecipients = this.formSendNotification
        .get('notificationRecipients')
        ?.getRawValue()
        .map((x: Suggestion) => x.value)
    }
    if (this.policy.remediationType === RemediationType.ScheduleReport) {
      this.policy.scheduleReport = this.policyService.createScheduledReport(
        {
          recurrenceData: this.formSchedule.get('recurrenceData')?.getRawValue() as Recurrence,
          remediationType: RemediationType.ScheduleReport,
          ...this.formSendReport.getRawValue(),
        },
        this.lastRequest
      )
    } else if (this.selectedSchedule === 'Scheduled') {
      const formValue = this.formSchedule.get('recurrenceData')?.getRawValue() as Recurrence
      this.policy.schedule = {
        startDatetime: formValue.startDateTime?.toDate(),
        endDatetime: formValue.endDateTime?.toDate() ?? null,
        cronExpression: this.cronExpression ?? '',
      }
    } else {
      this.policy.schedule = null
    }
    if (this.policy.javascriptFunctionForFilter) {
      this.policy.reportFilters = Function(
        'filters',
        'data',
        'workflowSettings',
        this.policy.javascriptFunctionForFilter
      )(this.policy.reportFilters, this.formPolicy.value, this.formWorkflow.value)
    }
    this.policy.emailAddressWhenWorkflowFails = this.formEmail.get('emailAddressWhenWorkflowFails')?.value
    this.policy.isThresholdEnabled = true
    this.playbookService.updatePolicy(this.policy).subscribe(
      () => {
        this.isSaved = true
        this.rightPanelRef.close(this.policy.id)
        if (this.policy?.reportUrl?.includes('api/onlineusers')) {
          this.dialog.open(DialogComponent, {
            width: '50%',
            data: {
              title: this.translateHelper.instant('common_SaveChange'),
              text: this.translateHelper.instant('playbook_OnlineUsersUpdated'),
              primaryText: this.translateHelper.instant('common_Continue'),
              type: 'success',
              centered: true,
            },
          })
        }
      },
      (err) =>
        this.toastService.open({
          id: 'error',
          variant: 'error',
          title: this.translateHelper.instant('common_Error'),
          message: this.translateHelper.combineTranslations('generic_ObjectCouldNotBeModified', 'playbook_Policy'),
        })
    )
  }

  resetPolicy(): void {
    this.formBuilderPolicy?.resetForm()
    this.formBuilderWorkflow?.resetForm()
    this.formSendReport.reset()
    this.formSendReport.disable()
    this.formSendNotification.reset()
    this.formSendNotification.disable()
    this.formEmail.reset()
    this.formEmail.disable()
    this.isNotReset = false
    this.policy.isThresholdEnabled = false
    this.policy.isWorkflowEnabled = false
    this.policy.sendEmailWhenWorkflowFails = false
    if (this.policy.remediationType === RemediationType.Workflow) {
      this.policy.scheduleType = ScheduleType.None
    }
    this.policy.schedule = null
    setTimeout(() => {
      this.initData()
    }, 100)
  }

  beforeClose(): Observable<boolean> {
    if ((this.areFormsPristine() && this.isPolicyUnchanged()) || this.isSaved || this.readMode) {
      return of(true)
    }
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      data: {
        title: this.translateHelper.instant('common_Confirmation'),
        text: this.translateHelper.instant('common_ConfirmAction'),
        primaryText: this.translateHelper.instant('common_DiscardChanges'),
        secondaryText: this.translateHelper.instant('common_Cancel'),
        type: 'alert',
        centered: false,
      },
    })
    return dialogRef.afterClosed()
  }

  areFormsPristine = () => this.forms.every((form) => form.pristine)

  isPolicyUnchanged(): boolean {
    const formValue = this.formSchedule.get('recurrenceData')?.getRawValue() as Recurrence
    let isScheduleUnchanged = true
    const isPolicyUnchanged = isEqual(this.policy, this.policyToShow)
    if (this.policy.scheduleType !== ScheduleType.None && (this.policy.schedule || this.policy.scheduleReport)) {
      const actualSchedule = {
        startDatetime: dayjs(formValue.startDateTime),
        endDatetime: dayjs(formValue.endDateTime),
        cronExpression: this.cronExpression ?? '',
      }
      if (this.policy?.policyType !== 'EventBased' && this.isDateSetFromDb && formValue.endDateTime !== null) {
        isScheduleUnchanged =
          this.startingScheduleValues.cronExpression === actualSchedule.cronExpression &&
          !!this.startingScheduleValues.startDatetime?.isSame(actualSchedule.startDatetime) &&
          !!this.startingScheduleValues.endDatetime?.isSame(actualSchedule.endDatetime)
      } else {
        isScheduleUnchanged =
          this.startingScheduleValues.cronExpression === actualSchedule.cronExpression &&
          !!this.startingScheduleValues.startDatetime?.isSame(actualSchedule.startDatetime)
      }
    }
    return this.areFormsPristine() && isPolicyUnchanged && isScheduleUnchanged && !this.changedRemediation
  }

  closePanel(): void {
    this.rightPanelRef.close()
  }

  validateThreshold(event: any) {
    const value = parseInt(event.target.value, 10)
    const clampedValue = this.policy.thresholdType === 'Percentage' ? Math.min(Math.max(value, 0), 100) : Math.max(value, 0)
    event.target.value = clampedValue
    this.policy.thresholdValue = clampedValue
  }

  getThresholdPattern(): RegExp {
    if (this.policy.thresholdType === ThresholdType.IsPercentage) {
      return /^(100|[1-9]?\d)$/
    }
    return /^\d+$/
  }

  scheduleChanged(selectValue: string): void {
    if (selectValue === ScheduleType.Scheduled) {
      this.formSchedule.get('recurrenceData')?.enable()
    } else {
      this.formSchedule.get('recurrenceData')?.disable()
    }
    this.policy.scheduleType = selectValue as ScheduleType
  }

  changePolicyEnablement() {
    this.policy.isPolicyEnabled = !this.policy.isPolicyEnabled
    if (this.policy.isPolicyEnabled) {
      this.formPolicy.enable()
      if (this.policy.isWorkflowEnabled) {
        this.formWorkflow.enable()
        this.formSendReport.enable()
        this.formSendNotification.enable()
        this.formSchedule.get('recurrenceData')?.enable()
      }
    } else {
      this.formPolicy.disable()
      if (this.policy.isWorkflowEnabled) {
        if (this.policy.remediationType !== RemediationType.ScheduleReport) {
          this.selectedSchedule = ScheduleType.None
          this.policy.scheduleType = ScheduleType.None
        }
        this.formWorkflow.disable()
        this.formSendReport.disable()
        this.formSendNotification.disable()
        this.formSchedule.get('recurrenceData')?.disable()
      }
    }
  }

  usersInputChanged(input: string) {
    if (input?.trim()?.length >= 3) {
      this.reportsService
        .getUserSuggestions({
          searchedString: input,
          excludeItems: this.formSendReport.controls.recipients?.value?.map((x: Suggestion) => x.value),
        })
        .subscribe((res) => {
          this.userSuggestions = res.map((x) => ({ value: x.userPrincipalName, displayValue: x.userPrincipalName }))
        })
    } else {
      this.userSuggestions = []
    }
  }

  remediationEnablementChanged() {
    this.policy.isWorkflowEnabled = !this.policy.isWorkflowEnabled
    this.policy.scheduleType = this.policy.isWorkflowEnabled ? ScheduleType.Scheduled : ScheduleType.None
    this.refreshForms()
  }

  sendEmailWhenWorkflowFailsChanged() {
    this.policy.sendEmailWhenWorkflowFails = !this.policy.sendEmailWhenWorkflowFails
    const control = this.formEmail.get('emailAddressWhenWorkflowFails')
    if (this.policy.sendEmailWhenWorkflowFails) {
      control?.enable()
      control?.addValidators(Validators.required)
    } else {
      control?.disable()
    }
    control?.updateValueAndValidity()
  }
  openWorkflow() {
    this.dialog.open(WorkflowPreviewDialogComponent, {
      width: '70%',
      height: '70%',
      data: { workflowId: this.policy.workflowId, isForPlaybook: true },
    })
  }

  getItems = () => (params: ApiDataParameters) => {
    const entity = this.policy.reportDefinition.entity
    if (entity === 'Audit' && !params?.days && !params?.since && !params?.to) {
      params = { ...params, days: '7' }
    }
    if (this.gridDefinition?.urlParameters) {
      Object.assign(params, this.gridDefinition.urlParameters)
    }
    return {
      items: this.reportsService.getData(this.gridDefinition?.apiUrl ?? '', this.gridDefinition?.verb ?? 'get', params),
      cols: this.gridDefinition?.isOnlineUsersType
        ? this.store.select(selectOnlineuserColumns).pipe(map((cols) => cloneDeep(cols)))
        : of([]),
    }
  }

  private refreshForms() {
    if (this.policy.isWorkflowEnabled) {
      this.forms.forEach((form) => form.enable())
      if (this.policy.remediationType === RemediationType.ScheduleReport) {
        this.formSchedule.get('recurrenceData')?.enable()
      }
    } else {
      if (this.policy.remediationType !== RemediationType.ScheduleReport) {
        this.selectedSchedule = ScheduleType.None
        this.policy.scheduleType = ScheduleType.None
      }
      //FormPolicy needs to be excluded
      this.forms.slice(1).forEach((form) => form.disable())
      if (this.policy.remediationType === RemediationType.ScheduleReport) {
        this.formSchedule.get('recurrenceData')?.disable()
      }
    }
    if (this.changedRemediation) {
      this.forms.forEach((form) => form.markAsDirty())
    }
  }

  private initData(): void {
    if (!this.policy.canEdit) {
      this.readMode = true
      this.policy.isPolicyEnabled = false
    }

    this.preparePolicyData()
    this.subscribeToRecurrenceDataChanges()
    if (this.policy.policySchema) {
      this.setJsonFormDataPolicy()
    }
    if (this.policy.workflowSchema) {
      this.setJsonFormDataWorkflow()
    }
    this.prepareHiddenGrid()
    this.prepareEmailForm()
    this.isNotReset = true
    this.setupScheduleForm()
    if (this.policy.remediationType === RemediationType.ScheduleReport) {
      this.formSendReport.patchValue({
        format: this.policy?.scheduleReport?.format,
        emailBody: this.policy?.scheduleReport?.text,
        sendReport: this.policy?.scheduleReport?.sendWhen,
        recipients: this.policy?.scheduleReport?.recipients?.map((x) => ({ value: x, displayValue: x })),
      })
    }
    if (this.policy.remediationType === RemediationType.SendNotification) {
      this.formSendNotification.patchValue({
        notificationRecipients: this.policy?.notificationRecipients?.map((x) => ({ value: x, displayValue: x })) || [],
      })
    }
    if (!this.policy.isPolicyEnabled || !this.policy.isWorkflowEnabled) {
      this.formSendReport.disable()
      this.formSchedule.get('recurrenceData')?.disable()
    }
    this.refreshForms()
    this.policyToShow = { ...this.policy }
  }

  private preparePolicyData(): void {
    this.treeFilters = this.policy.reportTreeFilters
    this.reportDefinition = {
      ...this.policy.reportDefinition,
      sortOrder: this.policy.reportDefinition?.sortOrder ?? null,
      treeFilters: this.policy?.policyType === 'EventBased' ? this.treeFilters : undefined,
      rowSelection: 'none',
      entity: undefined,
    }
    this.selectedSchedule = this.policy.scheduleType ?? ScheduleType.None
  }

  private subscribeToRecurrenceDataChanges(): void {
    this.formSchedule
      .get('recurrenceData')
      ?.valueChanges.pipe(
        filter((x) => !!x),
        distinctUntilChanged(isEqual),
        generateCronExpression()
      )
      .subscribe((newValue) => {
        this.cronExpression = newValue.cronExpression
      })
  }

  private setJsonFormDataPolicy(): void {
    this.dynamoFormsHelper.getFormattedJsonFormData(this.policy.policySchema).subscribe((formData) => {
      if (formData) {
        this.jsonFormDataPolicy = formData
      }
    })
  }

  private setJsonFormDataWorkflow(): void {
    this.dynamoFormsHelper.getFormattedJsonFormData(this.policy.workflowSchema).subscribe((formData) => {
      if (formData) {
        this.setNewComponents(formData)

        this.jsonFormDataWorkflow = formData
      }
    })
  }

  private prepareEmailForm(): void {
    this.formEmail.patchValue(this.policy)
    this.formEmail.markAsPristine()

    if (this.policy.sendEmailWhenWorkflowFails) {
      this.formEmail.get('emailAddressWhenWorkflowFails')?.addValidators(Validators.required)
    }
  }

  private setNewComponents(formData: EnhancedJsonFormData | EnhancedPropertyFormDescriptor | undefined) {
    Object.entries(formData?.properties || {})?.forEach(([v, p]) => {
      if (['extended-rich-input', 'extended-input'].includes(p.format || '')) {
        p.options = {
          customType: p.format === 'extended-rich-input' ? ExtendedRichInputComponent : NewExtendedInputComponent,
          customData: {
            bindValues: this.policy.reportFieldsForWorkflow?.map((x) => ({ property: x, title: this.translateHelper.instant(x) })),
            defaultValue: p.default,
            headerName: this.translateHelper.instant(p.title || ''),
            label: this.translateHelper.instant(p.title || ''),
            froalaOptions: {
              zIndex: 999999,
              key: 'qc1H2pF1B2A1B5D6D5F4hBi1a2d2Za1IXBh1f1THTBPLIIWBORpF1F1E1I4F1C11A8C2B5C3==',
              toolbarButtons: froalaToolbarButtons,
              charCounterCount: false,
              imageUploadURL: this.apiClient.baseCentralUrl + 'api/images?format=json',
              imageUploadMethod: 'POST',
              imageManagerLoadURL: this.apiClient.baseCentralUrl + 'api/images?format=json',
              imageManagerLoadMethod: 'GET',
              imageManagerDeleteURL: this.apiClient.baseCentralUrl + 'api/images?format=json',
              imageManagerDeleteMethod: 'DELETE',
              imageMaxSize: 10 * 1024 * 1024,
              imageOutputSize: true,
              requestWithCORS: false,
              requestHeaders: {
                Authorization: 'Bearer ' + this.storage.authToken,
                'x-scompany':
                  this.storage.selectedOrganization.id + '|' + btoa(encodeURIComponent(this.storage.selectedOrganization.text)) + '--',
              },
            },
          },
        }
        p.format = 'custom'
        p.type = 'object'
      }
      if (p?.properties) {
        this.setNewComponents(p)
      }
    })
  }

  private setupScheduleForm(): void {
    if (
      (this.policy.scheduleType === ScheduleType.Scheduled && this.policy.schedule) ||
      this.policy.remediationType === RemediationType.ScheduleReport
    ) {
      this.policy.scheduleType = this.policy.isWorkflowEnabled ? ScheduleType.Scheduled : ScheduleType.None
      this.populateRecurrence()
      this.formSchedule.get('recurrenceData')?.markAsPristine()
      this.isDateSetFromDb = true
    }
    this.setStartingScheduleValues()
  }

  private setStartingScheduleValues() {
    if (this.policy?.schedule) {
      this.startingScheduleValues = {
        startDatetime: this.policy.schedule?.startDatetime ? dayjs(this.policy.schedule?.startDatetime) : null,
        endDatetime: this.policy.schedule?.endDatetime ? dayjs(this.policy.schedule?.endDatetime) : null,
        cronExpression: this.cronExpression ?? '',
      }
    } else {
      this.startingScheduleValues = {
        startDatetime: this.policy.scheduleReport?.startTime ? dayjs(this.policy.scheduleReport.startTime) : null,
        endDatetime: this.policy.scheduleReport?.endTime ? dayjs(this.policy.scheduleReport.endTime) : null,
        cronExpression: this.cronExpression ?? '',
      }
    }
  }

  private populateRecurrence(): void {
    if (this.policy.remediationType === RemediationType.ScheduleReport) {
      const recurrence = this.policyService.createRecurrenceFromScheduleReport(this.policy)
      this.formSchedule.get('recurrenceData')?.patchValue(recurrence)
    } else if (this.policy?.schedule?.cronExpression && this.policy.remediationType !== RemediationType.ScheduleReport) {
      this.cronExpression = this.policy?.schedule?.cronExpression
      const recurrence = this.policyService.createRecurrenceFromCronExpression(
        this.cronExpression,
        this.policy.schedule.startDatetime,
        this.policy.schedule.endDatetime
      )
      this.formSchedule.get('recurrenceData')?.patchValue(recurrence)
    }
  }

  private prepareHiddenGrid() {
    this.store.select(selectReportsDefinition).subscribe((defs) => {
      if (!this.policy) {
        return
      }
      this.gridDefinition = {
        ...this.gridDefinition,
        ...this.policy.reportDefinition,
        sortOrder: this.policy.reportDefinition.sortOrder ?? null,
        apiUrl: this.policy.reportDefinition.url ?? '',
        entity: undefined,
        dbCollectionName: this.policy.reportDefinition.entity,
        entityDisplayField:
          this.policy.exceptionsDisplayFields && this.policy.exceptionsDisplayFields.length > 0
            ? this.policy.exceptionsDisplayFields[0]
            : undefined,
        defaultDaysFilter: this.rangeFilter?.days as any,
        since: this.rangeFilter?.since,
        to: this.rangeFilter?.to,
        initialUrlFilters: this.policy.reportFilters,
        treeFilters: this.treeFilters,
        urlParameters: this.policy.urlParameters,
        targetEntity: lodashFisrt(
          uniq(
            values(defs)
              .filter(
                (x) =>
                  (x?.url?.toLocaleLowerCase() === this.policy?.reportDefinition.url?.toLocaleLowerCase() ||
                    x?.url?.toLocaleLowerCase() === '/' + this.policy?.reportDefinition.url?.toLocaleLowerCase()) &&
                  !!x?.targetEntity
              )
              .map((x) => x?.targetEntity)
          )
        ),
      }
    })

    this.rangeFilter =
      !!this.policy.timeRangeFilter?.days || this.policy.timeRangeFilter?.since || this.policy.timeRangeFilter?.to
        ? {
            days: this.policy.timeRangeFilter?.days as any,
            since: this.policy.timeRangeFilter?.since,
            to: this.policy.timeRangeFilter?.to,
          }
        : undefined
  }
}
