/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthenticatedUser } from '../models/authenticated-user'
import { AuthHelperService } from './auth.helper.service'
import { environment } from '@environments/environment'
import { ApiclientService } from './apiclient.service'
import { LocalstorageService } from './localstorage.service'
import { Observable, ReplaySubject } from 'rxjs'
import { catchError, pluck } from 'rxjs/operators'
import { GlobalFilter } from '../models/GlobalFilter'
import { LoggerService } from './logger.service'
import { UserProfileService } from './user-profile.service'
import { Router } from '@angular/router'
import { TranslateHelper } from '@coreview/coreview-library'
import { loadTranslator } from '@app/app.translation-loader'
import { Constants } from '@app/shared/utilities/constants'
import { RootState } from '@app/store/RootState.type'
import { Store } from '@ngrx/store'
import { getOrganizationByIdResponse } from '@app/store/organizations/organizations.actions'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  loginCompleted$ = new ReplaySubject(1)
  loginStatus = -1

  constructor(
    private httpClient: HttpClient,
    private authHelperService: AuthHelperService,
    private apiclient: ApiclientService,
    private storage: LocalstorageService,
    private loggerService: LoggerService,
    private userProfileService: UserProfileService,
    private router: Router,
    private location: Location,
    private translateHelper: TranslateHelper,
    private store: Store<RootState>
  ) {}

  getLocationHref() {
    return this.location.href
  }

  setLocationHref(url: string) {
    this.location.href = environment.baseAuthUrl + 'login?returnUrl=' + encodeURIComponent(url)
  }

  gotoCentralAuth() {
    this.setLocationHref(this.getLocationHref())
    throw new Error('Unauthorized, redirect to login page')
  }

  async login() {
    const urlreferrer = this.getLocationHref()
    // if grantCode is present, it's already logged in on central auth
    if (urlreferrer.indexOf('grantCode=') > 0) {
      // after getting the grantCode, we can fetch the token
      await this.getTokenCentralizedLogin()
    } else {
      // if there's no grant code, we need to call central auth
      this.gotoCentralAuth()
    }
  }

  async getTokenCentralizedLogin() {
    try {
      this.storage.removeAuthToken()

      const code = this.authHelperService.getUrlParameterByName('grantCode')
      const state = this.authHelperService.getUrlParameterByName('state')
      const apiUrl = this.authHelperService.getUrlParameterByName('apiUrl')

      const params = new HttpParams().set('oauth_token', code)
      if (state) {
        params.set('oauth_verifier', state)
      }

      const options = {
        params,
        withCredentials: false,
      }

      //TODO manage all the errors from centralized
      const result = await this.httpClient.request<AuthenticatedUser>('post', apiUrl + '/api/auth/centralized', options).toPromise()
      this.storage.authToken = result.bearerToken
      this.apiclient.basePortalApiUrl = `${apiUrl}/api`
      this.apiclient.baseAuthApiUrl = `${environment.baseAuthUrl}api`
      await this.checkRole(result)
    } catch (error: any) {
      if (error?.error?.responseStatus?.errorCode === 'SubscriptionExpiredError') {
        if (error?.error?.responseStatus?.message) {
          const orgInfo = error?.error?.responseStatus.message.split('::') as string[]
          this.store.dispatch(
            getOrganizationByIdResponse({
              data: {
                guid: orgInfo[0],
                subscriptionLevel: orgInfo[1],
                organizationType: orgInfo[2],
              },
              selected: true,
            } as any)
          )
          this.setLoginStatus(479, JSON.stringify({ message: `login.getTokenCentralizedLogin`, details: error }))
        }
      } else {
        let ec = error?.error
        if (error?.error?.responseStatus?.errorCode === 'InternalServerError') {
          ec = 500
        }
        this.setLoginStatus(ec || 478, JSON.stringify({ message: `login.getTokenCentralizedLogin`, details: error }))
      }
    }
  }

  async checkRole(user: AuthenticatedUser) {
    let jwt: Record<string, any> = {}
    try {
      jwt = this.parseToken()
    } catch (error: any) {
        this.loginStatus = 475
        this.loginCompleted$.next(this.loginStatus)
        this.loggerService.logError('error: ' + JSON.stringify({ message: `base64 decode failed; error: 475`, details: error })).subscribe()
        throw error
    }
    if (jwt.roles && jwt.roles.length > 0) {
      if (jwt.roles.includes('Guest')) {
        this.setUserProfile({...user, organizationId: jwt.oid }, null)
        throw new ErrorEvent('User is Guest', { message: 'User is Guest', error: 473 })
      } else {
        this.getOperator().subscribe(
          (result) => {
            this.setUserProfile(user, result)
            this.authHelperService.gotoPage(this.location.href.substring(0, this.location.href.indexOf('grantCode') - 1))
          },
          (error) => {
            this.setLoginStatus(
              error?.status || 474,
              JSON.stringify({ message: `user profile get/set failed; error: 474`, details: error })
            )
            throw error
          }
        )
      }
    } else {
      throw new ErrorEvent('User has no roles', { message: 'User has no roles', error: 403 })
    }
  }

  public tokenIsExpired(token: string) {
    try {
      const jwt = JSON.parse(this.b64DecodeUnicode(token.split('.')[1]))
      const expdate = new Date(jwt.exp * 1000)
      return expdate < new Date()
    } catch (e) {
      this.loggerService.logError('error tokenIsExpired: ' + token).subscribe()
      throw e
    }
  }

  public checkHasAnyRole() {
    if (
      !this.storage.getLoggedUser().roles ||
      this.storage.getLoggedUser().roles.length === 0 ||
      !this.storage.getLoggedUser().organizationId
    ) {
      this.setLoginStatus(477, JSON.stringify({ message: `User ${this.storage.getLoggedUser().userName} has no roles` }))
      return false
    }
    this.loginStatus = 0
    this.loginCompleted$.next(this.loginStatus)
    return true
  }

  public isGuestUser() {
    return this.authHelperService.hasRole('Guest')
  }

  public parseToken(): Record<string, any> {
    let tkn = this.storage.authToken;
    if (!tkn) {
      throw new Error('AuthToken is missing');
    }
    try {
      return JSON.parse(this.b64DecodeUnicode(tkn.split('.')[1]));
    } catch (e) {
      tkn = this.authHelperService.padToNextMod4(tkn.split('.')[1]);
      try {
        return JSON.parse(this.authHelperService.base64Decode(tkn));
      } catch (error) {
        throw new Error('Failed to parse AuthToken');
      }
    }
  }

  getOperator() {
    const url = `${this.apiclient.basePortalApiUrl}/register`
    return this.httpClient.request<any>('get', url, { withCredentials: true })
  }

  setUserProfile(user: AuthenticatedUser, profile: AuthenticatedUser | null) {
    if (profile) {
      user.organizationName = profile.organizationName
      user.organizationId = profile.organizationId
      user.hashedUser = profile.hashedUser
      user.organizationType = profile.organizationType
      user.primaryEmail = profile.primaryEmail
      user.userName = profile.userName
      user.email = profile.email
      user.preferredLanguage = profile.preferredLanguage
    }

    if (user.preferredLanguage) {
      const isoLang = Constants.languages.find((l) => l.fullLabel === user.preferredLanguage)?.iso || Constants.languages[0].iso
      if (isoLang !== 'en') {
        this.storage.preferredLanguage = isoLang
        loadTranslator().subscribe(() => {
          this.translateHelper.use(isoLang)
        })
      }
    }
    this.storage.setLoggedUser(user)
  }

  logout() {
    this.storage.removeAll()
    this.authHelperService.gotoPage('/')
  }

  logoutAzure(): void {
    this.logout()
    this.location.href = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' + this.location.origin
  }

  getGlobalFilters(): Observable<GlobalFilter[]> {
    return this.getAllGlobalFilters()
  }

  goToFirstPage() {
    this.userProfileService.getFirstPage().subscribe((firstPage) => {
      if (firstPage !== '/') {
        this.router.navigate([firstPage]).catch((_: any) => _)
      }
    })
  }

  checkAvailability(): Observable<void> {
    return this.httpClient.post<void>(this.getAvailabilityUrl(), null, {
      withCredentials: true,
    })
  }

  getAvailabilityUrl() {
    return `${this.apiclient.basePortalApiUrl}/availability`
  }

  isBasePortalUrl(url: string): boolean {
    return url.startsWith(this.apiclient.basePortalApiUrl)
  }

  setTermsOfService(accepted: boolean, url: string) {
    return this.httpClient
      .post(
        `${this.apiclient.basePortalApiUrl}/organization/termsofservice`,
        { accepted, url },
        {
          withCredentials: true,
        }
      )
      .pipe(
        catchError((err) => {
          this.loggerService.logError('error: ' + JSON.stringify({ message: `login.setTermsOfService`, details: err })).subscribe()
          return err
        })
      )
  }

  getAllGlobalFilters(): Observable<GlobalFilter[]> {
    return this.httpClient
      .get(`${this.apiclient.basePortalApiUrl}/reportfilters`, {
        withCredentials: true,
        params: { filters: JSON.stringify({ TargetEntity: `=User` }), metadata: false },
      })
      .pipe(pluck('reportFilters'))
  }

  b64DecodeUnicode(jwt: string) {
    return decodeURIComponent(
      Array.prototype.map
        .call(
          atob(jwt.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, '')),
          (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        )
        .join('')
    )
  }

  setLoginStatus(loginStatus: number, error: string) {
    this.loginStatus = loginStatus
    this.loginCompleted$.next(this.loginStatus)
    this.loggerService.logError(`error: ${error}`).subscribe()
  }

  checkSecureByDefault(): Observable<boolean> {
    const url = `${this.apiclient.basePortalApiUrl}/checksecurebydefault`
    return this.httpClient.get<boolean>(url, { withCredentials: true })
  }
}
